import { FieldErrors } from "../../../../utils/validations/validations";

export const getProductReturnRequiredError = localHandlingData => {
  if (typeof localHandlingData.productReturnRequired === "undefined") {
    return {
      productReturnRequiredError: FieldErrors.PRODUCT_RETURN_REQUIRED_MANDATORY,
    };
  }
  return null;
};

export const getProductsHaveBeenReturnedError = localHandlingData => {
  if (typeof localHandlingData.productsHaveBeenReturned === "undefined") {
    return {
      productsHaveBeenReturnedError:
        FieldErrors.PRODUCTS_HAVE_BEEN_RETURNED_MANDATORY,
    };
  }
  return null;
};

export const getCompensationPermittedError = localHandlingData => {
  if (typeof localHandlingData.compensationPermitted === "undefined") {
    return {
      compensationPermittedError: FieldErrors.COMPENSATION_PERMITTED_MANDATORY,
    };
  }
  return null;
};
