import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton } from "@material-ui/core";
import { IconTextButton } from "@oriola-origo/origo-ui-core";
import { Add } from "@material-ui/icons";
import { Trashcan } from "../../../images";
import { OriolaColors } from "../../../theme";
import { updateLocalHandlingDataField } from "../../../redux";
import DateAndEditor from "../dateAndEditor";
import { TitleInput } from "../../common";
import { getSpdModificationData } from "../../../utils/getSpdModificationData/getSpdModificationData";
import { getValidationErrorMessage } from "../../../utils/validations/validations";
import Title from "../title/title";

const useStyles = makeStyles(() => ({
  controlContainer: {
    display: "flex",
    flexGrow: 1,
    position: "relative",
  },
}));

function PrincipalReferenceNumber({ disabled, user }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { localHandlingData = {} } = useSelector(state => state.case);
  const { principalReferenceNumberError } = useSelector(
    state => state.validationErrors
  );
  const { principalReferenceNumber = [], modifications = {} } =
    localHandlingData;

  const referenceNumbersModifications = getSpdModificationData(
    modifications,
    "principalReferenceNumber"
  );

  useEffect(() => {
    if (principalReferenceNumber.length === 0) {
      dispatch(updateLocalHandlingDataField("", "principalReferenceNumber", 0));
    }
  }, [dispatch, principalReferenceNumber.length]);

  const getModificationsDataForIndex = index =>
    referenceNumbersModifications != null
      ? referenceNumbersModifications[index]
      : null;

  return (
    <div id="principal-reference-number" className="handling-section">
      <Title title={t("principalReference.title")} />
      {principalReferenceNumber.map((prnValue, index) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          mb={index === principalReferenceNumber.length - 1 ? 0 : 2}
        >
          <div className={classes.controlContainer}>
            <Box width={540}>
              <TitleInput
                mt={0}
                disabled={disabled}
                inputClassName={disabled ? classes.input : null}
                value={prnValue}
                error={!!principalReferenceNumberError && !prnValue}
                title={t("principalReference.label")}
                secondaryTitle={t("optional")}
                onChange={value => {
                  dispatch(
                    updateLocalHandlingDataField(
                      value,
                      "principalReferenceNumber",
                      index
                    )
                  );
                }}
                rows={1}
                enableCopyButton
                helperText={getValidationErrorMessage(
                  principalReferenceNumberError,
                  t
                )}
              />
            </Box>
            {principalReferenceNumber.length > 1 && !disabled && (
              <IconButton
                id={`reference-number-remove-button-${index}`}
                size="small"
                style={{ position: "absolute", right: 2, bottom: "6px" }}
                onClick={() => {
                  const toBeReAdded = [...principalReferenceNumber].slice(
                    index + 1
                  );
                  const theEnd = principalReferenceNumber.length;
                  for (let idx = index; idx < theEnd; idx += 1) {
                    dispatch(
                      updateLocalHandlingDataField(
                        undefined,
                        "principalReferenceNumber",
                        idx
                      )
                    );
                  }
                  toBeReAdded.forEach((refno, offset) => {
                    dispatch(
                      updateLocalHandlingDataField(
                        refno,
                        "principalReferenceNumber",
                        index + offset
                      )
                    );
                  });
                }}
              >
                <Trashcan color="primary" />
              </IconButton>
            )}
          </div>
          {prnValue !== "" && (
            <DateAndEditor
              id={`reference-number-date-editor-${index}`}
              modificationData={getModificationsDataForIndex(index)}
              user={user}
            />
          )}
        </Box>
      ))}
      {!disabled && (
        <Box display="flex" justifyContent="flex-end">
          <IconTextButton
            text={t("addNew")}
            id="reference-number-add-new-button"
            onClick={() => {
              const nextIndex = principalReferenceNumber.length;
              dispatch(
                updateLocalHandlingDataField(
                  "",
                  "principalReferenceNumber",
                  nextIndex
                )
              );
            }}
            icon={<Add style={{ color: OriolaColors.primaryOrange }} />}
          />
        </Box>
      )}
    </div>
  );
}
PrincipalReferenceNumber.propTypes = {
  disabled: PropTypes.bool,
  user: PropTypes.shape({}),
};

PrincipalReferenceNumber.defaultProps = {
  disabled: false,
  user: {},
};
export default PrincipalReferenceNumber;
