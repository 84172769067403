import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, Box, makeStyles } from "@material-ui/core";
// eslint-disable-next-line import/no-cycle
import { FieldTitle } from "..";
import { StatefullTextField } from "../../../common";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";

const useStyles = makeStyles(theme => ({
  quantity: {
    "&:focus-within": {
      color: theme.palette.primary.main,
    },
  },
}));

function QuantityField({
  itemData,
  id,
  fieldName,
  error,
  disabled,
  config,
  updateItem,
  updateItemValidationError,
  description,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [showDescription, setShowDescription] = useState(false);

  return (
    <Box width={250}>
      <FieldTitle
        title={t(`caseItemFields.${fieldName}`)}
        optional={config.optional}
      />
      <div className={classes.quantity}>
        <StatefullTextField
          initialValue={itemData[fieldName] || ""}
          error={!!error}
          helperText={getValidationErrorMessage(error, t)}
          variant="outlined"
          margin="dense"
          disabled={disabled}
          onChange={quantityVal => {
            dispatch(
              updateItemValidationError(itemData.uuid, { [fieldName]: "" })
            );
            dispatch(
              updateItem({
                ...itemData,
                [fieldName]: quantityVal,
              })
            );
          }}
          id={`${id}-${fieldName}`}
          onFocus={() => setShowDescription(true)}
          onBlur={() => setShowDescription(false)}
        />
        {description && showDescription === true && (
          <Typography>{description}</Typography>
        )}
      </div>
    </Box>
  );
}

QuantityField.propTypes = {
  itemData: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  id: PropTypes.string,
  fieldName: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  config: PropTypes.shape({
    optional: PropTypes.bool,
  }),
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
  description: PropTypes.string,
};

QuantityField.defaultProps = {
  itemData: {},
  id: "",
  fieldName: "",
  error: "",
  disabled: false,
  config: {},
  updateItem: null,
  updateItemValidationError: null,
  description: "",
};

export default QuantityField;
