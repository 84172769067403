import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@oriola-origo/origo-ui-core";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  SuspectedProductDefectFields,
  DamagedProductFields,
  ProductDeliveryFields,
} from "../../fields";
import { getCaseConfig } from "../../../utils/case/case";
import { CaseHeading } from "../../common";
import { CaseType } from "../../../constants/caseType";
import {
  validateSPDFields,
  validateEditableFields,
} from "../../../utils/validations/validations";
import { setValidationError, updateValidationError } from "../../../redux";
import scrollToError from "../../../utils/scrollToView/scrollToView";
import { confirmAndSubmitCase } from "../../draft/caseActions";
import { Paths } from "../../../utils/navigation/navigation";

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(2),
    "&:first-child": {
      marginLeft: "auto",
    },
    "&:disabled": {
      background: theme.palette.grey[500],
    },
  },
}));

function EditCopiedCase({ reclamationCase, onCancel, onSubmitCallback }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    DamagedProductConfig,
    ProductReturnReasonIdentifiersConfig,
    SuspectedProductDefectConfig,
  } = useSelector(state => state.fieldsConfig);
  const history = useHistory();
  const contactInfo = useSelector(state => state.contactInfo);
  const { caseType, productReturn, reasonIdentifier } = reclamationCase;
  const caseConfig = getCaseConfig(
    caseType,
    productReturn,
    reasonIdentifier,
    DamagedProductConfig,
    ProductReturnReasonIdentifiersConfig,
    SuspectedProductDefectConfig
  );
  const getTitle = () => {
    if (caseType === CaseType.SUSPECTED_PRODUCT_DEFECT) {
      return t("category.productDefect.header");
    }
    if (caseType === CaseType.DAMAGED_PRODUCT) {
      return t("category.damagedProduct.header");
    }
    return t("category.productOrDelivery.header");
  };

  const dispatch = useDispatch();

  const validate = () => {
    let success = true;
    const errorState = {};
    if (caseType === CaseType.SUSPECTED_PRODUCT_DEFECT) {
      validateSPDFields({
        contactInfo,
        reclamationCase,
        errorState,
        caseConfig,
      });
    } else {
      validateEditableFields({
        contactInfo,
        reclamationCase,
        errorState,
        caseConfig,
      });
    }
    dispatch(updateValidationError(errorState));
    dispatch(setValidationError(errorState));
    if (Object.keys(errorState).length > 0) {
      success = false;
    }
    scrollToError(errorState);
    return success;
  };

  const onSubmit = () => {
    if (validate()) {
      confirmAndSubmitCase(t, dispatch, reclamationCase, data => {
        history.push(Paths.Case.replace(":reclamationId", data.reclamationId));
        onSubmitCallback();
      });
    }
  };

  const renderButtons = () => (
    <Box display="flex" mt={3}>
      <Button
        className={classes.button}
        variant="secondary"
        onClick={onCancel}
        id="copy-case-cancel"
      >
        {t("cancel")}
      </Button>
      <Button
        className={classes.button}
        id="copy-case-submit"
        onClick={onSubmit}
      >
        {t("submit")}
      </Button>
    </Box>
  );

  return (
    <div className={classes.container}>
      <CaseHeading leftText={t("newCase")} rightText={getTitle()} />
      {caseType === CaseType.SUSPECTED_PRODUCT_DEFECT && (
        <SuspectedProductDefectFields
          reclamationCase={reclamationCase}
          isNewCase={false}
          caseConfig={caseConfig}
        />
      )}
      {caseType === CaseType.DAMAGED_PRODUCT && (
        <DamagedProductFields
          reclamationCase={reclamationCase}
          isNewCase={false}
          isConvertedCase
          caseConfig={caseConfig}
        />
      )}
      {caseType === CaseType.PRODUCT_DELIVERY && (
        <ProductDeliveryFields
          reclamationCase={reclamationCase}
          isNewCase={false}
          isConvertedCase
          caseConfig={caseConfig}
        />
      )}
      {renderButtons()}
    </div>
  );
}

EditCopiedCase.propTypes = {
  reclamationCase: PropTypes.shape({
    caseType: PropTypes.string,
    productReturn: PropTypes.bool,
    reasonIdentifier: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  onSubmitCallback: PropTypes.func,
};

EditCopiedCase.defaultProps = {
  reclamationCase: {},
  onCancel: null,
  onSubmitCallback: null,
};

export default EditCopiedCase;
