import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { TooltipTypography } from "@oriola-origo/origo-ui-core";

const useStyles = makeStyles(theme => ({
  columnValueText: {
    color: theme.palette.text.primary,
    width: "100%",
    ...theme.typography.button,
    textTransform: "none",
  },
}));

function CaseStatusCell({
  singleCase,
  currentUserIsOriolaUser,
  currentUserIsCustomer,
  ...rest
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { caseStatus, caseSubStatus } = singleCase;

  let caseStatusText = t(`caseStatusDetail.${caseStatus}`);
  let subStatusText = "";
  if (caseSubStatus && (currentUserIsOriolaUser || currentUserIsCustomer)) {
    subStatusText = t(`changeSubStatus.${caseSubStatus}`);
    caseStatusText = `${caseStatusText} - ${subStatusText}`;
  }

  return (
    <Box {...rest}>
      {currentUserIsOriolaUser || currentUserIsCustomer ? (
        <TooltipTypography
          noWrap
          className={classes.columnValueText}
          tooltip={subStatusText}
        >
          {caseStatusText}
        </TooltipTypography>
      ) : (
        <Typography noWrap className={classes.columnValueText}>
          {caseStatusText}
        </Typography>
      )}
    </Box>
  );
}

CaseStatusCell.propTypes = {
  singleCase: PropTypes.shape({
    caseStatus: PropTypes.string,
    caseSubStatus: PropTypes.string,
  }),
  currentUserIsOriolaUser: PropTypes.bool,
  currentUserIsCustomer: PropTypes.bool,
};

CaseStatusCell.defaultProps = {
  singleCase: {},
  currentUserIsOriolaUser: false,
  currentUserIsCustomer: false,
};

export default CaseStatusCell;
