import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { OrigoAuthenticator } from "@oriola-origo/origo-ui-core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { rest as RestService } from "@oriola-origo/origo-common-client-lib";
import NotFound from "./components/404/404";
import Layout from "./components/layout";
import { Paths } from "./utils/navigation/navigation";
import CasesView from "./components/cases";
import CategorySelection from "./components/categorySelection";
import CaseView from "./components/draft/caseView";
import SubmittedCaseView from "./components/case/caseView";
import EditCaseView from "./components/editCase/caseView";
import NewCaseView from "./components/newCase/newCaseView";
import {
  Permission,
  ANY_CUSTOMER,
  ProtectedRoute,
  getRolesByCustomerValidity,
  mapGlobalRoles,
} from "./components/auth";
import {
  fetchCustomers,
  userSignIn,
  userSignOut,
  fetchUserDataFromOrigo,
  updateUserData,
  fetchFieldsConfig,
  setTokenData,
} from "./redux";

function App() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { userData } = user;
  const { i18n } = useTranslation();
  const [loadingPanel, setLoadingPanel] = useState(true);
  const [restInitialized, setRestInitialized] = useState(false);
  const [loginData, setLoginData] = useState(null);

  if (user.tokenData && user.sessionId) {
    RestService.init(user, res => {
      setRestInitialized(true);
      dispatch(setTokenData(res));
    });
  }

  useEffect(() => {
    if (loginData) {
      dispatch(userSignIn(loginData));

      if (restInitialized) {
        dispatch(fetchFieldsConfig());
        const { userId, organizationIds, rolesByOrgId } = loginData.userData;
        dispatch(fetchCustomers(organizationIds)).then(customers => {
          // update roles by customer validity
          const valid = getRolesByCustomerValidity(customers, rolesByOrgId);
          const validWithGlobal = mapGlobalRoles(valid);

          dispatch(
            updateUserData({
              ...loginData.userData,
              roles: validWithGlobal.roles,
              rolesByOrgId: validWithGlobal.rolesByOrgId,
            })
          );
          setLoadingPanel(false);
        });

        dispatch(fetchUserDataFromOrigo(userId)).then(origoUserData => {
          if (origoUserData && origoUserData.language) {
            i18n.changeLanguage(origoUserData.language);
          }
        });
      }
    }
  }, [restInitialized, loginData, dispatch, i18n]);

  const renderApp = () => (
    <Layout loadingPanel={loadingPanel}>
      <Switch>
        <Route exact path="/" component={CasesView} />
        <Route path={Paths.Cases} component={CasesView} />
        <ProtectedRoute
          exact
          path={Paths.NewCase}
          component={CategorySelection}
          failComponent={NotFound}
          user={userData}
          requiredPermission={Permission.CASE_CREATE}
          customerContext={ANY_CUSTOMER}
        />
        <ProtectedRoute
          path={Paths.NewCaseOfType}
          component={NewCaseView}
          failComponent={NotFound}
          user={userData}
          requiredPermission={Permission.CASE_CREATE}
          customerContext={ANY_CUSTOMER}
        />
        <Route path={Paths.Case} component={SubmittedCaseView} />
        <Route path={Paths.Draft} component={CaseView} />
        <ProtectedRoute
          path={Paths.EditCase}
          component={EditCaseView}
          failComponent={NotFound}
          user={userData}
          requiredPermission={Permission.CASE_UPDATE}
          customerContext={ANY_CUSTOMER}
        />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );

  const renderAuthApp = () => (
    <OrigoAuthenticator
      isSignedIn={user.signedIn}
      onSignedIn={signInData => setLoginData(signInData)}
      onSignedOut={() => {
        // clear
        dispatch(userSignOut());
      }}
    >
      {renderApp()}
    </OrigoAuthenticator>
  );

  return renderAuthApp();
}

export default App;
