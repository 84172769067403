import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Dropdown } from "@oriola-origo/origo-ui-core";
import { Box, TextField, Typography } from "@material-ui/core";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";
// eslint-disable-next-line import/no-cycle
import { FieldTitle } from "..";
import { updateValidationError, updateCase } from "../../../../redux";

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: "0",
  },
  subTitle: {
    ...theme.typography.body1,
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(6),
  },
  defectedComment: {
    maxWidth: "859px",
  },
  formControl: {
    marginBottom: theme.spacing(5),
  },
}));

function DefectDetectedBy({ disabled, reclamationCase, caseConfig }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { detectedBy } = reclamationCase;
  const { t } = useTranslation();
  const { detectedByError } = useSelector(state => state.validationErrors);

  const updateDefectDetectedBy = value => {
    dispatch(updateValidationError({ detectedByError: "" }));
    dispatch(updateCase({ detectedBy: { ...detectedBy, code: value } }));
    if (value !== "OTHER") {
      dispatch(updateCase({ detectedBy: { code: value } }));
    }
  };

  const handleInternalMessageChanged = event => {
    dispatch(
      updateCase({
        detectedBy: { ...detectedBy, description: event.target.value },
      })
    );
  };
  return (
    <div className={classes.formControl} data-error-name="detectedByError">
      <FieldTitle
        className={classes.title}
        title={t("caseInformation.defectDetectedBy.title")}
      />
      <Typography className={classes.subTitle}>
        {t("caseInformation.defectDetectedBy.subtitle")}
      </Typography>
      <Box maxWidth="32rem">
        <Dropdown
          disabled={disabled}
          name="detectedByError"
          error={!!detectedByError}
          value={(detectedBy && detectedBy.code) || ""}
          errorText={getValidationErrorMessage(detectedByError, t)}
          className={classes.inputField}
          emptySelectionLabel={t("select")}
          onFormatOptionLabel={x => t(`caseInformation.detectedBy.${x}`)}
          options={caseConfig.detectedBy.code.enumerated}
          onSelectOption={x => {
            updateDefectDetectedBy(x);
          }}
          showSelectNone
          selectNoneLabel={t("selectNone")}
          id="new-case-detected-by"
        />
      </Box>
      {detectedBy && detectedBy.code === "OTHER" && (
        <Box mb={4}>
          <TextField
            className={classes.defectedComment}
            value={(detectedBy && detectedBy.description) || ""}
            onChange={event => handleInternalMessageChanged(event)}
            id="new-case-detected-by-description"
            fullWidth
            multiline
            rows="3"
            variant="outlined"
            placeholder={t("caseInformation.defectDetectedBy.comment")}
            margin="dense"
          />
        </Box>
      )}
    </div>
  );
}

DefectDetectedBy.propTypes = {
  classes: PropTypes.shape({}),
  reclamationCase: PropTypes.shape({
    detectedBy: PropTypes.shape({
      code: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  reasons: PropTypes.shape({}),
  disabled: PropTypes.bool,
  caseConfig: PropTypes.shape({
    detectedBy: PropTypes.shape({
      code: PropTypes.shape({
        enumerated: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
};

DefectDetectedBy.defaultProps = {
  disabled: false,
  reasons: {},
  caseConfig: {},
  reclamationCase: {
    detectedBy: {
      code: "",
      description: "",
    },
  },
  classes: {},
};

DefectDetectedBy.displayName = "DefectteDetectedBy";

export default DefectDetectedBy;
