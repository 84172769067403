import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Divider, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { WarningMessage } from "@oriola-origo/origo-ui-core";
import { CaseType } from "../../../constants/caseType";
// eslint-disable-next-line import/no-cycle
import { ContactInfo } from "../index";
import {
  Attachments,
  InformationConfirmation,
  ProductReturn,
  CaseItems,
  ReasonIdentifier,
  ReturnPackagesAmount,
} from "../common";
import { shouldShowSubmitFields } from "../../../utils/case/case";

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textTransform: "uppercase",
    fontSize: "1rem",
    color: "#2b3e5b",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  titleText: {
    ...theme.typography.subtitle2,
    color: "#757070",
    marginBottom: theme.spacing(1),
  },
  textField: {
    height: theme.spacing(5),
    width: 300,
  },
}));

function ProductDeliveryFields({
  reclamationCase,
  isNewCase,
  disabled,
  caseConfig,
  editMode,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { reasonIdentifier } = useSelector(state => state.case.case);
  const [reasons, setReasons] = useState([]);
  const { ProductReturnReasonIdentifiersConfig } = useSelector(
    state => state.fieldsConfig
  );
  const { userData } = useSelector(state => state.user);
  const contactInfo = useSelector(state => state.contactInfo);
  const [attachmentError, setAttachmentError] = useState(null);

  const productReturnSelected = useCallback(() => {
    const safeCase = reclamationCase || {};
    const safeProductReturn = safeCase.productReturn || {};
    return safeProductReturn.returnValue != null;
  }, [reclamationCase]);

  const getReasons = useCallback(
    productReturnVal => {
      if (!ProductReturnReasonIdentifiersConfig) {
        return [];
      }
      if (productReturnVal === true) {
        return Object.keys(
          ProductReturnReasonIdentifiersConfig.willReturnProduct
        );
      }
      if (productReturnVal === false) {
        return Object.keys(
          ProductReturnReasonIdentifiersConfig.noProductReturn
        );
      }
      return [];
    },
    [ProductReturnReasonIdentifiersConfig]
  );

  useEffect(() => {
    if (productReturnSelected()) {
      setReasons(getReasons(reclamationCase.productReturn.returnValue));
    }
  }, [reclamationCase.productReturn, getReasons, productReturnSelected]);

  return (
    <div>
      <ContactInfo
        customerId={reclamationCase.customerId}
        deliveryAddressId={reclamationCase.deliveryAddressId}
        contactPerson={reclamationCase.contactPersonName}
        phoneNumber={reclamationCase.contactPhoneNumber}
        disabled={disabled}
        isNewCase={isNewCase}
      />
      <Divider className={classes.divider} />
      <Typography className={classes.title} variant="h5" color="secondary">
        {t("caseInformation.caseInformation")}
      </Typography>
      <ProductReturn disabled={disabled} editMode={editMode} my={4} />
      {productReturnSelected() === true && (
        <ReasonIdentifier
          reclamationCase={reclamationCase}
          reasons={reasons}
          editMode={editMode}
          mb={4}
          disabled={disabled}
        />
      )}
      {caseConfig && caseConfig.warning && (
        <Box mb={2} width="470px">
          <WarningMessage
            text={t(`productDeliveryWarning.${reasonIdentifier}`)}
            type="warning"
          />
        </Box>
      )}
      <CaseItems disabled={disabled} caseConfig={caseConfig} />
      {caseConfig && caseConfig.attachments && (
        <>
          <Attachments
            reclamationCase={reclamationCase}
            existingAttachments={reclamationCase.attachments || []}
            id="reclamation-file-upload"
            attachmentError={attachmentError}
            setAttachmentError={setAttachmentError}
            disabled={disabled}
          />
          <Divider className={classes.divider} />
        </>
      )}
      {shouldShowSubmitFields(reclamationCase, contactInfo, userData) && (
        <>
          {caseConfig && caseConfig.packageAmount && (
            <Box mb={3}>
              <ReturnPackagesAmount />
            </Box>
          )}
          <InformationConfirmation />
        </>
      )}
    </div>
  );
}

ProductDeliveryFields.propTypes = {
  history: PropTypes.shape({}),
  reclamationCase: PropTypes.shape({
    caseType: PropTypes.string,
    customerId: PropTypes.string,
    deliveryAddressId: PropTypes.string,
    contactPersonName: PropTypes.string,
    contactPhoneNumber: PropTypes.string,
    productReturn: PropTypes.shape({
      returnValue: PropTypes.bool,
    }),
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isNewCase: PropTypes.bool,
  disabled: PropTypes.bool,
  contactInfoRef: PropTypes.shape({}),
  caseConfig: PropTypes.shape({
    caseDescription: PropTypes.string,
    attachments: PropTypes.bool,
    packageAmount: PropTypes.bool,
    warning: PropTypes.bool,
  }),
  editMode: PropTypes.bool,
};

ProductDeliveryFields.defaultProps = {
  reclamationCase: { caseType: CaseType.DAMAGED_PRODUCT },
  isNewCase: false,
  disabled: false,
  contactInfoRef: {},
  caseConfig: {},
  editMode: false,
  history: {},
};

export default ProductDeliveryFields;
