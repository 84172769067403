import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Title from "../title/title";
import { getFormattedDateTime } from "../../../utils/date/date";
import Row from "../row/row";
import { CaseStatus as CaseStatusConstants } from "../../../constants/caseStatus";
import { Paths } from "../../../utils/navigation/navigation";
import { isPharmaceuticalCompany, isCustomer } from "../../auth/permission";

const useStyles = makeStyles(theme => ({
  relatedCase: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
  },
}));

function CaseStatus({ reclamationCase, currentUserIsOriolaUser }) {
  const { userData } = useSelector(state => state.user);
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    submissionTime,
    modificationTime,
    creator,
    modifier,
    caseStatus,
    caseSubStatus,
  } = reclamationCase;

  const createArr = [];
  const creationText =
    submissionTime && getFormattedDateTime(moment(submissionTime));
  createArr.push(creationText);
  if (creator && creator.userName) {
    createArr.push(creator.userName);
  }

  const updateArr = [];
  const showModified = submissionTime < modificationTime;
  const updateText =
    showModified && getFormattedDateTime(moment(modificationTime));
  updateArr.push(updateText);
  if (modifier && modifier.userName) {
    updateArr.push(modifier.userName);
  }

  const relatedCases = reclamationCase.relatedCases || [];
  const renderRelatedCases = () => {
    if (relatedCases.length === 0) {
      return null;
    }
    let rowRightComponent = "";
    if (!isPharmaceuticalCompany(userData)) {
      rowRightComponent = relatedCases.map((relatedCase, index) => {
        const link = Paths.Case.replace(
          ":reclamationId",
          relatedCase.reclamationId
        );
        return (
          <Link
            to={link}
            key={relatedCase.caseNumber}
            className={classes.relatedCase}
          >
            {relatedCase.caseNumber}
            {index === relatedCases.length - 1 ? "" : ","}
          </Link>
        );
      });
    }
    return (
      <Row rowLeft={["relatedCases"]} rowRightComponent={rowRightComponent} />
    );
  };

  let caseStatusText;
  if (caseStatus === CaseStatusConstants.OPEN_SUBMITTED) {
    caseStatusText = t("open");
  } else if (caseStatus === CaseStatusConstants.SOLVED) {
    caseStatusText = t("solved");
  } else if (
    caseStatus === CaseStatusConstants.OPEN_INFORMATION_REQUESTED_FROM_CUSTOMER
  ) {
    caseStatusText = t("openInformation");
  }

  if (caseSubStatus && (currentUserIsOriolaUser || isCustomer(userData))) {
    const subStatusText = t(`changeSubStatus.${caseSubStatus}`);
    caseStatusText = `${caseStatusText} - ${subStatusText}`;
  }

  return (
    <div className={classes.root} id="case-status">
      <Title title={t("caseStatus")} />
      <Row rowLeft={["caseStatus"]} rowRight={[t(caseStatusText)]} />
      <Row rowLeft={["created"]} rowRight={createArr} />
      {showModified && <Row rowLeft={["modified"]} rowRight={updateArr} />}
      {renderRelatedCases()}
    </div>
  );
}

CaseStatus.propTypes = {
  reclamationCase: PropTypes.shape({
    submissionTime: PropTypes.string,
    modificationTime: PropTypes.string,
    creator: PropTypes.shape({
      userName: PropTypes.string,
    }),
    modifier: PropTypes.shape({
      userName: PropTypes.string,
    }),
    caseStatus: PropTypes.string,
    caseSubStatus: PropTypes.string,
    relatedCases: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  currentUserIsOriolaUser: PropTypes.bool,
};

CaseStatus.defaultProps = {
  reclamationCase: {},
  currentUserIsOriolaUser: false,
};

export default CaseStatus;
