import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { Checkbox, FormControlLabel } from "@oriola-origo/origo-ui-core";
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line import/no-cycle
import { StorageSelect } from "..";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";
import SpecialFiledValue from "../../../../constants/specialFieldValue";

const useStyles = makeStyles(() => ({
  checkBox: {
    paddingTop: "1px",
  },
}));

function Stored({
  itemData,
  classes,
  error,
  disabled,
  id,
  updateItem,
  updateItemValidationError,
  config,
}) {
  const ownClasses = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [notKnownChecked, setNotKnownChecked] = useState(false);

  useEffect(() => {
    if (itemData.stored === SpecialFiledValue.NOT_AVAILABLE) {
      setNotKnownChecked(true);
    } else {
      setNotKnownChecked(false);
    }
  }, [itemData.stored]);

  const handleStorageSelectionChange = option => {
    dispatch(updateItemValidationError(itemData.uuid, { stored: "" }));
    dispatch(updateItem({ ...itemData, stored: option }));
  };

  return (
    <Box width="300px">
      <StorageSelect
        classes={classes}
        options={[...config.enumerated]}
        disabled={disabled || notKnownChecked === true}
        error={getValidationErrorMessage(error, t)}
        id={id}
        value={notKnownChecked === true ? "" : itemData.stored}
        onSelectOption={handleStorageSelectionChange}
      />
      {config.notAvailableConfirm && (
        <Box mt={0.5}>
          <FormControlLabel
            control={
              <Checkbox
                className={ownClasses.checkBox}
                checked={notKnownChecked}
                onChange={event => {
                  dispatch(
                    updateItem({
                      ...itemData,
                      stored: event.target.checked
                        ? SpecialFiledValue.NOT_AVAILABLE
                        : "",
                    })
                  );
                  dispatch(
                    updateItemValidationError(itemData.uuid, { stored: "" })
                  );
                }}
                id={`${id}-stored-not-known`}
              />
            }
            label={t("storageTemperature.-")}
            disabled={disabled}
          />
        </Box>
      )}
    </Box>
  );
}

Stored.propTypes = {
  classes: PropTypes.shape({}),
  error: PropTypes.string,
  disabled: PropTypes.bool,
  itemData: PropTypes.shape({
    uuid: PropTypes.string,
    stored: PropTypes.string,
  }),
  id: PropTypes.string,
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
  config: PropTypes.shape({
    enumerated: PropTypes.arrayOf(PropTypes.string),
    notAvailableConfirm: PropTypes.bool,
  }),
};

Stored.defaultProps = {
  classes: {},
  error: "",
  disabled: false,
  itemData: {},
  id: "",
  updateItem: null,
  updateItemValidationError: null,
  config: {},
};

export default Stored;
