import {
  FieldErrors,
  validateCompensation,
  validateRejectReason,
  validatePermittedCompensation,
} from "../../../../utils/validations/validations";

export const getRejectReasonError = localHandlingData => {
  if (!validateCompensation(localHandlingData)) {
    return { rejectReasonError: FieldErrors.REJECTION_REASON_MANDATORY };
  }
  return null;
};
export const getRejectDescriptionError = localHandlingData => {
  if (!validateRejectReason(localHandlingData)) {
    return {
      rejectDescriptionError: FieldErrors.REJECTION_DESCRIPTION_MANDATORY,
    };
  }
  return null;
};

export const getCompensationsError = localHandlingData => {
  if (localHandlingData.compensationPermitted) {
    const compensationsValidationResult = validatePermittedCompensation(
      localHandlingData.compensations
    );

    if (
      compensationsValidationResult &&
      !compensationsValidationResult.result
    ) {
      return {
        compensationsError: compensationsValidationResult.errors,
      };
    }
  }
  return null;
};
