import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { OriolaTheme } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import {
  handleOptimisticLockError,
  isOptimisticLockError,
} from "../../../utils/optimisticLock/optimisticLock";
import {
  sendlocalHandlingData,
  clearlocalHandlingData,
  addNotification,
  SendingStatus,
  setLocalHandlingData,
} from "../../../redux";
import { CaseCreatedIcon } from "../../../images";
import {
  isOriolaUser,
  isPharmaceuticalCompanyEditRole,
} from "../../auth/permission";
import { getCaseHandlingData, isSpdCase } from "../../../utils/case/case";
import SpdHandlingDataEdit from "./spdHandlingDataEdit";
import DefaultHandlingDataEdit from "./DefaultHandlingDataEdit";
import {
  handleUpdateAttachmentsError,
  isUpdateAttachmentsError,
} from "../../../utils/updateAttachments/updateAttachments";

const useStyles = makeStyles(theme => ({
  container: {
    "& .handling-section": {
      padding: theme.spacing(4),
    },
    "& .storage-location-section": {
      padding: theme.spacing(4),
    },
    height: "100%",
  },
  productChipArea: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  productChip: {
    ...OriolaTheme.typography.body2,
    backgroundColor: "#F5F5F5",
    borderRadius: "8px",
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    maxWidth: "400px",
    textOverflow: "ellipsis",
  },
}));

function CompensationHandlingNotSolved({
  validateFullHandlingData,
  validateFilledFieldsHandlingData,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reclamationCase = useSelector(state => state.case.case);
  const { userData } = useSelector(state => state.user);
  const currentUserIsOriolaUser = isOriolaUser(userData);
  const currentUserIsPharmaceuticalCompanyEditRole =
    isPharmaceuticalCompanyEditRole(userData);
  const [loading, setLoading] = useState(null);
  const onCancel = () => {
    dispatch(clearlocalHandlingData());
  };

  const doStoreHandlingData = (closeCase = false, buttonName = null) => {
    setLoading(buttonName);
    dispatch(sendlocalHandlingData(closeCase)).then(result => {
      if (result.status === SendingStatus.SUCCESS) {
        const updatedReclamationCase = result.data;

        // update with changes
        const updatedHandlingData =
          getCaseHandlingData(updatedReclamationCase) || {};
        dispatch(setLocalHandlingData(updatedHandlingData));

        // and notify success
        dispatch(
          addNotification({
            title: t("handlingInformation.savedSuccessfully"),
            icon: <CaseCreatedIcon />,
          })
        );
      } else if (result.status === SendingStatus.SENDING) {
        dispatch(
          addNotification({
            title: t("handlingInformation.duplicateSaving"),
          })
        );
      } else if (isOptimisticLockError(result)) {
        handleOptimisticLockError(
          dispatch,
          t,
          "saveHandlingDataFailedOptimisticLock"
        );
      } else if (isUpdateAttachmentsError(result)) {
        handleUpdateAttachmentsError(dispatch, t);
      } else {
        dispatch(
          addNotification({
            title: t("handlingInformation.saveFailed"),
            type: "error",
          })
        );
      }
      setLoading(null);
    });
  };

  const saveHandlingData = () => {
    if (validateFilledFieldsHandlingData()) {
      doStoreHandlingData(false, "save");
    }
  };

  const saveAndCloseHandlingData = () => {
    if (validateFullHandlingData()) {
      doStoreHandlingData(true, "saveAndClose");
    }
  };

  return (
    <div className={classes.container}>
      {isSpdCase(reclamationCase) === true && (
        <SpdHandlingDataEdit
          isStatementRequired={reclamationCase.isStatementRequired}
          userData={userData}
          currentUserIsOriolaUser={currentUserIsOriolaUser}
          currentUserIsPharmaceuticalCompanyEditRole={
            currentUserIsPharmaceuticalCompanyEditRole
          }
          onSave={saveHandlingData}
          onCancel={onCancel}
          onSaveAndClose={saveAndCloseHandlingData}
          loading={loading}
        />
      )}
      {isSpdCase(reclamationCase) === false && (
        <DefaultHandlingDataEdit
          currentUserIsOriolaUser={currentUserIsOriolaUser}
          onSave={saveHandlingData}
          onCancel={onCancel}
          onSaveAndClose={saveAndCloseHandlingData}
          loading={loading}
        />
      )}
    </div>
  );
}

CompensationHandlingNotSolved.propTypes = {
  validateFullHandlingData: PropTypes.func.isRequired,
  validateFilledFieldsHandlingData: PropTypes.func.isRequired,
};

CompensationHandlingNotSolved.defaultProps = {};

export default CompensationHandlingNotSolved;
