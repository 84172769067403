import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getFormattedDateTime } from "../../utils/date/date";
import { CaseStatus } from "../../constants/caseStatus";

const useStyles = makeStyles(theme => ({
  columnValueText: {
    color: theme.palette.text.primary,
    width: "100%",
    ...theme.typography.button,
    textTransform: "none",
  },
  dateCreatedText: {
    paddingTop: theme.spacing(0.5),
    ...theme.typography.body2,
    color: theme.palette.text.disabled,
  },
}));

function Dates({ singleCase, ...rest }) {
  const classes = useStyles();
  const created =
    singleCase.caseStatus === CaseStatus.DRAFT
      ? getFormattedDateTime(moment(singleCase.creationTime))
      : getFormattedDateTime(moment(singleCase.submissionTime));
  let updatedText = "-";
  if (singleCase.modificationTime) {
    updatedText =
      singleCase.submissionTime < singleCase.modificationTime
        ? getFormattedDateTime(moment(singleCase.modificationTime))
        : "-";
  }

  return (
    <div {...rest}>
      <Typography className={classes.columnValueText}>{created}</Typography>
      <Typography
        className={classes.columnValueText}
        color="textPrimary"
        noWrap
      >
        {updatedText}
      </Typography>
    </div>
  );
}

Dates.propTypes = {
  singleCase: PropTypes.shape({
    caseStatus: PropTypes.string,
    creationTime: PropTypes.string,
    submissionTime: PropTypes.string,
    modificationTime: PropTypes.string,
  }),
};

Dates.defaultProps = {
  singleCase: {},
};

export default Dates;
