const { CaseType } = require("../../constants/caseType");

const validateProductReturn = (reclamationCase, errorState, FieldErrors) => {
  const { caseType, productReturn, items } = reclamationCase;
  const customerIsReturningProduct = productReturn.returnValue === true;

  // validate damaged product return required (eli illy)
  if (caseType === CaseType.DAMAGED_PRODUCT) {
    items.forEach(item => {
      const product = item.product || {};
      const { isDamageProductReturnRequired } = product;
      if (
        customerIsReturningProduct === false &&
        isDamageProductReturnRequired === true
      ) {
        errorState.returnProductError = FieldErrors.PRODUCT_MUST_BE_RETURNED;
      }
    });
  }
};

module.exports = validateProductReturn;
