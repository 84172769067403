import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Button } from "@oriola-origo/origo-ui-core";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(3),
  },
  button: {
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.2)",
    marginRight: theme.spacing(4),
  },
  title: {
    ...theme.typography.body1,
    marginTop: "0.5rem",
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(6),
  },
}));

function ButtonBar({
  displayMandatoryFieldsInfo,
  isNewCase,
  onSaveDraft,
  onSubmit,
  onDeleteDraft,
  onCancel,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      {displayMandatoryFieldsInfo && (
        <Typography className={classes.title}>
          {t("mandatoryFieldsInfo")}
        </Typography>
      )}
      <Button
        className={classes.button}
        variant="secondary"
        onClick={onSaveDraft}
        id="new-case-save-draft"
      >
        <Box display="flex" alignItems="center">
          {t("saveDraft")}
        </Box>
      </Button>
      <Button
        className={classes.button}
        variant="secondary"
        onClick={isNewCase ? onCancel : onDeleteDraft}
        id="delete-case-button"
      >
        {isNewCase ? t("cancel") : t("delete")}
      </Button>
      <Button
        className={classes.button}
        onClick={onSubmit}
        id="new-case-submit"
      >
        {t("submit")}
      </Button>
    </div>
  );
}

ButtonBar.propTypes = {
  isNewCase: PropTypes.bool,
  displayMandatoryFieldsInfo: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  onDeleteDraft: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ButtonBar.defaultProps = {
  isNewCase: false,
  displayMandatoryFieldsInfo: false,
};

export default ButtonBar;
