import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line import/no-cycle
import { ButtonBar } from "..";
import { CaseType } from "../../../constants/caseType";
import { Paths, getDraftPath } from "../../../utils/navigation/navigation";
import { CaseHeading } from "../../common";
import { DamagedProductFields } from "../../fields";
import {
  confirmAndDeleteCase,
  confirmAndSubmitCase,
  confirmAndCancelCreate,
  saveDraftCase,
  openCaseDetailsPrintDialog,
} from "../caseActions";
import {
  FieldErrors,
  validateReturnNumber,
  validateReturnConfirm,
  validateDraftReclamationCase,
  validateCourierCompanyId,
  validateEditableFields,
} from "../../../utils/validations/validations";
import { updateValidationError } from "../../../redux";
import scrollToError from "../../../utils/scrollToView/scrollToView";
import { isCaseForOriolaByOriola } from "../../../utils/case/case";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "green",
    },
  },
  container: {
    padding: theme.spacing(4),
    minHeight: 580,
    marginLeft: theme.spacing(1),
  },
}));

function DamagedProductDraft({
  history,
  reclamationCase,
  isNewCase,
  disabled,
  id,
  caseConfig,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const contactInfo = useSelector(state => state.contactInfo);
  const { userData } = useSelector(state => state.user);

  const validateSubmit = () => {
    const errorState = {};
    validateEditableFields({
      contactInfo,
      reclamationCase,
      errorState,
      caseConfig,
    });

    // if oriola creates case for itself these fields are not needed
    const caseIsForOriolaByOriola = isCaseForOriolaByOriola(
      (contactInfo.customer || {}).customerId,
      userData
    );
    if (caseIsForOriolaByOriola === false) {
      if (
        !validateReturnNumber(
          reclamationCase.productReturn,
          reclamationCase.caseType,
          reclamationCase.caseStatus
        )
      ) {
        errorState.returnNumberError = FieldErrors.RETURN_AMOUNT_INCORRECT;
      }

      if (
        reclamationCase.productReturn.returnValue === true &&
        !validateCourierCompanyId(reclamationCase.courierCompanyId)
      ) {
        errorState.courierCompanyError = FieldErrors.MANDATORY;
      }

      if (
        !validateReturnConfirm(
          reclamationCase.productReturn.returnValue,
          reclamationCase.confirm
        )
      ) {
        errorState.returnConfirmError = FieldErrors.RETURN_CONFIRMATION;
      }
    }

    dispatch(updateValidationError(errorState));
    scrollToError(errorState);

    return Object.keys(errorState).length === 0;
  };

  const validateDraft = () => {
    let success = true;
    const errorState = validateDraftReclamationCase(
      reclamationCase,
      contactInfo
    );

    dispatch(updateValidationError(errorState));
    if (Object.keys(errorState).length > 0) {
      success = false;
    }
    return success;
  };

  const onSaveDraft = () => {
    if (validateDraft()) {
      saveDraftCase(t, dispatch, reclamationCase, result => {
        if (isNewCase === true) {
          history.push(getDraftPath(result.reclamationId));
        }
      });
    }
  };

  const onSubmitCase = async () => {
    const validateResult = validateSubmit();
    if (validateResult) {
      confirmAndSubmitCase(t, dispatch, reclamationCase, result => {
        if (result.productReturn.returnValue) {
          history.push(`/case/${result.reclamationId}`);
          openCaseDetailsPrintDialog(t, dispatch, result);
        } else {
          history.push(Paths.OpenCases);
        }
      });
    }
  };

  const buttonBar = displayMandatoryFieldsInfo => (
    <ButtonBar
      displayMandatoryFieldsInfo={displayMandatoryFieldsInfo}
      onSaveDraft={() => onSaveDraft()}
      onSubmit={() => onSubmitCase()}
      onDeleteDraft={() =>
        confirmAndDeleteCase(t, dispatch, reclamationCase, () =>
          history.push(Paths.Drafts)
        )
      }
      onCancel={() =>
        confirmAndCancelCreate(t, dispatch, () => history.goBack())
      }
      isNewCase={isNewCase}
    />
  );

  const topButtonBar = () => buttonBar(true);

  const bottomButtonBar = () => buttonBar(false);

  return (
    <Paper className={classes.container} id={id}>
      <CaseHeading
        leftText={t("newCase")}
        rightText={t("category.damagedProduct.header")}
      />
      {disabled ? (
        <Typography variant="subtitle2" className={classes.warningTitle}>
          {t("caseInformation.notEditableWarning")}
        </Typography>
      ) : (
        topButtonBar()
      )}
      <DamagedProductFields
        reclamationCase={reclamationCase}
        isNewCase={isNewCase}
        disabled={disabled}
        caseConfig={caseConfig}
      />
      {!disabled && bottomButtonBar()}
    </Paper>
  );
}

DamagedProductDraft.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }),
  reclamationCase: PropTypes.shape({
    caseType: PropTypes.string,
    reclamationId: PropTypes.string,
    productReturn: PropTypes.shape({
      returnValue: PropTypes.bool,
    }),
    caseStatus: PropTypes.string,
    confirm: PropTypes.bool,
    courierCompanyId: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  caseConfig: PropTypes.shape({}),
  isNewCase: PropTypes.bool,
};

DamagedProductDraft.defaultProps = {
  reclamationCase: {
    caseType: CaseType.DAMAGED_PRODUCT,
    productReturn: {
      returnValue: false,
    },
  },
  isNewCase: false,
  disabled: false,
  id: "",
  caseConfig: {},
  history: {
    push: null,
  },
};

export default withRouter(DamagedProductDraft);
