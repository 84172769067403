import { useCallback, useEffect, useState } from "react";

const useHasScrolledPast = (target, parent) => {
  const [hasPassed, setHasPassed] = useState(false);
  const checkIfPassed = useCallback(() => {
    if (target?.current) {
      setHasPassed(target.current.getBoundingClientRect().bottom < 0);
    }
  }, [target]);

  useEffect(() => {
    if (!parent) {
      return false;
    }
    const obj = parent.current;
    checkIfPassed();
    obj?.addEventListener("scroll", checkIfPassed);
    return () => {
      obj?.removeEventListener("scroll", checkIfPassed);
    };
  }, [checkIfPassed, parent]);

  return hasPassed;
};

export default useHasScrolledPast;
