import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import DetailRow from "../detailRow";
import { Can, Permission, ANY_CUSTOMER } from "../../../auth";
import getModifierStrOrNull from "./getModifierStrOrNull";
import getValueIfNotNull from "./getValueIfNotNull";

function CompensationInstructionsView({
  compensationInstructions,
  userData,
  modifications,
  ...containerProps
}) {
  const { t } = useTranslation();

  const { isCompensationToCustomerPermitted, reasonForRejection } =
    compensationInstructions;

  return (
    <Box {...containerProps}>
      <Typography variant="subtitle2">
        {t("compensationInstructions.title").toUpperCase()}
      </Typography>
      <Box mt={3}>
        <DetailRow
          keyName={t("compensationInstructions.compensatedTitle")}
          value={getValueIfNotNull(
            isCompensationToCustomerPermitted,
            isCompensationToCustomerPermitted
              ? t("compensationInstructions.compensatedToCustomerPermitted")
              : t("compensationInstructions.compensatedToCustomerNotPermitted")
          )}
          secondaryValue={getModifierStrOrNull(
            isCompensationToCustomerPermitted != null,
            modifications,
            "compensationInstructions.isCompensationToCustomerPermitted"
          )}
        />
        {isCompensationToCustomerPermitted === false && (
          <DetailRow
            keyName={t(
              "compensationInstructions.reasonForCompensationRejection"
            )}
            value={reasonForRejection}
            secondaryValue={getModifierStrOrNull(
              reasonForRejection != null,
              modifications,
              "compensationInstructions.reasonForRejection"
            )}
          />
        )}
        <Can
          user={userData}
          perform={Permission.HANDLING_DATA_PRINCIPAL_VIEW}
          customerContext={ANY_CUSTOMER}
        >
          <DetailRow
            keyName={t("compensationInstructions.title")}
            value={compensationInstructions.compensationInstructions}
            secondaryValue={getModifierStrOrNull(
              compensationInstructions.compensationInstructions != null,
              modifications,
              "compensationInstructions.compensationInstructions"
            )}
          />
        </Can>
      </Box>
    </Box>
  );
}

CompensationInstructionsView.propTypes = {
  compensationInstructions: PropTypes.shape({
    isCompensationToCustomerPermitted: PropTypes.bool,
    reasonForRejection: PropTypes.string,
    compensationInstructions: PropTypes.string,
  }).isRequired,
  userData: PropTypes.shape({}).isRequired,
  modifications: PropTypes.shape({}),
};

CompensationInstructionsView.defaultProps = {
  modifications: null,
};

export default CompensationInstructionsView;
