import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
// eslint-disable-next-line import/no-cycle
import { FieldTitle, ProductSearch } from "..";
import { CaseType } from "../../../../constants/caseType";

function ProductField({
  itemData,
  classes,
  id,
  productFieldName,
  config,
  error,
  updateItem,
  updateItemValidationError,
  disabled,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const product = itemData[productFieldName] || {};
  const { caseType, productReturn } = useSelector(state => state.case.case);

  const switchProductComponent = customerAddedProduct => {
    const productData = customerAddedProduct
      ? { customerAddedProduct: true }
      : undefined;
    dispatch(
      updateItem({
        ...itemData,
        [productFieldName]: productData,
      })
    );
  };
  const updateProductData = value => {
    dispatch(
      updateItemValidationError(itemData.uuid, {
        [productFieldName]: undefined,
      })
    );
    let updateEntry = {
      ...product,
      ...value,
    };
    if (!value) {
      updateEntry = undefined;
    }

    if (caseType === CaseType.SUSPECTED_PRODUCT_DEFECT) {
      dispatch(
        updateItem({
          ...itemData,
          [productFieldName]: updateEntry,
          productCode:
            updateEntry &&
            updateEntry.gtinCodes &&
            updateEntry.gtinCodes[0] &&
            updateEntry.gtinCodes[0].gtin,
        })
      );
    } else {
      dispatch(
        updateItem({
          ...itemData,
          [productFieldName]: updateEntry,
        })
      );
    }
  };

  const renderProductSearch = () => (
    <OriolaThemeProvider>
      <Box display="flex" sx={{ flexWrap: "wrap" }}>
        <Box
          position="relative"
          style={{ width: "700px", marginRight: "20px" }}
        >
          <Box display="flex" flexWrap="wrap">
            <FieldTitle
              title={t(`caseItemFields.${productFieldName}`)}
              type="selection"
              optional={config.optional}
            />
          </Box>
          <ProductSearch
            product={product || {}}
            error={error}
            onSelect={updateProductData}
            id={id}
            caseType={caseType}
            productReturn={productReturn}
            disabled={disabled}
            updateProductData={updateProductData}
            switchProductComponent={switchProductComponent}
          />
        </Box>
      </Box>
    </OriolaThemeProvider>
  );
  return <div className={classes.rowContainer}>{renderProductSearch()}</div>;
}

ProductField.propTypes = {
  itemData: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  classes: PropTypes.shape({
    rowContainer: PropTypes.string,
    findProduct: PropTypes.string,
    findProductButton: PropTypes.string,
    instruction: PropTypes.string,
    greyBackground: PropTypes.string,
  }),
  id: PropTypes.string,
  productFieldName: PropTypes.string.isRequired,
  config: PropTypes.shape({
    optional: PropTypes.bool,
  }),
  error: PropTypes.string,
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
  disabled: PropTypes.bool,
};

ProductField.defaultProps = {
  itemData: {},
  classes: {},
  id: "",
  config: {},
  error: "",
  updateItem: null,
  updateItemValidationError: null,
  disabled: false,
};

export default ProductField;
