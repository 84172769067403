import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import DetailRow from "../detailRow";

function HandlerInformationView({ caseHandler, ...containerProps }) {
  const { t } = useTranslation();

  let handler = "-";
  if (caseHandler) {
    handler = `${caseHandler.userName || ""}`;
  }

  return (
    <Box {...containerProps}>
      <Typography variant="subtitle2">
        {t("handlerInformation").toUpperCase()}
      </Typography>
      <Box mt={3}>
        <DetailRow
          keyName={t("handlingInformation.handlerName")}
          value={handler}
        />
      </Box>
    </Box>
  );
}

HandlerInformationView.propTypes = {
  caseHandler: PropTypes.shape({
    userName: PropTypes.string,
  }).isRequired,
};

export default HandlerInformationView;
