import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
// eslint-disable-next-line import/no-cycle
import { FieldTitle } from "..";
import { StatefullTextField } from "../../../common";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";

function InvoiceNumber({
  itemData,
  classes,
  id,
  config,
  error,
  updateItem,
  updateItemValidationError,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className={classes.rowContainer}>
      <FieldTitle
        title={t("caseItemFields.invoiceNumber")}
        optional={config.optional}
      />
      <StatefullTextField
        error={!!error}
        helperText={getValidationErrorMessage(error, t, {
          validLength: config.validLength,
        })}
        initialValue={itemData.invoiceNumber || ""}
        variant="outlined"
        placeholder={t("caseItemFields.invoiceNumber")}
        margin="dense"
        onChange={invoiceNumber => {
          dispatch(
            updateItemValidationError(itemData.uuid, { invoiceNumber: "" })
          );
          dispatch(
            updateItem({
              ...itemData,
              invoiceNumber,
            })
          );
        }}
        id={`${id}-invoice-number`}
        inputProps={{ maxLength: config.validLength }}
      />
    </div>
  );
}

InvoiceNumber.propTypes = {
  itemData: PropTypes.shape({
    invoiceNumber: PropTypes.string,
    uuid: PropTypes.string,
  }),
  classes: PropTypes.shape({
    rowContainer: PropTypes.string,
    controlContainer: PropTypes.string,
  }),
  id: PropTypes.string,
  config: PropTypes.shape({
    optional: PropTypes.bool,
    validLength: PropTypes.number,
  }),
  error: PropTypes.string,
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
};

InvoiceNumber.defaultProps = {
  itemData: {},
  classes: {},
  id: "",
  config: {},
  error: "",
  updateItem: null,
  updateItemValidationError: null,
};

export default InvoiceNumber;
