import React from "react";
import Box from "@oriola-origo/core/lib/Box";
import { styled } from "@oriola-origo/core/lib/styles";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";

const CaseIconContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.lightest,
  border: `1px solid ${theme.palette.primary.lighter}`,
  borderRadius: theme.spacing(0.5),
  marginTop: theme.spacing(1),
  height: theme.spacing(3),
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

interface CaseIconV2Props {
  children: React.ReactNode;
}
function CaseIconV2({ children }: Readonly<CaseIconV2Props>) {
  return (
    <OriolaThemeProvider>
      <CaseIconContainer>{children}</CaseIconContainer>
    </OriolaThemeProvider>
  );
}

export default CaseIconV2;
