import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { OriolaTheme } from "@oriola-origo/origo-ui-core";
import { Divider, Box } from "@material-ui/core";
import CompensationEdit from "../compensation/compensationEdit";
import ProductReturns from "../productReturns/productReturns";
import HandlingInformation from "../handlingInformation/handlingInformation";
import SaveAndCloseButtonGroup, {
  HANDLING_BUTTON_GROUP_HEIGHT_FULL,
  HANDLING_BUTTON_GROUP_HEIGHT,
} from "../handlingDataButtonBar/saveAndCloseButtonGroup";
import StorageLocation from "../storageLocation";
import ProductChips from "./productChips";

const useStyles = makeStyles(theme => ({
  divider: {
    borderBottomColor: "#e7e7e7",
  },
  innerContainer: {
    "& > div": {
      position: "relative",
    },
  },
  title: {
    ...OriolaTheme.typography.h4,
    textTransform: "uppercase",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
}));

function DefaultHandlingDataEdit({
  onSave,
  onSaveAndClose,
  onCancel,
  loading,
  currentUserIsOriolaUser,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const getContentAreaHeight = () => {
    const buttonAreaHeight = currentUserIsOriolaUser
      ? HANDLING_BUTTON_GROUP_HEIGHT_FULL
      : HANDLING_BUTTON_GROUP_HEIGHT;
    return `calc(100% - ${buttonAreaHeight}px)`;
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        className={classes.innerContainer}
        display="flex"
        flexDirection="column"
        height={getContentAreaHeight()}
        style={{ overflow: "auto" }}
      >
        <div className={classes.title}>{t("caseHandling")}</div>
        <ProductChips />
        <ProductReturns />
        <Divider className={classes.divider} />
        <CompensationEdit />
        <Divider className={classes.divider} />
        <HandlingInformation />
        <Divider className={classes.divider} />
        <StorageLocation />
        <Divider className={classes.divider} />
      </Box>
      <SaveAndCloseButtonGroup
        onSave={onSave}
        onCancel={onCancel}
        onSaveAndClose={onSaveAndClose}
        currentUserIsOriolaUser={currentUserIsOriolaUser}
        loading={loading}
      />
    </Box>
  );
}

DefaultHandlingDataEdit.propTypes = {
  onSave: PropTypes.func,
  onSaveAndClose: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.string,
  currentUserIsOriolaUser: PropTypes.bool,
};

DefaultHandlingDataEdit.defaultProps = {
  onSave: null,
  onSaveAndClose: null,
  onCancel: null,
  loading: "",
  currentUserIsOriolaUser: false,
};

export default DefaultHandlingDataEdit;
