import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  MuiStartEndDatePicker,
  OriolaColors,
} from "@oriola-origo/origo-ui-core";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const StyledMuiStartEndDatePicker = withStyles({
  root: {
    "& label": {
      color: OriolaColors.Grey,
    },
  },
})(MuiStartEndDatePicker);

function DaterangeSelect({
  startDate,
  endDate,
  onDatesSelected,
  ...containerProps
}) {
  const { t } = useTranslation();
  const filters = useSelector(state => state.casesFilters);

  const dateFormat = "YYYY-MM-DD";

  useEffect(() => {
    if (filters.startDate === dateFormat && filters.endDate === dateFormat) {
      onDatesSelected(null, null);
    }
  }, [filters, onDatesSelected]);

  const handleDatesSelected = (momentStartDate, momentEndDate) => {
    const startDateStr =
      momentStartDate != null ? momentStartDate.format(dateFormat) : null;
    const endDateStr =
      momentEndDate != null ? momentEndDate.format(dateFormat) : null;
    onDatesSelected(startDateStr, endDateStr);
  };

  return (
    <StyledMuiStartEndDatePicker
      startDateValue={startDate != null ? moment(startDate) : null}
      endDateValue={endDate != null ? moment(endDate) : null}
      startDateLabel={t("startDate")}
      endDateLabel={t("endDate")}
      onDatesSelected={handleDatesSelected}
      {...containerProps}
      startDateProps={{ disableFuture: true }}
      endDateProps={{ disableFuture: true }}
    />
  );
}

DaterangeSelect.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onDatesSelected: PropTypes.func.isRequired,
};

DaterangeSelect.defaultProps = {
  startDate: null,
  endDate: null,
};

export default DaterangeSelect;
