import { showDialog, hideDialog, StoreCaseErrorType } from "../../redux";

export const isUpdateAttachmentsError = (error = {}) => {
  const data = error.data || {};
  return data.message === StoreCaseErrorType.UPDATE_ATTACHMENTS;
};

export const handleUpdateAttachmentsError = (dispatch, t) => {
  dispatch(
    showDialog({
      open: true,
      title: t("updateAttachmentsError.title"),
      description: t("updateAttachmentsError.description"),
      hideCancelButton: true,
      disableBackdropClick: true,
      onCancel: () => {
        dispatch(hideDialog());
        window.location.reload();
      },
      onOk: () => {
        dispatch(hideDialog());
        window.location.reload();
      },
    })
  );
};
