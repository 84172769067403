import { CaseType } from "../../../../constants/caseType";
import { FieldErrors } from "../../../../utils/validations/validations";
import {
  getProductHandlingErrors,
  getProductHandlingInstructionAllErrors,
  getPermissionRequiredFromPharmaCompanyErrors,
  getCompensationInstructionsErrors,
  getCompensationInformationAllErrors,
  getCompensationInformationFilledFieldErrors,
  getProductHandlingInformationError,
  getConclusionErrors,
  getProductReceivedReturnedAmountError,
} from "./suspectedProductDefectErrors";
import {
  getProductReturnRequiredError,
  getProductsHaveBeenReturnedError,
  getCompensationPermittedError,
} from "./damagedProductErrors";
import getProductReturnToSellableError from "./productDeliveryErrors";
import {
  getRejectReasonError,
  getRejectDescriptionError,
  getCompensationsError,
} from "./commonErrors";

const getEmptyDataErrors = () => {
  const arrErrors = [];
  arrErrors.push({
    productReturnRequiredError: FieldErrors.PRODUCT_RETURN_REQUIRED_MANDATORY,
  });
  arrErrors.push({
    productsHaveBeenReturnedError:
      FieldErrors.PRODUCTS_HAVE_BEEN_RETURNED_MANDATORY,
  });
  arrErrors.push({
    productReturnToSellableError: FieldErrors.PRODUCT_SELLABLE_MANDATORY,
  });
  arrErrors.push({
    compensationPermittedError: FieldErrors.COMPENSATION_PERMITTED_MANDATORY,
  });
  return arrErrors;
};

const getDamagedProductErrors = localHandlingData => {
  const arrErrors = [];
  arrErrors.push(getProductReturnRequiredError(localHandlingData));
  arrErrors.push(getProductsHaveBeenReturnedError(localHandlingData));
  arrErrors.push(getCompensationPermittedError(localHandlingData));
  return arrErrors;
};

const getProductDeliveryErrors = (reclamationCase, localHandlingData) => {
  const arrErrors = [];
  // check only if products are returned
  const { productReturn } = reclamationCase;
  const isReturningProducts =
    productReturn != null && productReturn.returnValue === true;
  if (isReturningProducts === true) {
    arrErrors.push(getProductReturnToSellableError(localHandlingData));
  }
  arrErrors.push(getCompensationPermittedError(localHandlingData));
  return arrErrors;
};

const getSuspectedProductDeliveryErrors = localHandlingData => {
  const arrErrors = [];
  arrErrors.push(...getProductHandlingErrors(localHandlingData));
  arrErrors.push(
    ...getPermissionRequiredFromPharmaCompanyErrors(localHandlingData)
  );
  arrErrors.push(...getProductHandlingInstructionAllErrors(localHandlingData));
  arrErrors.push(...getCompensationInstructionsErrors(localHandlingData));
  arrErrors.push(...getProductHandlingInformationError(localHandlingData));
  arrErrors.push(...getCompensationInformationAllErrors(localHandlingData));
  return arrErrors;
};

export const getFilledFieldsHandlingDataErrors = (
  reclamationCase,
  localHandlingData,
  messages,
  userData
) => {
  const { caseType } = reclamationCase;

  const arrErrors = [];
  if (caseType === CaseType.SUSPECTED_PRODUCT_DEFECT) {
    arrErrors.push(
      ...getCompensationInformationFilledFieldErrors(localHandlingData)
    );
    arrErrors.push(
      ...getProductHandlingInstructionAllErrors(
        localHandlingData,
        reclamationCase,
        messages,
        userData
      )
    );
    arrErrors.push(...getProductReceivedReturnedAmountError(localHandlingData));
    arrErrors.push(
      ...getConclusionErrors(
        reclamationCase,
        localHandlingData,
        false,
        messages,
        userData
      )
    );
  } else {
    arrErrors.push(getCompensationsError(localHandlingData));
  }

  return arrErrors.filter(x => x);
};

export const getAllHandlingDataErrors = (
  reclamationCase,
  localHandlingData
) => {
  const { caseType } = reclamationCase;

  const arrErrors = [];
  if (!localHandlingData) {
    arrErrors.push(...getEmptyDataErrors());
  } else if (caseType === CaseType.DAMAGED_PRODUCT) {
    arrErrors.push(...getDamagedProductErrors(localHandlingData));
    arrErrors.push(getRejectReasonError(localHandlingData));
    arrErrors.push(getRejectDescriptionError(localHandlingData));
    arrErrors.push(getCompensationsError(localHandlingData));
  } else if (caseType === CaseType.PRODUCT_DELIVERY) {
    arrErrors.push(
      ...getProductDeliveryErrors(reclamationCase, localHandlingData)
    );
    arrErrors.push(getRejectReasonError(localHandlingData));
    arrErrors.push(getRejectDescriptionError(localHandlingData));
    arrErrors.push(getCompensationsError(localHandlingData));
  } else if (caseType === CaseType.SUSPECTED_PRODUCT_DEFECT) {
    arrErrors.push(...getSuspectedProductDeliveryErrors(localHandlingData));
    arrErrors.push(
      ...getConclusionErrors(reclamationCase, localHandlingData, false)
    );
  }

  return arrErrors.filter(x => x);
};
