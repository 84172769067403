import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import DetailRow from "../../detailRow";
import getModifierStrOrNull from "../getModifierStrOrNull";
import getValueIfNotNull from "../getValueIfNotNull";

export interface ProductHandlingViewProps {
  isOriolaWaitingForInstructions?: boolean;
  principalReferenceNumber?: string[];
  modifications?: Record<string, unknown>;
  trackingCode?: string;
  containerProps: Record<string, unknown>;
}

function ProductHandlingView({
  isOriolaWaitingForInstructions,
  principalReferenceNumber,
  modifications = {},
  trackingCode = "",
  ...containerProps
}: Readonly<ProductHandlingViewProps>) {
  const { t } = useTranslation();

  return (
    <Box {...containerProps}>
      <Typography variant="subtitle2">
        {t("productHandling.viewTitle").toUpperCase()}
      </Typography>
      <Box mt={3}>
        <DetailRow
          keyName={t("parcelTracking.label")}
          value={trackingCode}
          secondaryValue={getModifierStrOrNull(
            trackingCode != null,
            modifications,
            "trackingCode"
          )}
        />
        <DetailRow
          keyName={t("productHandling.title")}
          value={getValueIfNotNull(
            isOriolaWaitingForInstructions,
            isOriolaWaitingForInstructions ? t("yes") : t("no")
          )}
          secondaryValue={getModifierStrOrNull(
            isOriolaWaitingForInstructions != null,
            modifications,
            "isOriolaWaitingForInstructions"
          )}
        />
        <DetailRow
          keyName={t("principalReference.title")}
          value={(principalReferenceNumber ?? []).join(", ")}
          secondaryValue={getModifierStrOrNull(
            principalReferenceNumber != null,
            modifications,
            "principalReferenceNumber"
          )}
        />
      </Box>
    </Box>
  );
}

export default ProductHandlingView;
