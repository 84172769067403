/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";
import Box from "@oriola-origo/core/lib/Box";
import { styled } from "@oriola-origo/core/lib/styles";
import Typography from "@oriola-origo/core/lib/Typography";
import TextField from "@oriola-origo/core/lib/TextField";
import Checkbox from "@oriola-origo/core/lib/Checkbox";
import IconButton from "@oriola-origo/core/lib/IconButton";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import DateAndEditor from "../../dateAndEditor";
// eslint-disable-next-line import/no-cycle
import {
  updateLocalHandlingDataField,
  updateValidationError,
} from "../../../../redux";
import { getSpdModificationData } from "../../../../utils/getSpdModificationData/getSpdModificationData";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";

interface PhotographRequestProps {
  user: Record<string, any>;
  disabled: boolean;
  modifications: Record<string, any>;
  productHandlingInstructions: Record<string, any>;
}

const PhotographyRequest = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const PhotographyRequestHeader = styled(Box)(() => ({
  display: "flex",
}));

const PhotographyInstructions = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const PhotographyInstructionsTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(3.75),
  marginBottom: theme.spacing(2),
}));

const PhotographyInstructionsInput = styled(Box)(({ theme }) => ({
  position: "relative",
  height: theme.spacing(16),
}));

const PhotographyInstructionsTextField = styled(TextField)(({ theme }) => ({
  paddingLeft: theme.spacing(3.75),
  paddingRight: theme.spacing(3.75),
}));

const CopyButtonField = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(-3.25),
  bottom: theme.spacing(1.25),
}));

function PhotographRequest({
  user,
  disabled,
  modifications,
  productHandlingInstructions,
}: Readonly<PhotographRequestProps>) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const { photographInstructions = "", isPhotographRequested = false } =
    productHandlingInstructions;

  const { spdPhotographInstructionsRequired } = useSelector(
    (state: any) => state.validationErrors
  );

  const getModificationData = (field: string) =>
    getSpdModificationData(
      modifications,
      `productHandlingInstructions.${field}`
    );

  const setPhotographRequestedValue = (value: boolean) => {
    dispatch(
      updateValidationError({
        spdPhotographRequestRequired: null,
      })
    );

    dispatch(
      updateLocalHandlingDataField(
        value,
        "productHandlingInstructions",
        "isPhotographRequested"
      )
    );

    // Clear instruction value since photograph request is unchecked
    if (!value) {
      dispatch(
        updateLocalHandlingDataField(
          null,
          "productHandlingInstructions",
          "photographInstructions"
        )
      );
    }
  };

  const setPhotographInstructionsValue = (value: string) => {
    dispatch(
      updateValidationError({
        spdPhotographInstructionsRequired: null,
      })
    );

    const valueToStoreInLocalHandling = value || null;

    dispatch(
      updateLocalHandlingDataField(
        valueToStoreInLocalHandling,
        "productHandlingInstructions",
        "photographInstructions"
      )
    );
  };

  return (
    <OriolaThemeProvider>
      <Box mt={2}>
        <PhotographyRequest>
          <PhotographyRequestHeader>
            <Typography variant="h6" color="textSecondary">
              {t("productHandlingInstructions.photographs")}
            </Typography>
            <Tooltip
              placement="top"
              arrow
              title={t(
                "productHandlingInstructions.photographRequestPopupText"
              )}
            >
              <span>
                <IconButton size="small" disabled>
                  <FontIcon
                    color="secondary"
                    baseClassName="material-icons-outlined"
                    colorTone="light"
                    fontSize="small"
                  >
                    info
                  </FontIcon>
                </IconButton>
              </span>
            </Tooltip>
          </PhotographyRequestHeader>
          <Checkbox
            label={t("productHandlingInstructions.photographRequested")}
            disabled={disabled}
            onChange={e => setPhotographRequestedValue(e.target.checked)}
            checked={isPhotographRequested}
          />
          {isPhotographRequested && (
            <DateAndEditor
              id="productHandlingInstructions-date-editor-photograph-request"
              modificationData={getModificationData("isPhotographRequested")}
              user={user}
              style={{ marginLeft: "30px", marginTop: "-8px" }}
            />
          )}
        </PhotographyRequest>
        {isPhotographRequested && (
          <PhotographyInstructions>
            <PhotographyInstructionsTitle variant="h6" color="textSecondary">
              {t("productHandlingInstructions.photographyInstructionsTitle")}
            </PhotographyInstructionsTitle>
            <PhotographyInstructionsInput>
              <PhotographyInstructionsTextField
                placeholder={t(
                  "productHandlingInstructions.photographyInstructionsGuideText"
                )}
                fullWidth
                multiline
                disabled={disabled}
                rows={5}
                onChange={e => setPhotographInstructionsValue(e.target.value)}
                value={photographInstructions}
                error={
                  spdPhotographInstructionsRequired && isPhotographRequested
                }
                helperText={getValidationErrorMessage(
                  spdPhotographInstructionsRequired,
                  t
                )}
              />
              <CopyButtonField>
                <Tooltip
                  title={copied ? t("copied") : t("copy")}
                  placement="top"
                >
                  <IconButton
                    size="small"
                    sx={{ paddingRight: 0, backgroundColor: "transparent" }}
                    onClick={() => {
                      navigator.clipboard.writeText(photographInstructions);
                      setCopied(true);
                      setTimeout(() => {
                        setCopied(false);
                      }, 1000);
                    }}
                  >
                    <FontIcon color="secondary">file_copy</FontIcon>
                  </IconButton>
                </Tooltip>
              </CopyButtonField>
            </PhotographyInstructionsInput>
            {photographInstructions && (
              <DateAndEditor
                id="productHandlingInstructions-date-editor-photograph-instructions"
                modificationData={getModificationData("photographInstructions")}
                user={user}
                style={{ marginLeft: "30px", marginTop: "-8px" }}
              />
            )}
          </PhotographyInstructions>
        )}
      </Box>
    </OriolaThemeProvider>
  );
}

export default PhotographRequest;
