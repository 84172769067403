export const validationErrorsActions = Object.freeze({
  UPDATE_VALIDATION_ERROR: "UPDATE_VALIDATION_ERROR",
  UPDATE_PRODUCT_ERROR: "UPDATE_PRODUCT_ERROR",
  SET_VALIDATION_ERROR: "SET_VALIDATION_ERROR",
  CLEAR_ERRORS: "CLEAR_ERRORS",
  UPDATE_COMPENSATION_ERROR: "UPDATE_COMPENSATION_ERROR",
  UPDATE_SHIPPING_BOX_ERROR: "UPDATE_SHIPPING_BOX_ERROR",
  UPDATE_ORDER_ERROR: "UPDATE_ORDER_ERROR",
  UPDATE_ITEM_ERROR: "UPDATE_ITEM_ERROR",
});

export const INIT_STATE = {
  contactPersonError: "",
  phoneNumberError: "",
  reasonIdentifierError: "",
  productReceivedDateError: "",
  caseDescriptionError: "",
  productReturnRequiredError: "",
  productsHaveBeenReturnedError: "",
  productReturnToSellableError: "",
  compensationsError: {},
  itemsError: {},
  noRequiredItemError: "",
  confirmError: "",
  courierCompanyError: null,
  suspectedAdverseReactionError: {},
  spdProductReturnRequiredToPrincipalRequired: null,
  spdReturnToPrincipalRecipientConfirmationRequired: null,
  spdMethodOfDisposalRequired: null,
  spdReturnToPrincipalAddressRequired: null,
  spdReturnToPrincipalInvalidAttachment: null,
  spdCertificationOfDisposalValueRequired: null,
  spdCustomerCompensationReasonRejectionError: null,
  spdCustomerCompensationPermittedError: null,
  spdCustomerCompensationIsCompensatedError: null,
  spdCustomerCompensationDetailsError: null,
  spdPermissionRequiredFromPharmaCompany: null,
  spdOriolaIsWaitingForInstructions: null,
};

export const updateValidationError = validationError => dispatch => {
  dispatch({
    type: validationErrorsActions.UPDATE_VALIDATION_ERROR,
    payload: validationError,
  });
};

export const setValidationError = validationError => dispatch => {
  dispatch({
    type: validationErrorsActions.SET_VALIDATION_ERROR,
    payload: {
      ...INIT_STATE,
      ...validationError,
    },
  });
};

export const updateItemValidationError = (uuid, error) => dispatch => {
  dispatch({
    type: validationErrorsActions.UPDATE_ITEM_ERROR,
    payload: {
      uuid,
      error,
    },
  });
};

export const clearValidationErrors = () => dispatch => {
  dispatch({
    type: validationErrorsActions.CLEAR_ERRORS,
  });
};

export const validationErrorsReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case validationErrorsActions.UPDATE_VALIDATION_ERROR:
      return { ...state, ...action.payload };
    case validationErrorsActions.SET_VALIDATION_ERROR:
      return { ...action.payload };
    case validationErrorsActions.UPDATE_PRODUCT_ERROR:
      return {
        ...state,
        productsError: {
          ...state.productsError,
          [action.payload.uuid]: action.payload.error,
        },
      };
    case validationErrorsActions.UPDATE_SHIPPING_BOX_ERROR:
      return {
        ...state,
        shippingBoxesError: {
          ...state.shippingBoxesError,
          [action.payload.uuid]: action.payload.error,
        },
      };
    case validationErrorsActions.UPDATE_ITEM_ERROR:
      return {
        ...state,
        itemsError: {
          ...state.itemsError,
          [action.payload.uuid]: {
            ...state.itemsError[action.payload.uuid],
            ...action.payload.error,
          },
        },
      };
    case validationErrorsActions.CLEAR_ERRORS:
      return { ...INIT_STATE };
    default:
      return state;
  }
};
