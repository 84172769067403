// MODULES IMPORT
import {
  isOriolaUser,
  isCustomer,
  isPharmaceuticalCompany,
} from "../../components/auth/permission";
import UserType from "../../constants/userType";

/**
 * Determine user organization type as string value
 * from user data object
 * @param {*} userData
 * @returns
 */
const getUserType = userData => {
  if (isOriolaUser(userData)) {
    return UserType.ORIOLA;
  }
  if (isCustomer(userData)) {
    return UserType.CUSTOMER;
  }
  if (isPharmaceuticalCompany(userData)) {
    return UserType.PRINCIPAL;
  }
  return "";
};

export default getUserType;
