import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
import moment from "moment";
import UnsavedChangesLabel from "../generic/unsavedChangesLabel/unsavedChangesLabel";

const useStyles = makeStyles(theme => ({
  greyText12: {
    ...theme.typography.body2,
    fontWeight: "400",
    color: OriolaColors.secondaryDarkGray,
  },
}));

function DateAndEditor({ modificationData, user, ...containerProps }) {
  const classes = useStyles();
  let modificationText;
  if (modificationData?.isLocalChange) {
    modificationText = <UnsavedChangesLabel />;
  } else if (!modificationData?.hideModifierName) {
    if (modificationData?.modificationTime) {
      const { modificationTime, userName } = modificationData;
      modificationText = `${moment(modificationTime).format(
        "DD.MM.YYYY"
      )} - ${userName}`;
    } else {
      modificationText = `${moment().format("DD.MM.YYYY")} - ${
        (user || {}).name
      }`;
    }
  }

  return (
    <Box {...containerProps}>
      <Typography className={`${classes.greyText12} ${classes.italic}`}>
        {modificationText}
      </Typography>
    </Box>
  );
}

DateAndEditor.propTypes = {
  modificationData: PropTypes.shape({
    modificationTime: PropTypes.string,
    userName: PropTypes.string,
    isLocalChange: PropTypes.bool,
    hideModifierName: PropTypes.bool,
  }),
  user: PropTypes.shape({}).isRequired,
};

DateAndEditor.defaultProps = {
  modificationData: null,
};

export default DateAndEditor;
