// MODULES IMPORT
import {
  getCustomerRecipient,
  getPrincipalRecipient,
  getOriolaRecipient,
} from "../../components/case/messages/recipients";
import getUserType from "../user";
import UserType from "../../constants/userType";

/**
 * Return an object containing identities from all relevant parties
 * that can send messages in a reclamation case. (CUSTOMER, PRINCIPAL, ORIOLA)
 * @param {*} reclamationCase
 * @returns
 */
export const getMessageCreatorIdentities = reclamationCase => ({
  oriola: getOriolaRecipient(),
  principal: getPrincipalRecipient(reclamationCase),
  customer: getCustomerRecipient(reclamationCase),
});

/**
 * Check if the message belongs to the user organization
 * @param {*} message - A single message object
 * @param {*} userData - Current user data object
 * @returns
 */
export const isOwnOrganizationMessage = (message, userData) =>
  message.creator.type === getUserType(userData);

/**
 * Return the message creator first & last name concatenated with
 * their correct organization name.
 * Return 'Oriola' by default if first and last name are unavailable.
 * @param {*} creator - A single message's creator details object
 * @param {*} identities - Object containing relevant organization identities for a case
 * @returns
 */
export const getMessageCreatorDetails = (creator, identities) => {
  if (creator.type === UserType.PRINCIPAL) {
    return `${creator.firstName} ${creator.lastName} - ${identities.principal.orgName}`;
  }
  if (creator.type === UserType.CUSTOMER) {
    return `${creator.firstName} ${creator.lastName} - ${identities.customer.orgName}`;
  }
  return creator.firstName && creator.lastName
    ? `${creator.firstName} ${creator.lastName}`
    : "Oriola";
};
