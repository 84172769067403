import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";
// eslint-disable-next-line import/no-cycle
import DateSelect from "./dateSelect";

function DateReceived({
  itemData,
  id,
  config,
  error,
  updateItem,
  updateItemValidationError,
  disabled,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <DateSelect
      title={t("caseItemFields.dateReceived")}
      optional={config.optional}
      date={itemData.dateReceived}
      onSelect={date => {
        dispatch(
          updateItemValidationError(itemData.uuid, { dateReceived: "" })
        );
        dispatch(
          updateItem({
            ...itemData,
            dateReceived: date ? moment(date).format("YYYY-MM-DD") : null,
          })
        );
      }}
      id={`${id}-received-date`}
      error={!!error}
      helperText={getValidationErrorMessage(error, t)}
      placeholder={t("datepickerPlaceholder")}
      disableFuture
      width="310px"
      disabled={disabled}
    />
  );
}

DateReceived.propTypes = {
  itemData: PropTypes.shape({
    dateReceived: PropTypes.string,
    uuid: PropTypes.string,
  }),
  id: PropTypes.string,
  config: PropTypes.shape({
    optional: PropTypes.bool,
  }),
  error: PropTypes.string,
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
  disabled: PropTypes.bool,
};

DateReceived.defaultProps = {
  itemData: {},
  id: "",
  config: {},
  error: "",
  updateItem: null,
  updateItemValidationError: null,
  disabled: false,
};

DateReceived.displayName = "ProductReceived";

export default DateReceived;
