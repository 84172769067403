import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { CaseType } from "../../../constants/caseType";
// eslint-disable-next-line import/no-cycle
import { ContactInfo } from "../index";
import {
  Attachments,
  CaseItems,
  ReasonIdentifier,
  StatementRequiredRadio,
  DefectDetectedBy,
  CaseDescriptionInput,
  SuspectedInfectiousRadio,
  InformationConfirmation,
  IsCustomerCompensated,
  ReturnPackagesAmount,
} from "../common";
import AdverseEvent from "./adverseEvent";
import { shouldShowSubmitFields } from "../../../utils/case/case";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "green",
    },
  },
  container: {
    padding: theme.spacing(4),
    minHeight: 580,
    marginLeft: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(3),
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textTransform: "uppercase",
    fontSize: "1rem",
    color: "#2b3e5b",
  },
  formControl: {
    marginBottom: theme.spacing(5),
  },
  rowContainer: {
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    position: "relative",
  },
}));

function SuspectedProductDefectFields({
  reclamationCase,
  isNewCase,
  disabled,
  caseConfig,
  editMode,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { SuspectedProductDefectReasonIdentifiers } = useSelector(
    state => state.fieldsConfig
  );
  const { userData } = useSelector(state => state.user);
  const contactInfo = useSelector(state => state.contactInfo);
  const [attachmentError, setAttachmentError] = useState(null);

  return (
    <div>
      <ContactInfo
        customerId={reclamationCase.customerId}
        deliveryAddressId={reclamationCase.deliveryAddressId}
        contactPerson={reclamationCase.contactPersonName}
        phoneNumber={reclamationCase.contactPhoneNumber}
        disabled={disabled}
        isNewCase={isNewCase}
      />
      <Divider className={classes.divider} />
      <Typography className={classes.title} variant="h5" color="secondary">
        {t("caseInformation.caseInformation")}
      </Typography>
      <ReasonIdentifier
        reclamationCase={reclamationCase}
        reasons={Object.values(SuspectedProductDefectReasonIdentifiers || [])}
        disabled={disabled}
        editMode={editMode}
        className={classes.formControl}
      />
      <CaseItems disabled={disabled} caseConfig={caseConfig} />
      {caseConfig && caseConfig.detectedBy && (
        <DefectDetectedBy
          caseConfig={caseConfig}
          classes={classes}
          reclamationCase={reclamationCase}
          disabled={disabled}
        />
      )}
      {caseConfig && caseConfig.caseDescription && (
        <CaseDescriptionInput
          classes={classes}
          reclamationCase={reclamationCase}
          title={t("defectDetails.title")}
          subtitle={t("defectDetails.subtitle")}
          placeholder={t("defectDetails.placeholder")}
          caseConfig={caseConfig}
          disabled={disabled}
        />
      )}
      {caseConfig &&
        caseConfig.isCustomerCompensated &&
        reclamationCase &&
        reclamationCase.detectedBy &&
        reclamationCase.detectedBy.code === "CONSUMER" && (
          <IsCustomerCompensated
            classes={classes}
            reclamationCase={reclamationCase}
            caseConfig={caseConfig}
            disabled={disabled}
          />
        )}
      <StatementRequiredRadio disabled={disabled} classes={classes} />
      {caseConfig &&
        caseConfig.suspectedAdverseReaction &&
        !reclamationCase.sourceReclamationId && (
          <AdverseEvent
            id="suspected-adverse-reaction"
            suspectedAdverseReaction={reclamationCase.suspectedAdverseReaction}
            caseConfig={caseConfig}
            disabled={disabled}
          />
        )}
      <Attachments
        reclamationCase={reclamationCase}
        disabled={disabled}
        existingAttachments={reclamationCase.attachments || []}
        id="reclamation-file-upload"
        attachmentError={attachmentError}
        setAttachmentError={setAttachmentError}
      />
      <Divider className={classes.divider} />
      <Typography className={classes.title} variant="h5" color="secondary">
        {t("submit")}
      </Typography>
      <SuspectedInfectiousRadio disabled={disabled} />
      {shouldShowSubmitFields(reclamationCase, contactInfo, userData) && (
        <>
          <ReturnPackagesAmount />
          <InformationConfirmation />
        </>
      )}
    </div>
  );
}

SuspectedProductDefectFields.propTypes = {
  history: PropTypes.shape({}),
  reclamationCase: PropTypes.shape({
    customerId: PropTypes.string,
    deliveryAddressId: PropTypes.string,
    contactPersonName: PropTypes.string,
    contactPhoneNumber: PropTypes.string,
    caseType: PropTypes.string,
    sourceReclamationId: PropTypes.string,
    detectedBy: PropTypes.shape({
      code: PropTypes.string,
    }),
    suspectedAdverseReaction: PropTypes.shape({}),
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isNewCase: PropTypes.bool,
  disabled: PropTypes.bool,
  editMode: PropTypes.bool,
  userData: PropTypes.shape({}),
  caseConfig: PropTypes.shape({
    detectedBy: PropTypes.shape({
      enumerated: PropTypes.arrayOf(PropTypes.string),
    }),
    suspectedAdverseReaction: PropTypes.shape({
      report: PropTypes.bool,
    }),
    isCustomerCompensated: PropTypes.shape({
      enumerated: PropTypes.arrayOf(PropTypes.string),
    }),
    caseDescription: PropTypes.string,
    returnPackagesAmount: PropTypes.bool,
    informationConfirmation: PropTypes.bool,
  }),
};

SuspectedProductDefectFields.defaultProps = {
  reclamationCase: { caseType: CaseType.DAMAGED_PRODUCT },
  isNewCase: false,
  disabled: false,
  editMode: false,
  caseConfig: null,
  userData: {},
  history: {},
};

export default SuspectedProductDefectFields;
