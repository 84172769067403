import { IAttachment, ICase } from "../../../interfaces/case";
import { IMessage } from "../../../interfaces/message";
import { downloadAttachment } from "../../../utils/attachments/attachments";
import { getMessagesAttachments, getSPDAttachments } from "./attachments";

const handleOpenFile = (
  reclamationCase: Partial<ICase>,
  messages: IMessage[],
  attachment: IAttachment
) => {
  const attachmentList = (reclamationCase.attachments || []).slice(0);
  const handlingAttachmentResult = getSPDAttachments(reclamationCase);
  attachmentList.push(...handlingAttachmentResult.productHandlingAttachments);
  attachmentList.push(...handlingAttachmentResult.conclusionAttachments);
  attachmentList.push(...getMessagesAttachments(messages));
  const index = attachmentList.findIndex(
    (entry: IAttachment) => entry.id === attachment.id
  );
  if (index >= 0) {
    const targetAttachment = attachmentList[index];
    downloadAttachment(
      targetAttachment.id,
      reclamationCase.reclamationId,
      targetAttachment.contentType
    );
  }
};

export default handleOpenFile;
