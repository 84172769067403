import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// eslint-disable-next-line import/no-cycle
import { FieldTitle } from "..";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";
import CssTextField from "../../../common/cssTextField/cssTextField";

function ProductCode({
  itemData,
  classes,
  id,
  error,
  updateItem,
  updateItemValidationError,
  height,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const { product, productCode } = itemData;
  useEffect(() => {
    // item.product level
    if (product) {
      const { gtinCodes } = product;
      let gtinCode = "";

      if (gtinCodes && gtinCodes.length > 0) {
        gtinCode = gtinCodes[0].gtin;
        setValue(gtinCode);
      } else if (productCode) {
        setValue(productCode);
      } else {
        setValue("-");
      }
    }
  }, [product, productCode]);
  return (
    <div>
      <FieldTitle
        title={t("caseItemFields.productCode")}
        optionalLabelHeight={height.optional}
      />
      <div className={classes.controlContainer}>
        <CssTextField
          error={!!error}
          className={classes.productCodeField}
          helperText={getValidationErrorMessage(error, t)}
          value={value}
          variant="outlined"
          margin="dense"
          width="300"
          onChange={e => {
            setValue(e.target.value);
            dispatch(
              updateItemValidationError(itemData.uuid, { productCode: "" })
            );
            dispatch(
              updateItem({
                ...itemData,
                productCode: e.target.value,
              })
            );
          }}
          id={`${id}-product-code`}
          disabled={itemData.product && !itemData.product.customerAddedProduct}
        />
      </div>
    </div>
  );
}

ProductCode.propTypes = {
  itemData: PropTypes.shape({
    product: PropTypes.shape({
      customerAddedProduct: PropTypes.bool,
      gtinCodes: PropTypes.arrayOf(
        PropTypes.shape({
          gtin: PropTypes.string,
        })
      ),
    }),
    productCode: PropTypes.string,
    uuid: PropTypes.string,
  }),
  classes: PropTypes.shape({
    controlContainer: PropTypes.string,
    productCodeField: PropTypes.string,
  }),
  id: PropTypes.string,
  height: PropTypes.shape({
    optional: PropTypes.string,
  }),
  error: PropTypes.string,
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
};

ProductCode.defaultProps = {
  itemData: {},
  classes: {},
  id: "",
  height: {
    optional: "",
  },
  error: "",
  updateItem: null,
  updateItemValidationError: null,
};

export default ProductCode;
