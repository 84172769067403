import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Dropdown, OriolaColors } from "@oriola-origo/origo-ui-core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { StatefullTextField } from "../../common";
import { updateCase, updateValidationError } from "../../../redux";
import {
  FieldErrors,
  getValidationErrorMessage,
} from "../../../utils/validations/validations";
import { AgeSelection, FieldTitle } from "../common";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
  },
  rowContainer: {
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  inputLabelContainer: {
    paddingBottom: theme.spacing(2),
  },
  inputLabel: {
    ...theme.typography.subtitle2,
    color: "#757070",
    marginRight: theme.spacing(2),
  },
  dropdown: {
    width: "16rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  inputField: {
    marginTop: "0px",
    marginBottom: "0px",
  },
  errorText: {
    fontSize: "1rem",
    color: theme.palette.error.main,
    marginBottom: theme.spacing(3),
  },
}));

function AdverseEvent({ suspectedAdverseReaction, caseConfig, id, disabled }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const config = caseConfig.suspectedAdverseReaction;

  const ageGroupOptions = config.ageGroup.enumerated;
  const genderOptions = config.gender.enumerated;
  const { suspectedAdverseReactionError } = useSelector(
    state => state.validationErrors
  );

  const { report, gender, ageGroup, ageYears, ageUnknown, description } =
    suspectedAdverseReaction;

  const {
    gender: genderError,
    age: ageError,
    description: descriptionError,
  } = suspectedAdverseReactionError;

  const updateData = data => {
    dispatch(
      updateCase({
        suspectedAdverseReaction: {
          ...suspectedAdverseReaction,
          ...data,
        },
      })
    );
  };

  const updateFieldError = error => {
    dispatch(
      updateValidationError({
        suspectedAdverseReactionError: {
          ...suspectedAdverseReactionError,
          ...error,
        },
      })
    );
  };

  const handleToggle = event => {
    updateData({ report: event.target.checked });
  };

  const handleGenderChange = value => {
    updateFieldError({ gender: "" });
    updateData({ gender: value });
  };
  const handleDescriptionChanged = text => {
    if (text && text.length > config.description.maxLength) {
      updateFieldError({ description: FieldErrors.OVER_LENGTH });
    } else {
      updateFieldError({ description: "" });
    }
    updateData({ description: text });
  };

  const renderToggle = () => (
    <div>
      <FormControlLabel
        id={id}
        control={
          <Checkbox
            style={{ color: OriolaColors.secondaryNavyBlue }}
            checked={report || false}
            onChange={handleToggle}
          />
        }
        label={t("caseInformation.adverseEvent.adverseEventToggleText")}
        disabled={disabled}
      />
    </div>
  );

  // yay, it rhymes!
  const renderGender = () => (
    <div className={classes.rowContainer}>
      <div className={classes.inputLabelContainer}>
        <Typography className={classes.inputLabel} variant="subtitle2" noWrap>
          {t("caseInformation.gender")}
        </Typography>
      </div>
      <Dropdown
        id={`${id}-gender`}
        data-error-name="gender"
        error={!!genderError}
        errorText={getValidationErrorMessage(genderError, t)}
        className={classes.dropdown}
        value={gender}
        emptySelectionLabel={t("select")}
        onFormatOptionLabel={x => t(`gender.${x}`)}
        options={genderOptions}
        onSelectOption={handleGenderChange}
        disabled={disabled}
      />
    </div>
  );

  const onAgeChange = ({
    ageYears: nAgeYears,
    ageGroup: nAgeGroup,
    ageUnknown: nageUnknown,
  }) => {
    updateFieldError({ age: "" });
    updateData({
      ageYears: nAgeYears,
      ageGroup: nAgeGroup,
      ageUnknown: nageUnknown,
    });
  };

  const renderDescription = () => (
    <div className={classes.rowContainer} data-error-name="description">
      <div className={classes.inputLabelContainer}>
        <FieldTitle
          title={t("caseInformation.adverseEvent.adverseEventDescription")}
          subtitle={t(
            "caseInformation.adverseEvent.adverseEventDescriptionDetail"
          )}
          className={classes.inputLabel}
          variant="subtitle2"
          noWrap
        />
      </div>
      <StatefullTextField
        id={`${id}-description`}
        error={!!descriptionError}
        helperText={getValidationErrorMessage(
          descriptionError,
          t,
          config.description
        )}
        fullWidth
        multiline
        rows="3"
        initialValue={description}
        variant="outlined"
        className={classes.inputField}
        placeholder={t(
          "caseInformation.adverseEvent.adverseEventDescriptionPlaceholder"
        )}
        margin="dense"
        onChange={handleDescriptionChanged}
        disabled={disabled}
      />
    </div>
  );

  return (
    <div
      className={classes.container}
      data-error-name="suspectedAdverseReactionError"
    >
      <div className={classes.rowContainer}>
        <Typography className={classes.inputLabel} variant="subtitle2">
          {t("caseInformation.adverseEvent.heading")}
        </Typography>
        {renderToggle()}
      </div>
      {report && renderGender()}
      {report && (
        <AgeSelection
          id={`${id}-age`}
          classes={classes}
          ageYears={ageYears}
          ageGroup={ageGroup}
          ageUnknown={ageUnknown}
          ageError={ageError}
          ageGroupOptions={ageGroupOptions}
          onDataChange={onAgeChange}
          disabled={disabled}
        />
      )}
      {report && renderDescription()}
    </div>
  );
}

AdverseEvent.propTypes = {
  id: PropTypes.string.isRequired,
  suspectedAdverseReaction: PropTypes.shape({
    report: PropTypes.bool,
    ageYears: PropTypes.number,
    ageGroup: PropTypes.string,
    ageUnknown: PropTypes.bool,
    description: PropTypes.string,
    gender: PropTypes.string,
  }),
  caseConfig: PropTypes.PropTypes.shape({
    suspectedAdverseReaction: PropTypes.shape({
      ageGroup: PropTypes.shape({
        enumerated: PropTypes.arrayOf(PropTypes.string),
      }),
      gender: PropTypes.shape({
        enumerated: PropTypes.arrayOf(PropTypes.string),
      }),
      description: PropTypes.shape({
        maxLength: PropTypes.number,
      }),
      ageYears: PropTypes.number,
      ageUnknown: PropTypes.bool,
    }),
  }),
  disabled: PropTypes.bool,
};

AdverseEvent.defaultProps = {
  suspectedAdverseReaction: {},
  caseConfig: {},
  disabled: false,
};

export default AdverseEvent;
