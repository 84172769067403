import PropTypes from "prop-types";
import { CanWrapper } from "@oriola-origo/origo-ui-core";
import { isAllowed } from "./permission";

const Can = CanWrapper(isAllowed);

Can.propTypes = {
  user: PropTypes.shape({}),
  customerContext: PropTypes.string,
  perform: PropTypes.string,
  data: PropTypes.shape({}),
  ok: PropTypes.func,
  nok: PropTypes.func,
};

export default Can;
