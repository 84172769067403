import React from "react";
import PropTypes from "prop-types";
import { AlertDialog } from "@oriola-origo/origo-ui-core";
import { Typography, Box, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { ReOpenCaseIcon } from "../../../images";

const useStyles = makeStyles(theme => ({
  errorText: {
    fontSize: "1rem",
    color: theme.palette.error.main,
    marginBottom: theme.spacing(3),
  },
  contentText: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.disabled,
  },
}));

function ReopenCaseDialog({
  open,
  handleCloseDialog,
  handleContinue,
  message,
  setMessage,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <AlertDialog
      open={open}
      onCancel={handleCloseDialog}
      id="reopen-case-dialog"
      onOk={() => {
        handleContinue();
      }}
      title={t("reopenCase.button")}
      cancelButtonText={t("reopenCase.cancel")}
      okButtonText={t("reopenCase.confirm")}
      disableBackdropClick
      width="360px"
      maxWidth="xs"
    >
      <Box mb={8}>
        <Box py={5} display="flex" alignItems="center" justifyContent="center">
          <Box>
            <ReOpenCaseIcon />
          </Box>
        </Box>

        <Box width="360px">
          <Typography
            style={{ fontWeight: 600 }}
            className={classes.contentText}
            paragraph
          >
            {t("reopenCase.reason")}
          </Typography>
          <TextField
            value={message}
            onChange={e => setMessage(e.target.value)}
            id="reopen-reason-input"
            fullWidth
            multiline
            rows="3"
            variant="outlined"
            placeholder={t("message.placeholder")}
            margin="dense"
          />
        </Box>
      </Box>
    </AlertDialog>
  );
}

ReopenCaseDialog.propTypes = {
  open: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  handleContinue: PropTypes.func,
  message: PropTypes.string,
  setMessage: PropTypes.func,
};

ReopenCaseDialog.defaultProps = {
  open: false,
  handleCloseDialog: null,
  handleContinue: null,
  message: "",
  setMessage: null,
};

export default ReopenCaseDialog;
