import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import {
  updateLocalHandlingDataField,
  updateValidationError,
} from "../../../redux";
import DateAndEditor from "../dateAndEditor";
import { TitleInput, Badge } from "../../common";
import { getSpdModificationData } from "../../../utils/getSpdModificationData/getSpdModificationData";
import {
  getValidationErrorMessage,
  FieldErrors,
  TRACKING_CODE_MAX_LENGTH,
} from "../../../utils/validations/validations";
import Title from "../title/title";
import CheckBoxWithLabel from "../checkBoxWithLabel";
import { isOriolaUser } from "../../auth/permission";

const useStyles = makeStyles(() => ({
  controlContainer: {
    display: "flex",
    flexGrow: 1,
    position: "relative",
  },
}));

function ParcelTracking({ disabled, user }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { localHandlingData = {}, case: reclamationCase } = useSelector(
    state => state.case
  );
  const { trackingCodeError } = useSelector(state => state.validationErrors);
  const {
    trackingCode,
    modifications = {},
    isTrackingCodeReadConfirmed,
  } = localHandlingData;

  const storedTrackingCode =
    reclamationCase.items[0]?.handlingData?.trackingCode;
  const setTrackingCodeReadConfirmation = useCallback(
    e => {
      dispatch(
        updateLocalHandlingDataField(
          e.target.checked,
          "isTrackingCodeReadConfirmed"
        )
      );
    },
    [dispatch]
  );

  const trackingCodeModifications = getSpdModificationData(
    modifications,
    "trackingCode"
  );
  return (
    <div id="tracking-code" className="handling-section">
      <Title title={t("parcelTracking.title")} />
      <Box>
        <div className={classes.controlContainer}>
          <Box width={540}>
            <TitleInput
              mt={0}
              disabled={disabled}
              inputClassName={disabled ? classes.input : null}
              value={trackingCode}
              error={!!trackingCodeError}
              title={t("parcelTracking.label")}
              secondaryTitle={t("optional")}
              onChange={value => {
                if (value.length < TRACKING_CODE_MAX_LENGTH) {
                  dispatch(updateValidationError({ trackingCodeError: "" }));
                } else {
                  dispatch(
                    updateValidationError({
                      trackingCodeError: FieldErrors.TRACKING_CODE,
                    })
                  );
                }
                dispatch(updateLocalHandlingDataField(value, "trackingCode"));
              }}
              rows={1}
              enableCopyButton
              helperText={getValidationErrorMessage(trackingCodeError, t)}
            />
          </Box>
        </div>
        {trackingCode && (
          <DateAndEditor
            modificationData={trackingCodeModifications}
            user={user}
          />
        )}
        {storedTrackingCode &&
          storedTrackingCode === trackingCode &&
          isOriolaUser(user) && (
            <Box position="relative">
              <Badge
                text={t("oriola")}
                sx={{ position: "absolute", right: 0, top: 5 }}
              />
              <CheckBoxWithLabel
                key="isTrackingCodeReadConfirmed"
                title={t("readConfirmation")}
                fieldName="isTrackingCodeReadConfirmed"
                modificationData={getSpdModificationData(
                  modifications,
                  "isTrackingCodeReadConfirmed"
                )}
                onChecked={setTrackingCodeReadConfirmation}
                checked={isTrackingCodeReadConfirmed}
                user={user}
              />
            </Box>
          )}
      </Box>
    </div>
  );
}
ParcelTracking.propTypes = {
  disabled: PropTypes.bool,
  user: PropTypes.shape({}),
};

ParcelTracking.defaultProps = {
  disabled: false,
  user: {},
};
export default ParcelTracking;
