import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "../row/row";
import { TranslateField } from "../translateFieldDialog/translateFieldDialog";
import TranslateButton from "../translateButton/translateButton";
import RowComponent from "../row/rowComponent";
import TranslatedText from "../translatedText/translatedText";

function AdverseReaction({
  caseConfig,
  suspectedAdverseReaction,
  onTranslateClick,
  translatedText,
  showTranslateButton,
  showTranslatedText,
}) {
  const { t } = useTranslation();

  const getAgeRowValue = () => {
    let ageTitle = "";
    let age = "";
    if (suspectedAdverseReaction.ageGroup) {
      ageTitle = t("caseInformation.ageInputMethod.AGE_GROUP");
      age = t(`ageGroup.${suspectedAdverseReaction.ageGroup}`);
      return [`${ageTitle}:${age}`];
    }
    if (suspectedAdverseReaction.ageYears) {
      ageTitle = t("caseInformation.ageInputMethod.AGE_YEARS");
      age = t(`${suspectedAdverseReaction.ageYears}`);
      return [`${ageTitle}:${age}`];
    }
    if (suspectedAdverseReaction.ageUnknown) {
      return [t("caseInformation.ageInputMethod.AGE_UNKNOWN")];
    }
    return ["-"];
  };
  if (
    !caseConfig ||
    !caseConfig.suspectedAdverseReaction ||
    !suspectedAdverseReaction
  ) {
    return null;
  }

  return (
    <>
      <Row
        rowLeft={["caseInformation.adverseReaction.rowTitle"]}
        rowRight={[suspectedAdverseReaction.report ? t("yes") : t("no")]}
      />
      {suspectedAdverseReaction.report && (
        <>
          <Row
            rowLeft={["caseInformation.gender"]}
            rowRight={[
              suspectedAdverseReaction.gender
                ? t(`gender.${suspectedAdverseReaction.gender}`)
                : "-",
            ]}
          />
          <Row
            rowLeft={["caseInformation.ageRowTitle"]}
            rowRight={[getAgeRowValue()]}
          />
          <RowComponent
            rowTitleKey="caseInformation.adverseReaction.description"
            rowValueComponent={
              <TranslatedText
                originalText={suspectedAdverseReaction.description || "-"}
                translatedText={translatedText}
                hideTranslatedText={showTranslatedText === false}
              />
            }
            rowButtonComponent={
              showTranslateButton ? (
                <TranslateButton
                  id="adverse-reaction-translate-button"
                  onClick={() =>
                    onTranslateClick(
                      TranslateField.ADVERSE_REACTION,
                      suspectedAdverseReaction.description,
                      translatedText
                    )
                  }
                />
              ) : null
            }
          />
        </>
      )}
    </>
  );
}

AdverseReaction.propTypes = {
  suspectedAdverseReaction: PropTypes.shape({
    ageGroup: PropTypes.string,
    ageYears: PropTypes.string,
    ageUnknown: PropTypes.bool,
    gender: PropTypes.string,
    report: PropTypes.bool,
    description: PropTypes.string,
  }),
  caseConfig: PropTypes.PropTypes.shape({
    suspectedAdverseReaction: PropTypes.shape({
      report: PropTypes.bool,
    }),
  }),
  onTranslateClick: PropTypes.func,
  translatedText: PropTypes.string,
  showTranslateButton: PropTypes.bool,
  showTranslatedText: PropTypes.bool,
};

AdverseReaction.defaultProps = {
  suspectedAdverseReaction: {},
  caseConfig: {},
  onTranslateClick: null,
  translatedText: "",
  showTranslateButton: false,
  showTranslatedText: false,
};

export default AdverseReaction;
