import React from "react";
import { Radio, FormControlLabel } from "@material-ui/core";

const radioButton = (key, label, value, disable = false) => (
  <FormControlLabel
    key={key}
    value={value}
    label={label}
    labelPlacement="end"
    control={<Radio />}
    disabled={disable}
  />
);

export default radioButton;
