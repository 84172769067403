import React from "react";
import PropTypes from "prop-types";
import { Box, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import xmlFormatter from "xml-formatter";
import Title from "../title/title";

function NMVSError({ sfmdXml, ...containerProps }) {
  const { t } = useTranslation();

  return (
    <Box {...containerProps}>
      <Title title={t("nmvsError")} />
      <TextField
        id="handling-information-input"
        fullWidth
        multiline
        rows="10"
        value={xmlFormatter(sfmdXml.replaceAll("> <", "><"), {
          collapseContent: true,
        })}
        variant="outlined"
        margin="dense"
        disabled
      />
    </Box>
  );
}

NMVSError.propTypes = {
  sfmdXml: PropTypes.string,
};

NMVSError.defaultProps = {
  sfmdXml: "",
};

export default NMVSError;
