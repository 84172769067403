import React from "react";
import { Box, Typography } from "@material-ui/core";
import { TransParentCloseIcon, BlackCloseIcon } from "../../../../images";

const renderSelectedItems = ({
  selectedProfitCenters,
  selectedSuppliers,
  removeSelectedProfitCenter,
  removeSelectedSupplier,
  classes,
}) => (
  <Box mt={2} display="flex" flexWrap="wrap">
    {selectedProfitCenters.map(profitCenter => (
      <div
        className={`${classes.selectedItem} ${classes.selectedProfitCenter}`}
        key={profitCenter.number}
      >
        <Typography variant="body1">{`${profitCenter.number} - ${profitCenter.name}`}</Typography>
        <Box
          ml={1}
          style={{ cursor: "pointer" }}
          onClick={() => {
            removeSelectedProfitCenter(profitCenter);
          }}
        >
          <TransParentCloseIcon />
        </Box>
      </div>
    ))}
    {selectedSuppliers.map(supplier => (
      <div
        className={`${classes.selectedItem} ${classes.selectedSupplier}`}
        key={supplier.number}
      >
        <Typography variant="body1">{`${supplier.number} - ${supplier.name}`}</Typography>
        <Box
          ml={1}
          style={{ cursor: "pointer" }}
          onClick={() => {
            removeSelectedSupplier(supplier);
          }}
        >
          <BlackCloseIcon />
        </Box>
      </div>
    ))}
  </Box>
);

export default renderSelectedItems;
