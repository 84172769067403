import {
  NewReturnAddressFormat,
  ReturnAddressModificationData,
  ReturnAddressSubfield,
} from "../../productHandlingInstructions/returnAddress/types";
import { ModificationData } from "../../../../interfaces/case";

export const prepareNewReturnAddressText = (
  returnAddress: NewReturnAddressFormat
) =>
  `${returnAddress.company}\n${returnAddress.contactName}\n${returnAddress.phoneNumber}\n${returnAddress.address}\n${returnAddress.country}`;

export const formatReturnAddressValue = (
  isNewReturnAddressFormat: boolean,
  value: NewReturnAddressFormat
) => (!isNewReturnAddressFormat ? value : prepareNewReturnAddressText(value));

const getMostRecentlyUpdatedReturnAddressField = (
  returnAddressFields: [ReturnAddressSubfield, ModificationData][]
) => {
  const mostRecentUpdatedReturnAddressField = returnAddressFields.reduce(
    (max, item) => {
      const [, { modificationTime: itemModtime }] = item;
      const { modificationTime: maxModtime } = max[1];

      return new Date(itemModtime) > new Date(maxModtime) ? item : max;
    },
    returnAddressFields[0]
  );

  if (!mostRecentUpdatedReturnAddressField) {
    return { field: null, modification: null };
  }
  const [field, modification] = mostRecentUpdatedReturnAddressField;

  return { field, modification };
};

export const preparePathToReturnAddressValue = (
  isNewReturnAddress: boolean,
  modificationData: ReturnAddressModificationData
) => {
  if (!isNewReturnAddress) {
    return "productHandlingInstructions.returnAddress";
  }
  delete modificationData.userId;
  delete modificationData.userName;

  const returnAddressFields = Object.entries(modificationData) as Array<
    [ReturnAddressSubfield, ModificationData]
  >;
  const { field } =
    getMostRecentlyUpdatedReturnAddressField(returnAddressFields);

  return `productHandlingInstructions.returnAddress.${field}`;
};

export const getModificationDataToReturnAddress = (
  modificationData: ReturnAddressModificationData
) => {
  const returnAddressFields = Object.entries(modificationData) as Array<
    [ReturnAddressSubfield, ModificationData]
  >;
  const { modification } =
    getMostRecentlyUpdatedReturnAddressField(returnAddressFields);

  return modification;
};
