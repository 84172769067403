import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import Field from "./field";
import DetailRow from "./detailRow";
import {
  getProductDisplayName,
  formatProductCompact,
} from "../../../utils/productUtil/productUtil";

function ItemFields({ item, mainProductField, config }) {
  const { t, i18n } = useTranslation();

  const field = (key, i18nKey, targetItem, format) => {
    const value = format ? format(targetItem[key]) : targetItem[key];
    return config[key] ? (
      <DetailRow keyName={t(i18nKey)} value={value} />
    ) : null;
  };

  const product = (targetItem, sourceField = "product") => {
    const prd = targetItem[sourceField] || {};
    return config[sourceField] ? (
      <>
        <DetailRow
          keyName={t("caseItemFields.productName")}
          value={getProductDisplayName(prd, i18n.language)}
        />
        <DetailRow keyName={t("productNo")} value={prd.materialId} />
        <DetailRow keyName={t("vnrmsi")} value={prd.vnr || prd.msiCode} />
        {prd.profitCenterName && (
          <DetailRow
            keyName={t("caseItemFields.principal")}
            value={prd.profitCenterName}
            note={prd.customerAddedProduct && t("manualEntry")}
          />
        )}
        {prd.pharmaceuticalForm && (
          <DetailRow
            keyName={t("caseItemFields.pharmaceuticalForm")}
            value={ProductUtil.getProductPharmaceuticalForm(prd, i18n.language)}
          />
        )}
        {prd.narcoticsClassFI && (
          <DetailRow
            keyName={t("caseItemFields.narcoticsClass")}
            value={prd.narcoticsClassFI}
          />
        )}
      </>
    ) : null;
  };

  const deliveredProduct = targetItem => {
    const prd = targetItem.deliveredProduct || {};
    return config.deliveredProduct ? (
      <DetailRow
        keyName={t("caseItemFields.deliveredProduct")}
        value={formatProductCompact(prd, i18n.language, t)}
      />
    ) : null;
  };

  const formatPrice = s => (s ? `${s} ${t("caseItemFields.priceUnit")}` : "");

  const formatCustomer = c => {
    const { id, name } = c || {};
    return [id, name].filter(x => x).join(", ");
  };

  return (
    <>
      {Field.orderNumber(item, field)}
      {product(item, mainProductField)}
      {Field.invoiceNumber(item, field)}
      {Field.dateReceived(item, field)}
      {Field.amountOfPackages(item, field)}
      {Field.quantity(item, field)}
      {Field.quantityOrdered(item, field)}
      {Field.quantityMissing(item, field)}
      {deliveredProduct(item)}
      {Field.quantityReceived(item, field)}
      {Field.quantityReturned(item, field)}
      {Field.batchNumber(item, field, t("batchNumberNotAvailable"))}
      {Field.expirationDate(item, field)}
      {Field.stored(item, field, x => (x ? t(`storageTemperature.${x}`) : ""))}
      {Field.pricePerUnitCharged(item, field, formatPrice)}
      {Field.pricePerUnit(item, field, formatPrice)}
      {Field.salesRepresentative(item, field)}
      {Field.whoAgreedReturn(item, field)}
      {Field.reasonForReturn(item, field)}
      {Field.reasonForProductRecall(item, field)}
      {Field.reasonForRefund(item, field)}
      {Field.transferToAnotherCustomer(item, field, formatCustomer)}
      {Field.moreInfo(item, field)}
      {Field.expectedReceiveDate(item, field)}
      {Field.shippingBoxCode(item, field)}
      {Field.productCode(item, field)}
      {Field.uniqueSerialNumber(item, field)}
      {Field.alertCode(item, field)}
    </>
  );
}

ItemFields.propTypes = {
  item: PropTypes.shape({}),
  mainProductField: PropTypes.string,
  config: PropTypes.shape({
    deliveredProduct: PropTypes.shape({}),
  }),
  userData: PropTypes.shape({}),
};

ItemFields.defaultProps = {
  item: null,
  mainProductField: null,
  config: null,
  userData: null,
};

export default ItemFields;
