const EventSubQueueTarget = Object.freeze({
  RETURN_DEPARTMENT_SUBQUEUES: {
    RETURN_DEPARTMENT_NO_SUBQUEUE: "NONE",
    RETURN_DEPARTMENT_SUB_1: "RETURN_DEPARTMENT_SUB_1",
    RETURN_DEPARTMENT_SUB_2: "RETURN_DEPARTMENT_SUB_2",
    RETURN_DEPARTMENT_SUB_3: "RETURN_DEPARTMENT_SUB_3",
    RETURN_DEPARTMENT_SUB_4: "RETURN_DEPARTMENT_SUB_4",
    RETURN_DEPARTMENT_SUB_5: "RETURN_DEPARTMENT_SUB_5",
  },
});

export default EventSubQueueTarget;
