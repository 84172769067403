import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line import/no-cycle
import {
  updateContactInfo,
  updateValidationError,
  updateCase,
} from "../../../../redux";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";
import { FieldTitle } from "..";

function ContactPersonInput({ contactInfo, disabled, classes }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { contactPersonError } = useSelector(state => state.validationErrors);

  return (
    <div
      className={classes.selectContainer}
      data-error-name="contactPersonError"
    >
      <FieldTitle title={t("contactInformation.contactPerson")} />
      <div>
        <TextField
          value={contactInfo.contactPerson}
          variant="outlined"
          placeholder={t("contactInformation.contactPerson")}
          className={classes.textField}
          margin="dense"
          onChange={event => {
            dispatch(updateContactInfo({ contactPerson: event.target.value }));
            dispatch(updateCase({ contactPersonName: event.target.value }));
            dispatch(updateValidationError({ contactPersonError: "" }));
          }}
          id="contact-info-person"
          disabled={disabled}
          error={!!contactPersonError}
          helperText={getValidationErrorMessage(contactPersonError, t)}
        />
      </div>
    </div>
  );
}

ContactPersonInput.propTypes = {
  contactInfo: PropTypes.shape({
    contactPerson: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    selectContainer: PropTypes.string,
    textField: PropTypes.string,
  }),
};

ContactPersonInput.defaultProps = {
  contactInfo: {},
  classes: {},
  disabled: false,
};

export default ContactPersonInput;
