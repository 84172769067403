import { RestService } from "../../components/common";
import { CaseType } from "../../constants/caseType";
import { baseUrl, Case, reclamationApi } from "./constants";
import SPDDetectedBy from "../../constants/spdDetectedBy";

const mapCopiedCommonFields = reclamationCase => {
  const commonFieldsOfCase = [
    "customerId",
    "customerName",
    "contactPersonName",
    "deliveryAddress",
    "deliveryAddressId",
    "contactPhoneNumber",
    "creator",
    "attachments",
    "caseDescription",
    "courierCompanyId",
    "confirm",
  ];

  const newReclamationCase = {};

  commonFieldsOfCase.forEach(field => {
    if (reclamationCase[field]) {
      newReclamationCase[field] = reclamationCase[field];
    }
  });
  return newReclamationCase;
};

const mapCopiedProductReturn = (
  newCaseType,
  newReasonIdentifier,
  productReturn = {},
  ProductReturnReasonIdentifiersConfig = {}
) => {
  if (newCaseType === CaseType.SUSPECTED_PRODUCT_DEFECT) {
    return {
      amount: productReturn.amount,
      returnValue: !!productReturn.amount,
    };
  }
  // Map user selected reason identifier to matching product return value
  // like how original PR/IC cases are created
  if (newCaseType === CaseType.PRODUCT_DELIVERY) {
    return ProductReturnReasonIdentifiersConfig.willReturnProduct[
      newReasonIdentifier
    ]
      ? { amount: productReturn.amount, returnValue: true }
      : { returnValue: false };
  }
  return productReturn;
};

const mapCopiedItem = item => {
  const newItem = {
    ...item,
  };
  delete newItem.handlingData;
  return newItem;
};

export const mapCaseToCopiedCase =
  (newCaseType, newReasonIdentifier, newProductOrder) =>
  (dispatch, getState) => {
    const { sourceCase } = getState().case;
    const {
      SuspectedProductDefectReasonIdentifiers,
      ProductReturnReasonIdentifiersConfig,
    } = getState().fieldsConfig;
    const newReclamationCase = {
      ...mapCopiedCommonFields(sourceCase),
      sourceReclamationId: sourceCase.reclamationId,
      url: `${window.location.origin}/case/[reclamationId]`,
      caseType: newCaseType,
      reasonIdentifier: newReasonIdentifier,
      detectedBy:
        newCaseType === CaseType.SUSPECTED_PRODUCT_DEFECT
          ? {
              code:
                newReasonIdentifier ===
                SuspectedProductDefectReasonIdentifiers.FALSIFIED_MEDICINE
                  ? SPDDetectedBy.HOSPITAL
                  : SPDDetectedBy.PHARMACY,
            }
          : undefined,
      suspectedInfectiousProduct: false,
      certify: true,
      items: [mapCopiedItem(newProductOrder)],
      productReturn: {
        ...mapCopiedProductReturn(
          newCaseType,
          newReasonIdentifier,
          sourceCase.productReturn,
          ProductReturnReasonIdentifiersConfig
        ),
      },
    };

    dispatch({
      type: Case.MAP_COPIED_CASE,
      payload: {
        copiedCase: newReclamationCase,
      },
    });
  };

export const cancelEditingCopiedCase = () => (dispatch, getState) => {
  const { sourceCase } = getState().case;
  dispatch({
    type: Case.CANCEL_EDITING_COPIED_CASE,
    payload: {
      case: { ...sourceCase },
      sourceCase: null,
    },
  });
};

export const startToCopyCase = () => dispatch => {
  dispatch({
    type: Case.COPY_CASE_START,
  });
};

export const getRelatedCases = reclamationCase => async dispatch => {
  const { sourceReclamationId, relatedCases, reclamationId } = reclamationCase;
  if (sourceReclamationId) {
    const path = `${baseUrl}${reclamationApi}/${sourceReclamationId}`;
    try {
      const sourceReclamationCase = await RestService.get(path);
      const relatedCasesOfSourceReclamation =
        sourceReclamationCase.relatedCases || [];
      const newRelatedCases = relatedCases
        .concat(relatedCasesOfSourceReclamation)
        .filter(relatedCase => relatedCase.reclamationId !== reclamationId);
      dispatch({
        type: Case.UPDATE_RELATED_CASES,
        payload: newRelatedCases,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("Update related cases error");
    }
  }
};
