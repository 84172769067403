import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Checkbox } from "@oriola-origo/origo-ui-core";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import { useDispatch, useSelector } from "react-redux";
import { getProductDisplayName } from "../../../utils/productUtil/productUtil";
import { RemoveHandlingDataIcon, ModalDeleteIcon } from "../../../images";
import DefaultHandlingData from "./defaultHandlingData";
import SpdHandlingData from "./spdHandlingData/spdHandlingData";
import {
  showDialog,
  hideDialog,
  removeItemHandligData,
  fetchMessages,
  clearlocalHandlingData,
} from "../../../redux";
import { isItemHandled } from "../../../utils/case/case";
import { CaseType } from "../../../constants/caseType";
import CaseItemsType from "../../../constants/caseItemsType";
import { isOriolaUser } from "../../auth/permission";
import ItemFields from "./itemFields";
import { CaseStatus as CaseStatusConstants } from "../../../constants/caseStatus";
import { Badge } from "../../common";

const { isDisplayNarcoticsWarning } = ProductUtil;

const useStyles = makeStyles(theme => ({
  root: {
    "& .Mui-expanded": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  badge: {
    borderRadius: 4,
    height: 24,
    fontWeight: "bold",
    textAlign: "center",
    lineHeight: "24px",
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  handlingData: {
    backgroundColor: "#ECF4ED",
    border: "1px solid rgba(43, 91, 54, 0.5)",
    color: "#2B5B36",
  },
  accordionSummary: {
    display: "flex",
    "& > div": {
      flexDirection: "column",
    },
    "& > div:nth-child(1)": {
      display: "flex",
      flexDirection: "row",
      "& > h6": {
        flexGrow: 1,
      },
    },
  },
  itemCheckbox: {
    padding: "0px",
  },
}));

function AccordionItemList({
  items,
  config,
  title,
  reclamationCase,
  expandSettings,
  handleExpandChange,
  selectingItemsEnabled,
  selectedItems,
  handleItemsSelected,
  handleItemsDeselected,
  caseType,
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector(state => state.user.userData);
  const currentUserIsOriolaUser = isOriolaUser(userData);

  const closeAndRemoveHandlingData = async itemUuid => {
    const result = await dispatch(removeItemHandligData(itemUuid));
    if (result.status === "SUCCESS") {
      dispatch(clearlocalHandlingData());
    }

    dispatch(hideDialog());
    if (reclamationCase.caseStatus === CaseStatusConstants.SOLVED) {
      dispatch(fetchMessages(reclamationCase.reclamationId));
    }
  };

  const openDeleteHandlingDataModal = (id, e) => {
    if (
      !currentUserIsOriolaUser ||
      reclamationCase.caseType === CaseType.SUSPECTED_PRODUCT_DEFECT
    ) {
      return;
    }
    e.stopPropagation();
    dispatch(
      showDialog({
        open: true,
        title: t("deleteHandlingInformation.title"),
        description: t("deleteHandlingInformation.description"),
        cancelButtonText: t("deleteHandlingInformation.cancel"),
        okButtonText: t("deleteHandlingInformation.confirm"),
        onCancel: () => dispatch(hideDialog()),
        onOk: () => {
          closeAndRemoveHandlingData(id);
        },
        icon: (
          <div>
            <ModalDeleteIcon />
          </div>
        ),
        disableBackdropClick: true,
      })
    );
  };

  const renderRemoveHandlingData = (id, index) => (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      onClick={e => openDeleteHandlingDataModal(id, e)}
      className={`${classes.badge} ${classes.handlingData}`}
      id={`remove-handling-button-${index}`}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box mx={1}>
          <Typography
            align="left"
            className={classes.badge}
            variant="body2"
            fontWeight="bold"
          >
            {t("removeHandlingData")}
          </Typography>
        </Box>
        {currentUserIsOriolaUser &&
          reclamationCase.caseType !== CaseType.SUSPECTED_PRODUCT_DEFECT && (
            <RemoveHandlingDataIcon />
          )}
      </Box>
    </div>
  );

  const getMainProductField = () => {
    let fld = "product";
    if (!config[fld] && config.missingProduct) {
      fld = "missingProduct";
    }
    return fld;
  };

  const handleProductCheckboxClick = (event, item) => {
    if (event.target.checked) {
      handleItemsSelected([item]);
    } else {
      handleItemsDeselected([item]);
    }
    event.stopPropagation();
  };

  const renderHandlingData = targetItem => {
    const { handlingData } = targetItem;
    if (handlingData) {
      if (caseType === CaseType.SUSPECTED_PRODUCT_DEFECT) {
        return (
          <SpdHandlingData handlingData={handlingData} userData={userData} />
        );
      }
      return (
        <DefaultHandlingData
          handlingData={handlingData}
          isOriolaUser={currentUserIsOriolaUser}
        />
      );
    }
    return null;
  };

  const renderItems = () =>
    items.map((item, index) => {
      const mainProductField = getMainProductField();
      const prd = item[mainProductField] || {};
      const isSelected = selectedItems.includes(item.uuid);
      const key = `${item.uuid} - ${index}`;
      return (
        <Accordion
          square
          key={key}
          expanded={expandSettings[index]}
          onChange={handleExpandChange(index)}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordionSummary}
          >
            <Typography variant="subtitle2">
              {!isItemHandled(item, reclamationCase) &&
                selectingItemsEnabled && (
                  <Checkbox
                    className={classes.itemCheckbox}
                    onClick={e => handleProductCheckboxClick(e, item)}
                    checked={isSelected}
                  />
                )}
              {title === "products" && prd.materialId}{" "}
              {getProductDisplayName(prd, i18n.language)}
              {title === "shippingBoxes" &&
                `${t("shippingBox.name")} ${items.length - index}`}
              {title === "entireOrders" &&
                `${t("entireOrder.name")} ${items.length - index}`}
              {item.type === CaseItemsType.NO_ITEM &&
                t(
                  `caseInformation.reasonIdentifiers.${reclamationCase.reasonIdentifier}`
                )}
            </Typography>
            {isDisplayNarcoticsWarning(prd) && (
              <Badge text={t("narcotic")} mr={1} />
            )}
            {reclamationCase.isSFMD && <Badge text={t("sfmd")} />}
            {isItemHandled(item, reclamationCase) &&
              renderRemoveHandlingData(item.uuid, index)}
          </AccordionSummary>
          <AccordionDetails>
            <ItemFields
              item={item}
              mainProductField={mainProductField}
              config={config}
              userData={userData}
            />
            {renderHandlingData(item)}
          </AccordionDetails>
        </Accordion>
      );
    });
  return <div className={classes.root}>{renderItems()}</div>;
}

AccordionItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  config: PropTypes.shape({
    missingProduct: PropTypes.shape({}),
  }),
  reclamationCase: PropTypes.shape({
    caseStatus: PropTypes.string,
    reclamationId: PropTypes.string,
    caseType: PropTypes.string,
    reasonIdentifier: PropTypes.string,
    isSFMD: PropTypes.bool,
  }),
  title: PropTypes.string,
  expandSettings: PropTypes.arrayOf(PropTypes.bool),
  handleExpandChange: PropTypes.func,
  selectingItemsEnabled: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  handleItemsSelected: PropTypes.func,
  handleItemsDeselected: PropTypes.func,
  caseType: PropTypes.string,
};

const noop = () => {
  /* noop */
};

AccordionItemList.defaultProps = {
  items: [],
  config: {},
  reclamationCase: {},
  title: "",
  selectingItemsEnabled: false,
  selectedItems: [],
  handleItemsSelected: noop,
  handleItemsDeselected: noop,
  expandSettings: null,
  handleExpandChange: null,
  caseType: null,
};

export default AccordionItemList;
