import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import DetailRow from "../detailRow";
import { ProductDisposers } from "../../productHandlingInstructions/productHandlingInstructions";
import getModifierStrOrNull from "./getModifierStrOrNull";
import getValueIfNotNull from "./getValueIfNotNull";
import { Can, Permission, ANY_CUSTOMER } from "../../../auth";
import { isCustomer } from "../../../auth/permission";
import { checkIfNewReturnAddressFormat } from "../../../../utils/case/case";
import {
  formatReturnAddressValue,
  preparePathToReturnAddressValue,
} from "./utils";

function ProductHandlingInstructionsView({
  productHandlingInstructions,
  modifications,
  userData,
  ...containerProps
}) {
  const { t } = useTranslation();

  const {
    methodOfDisposal,
    handlingInstructions,
    isReturnToPrincipalRequired,
    returnAddress,
    isCertificateOfDestructionRequired,
    isPhotographRequested,
    photographInstructions,
  } = productHandlingInstructions;

  // Product handling instructions should be visible for the customer ONLY if
  // customer is selected to dispose the item.
  if (isCustomer(userData) && methodOfDisposal !== ProductDisposers.CUSTOMER) {
    return null;
  }

  const isNewReturnAddressFormat = checkIfNewReturnAddressFormat(returnAddress);

  return (
    <Box {...containerProps}>
      <Typography variant="subtitle2">
        {t("productHandlingInstructions.title").toUpperCase()}
      </Typography>
      <Box mt={3}>
        <Can
          user={userData}
          perform={Permission.HANDLING_DATA_PRINCIPAL_VIEW}
          customerContext={ANY_CUSTOMER}
        >
          <DetailRow
            keyName={t(
              "productHandlingInstructions.productReturnRequiredToPrincipal"
            )}
            value={getValueIfNotNull(
              isReturnToPrincipalRequired,
              isReturnToPrincipalRequired ? t("yes") : t("no")
            )}
            secondaryValue={getModifierStrOrNull(
              isReturnToPrincipalRequired !== null,
              modifications,
              "productHandlingInstructions.isReturnToPrincipalRequired"
            )}
          />
        </Can>
        <Can
          user={userData}
          perform={Permission.HANDLING_DATA_PRINCIPAL_VIEW}
          customerContext={ANY_CUSTOMER}
        >
          <DetailRow
            keyName={t("productHandlingInstructions.returnAddress")}
            value={formatReturnAddressValue(
              isNewReturnAddressFormat,
              returnAddress
            )}
            secondaryValue={getModifierStrOrNull(
              returnAddress !== null,
              modifications,
              preparePathToReturnAddressValue(
                isNewReturnAddressFormat,
                modifications.productHandlingInstructions?.returnAddress
              )
            )}
            useMultilineFormat={isNewReturnAddressFormat}
          />
        </Can>

        <Can
          user={userData}
          perform={Permission.HANDLING_DATA_PRINCIPAL_VIEW}
          customerContext={ANY_CUSTOMER}
        >
          <DetailRow
            keyName={t("productHandlingInstructions.photographs")}
            value={getValueIfNotNull(
              isPhotographRequested,
              isPhotographRequested
                ? t("productHandlingInstructions.photographRequested")
                : t("no")
            )}
            secondaryValue={getModifierStrOrNull(
              isPhotographRequested !== null,
              modifications,
              "productHandlingInstructions.isPhotographRequested"
            )}
          />
        </Can>

        <Can
          user={userData}
          perform={Permission.HANDLING_DATA_PRINCIPAL_VIEW}
          customerContext={ANY_CUSTOMER}
        >
          <DetailRow
            keyName={t(
              "productHandlingInstructions.photographyInstructionsTitle"
            )}
            value={getValueIfNotNull(
              photographInstructions,
              photographInstructions
            )}
            secondaryValue={getModifierStrOrNull(
              photographInstructions !== null,
              modifications,
              "productHandlingInstructions.photographInstructions"
            )}
          />
        </Can>

        <DetailRow
          keyName={t("productHandlingInstructions.productDisposal")}
          value={getValueIfNotNull(
            methodOfDisposal,
            methodOfDisposal === ProductDisposers.ORIOLA
              ? t("productHandlingInstructions.oriolaWillDispose")
              : t("productHandlingInstructions.customerWillDispose")
          )}
          secondaryValue={getModifierStrOrNull(
            methodOfDisposal !== null,
            modifications,
            "productHandlingInstructions.methodOfDisposal"
          )}
        />

        <Can
          user={userData}
          perform={Permission.HANDLING_DATA_PRINCIPAL_VIEW}
          customerContext={ANY_CUSTOMER}
        >
          <DetailRow
            keyName={t(
              "productHandlingInstructions.certificateOfDestructionNeeded"
            )}
            value={getValueIfNotNull(
              isCertificateOfDestructionRequired,
              isCertificateOfDestructionRequired ? t("yes") : t("no")
            )}
            secondaryValue={getModifierStrOrNull(
              isCertificateOfDestructionRequired !== null,
              modifications,
              "productHandlingInstructions.isCertificateOfDestructionRequired"
            )}
          />
        </Can>

        <Can
          user={userData}
          perform={Permission.HANDLING_DATA_PRINCIPAL_VIEW}
          customerContext={ANY_CUSTOMER}
        >
          <DetailRow
            keyName={t("productHandlingInstructions.handlingInstructions")}
            value={handlingInstructions}
            secondaryValue={getModifierStrOrNull(
              handlingInstructions !== null,
              modifications,
              "productHandlingInstructions.handlingInstructions"
            )}
          />
        </Can>
      </Box>
    </Box>
  );
}

ProductHandlingInstructionsView.propTypes = {
  productHandlingInstructions: PropTypes.shape({
    methodOfDisposal: PropTypes.string,
    handlingInstructions: PropTypes.string,
    isReturnToPrincipalRequired: PropTypes.bool,
    returnAddress: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    isCertificateOfDestructionRequired: PropTypes.bool,
    isPhotographRequested: PropTypes.bool,
    photographInstructions: PropTypes.string,
  }).isRequired,
  userData: PropTypes.shape({}).isRequired,
  modifications: PropTypes.shape({
    productHandlingInstructions: PropTypes.shape({
      returnAddress: PropTypes.shape({}),
    }),
  }),
};

ProductHandlingInstructionsView.defaultProps = {
  modifications: {},
};

export default ProductHandlingInstructionsView;
