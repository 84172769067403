const SPDDetectedBy = Object.freeze({
  CONSUMER: "CONSUMER",
  PHARMACY: "PHARMACY",
  NURSE: "NURSE",
  HOSPITAL: "HOSPITAL",
  DISPENSARY: "DISPENSARY",
  ORIOLA: "ORIOLA",
  OTHER: "OTHER",
});

module.exports = SPDDetectedBy;
