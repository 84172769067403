import { ReclamationRoles as Roles } from "@oriola-origo/origo-common-client-lib";

const resolveRole = (role, allowEdit) => {
  // all allowed
  if (allowEdit === true) {
    return role;
  }

  // replace edit roles with view roles
  switch (role) {
    case Roles.RECLAMATION_CUSTOMER_EDIT:
      return Roles.RECLAMATION_CUSTOMER_VIEW;
    case Roles.RECLAMATION_ORIOLA_EDIT:
    case Roles.RECLAMATION_ORIOLA_ADMIN:
      return Roles.RECLAMATION_ORIOLA_VIEW;
    default:
      return role;
  }
};

const getRolesByCustomerValidity = (customers = [], rolesByOrgId = []) => {
  const result = customers.reduce(
    (acc, customer) => {
      const organization = rolesByOrgId.find(
        rbo => rbo.orgId === customer.customerId
      );
      if (organization != null) {
        const allowedRoles = organization.roles.map(role =>
          resolveRole(role, customer.isCurrentlyValid)
        );

        // update
        acc.roles = acc.roles.concat(allowedRoles);
        acc.rolesByOrgId.push({ ...organization, roles: allowedRoles });
      }
      return acc;
    },
    { roles: [], rolesByOrgId: [] }
  );

  // remove duplicates
  result.roles = Array.from(new Set(result.roles));

  return result;
};

export default getRolesByCustomerValidity;
