import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getEachTypesOfItems } from "../../utils/case/case";

const useStyles = makeStyles(theme => ({
  columnValueText: {
    color: theme.palette.text.primary,
    width: "100%",
    ...theme.typography.button,
    textTransform: "none",
  },
}));

function VnrMsi({ items, ...rest }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { productOrders } = getEachTypesOfItems(items);

  let vnrMsi = "-";
  if (productOrders.length > 1) {
    vnrMsi = t("multiple");
  }

  // if single product and nothing else
  if (productOrders && productOrders.length === 1 && items.length === 1) {
    const product =
      productOrders[0].product || productOrders[0].missingProduct || {};
    vnrMsi =
      product.vnr && product.vnr !== "n/a" ? product.vnr : product.msiCode;
  }
  return (
    <div {...rest}>
      <Typography className={classes.columnValueText} color="textPrimary">
        {vnrMsi}
      </Typography>
    </div>
  );
}

VnrMsi.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({
        vnr: PropTypes.string,
        msiCode: PropTypes.string,
      }),
      missingProduct: PropTypes.shape({
        vnr: PropTypes.string,
        msiCode: PropTypes.string,
      }),
    })
  ),
};

VnrMsi.defaultProps = {
  items: [],
};

export default VnrMsi;
