import { ensureTrailingSlash } from "../../utils/url/url";

export const baseUrl = ensureTrailingSlash(
  process.env.REACT_APP_RECLAMATION_SERVICE
);
export const reclamationApi = process.env.REACT_APP_RECLAMATION_API;

export const Case = Object.freeze({
  NEW_CASE: "CASE_NEW",
  UPDATE_CASE: "CASE_UPDATE",
  ADD_ITEM: "CASE_ADD_ITEM",
  CHANGE_ITEM: "CHANGE_ITEM",
  REMOVE_ITEM: "CASE_REMOVE_ITEM",
  UPDATE_ITEM: "CASE_UPDATE_ITEM",
  FETCH_STARTED: "CASE_FETCH_STARTED",
  FETCH_FINISHED: "CASE_FETCH_FINISHED",
  FETCH_ERROR: "CASE_FETCH_ERROR",
  SEND_STARTED: "CASE_SEND_STARTED",
  SEND_FINISHED: "CASE_SEND_FINISHED",
  SEND_ERROR: "CASE_SEND_ERROR",
  CLEAR_CASE: "CASE_CLEAR",
  UPDATE_MODIFIED_HANDLING_DATA: "UPDATE_MODIFIED_HANDLING_DATA",
  UPDATE_SERIAL_NUMBER_DATA: "UPDATE_SERIAL_NUMBER_DATA",
  REMOVE_SERIAL_NUMBER_DATA: "REMOVE_SERIAL_NUMBER_DATA",
  HANDLING_DATA_SEND_STARTED: "HANDLING_DATA_SEND_STARTED",
  HANDLING_DATA_SEND_FINISHED: "HANDLING_DATA_SEND_FINISHED",
  HANDLING_DATA_SEND_ERROR: "HANDLING_DATA_SEND_ERROR",
  QUEUE_CHANGE_STARTED: "QUEUE_CHANGE_STARTED",
  QUEUE_CHANGE_FINISHED: "QUEUE_CHANGE_FINISHED",
  FETCH_PDF_STARTED: "FETCH_PDF_STARTED",
  FETCH_PDF_FINISHED: "FETCH_PDF_FINISHED",
  FETCH_PDF_ERROR: "FETCH_PDF_ERROR",
  UPDATE_CASE_HANDLING_ITEMS: "UPDATE_CASE_HANDLING_ITEMS",
  REQUIRE_MORE_INFO_FROM_CUSTOMER: "REQUIRE_MORE_INFO_FROM_CUSTOMER",
  SET_EDITED_KEY_FIELD: "SET_EDITED_KEY_FIELD",
  REOPEN_CASE_START: "REOPEN_CASE_START",
  REOPEN_CASE_FINISHED: "REOPEN_CASE_FINISHED",
  CLOSE_CASE: "CLOSE_CASE",
  FETCH_PROFIT_CENTER_START: "FETCH_PROFIT_CENTER_START",
  FETCH_PROFIT_CENTER_FINISHED: "FETCH_PROFIT_CENTER_FINISHED",
  FETCH_PROFIT_CENTER_ERROR: "FETCH_PROFIT_CENTER_ERROR",
  MAP_COPIED_CASE: "MAP_COPIED_CASE",
  CANCEL_EDITING_COPIED_CASE: "CANCEL_EDITING_COPIED_CASE",
  COPY_CASE_START: "COPY_CASE_START",
  UPDATE_RELATED_CASES: "UPDATE_RELATED_CASES",
});

export const SendingStatus = Object.freeze({
  SENDING: "SENDING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
});

export const StoreCaseErrorType = Object.freeze({
  UPDATE_ATTACHMENTS: "UPDATE_ATTACHMENTS",
});
