import React from "react";
import PropTypes from "prop-types";
import {
  Radio,
  Typography,
  RadioGroup,
  FormControlLabel,
  Box,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { OriolaColors } from "@oriola-origo/origo-ui-core";

const useStyles = makeStyles(theme => ({
  titleText: {
    ...theme.typography.subtitle2,
    color: OriolaColors.secondaryDarkGray,
    marginBottom: theme.spacing(1),
  },
  label: {
    ...theme.typography.button,
    textTransform: "none",
    fontWeight: "400",
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

function RadioButtonSelection({
  title,
  button1LabelText,
  button2LabelText,
  button1Value,
  button2Value,
  value,
  disabled,
  onChecked,
  checkboxColor,
  button1LabelChildren,
  button2LabelChildren,
  error,
  helperText,
  ...containerProps
}) {
  const classes = useStyles();

  let color = checkboxColor;
  if (disabled === true) {
    color = OriolaColors.secondaryDarkGray;
  }
  if (error === true) {
    color = OriolaColors.errorRed;
  }

  const radioButton = newValue => (
    <Radio
      value={newValue}
      size="small"
      style={{
        color,
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
    />
  );

  return (
    <Box {...containerProps}>
      <FormControl error={error} disabled={disabled} component="fieldset">
        {title != null && (
          <Typography className={classes.titleText}>{title}</Typography>
        )}
        <RadioGroup value={value} onChange={e => onChecked(e.target.value)}>
          <FormControlLabel
            className={classes.formControlLabel}
            control={radioButton(button1Value)}
            label={
              <>
                <Typography className={classes.label} style={{ color }}>
                  {button1LabelText}
                </Typography>
                {button1LabelChildren ?? button1LabelChildren}
              </>
            }
          />
          <FormControlLabel
            control={radioButton(button2Value)}
            label={
              <>
                <Typography className={classes.label} style={{ color }}>
                  {button2LabelText}
                </Typography>
                {button2LabelChildren ?? button2LabelChildren}
              </>
            }
          />
        </RadioGroup>
        <FormHelperText className={classes.errorText}>
          {helperText}
        </FormHelperText>
      </FormControl>
    </Box>
  );
}

RadioButtonSelection.propTypes = {
  // Allow any as MUI components corresponding type is any
  // eslint-disable-next-line react/forbid-prop-types
  button1Value: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  button2Value: PropTypes.any.isRequired,
  button1LabelText: PropTypes.string.isRequired,
  button2LabelText: PropTypes.string.isRequired,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  disabled: PropTypes.bool,
  onChecked: PropTypes.func,
  checkboxColor: PropTypes.string,
  button1LabelChildren: PropTypes.shape({}),
  button2LabelChildren: PropTypes.shape({}),
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

RadioButtonSelection.defaultProps = {
  checkboxColor: OriolaColors.secondaryNavyBlue,
  title: "",
  disabled: false,
  onChecked: null,
  error: false,
  helperText: "",
  value: "",
  button1LabelChildren: null,
  button2LabelChildren: null,
};

export default RadioButtonSelection;
