import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { contactInfoReducer } from "./contactInfo/contactInfo";
import { userReducer } from "./user/user";
import { caseReducer } from "./case/case";
import { listCasesReducer } from "./listCases/listCases";
import { casesFilterReducer } from "./casesFilters/casesFilters";
import { draftsReducer } from "./drafts/drafts";
import { customerReducer } from "./customer/customer";
import { productsReducer } from "./products/products";
import { notificationPopupsReducer } from "./notificationPopups/notificationPopups";
import { dialogReducer } from "./dialog/dialog";
import { validationErrorsReducer } from "./validationErrors/validationErrors";
import { messageReducer } from "./message/message";
import { organizationReducer } from "./organization/organization";
import { fieldsConfigReducer } from "./fieldsConfig/fieldsConfig";
import { profitCentersReducer } from "./profitCenters/profitCenters";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    contactInfo: contactInfoReducer,
    user: userReducer,
    case: caseReducer,
    listCases: listCasesReducer,
    casesFilters: casesFilterReducer,
    draft: draftsReducer,
    customer: customerReducer,
    products: productsReducer,
    notificationPopups: notificationPopupsReducer,
    dialog: dialogReducer,
    validationErrors: validationErrorsReducer,
    message: messageReducer,
    organization: organizationReducer,
    fieldsConfig: fieldsConfigReducer,
    profitCenters: profitCentersReducer,
  });

export default createRootReducer;
