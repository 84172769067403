import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Dropdown, WarningMessage } from "@oriola-origo/origo-ui-core";
import { Box } from "@material-ui/core";
// eslint-disable-next-line import/no-cycle
import {
  updateCase,
  updateValidationError,
  showDialog,
  hideDialog,
  setEditedKeyField,
} from "../../../../redux";

import { getValidationErrorMessage } from "../../../../utils/validations/validations";
import { FieldTitle } from "..";
import { CaseType } from "../../../../constants/caseType";
import { EditWarningIcon } from "../../../../images";

function ReasonIdentifier({
  reclamationCase,
  reasons,
  disabled,
  editMode,
  ...containerProps
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { reasonIdentifierError } = useSelector(
    state => state.validationErrors
  );
  const { editedKeyField } = useSelector(state => state.case);
  const { reasonIdentifier, caseType } = reclamationCase;
  const { SuspectedProductDefectReasonIdentifiers } = useSelector(
    state => state.fieldsConfig
  );

  const updateReasonIdentifier = value => {
    dispatch(updateValidationError({ reasonIdentifierError: "" }));
    dispatch(updateValidationError({ noRequiredItemError: "" }));
    dispatch(updateCase({ reasonIdentifier: value }));
  };

  const onSelectOptionInEditMode = value => {
    dispatch(
      showDialog({
        open: true,
        description: t("editWarningDialog.description"),
        boldDescription: t("editWarningDialog.boldDescription"),
        icon: (
          <div>
            <EditWarningIcon color="primary" />
          </div>
        ),
        title: t("editWarningDialog.title"),
        cancelButtonText: t("editWarningDialog.cancel"),
        okButtonText: t("editWarningDialog.confirm"),
        onCancel: () => dispatch(hideDialog()),
        onOk: () => {
          updateReasonIdentifier(value);
          dispatch(hideDialog());
          dispatch(setEditedKeyField(true));
        },
      })
    );
  };

  useEffect(() => {
    if (
      reasons.length > 0 &&
      reasonIdentifier &&
      reasons.indexOf(reasonIdentifier) < 0
    ) {
      dispatch(updateCase({ reasonIdentifier: "" }));
    }
  }, [reasons, reasonIdentifier, dispatch]);

  return (
    <Box width="100%" {...containerProps}>
      <FieldTitle
        title={t("caseInformation.reasonIdentifier")}
        type="selection"
      />
      <Box maxWidth="32rem">
        <Dropdown
          disabled={disabled}
          data-error-name="reasonIdentifierError"
          error={!!reasonIdentifierError}
          errorText={getValidationErrorMessage(reasonIdentifierError, t)}
          value={reasonIdentifier}
          emptySelectionLabel={t("caseInformation.reasonIdentifierPlaceholder")}
          onFormatOptionLabel={x => t(`caseInformation.reasonIdentifiers.${x}`)}
          options={reasons}
          onSelectOption={x => {
            if (
              editMode &&
              caseType === CaseType.PRODUCT_DELIVERY &&
              !editedKeyField
            ) {
              onSelectOptionInEditMode(x);
            } else {
              updateReasonIdentifier(x);
              if (caseType === CaseType.SUSPECTED_PRODUCT_DEFECT) {
                dispatch(updateCase({ detectedBy: {} }));
              }
            }
          }}
          showSelectNone
          selectNoneLabel={t("selectNone")}
          id="new-case-reason-identifier"
        />
        {SuspectedProductDefectReasonIdentifiers &&
          reasonIdentifier ===
            SuspectedProductDefectReasonIdentifiers.FALSIFIED_MEDICINE && (
            <Box mt={2}>
              <WarningMessage text={t("sfmdWarningToCustomer")} />
            </Box>
          )}
      </Box>
    </Box>
  );
}

ReasonIdentifier.propTypes = {
  reclamationCase: PropTypes.shape({
    reasonIdentifier: PropTypes.string,
    caseType: PropTypes.string,
  }),
  reasons: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  editMode: PropTypes.bool,
};

ReasonIdentifier.defaultProps = {
  disabled: false,
  reasons: [],
  editMode: false,
  reclamationCase: {},
};

ReasonIdentifier.displayName = "ReasonIdentifier";

export default ReasonIdentifier;
