import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as TrackingCodeIcon } from "../../../images/trackingCode/trackingCode.svg";
import CaseIcon from "./caseIcon";

function TrackingCodeAddedIcon({ ...props }) {
  const { t } = useTranslation();
  return (
    <CaseIcon tooltip={t("trackingCodeAdded")} {...props}>
      <TrackingCodeIcon width="22px" height="100%" />
    </CaseIcon>
  );
}

export default TrackingCodeAddedIcon;
