import { showDialog, hideDialog } from "../../../redux";

const cancelEditing = ({ dispatch, t, onCancelled }) => {
  dispatch(
    showDialog({
      open: true,
      title: t("confirmLeavinPage.title"),
      description: null,
      cancelButtonText: t("confirmLeavinPage.cancel"),
      okButtonText: t("confirmLeavinPage.confirm"),
      onCancel: () => dispatch(hideDialog()),
      onOk: () => {
        dispatch(hideDialog());
        onCancelled();
      },
    })
  );
};

export default cancelEditing;
