import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Grid } from "@material-ui/core";
import Category from "../category/category";
import { Paths } from "../../../utils/navigation/navigation";
import { ReactComponent as ProductReturnIcon } from "../../../images/productReturnIcon/productReturnIcon.svg";
import { ReactComponent as ProductDefectIcon } from "../../../images/productDefectIcon/productDefectIcon.svg";
import { ReactComponent as DamageProductIcon } from "../../../images/damageProductIcon/damageProductIcon.svg";
import { CaseType } from "../../../constants/caseType";

const useStyles = makeStyles(theme => ({
  selectCategoryHeader: {
    color: "#757070",
  },
  categoryContainer: {
    display: "flex",
    "& > *": {
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    flexWrap: "wrap",
  },
}));

function CategorySelection({ history }) {
  const classes = useStyles();
  const { t } = useTranslation();

  // navigation helper
  const navigate = path => {
    history.push(path);
  };

  const navigateByType = type => {
    switch (type) {
      case CaseType.DAMAGED_PRODUCT:
        navigate(Paths.NewCaseDamagedProduct);
        break;
      case CaseType.SUSPECTED_PRODUCT_DEFECT:
        navigate(Paths.NewCaseDefect);
        break;
      case CaseType.PRODUCT_DELIVERY:
        navigate(Paths.NewCaseDelivery);
        break;
      default:
        break;
    }
  };

  return (
    <Box
      id="case-category-selection"
      width="95%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      margin="auto"
      height="calc(100vh - 135px)"
      style={{ overflowY: "auto", overflowX: "hidden" }}
    >
      <Box>
        <Box mt={4}>
          <Typography variant="h4" color="textPrimary" noWrap>
            {t("reclamationRefunds")}
          </Typography>
        </Box>
        <Box mt={4} mb={2}>
          <Typography
            className={classes.selectCategoryHeader}
            variant="subtitle2"
            noWrap
          >
            {t("selectCategory")}
          </Typography>
        </Box>
        <Grid container spacing={2} justifyContent="center">
          <Grid item md={12} lg={4}>
            <Category
              header={t("category.productOrDelivery.header")}
              descriptionsKey="category.productOrDelivery.descriptions"
              icon={<ProductReturnIcon />}
              actionComponent={t("createCase")}
              onSelect={() => navigateByType(CaseType.PRODUCT_DELIVERY)}
              buttonId="product-return-button"
            />
          </Grid>
          <Grid item md={12} lg={4}>
            <Category
              header={t("category.productDefect.header")}
              descriptionsKey="category.productDefect.descriptions"
              subDescription="category.productDefect.subDescription"
              icon={<ProductDefectIcon />}
              actionComponent={t("createCase")}
              onSelect={() => navigateByType(CaseType.SUSPECTED_PRODUCT_DEFECT)}
              buttonId="product-defect-button"
            />
          </Grid>
          <Grid item md={12} lg={4}>
            <Category
              header={t("category.damagedProduct.header")}
              descriptionsKey="category.damagedProduct.descriptions"
              icon={<DamageProductIcon />}
              actionComponent={t("createCase")}
              onSelect={() => navigateByType(CaseType.DAMAGED_PRODUCT)}
              buttonId="damaged-product-button"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

CategorySelection.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

CategorySelection.defaultProps = {
  history: {
    push: null,
  },
};

export default withRouter(CategorySelection);
