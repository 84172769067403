import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  badge: {
    borderRadius: 4,
    height: 24,
    fontWeight: "bold",
    textAlign: "center",
    lineHeight: "24px",
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    backgroundColor: "#FFE9C6",
    border: "1px solid #FFD48D",
    color: theme.palette.primary.main,
  },
}));

function Badge({ text, ...containerProps }) {
  const classes = useStyles();
  return (
    <Box {...containerProps}>
      <Typography className={classes.badge} variant="body2">
        {text}
      </Typography>
    </Box>
  );
}

Badge.propTypes = {
  text: PropTypes.string,
};

Badge.defaultProps = {
  text: "",
};

export default Badge;
