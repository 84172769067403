import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { v4 as uuidV4 } from "uuid";
import { IconTextButton } from "@oriola-origo/origo-ui-core";
import { Add } from "@material-ui/icons";
import moment from "moment";
import { OriolaColors } from "../../../theme";
import Compensation from "./compensation";

function Compensations({
  compensations,
  onCompensationUpdated,
  onCompensationRemoved,
  onAddNewCompensation,
}) {
  const { t } = useTranslation();

  const createEmptyCompensation = () => ({
    id: uuidV4(),
    identifierCode: "",
    compensationNumber: "",
    compensationDate: moment().format("YYYY-MM-DD"),
    handler: "",
  });

  useEffect(() => {
    if (compensations == null || compensations.length === 0) {
      onAddNewCompensation(createEmptyCompensation());
    }
  }, [compensations, onAddNewCompensation]);

  const compensationRow = (compensation, index) => {
    const id = `compensation-row-${index}`;
    return (
      <Box mt={2} key={index} id={id}>
        <Compensation
          compensation={compensation}
          hideRemoveButton={compensations.length === 1}
          onCompensationUpdated={onCompensationUpdated}
          onCompensationRemoved={onCompensationRemoved}
          domId={id}
        />
      </Box>
    );
  };

  return (
    <Box my={2}>
      <Box>{compensations.map(compensationRow)}</Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        id="compensation-add-new-row"
        mr={2}
      >
        <IconTextButton
          text={t("addNew")}
          icon={<Add style={{ color: OriolaColors.primaryOrange }} />}
          onClick={() => onAddNewCompensation(createEmptyCompensation())}
        />
      </Box>
    </Box>
  );
}

Compensations.propTypes = {
  compensations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCompensationUpdated: PropTypes.func.isRequired,
  onCompensationRemoved: PropTypes.func.isRequired,
  onAddNewCompensation: PropTypes.func.isRequired,
};

Compensations.defaultProps = {};

export default Compensations;
