import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import QuantityField from "./quantityField";

function Quantities({
  itemData,
  id,
  fieldsConfig,
  getValidationError,
  updateItem,
  updateItemValidationError,
  disabled,
}) {
  const renderQuantity = quantityType => (
    <QuantityField
      itemData={itemData}
      id={id}
      fieldName={quantityType}
      config={fieldsConfig[quantityType]}
      error={getValidationError(quantityType)}
      updateItem={updateItem}
      updateItemValidationError={updateItemValidationError}
      disabled={disabled}
    />
  );

  return (
    <>
      {fieldsConfig.quantity && renderQuantity("quantity")}
      {fieldsConfig.quantityOrdered && renderQuantity("quantityOrdered")}
      {fieldsConfig.quantityReceived && renderQuantity("quantityReceived")}
      {fieldsConfig.quantityReturned && renderQuantity("quantityReturned")}
    </>
  );
}

Quantities.propTypes = {
  itemData: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  id: PropTypes.string,
  fieldsConfig: PropTypes.shape({
    quantity: PropTypes.shape({}),
    quantityOrdered: PropTypes.shape({}),
    quantityReceived: PropTypes.shape({}),
    quantityReturned: PropTypes.shape({}),
  }),
  getValidationError: PropTypes.func,
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
  disabled: PropTypes.bool,
};

Quantities.defaultProps = {
  itemData: {},
  id: "",
  fieldsConfig: {},
  getValidationError: null,
  updateItem: null,
  updateItemValidationError: null,
  classes: {},
  disabled: false,
};

export default Quantities;
