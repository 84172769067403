import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Dropdown, ErrorMessage } from "@oriola-origo/origo-ui-core";
import { useSelector } from "react-redux";
import { StatefullTextField, TitleComponent } from "../../common";
import { getValidationErrorMessage } from "../../../utils/validations/validations";

export const RejectionReasons = Object.freeze({
  ProductWasNotReturnedToOriola: "productWasNotReturnedToOriola",
  NoNeedForCompensation: "noNeedForCompensation",
  IncorrectWholesaler: "incorrectWholesaler",
  ReclamationTimeExceeded: "reclamationTimeExceeded",
  Other: "other",
});

function RejectionReason({
  handlingData,
  onReasonChanged,
  onReasonDescriptionChanged,
}) {
  const { t } = useTranslation();
  const { rejectReasonError, rejectDescriptionError } = useSelector(
    state => state.validationErrors
  );

  const rejectionReasonToText = reason => {
    switch (reason) {
      case RejectionReasons.ProductWasNotReturnedToOriola:
        return t("compensationRejectReasons.productWasNotReturnedToOriola");
      case RejectionReasons.NoNeedForCompensation:
        return t("compensationRejectReasons.noNeedForCompensation");
      case RejectionReasons.IncorrectWholesaler:
        return t("compensationRejectReasons.incorrectWholesaler");
      case RejectionReasons.ReclamationTimeExceeded:
        return t("compensationRejectReasons.reclamationTimeExceeded");
      default:
        return t("compensationRejectReasons.other");
    }
  };

  return (
    <Box my={2} pr={2}>
      <TitleComponent title={t("reasonForRejection")}>
        <Dropdown
          id="compensation-rejection-reasons-dropdown"
          disabled={false}
          value={handlingData.compensationRejectionReason || ""}
          emptySelectionLabel={t("select")}
          onFormatOptionLabel={label => rejectionReasonToText(label)}
          options={Object.values(RejectionReasons)}
          onSelectOption={reason => {
            onReasonChanged(reason);
          }}
          showSelectNone
          selectNoneLabel={t("selectNone")}
        />
        {rejectReasonError && (
          <ErrorMessage
            text={getValidationErrorMessage(rejectReasonError, t)}
          />
        )}
      </TitleComponent>
      {handlingData.compensationRejectionReason === RejectionReasons.Other && (
        <Box mt={2}>
          <StatefullTextField
            id="compensation-rejection-description-text"
            error={false}
            fullWidth
            multiline
            rows="3"
            initialValue={handlingData.compensationRejectionDescription || ""}
            variant="outlined"
            placeholder={t("rejectionReason")}
            margin="dense"
            disabled={false}
            onChange={newValue => onReasonDescriptionChanged(newValue)}
          />
          {rejectDescriptionError && (
            <ErrorMessage
              text={getValidationErrorMessage(rejectDescriptionError, t)}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

RejectionReason.propTypes = {
  handlingData: PropTypes.shape([]).isRequired,
  onReasonChanged: PropTypes.func.isRequired,
  onReasonDescriptionChanged: PropTypes.func.isRequired,
};

RejectionReason.defaultProps = {};

export default RejectionReason;
