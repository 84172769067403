import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CssTextField from "../cssTextField/cssTextField";

function StatefullTextField({
  onCustomizeValue,
  onChange,
  initialValue,
  ...rest
}) {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <CssTextField
      {...rest}
      value={value}
      onChange={e => {
        const newValue = e.target.value;
        const customizedValue =
          onCustomizeValue != null ? onCustomizeValue(newValue) : newValue;
        // value needs to be set on onChange - method in order to preserve cursor position
        setValue(customizedValue);
        onChange(customizedValue);
      }}
    />
  );
}

StatefullTextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  onCustomizeValue: PropTypes.func,
  initialValue: PropTypes.string,
};

StatefullTextField.defaultProps = {
  initialValue: "",
  onCustomizeValue: null,
};

export default StatefullTextField;
