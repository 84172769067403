import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
// eslint-disable-next-line import/no-cycle
import { updateCase, updateValidationError } from "../../../../redux";
import {
  getValidationErrorMessage,
  RETURN_NUMBER_MIN_LENGTH,
  RETURN_NUMBER_MAX_LENGTH,
} from "../../../../utils/validations/validations";
import { YesNoSelectionValues, CssTextField } from "../../../common";
import CourierSelect from "../courierSelect/courierSelect";

const useStyles = makeStyles(theme => ({
  titleText: {
    ...theme.typography.subtitle2,
    color: "#757070",
    marginBottom: theme.spacing(1),
  },
  textField: {
    height: theme.spacing(5),
    width: 300,
  },
}));

function ReturnPackagesAmount({ disabled }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { productReturn } = useSelector(state => state.case.case);
  const { returnNumberError, courierCompanyError } = useSelector(
    state => state.validationErrors
  );
  const { case: reclamationCase } = useSelector(state => state.case);

  if (reclamationCase.sourceReclamationId) {
    return null;
  }

  const handleProductReturnAmountChange = e => {
    const { value } = e.target;
    dispatch(updateValidationError({ returnNumberError: "" }));
    dispatch(
      updateCase({
        productReturn: { ...productReturn, amount: parseInt(value, 10) || "" },
        confirm: false,
      })
    );
  };

  const getProductReturnValue = () => {
    if (productReturn == null || productReturn.returnValue == null) {
      return "";
    }
    return productReturn.returnValue === true
      ? YesNoSelectionValues.Yes
      : YesNoSelectionValues.No;
  };

  const renderYesReturnProducts = () => (
    <Box my={3}>
      <CourierSelect
        mt={3}
        value={reclamationCase.courierCompanyId}
        error={getValidationErrorMessage(courierCompanyError, t)}
        id="return-courier-select"
        disabled={disabled}
      />
      <Typography className={`${classes.titleText}`} noWrap>
        {t("productReturnDetail")}
      </Typography>
      <CssTextField
        data-error-name="returnNumberError"
        error={!!returnNumberError}
        helperText={getValidationErrorMessage(returnNumberError, t)}
        inputProps={{
          min: RETURN_NUMBER_MIN_LENGTH,
          max: RETURN_NUMBER_MAX_LENGTH,
        }}
        disabled={disabled}
        value={productReturn.amount || ""}
        type="number"
        variant="outlined"
        className={`${classes.textField}`}
        margin="dense"
        size="medium"
        onChange={handleProductReturnAmountChange}
        id="return-amount-field"
      />
    </Box>
  );

  return (
    getProductReturnValue() === YesNoSelectionValues.Yes &&
    renderYesReturnProducts()
  );
}

ReturnPackagesAmount.propTypes = {
  disabled: PropTypes.bool,
};

ReturnPackagesAmount.defaultProps = {
  disabled: false,
};

ReturnPackagesAmount.displayName = "ReturnPackagesAmount";

export default ReturnPackagesAmount;
