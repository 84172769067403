import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Button } from "@oriola-origo/origo-ui-core";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  button: {
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.2)",
    marginRight: theme.spacing(4),
  },
  title: {
    ...theme.typography.body1,
    marginTop: "0.5rem",
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(6),
  },
}));

function ButtonBar({ onSave, onCancel }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="primary"
        onClick={onSave}
        id="save-edit-case-button"
      >
        {t("save")}
      </Button>
      <Button
        className={classes.button}
        variant="secondary"
        onClick={onCancel}
        id="cancel-edit-case-button"
      >
        {t("cancel")}
      </Button>
    </div>
  );
}

ButtonBar.propTypes = {
  history: PropTypes.shape({}),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ButtonBar.defaultProps = {
  history: {},
};

export default ButtonBar;
