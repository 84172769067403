import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OriolaTheme } from "@oriola-origo/origo-ui-core";
import SaveAndCloseButtonGroup, {
  HANDLING_BUTTON_GROUP_HEIGHT_FULL,
  HANDLING_BUTTON_GROUP_HEIGHT,
} from "../handlingDataButtonBar/saveAndCloseButtonGroup";
import ProductHandling from "../productHandling/productHandling";
import ProductHandlingInstructions from "../productHandlingInstructions/productHandlingInstructions";
import PrincipalReferenceNumber from "../principalReferenceNumber/principalReferenceNumber";
import ParcelTracking from "../parcelTracking/parcelTracking";
import CompensationInstructions from "../compensationInstructions/compensationInstructions";
import CompensationInformation from "../compensationInformation/compensationInformation";
import ProductHandlingInformation from "../productHandlingInformation/productHandlingInformation";
import CompensationPermission from "../compensationPermission/compensationPermission";
import Conclusion from "../conclusion/conclusion";
import { Badge } from "../../common";
import StorageLocation from "../storageLocation";
import ProductChips from "./productChips";

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(3),
  },
  innerContainer: {
    "& > div": {
      position: "relative",
    },
  },
  divider: {
    borderBottomColor: "#e7e7e7",
  },
  disabled: {
    background: theme.palette.grey[100],
  },
  title: {
    ...OriolaTheme.typography.h4,
    textTransform: "uppercase",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
}));

function SpdHandlingDataEdit({
  isStatementRequired,
  userData,
  currentUserIsOriolaUser,
  currentUserIsPharmaceuticalCompanyEditRole,
  onSave,
  onCancel,
  onSaveAndClose,
  loading,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderLabel = text => (
    <Badge text={text} sx={{ position: "absolute", right: 32, top: 28 }} />
  );

  const getClassName = disabled => (disabled ? classes.disabled : "");

  const renderParcelTracking = () => (
    <Box className={getClassName(!currentUserIsOriolaUser)}>
      {renderLabel(t("oriola"))}
      <ParcelTracking disabled={!currentUserIsOriolaUser} user={userData} />
      <Divider className={classes.divider} />
    </Box>
  );

  const renderProductHandling = () => {
    if (currentUserIsOriolaUser || currentUserIsPharmaceuticalCompanyEditRole) {
      return (
        <Box className={getClassName(!currentUserIsOriolaUser)}>
          {renderLabel(t("oriola"))}
          <ProductHandling
            user={userData}
            disabled={!currentUserIsOriolaUser}
            currentUserIsOriolaUser={currentUserIsOriolaUser}
          />
          <Divider className={classes.divider} />
        </Box>
      );
    }
    return null;
  };

  const renderPrincipalReferenceNumber = () => (
    <Box className={getClassName(!currentUserIsPharmaceuticalCompanyEditRole)}>
      <PrincipalReferenceNumber
        disabled={!currentUserIsPharmaceuticalCompanyEditRole}
        user={userData}
      />
      <Divider className={classes.divider} />
    </Box>
  );

  const renderProductHandlingInstructions = () => {
    if (currentUserIsOriolaUser || currentUserIsPharmaceuticalCompanyEditRole) {
      return (
        <Box
          className={getClassName(!currentUserIsPharmaceuticalCompanyEditRole)}
        >
          <ProductHandlingInstructions
            user={userData}
            disabled={!currentUserIsPharmaceuticalCompanyEditRole}
            currentUserIsPharmaceuticalCompanyEditRole={
              currentUserIsPharmaceuticalCompanyEditRole
            }
          />
          <Divider className={classes.divider} />
        </Box>
      );
    }
    return null;
  };

  const renderProductHandlingInformation = () => {
    if (currentUserIsOriolaUser) {
      return (
        <Box>
          {renderLabel(t("oriola"))}
          <ProductHandlingInformation user={userData} />
          <Divider className={classes.divider} />
        </Box>
      );
    }
    return null;
  };

  const renderCompensationPermission = () => {
    if (currentUserIsOriolaUser || currentUserIsPharmaceuticalCompanyEditRole) {
      return (
        <Box className={getClassName(!currentUserIsOriolaUser)}>
          {renderLabel(t("oriola"))}
          <CompensationPermission
            user={userData}
            disabled={!currentUserIsOriolaUser}
            currentUserIsOriolaUser={currentUserIsOriolaUser}
          />
          <Divider className={classes.divider} />
        </Box>
      );
    }
    return null;
  };

  const renderCompensationInstructions = () => (
    <Box className={getClassName(!currentUserIsPharmaceuticalCompanyEditRole)}>
      <CompensationInstructions
        user={userData}
        disabled={!currentUserIsPharmaceuticalCompanyEditRole}
        currentUserIsPharmaceuticalCompanyEditRole={
          currentUserIsPharmaceuticalCompanyEditRole
        }
      />
      <Divider className={classes.divider} />
    </Box>
  );

  const renderCompensationInformation = () => {
    if (currentUserIsOriolaUser === false) {
      return null;
    }

    return (
      <Box>
        {renderLabel(t("oriola"))}
        <CompensationInformation user={userData} />
        <Divider className={classes.divider} />
      </Box>
    );
  };

  const renderConclusion = () => (
    <Box className={getClassName(!currentUserIsPharmaceuticalCompanyEditRole)}>
      <Conclusion
        disabled={!currentUserIsPharmaceuticalCompanyEditRole}
        user={userData}
      />
      <Divider className={classes.divider} />
    </Box>
  );

  const renderStorageLocation = () => {
    if (currentUserIsOriolaUser) {
      return (
        <Box>
          {renderLabel(t("oriola"))}
          <StorageLocation user={userData} />
          <Divider className={classes.divider} />
        </Box>
      );
    }
    return null;
  };

  const getContentAreaHeight = () => {
    const buttonAreaHeight = currentUserIsOriolaUser
      ? HANDLING_BUTTON_GROUP_HEIGHT_FULL
      : HANDLING_BUTTON_GROUP_HEIGHT;
    return `calc(100% - ${buttonAreaHeight}px)`;
  };

  return (
    <Box className={classes.container} height="100%">
      <Box
        className={classes.innerContainer}
        display="flex"
        flexDirection="column"
        height={getContentAreaHeight()}
        style={{ overflow: "auto" }}
      >
        <div className={classes.title}>{t("caseHandling")}</div>
        <ProductChips />
        {renderParcelTracking()}
        {renderProductHandling()}
        {renderPrincipalReferenceNumber()}
        {renderProductHandlingInstructions()}
        {renderProductHandlingInformation()}
        {renderCompensationPermission()}
        {renderCompensationInstructions()}
        {renderCompensationInformation()}
        {isStatementRequired === true && renderConclusion()}
        {renderStorageLocation()}
      </Box>
      <SaveAndCloseButtonGroup
        onSave={onSave}
        onCancel={onCancel}
        onSaveAndClose={onSaveAndClose}
        currentUserIsOriolaUser={currentUserIsOriolaUser}
        loading={loading}
      />
    </Box>
  );
}

SpdHandlingDataEdit.propTypes = {
  isStatementRequired: PropTypes.bool,
  userData: PropTypes.shape({
    caseStatus: PropTypes.string,
    currentUserIsOriolaUser: PropTypes.bool,
    currentUserIsPharmaceuticalCompanyEditRole: PropTypes.bool,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onSaveAndClose: PropTypes.func,
    loading: PropTypes.string,
  }),
  currentUserIsOriolaUser: PropTypes.bool,
  currentUserIsPharmaceuticalCompanyEditRole: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onSaveAndClose: PropTypes.func,
  loading: PropTypes.string,
};

SpdHandlingDataEdit.defaultProps = {
  isStatementRequired: false,
  userData: {},
  currentUserIsOriolaUser: false,
  currentUserIsPharmaceuticalCompanyEditRole: false,
  onSave: null,
  onCancel: null,
  onSaveAndClose: null,
  loading: "",
};

export default SpdHandlingDataEdit;
