import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Title from "../title/title";
import Row from "../row/row";

const useStyles = makeStyles(() => ({
  root: {},
}));

function BasicInformation({ reclamationCase }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Title title={t("basicInformation")} />
      <Row
        rowLeft={["contactInformation.customerNumber"]}
        rowRight={[reclamationCase.customerId, reclamationCase.customerName]}
      />
      <Row
        rowLeft={["contactInformation.contactPerson"]}
        rowRight={[reclamationCase.contactPersonName]}
      />
      <Row
        rowLeft={["contactInformation.phoneNumber"]}
        rowRight={[reclamationCase.contactPhoneNumber]}
      />
      <Row
        rowLeft={["contactInformation.shipToAddress"]}
        rowRight={[reclamationCase.deliveryAddress]}
      />
    </div>
  );
}

BasicInformation.propTypes = {
  reclamationCase: PropTypes.shape({
    customerId: PropTypes.string,
    customerName: PropTypes.string,
    contactPersonName: PropTypes.string,
    contactPhoneNumber: PropTypes.string,
    deliveryAddress: PropTypes.string,
  }),
};

BasicInformation.defaultProps = {
  reclamationCase: {},
};

export default BasicInformation;
