// eslint-disable-next-line
import { FieldErrors } from "./validations";
import {
  hasProductOrders,
  hasEntireOrders,
  hasShippingBoxes,
  getItemFieldsConfig,
  filterCaseItems,
  hasNoItem,
} from "../case/case";
import {
  isMandatoryFieldEmpty,
  isInvalidDate,
  isInvalidFutureDate,
  isInvalidLength,
  isInvalidNumber,
  isOverLength,
} from "./utils";

const isReturnExceedReceived = order =>
  parseInt(order.quantityReturned, 10) > parseInt(order.quantityReceived, 10);

const multipleFieldContainsEmptyValue = (fieldConfig, fieldValue) =>
  !fieldConfig.optional &&
  fieldConfig.multiple &&
  fieldValue.some(value => value === "");

const validateCustomerAddedProduct = (fieldValue, field, error) => {
  if (fieldValue.customerAddedProduct) {
    if (!fieldValue.productName) {
      error[field] = {};
      error[field].productName = FieldErrors.MANDATORY;
    }
  }
};

const validateEachField = (order, field, fieldsConfig, errors) => {
  const { uuid } = order;
  const fieldValue = order[field];
  const fieldConfig = fieldsConfig[field];
  // Validate it is non-empty if this field is mandatory
  if (isMandatoryFieldEmpty(fieldConfig, fieldValue)) {
    errors[uuid][field] = FieldErrors.MANDATORY;
    if (fieldConfig.notAvailableConfirm) {
      errors[uuid][field] = FieldErrors.MANDATORY_OR_NOT_AVAILABLE;
    }
  }

  // If this field passed mandatory check and it is null and then just move on to next one
  if (!fieldValue) {
    return;
  }

  if (isReturnExceedReceived(order)) {
    errors[uuid].quantityReturned = FieldErrors.INVALID_RETURN_RECEIVE_AMOUNT;
  }

  if (multipleFieldContainsEmptyValue(fieldConfig, fieldValue)) {
    errors[uuid][field] = FieldErrors.MANDATORY;
  }

  // validate the product added by customer
  validateCustomerAddedProduct(fieldValue, field, errors[uuid]);

  // Validate if it is a valid date
  if (isInvalidDate(fieldConfig, fieldValue)) {
    errors[uuid][field] = FieldErrors.INVALID_DATE;
  }
  if (isInvalidNumber(fieldConfig, fieldValue)) {
    errors[uuid][field] = FieldErrors.INVALID_NUMBER;
  }
  // Validate if value is a future date if future date is disabled
  if (isInvalidFutureDate(fieldConfig, fieldValue)) {
    errors[uuid][field] = FieldErrors.INVALID_DATE;
  }
  // Validate Length
  if (isOverLength(fieldConfig, fieldValue)) {
    errors[uuid][field] = FieldErrors.OVER_LENGTH;
  }

  if (isInvalidLength(fieldConfig, fieldValue) && fieldConfig.number) {
    errors[uuid][field] = FieldErrors.INVALID_LENGTH_CODE;
  } else if (isInvalidLength(fieldConfig, fieldValue)) {
    errors[uuid][field] = FieldErrors.INVALID_LENGTH_STRING;
  }
};

const validateFieldsOfItem = (order, fieldsConfig, errors) => {
  if (!fieldsConfig) {
    return;
  }
  const fieldsConfigKeys = Object.keys(fieldsConfig);

  fieldsConfigKeys.forEach(field => {
    validateEachField(order, field, fieldsConfig, errors);
  });
};

const validateItems = (items = [], caseConfig = {}) => {
  const errors = {};
  //  for (const item of items) {
  items.forEach(item => {
    const { uuid } = item;
    errors[uuid] = {};
    const fieldsConfig = getItemFieldsConfig(item.type, caseConfig);
    validateFieldsOfItem(item, fieldsConfig, errors);
    if (Object.keys(errors[uuid]).length === 0) {
      delete errors[uuid];
    }
  });
  if (Object.keys(errors).length > 0) {
    return {
      result: false,
      errors,
    };
  }
  return {
    result: true,
    errors,
  };
};

const noItemsRequired = caseConfig => {
  if (
    hasProductOrders(caseConfig) ||
    hasShippingBoxes(caseConfig) ||
    hasEntireOrders(caseConfig) ||
    hasNoItem(caseConfig)
  ) {
    return false;
  }
  return true;
};

export const validateItemCount = (items = [], caseConfig = {}) => {
  if (noItemsRequired(caseConfig)) {
    return true;
  }
  const filteredItems = filterCaseItems(items, caseConfig);
  return filteredItems.length > 0;
};

export const validateAllItems = (items, caseConfig) => {
  let itemsError = {};
  const itemsValidationResult = validateItems(items, caseConfig);
  if (!itemsValidationResult.result) {
    itemsError = { ...itemsError, ...itemsValidationResult.errors };
  }
  return itemsError;
};
