import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Dialog from "@oriola-origo/core/lib/Dialog";
import DialogContent from "@oriola-origo/core/lib/DialogContent";
import DialogActions from "@oriola-origo/core/lib/DialogActions";
import DialogTitle from "@oriola-origo/core/lib/DialogTitle";
import Button from "@oriola-origo/core/lib/Button";
import Typography from "@oriola-origo/core/lib/Typography";
import Box from "@oriola-origo/core/lib/Box";
import RadioGroup from "@oriola-origo/core/lib/RadioGroup";
import TextField from "@oriola-origo/core/lib/TextField";
import { useTranslation } from "react-i18next";
import EventQueueTarget from "../../../constants/eventQueueTarget";
import EventSubQueueTarget from "../../../constants/eventSubQueueTarget";
import {
  getValidationErrorMessage,
  FieldErrors,
} from "../../../utils/validations/validations";
import { updateValidationError } from "../../../redux";

function ChangeQueueDialog({
  department,
  setDepartment,
  internalMessage,
  setInternalMessage,
  open,
  reclamationCase,
  handleCloseDialog,
  handleContinue,
  sourceQueue,
}) {
  const { t } = useTranslation();
  const { changingQueue } = useSelector(state => state.case);
  const dispatch = useDispatch();
  const { messageError } = useSelector(state => state.validationErrors);
  const { subQueue } = reclamationCase;
  const { queue } = department;

  const returnDepartmentSubQueues = Object.values(
    EventSubQueueTarget.RETURN_DEPARTMENT_SUBQUEUES
  ).filter(
    returnsSubQueue =>
      returnsSubQueue !==
      EventSubQueueTarget.RETURN_DEPARTMENT_SUBQUEUES
        .RETURN_DEPARTMENT_NO_SUBQUEUE
  );

  const onValueChanged = e => {
    setDepartment({
      queue: e.target.value,
      subQueue: null,
    });
    dispatch(updateValidationError({ messageError: "" }));
  };

  const setSubDepartmentQueueValues = e => {
    if (queue !== EventQueueTarget.RETURN_DEPARTMENT) {
      setDepartment({
        queue: EventQueueTarget.RETURN_DEPARTMENT,
        subQueue: e.target.value,
      });
    } else {
      setDepartment({
        queue,
        subQueue: e.target.value,
      });
    }
    dispatch(updateValidationError({ messageError: "" }));
  };

  const handleInternalMessageChanged = e => {
    setInternalMessage(e.target.value);
  };

  const getSelectedQueue = () => {
    if (!queue && sourceQueue === EventQueueTarget.RETURN_DEPARTMENT) {
      return EventQueueTarget.RETURN_DEPARTMENT;
    }
    return queue;
  };

  const getSelectedSubQueue = () => {
    if (!queue && subQueue) {
      return subQueue;
    }
    return department.subQueue;
  };

  const onCloseDialog = (event, reason) => {
    // event The event source of the callback.
    // reason Can be: "escapeKeyDown", "backdropClick".
    if (reason !== "backdropClick") {
      handleCloseDialog();
    }
  };

  const handleChange = () => {
    if (!queue && !department.subQueue) {
      dispatch(
        updateValidationError({ messageError: FieldErrors.QUEUE_MANDATORY })
      );
      return;
    }
    if (changingQueue !== true) {
      department.subQueue
        ? handleContinue(queue, internalMessage, department.subQueue)
        : handleContinue(queue, internalMessage);
    }
  };

  const getReturnDepartmentSubQueues = () =>
    returnDepartmentSubQueues.map(target => ({
      value: target,
      label: t(`subQueues.returnDepartment.${target}`),
    }));

  const getOptions = () => {
    const allOptions = [
      {
        label: t("queues.INVOICING_DEPARTMENT"),
        value: EventQueueTarget.INVOICING_DEPARTMENT,
      },
      {
        label: t("queues.CUSTOMER_SERVICE_DEPARTMENT"),
        value: EventQueueTarget.CUSTOMER_SERVICE_DEPARTMENT,
      },
      {
        label: t("queues.SCC"),
        value: EventQueueTarget.SCC,
      },
      {
        label: t("queues.TRANSLATIONS"),
        value: EventQueueTarget.TRANSLATIONS,
      },
      {
        label: t("queues.TRANSPORTATION"),
        value: EventQueueTarget.TRANSPORTATION,
      },
      {
        label: t("queues.RETURN_DEPARTMENT"),
        value: EventQueueTarget.RETURN_DEPARTMENT,
      },
    ];
    return allOptions.filter(option => {
      const isTranslationRequired =
        reclamationCase.translationsRequired ||
        option.value !== EventQueueTarget.TRANSLATIONS;
      const isSourceQueueChecked =
        sourceQueue === EventQueueTarget.RETURN_DEPARTMENT ||
        option.value !== sourceQueue;
      return isTranslationRequired && isSourceQueueChecked;
    });
  };

  return (
    <OriolaThemeProvider>
      <Dialog open={open} onClose={onCloseDialog} id="change-queue-dialog">
        <DialogTitle onCloseButtonClick={() => handleCloseDialog()}>
          {t("changeQueue.title")}
        </DialogTitle>
        <DialogContent sx={{ minWidth: "500px" }}>
          <Typography
            sx={theme => ({
              fontSize: "16px",
              paddingBottom: theme.spacing(1),
              color: theme.palette.text.secondary,
            })}
          >
            {t("changeQueue.currentQueue")}
            :&nbsp;
            {t(`queues.${sourceQueue}`)}
          </Typography>
          <Box display="flex" sx={{ flexDirection: "column" }}>
            <RadioGroup
              value={getSelectedQueue()}
              onClick={onValueChanged}
              items={getOptions()}
            />
            <RadioGroup
              value={getSelectedSubQueue()}
              onClick={setSubDepartmentQueueValues}
              helperText={getValidationErrorMessage(messageError, t)}
              items={getReturnDepartmentSubQueues()}
              sx={theme => ({ paddingLeft: theme.spacing(4) })}
            />
          </Box>
          <Box
            sx={theme => ({
              paddingBottom: theme.spacing(2),
              paddingTop: theme.spacing(2),
            })}
          >
            <Box display="flex" sx={{ alignItems: "baseline" }}>
              <Typography noWrap variant="subtitle2">
                {t("changeQueue.internalMessage")}
              </Typography>
              <Typography noWrap>
                &nbsp;
                {t("changeQueue.optional")}
              </Typography>
            </Box>
            <TextField
              value={internalMessage}
              onChange={event => handleInternalMessageChanged(event)}
              id="internal-message-input"
              fullWidth
              multiline
              minRows={5}
              maxRows={5}
              variant="outlined"
              placeholder={t("message.placeholder")}
              margin="dense"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={changingQueue}
            variant="outlined"
            color="secondary"
            onClick={handleCloseDialog}
          >
            {t("cancel")}
          </Button>
          <Button
            disabled={changingQueue}
            isLoading={changingQueue}
            variant="contained"
            color="secondary"
            onClick={handleChange}
          >
            {t("transfer")}
          </Button>
        </DialogActions>
      </Dialog>
    </OriolaThemeProvider>
  );
}

ChangeQueueDialog.propTypes = {
  open: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  handleContinue: PropTypes.func,
  sourceQueue: PropTypes.string,
  department: PropTypes.shape({
    queue: PropTypes.string,
    subQueue: PropTypes.string,
  }),
  setDepartment: PropTypes.func,
  internalMessage: PropTypes.string,
  setInternalMessage: PropTypes.func,
  reclamationCase: PropTypes.shape({
    subQueue: PropTypes.string,
    translationsRequired: PropTypes.bool,
  }),
};

ChangeQueueDialog.defaultProps = {
  open: false,
  handleCloseDialog: null,
  handleContinue: null,
  sourceQueue: "",
  department: {
    queue: "",
    subQueue: "",
  },
  setDepartment: null,
  internalMessage: "",
  setInternalMessage: null,
  reclamationCase: {
    subQueue: "",
    translationsRequired: false,
  },
};

export default ChangeQueueDialog;
