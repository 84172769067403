import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import StringField from "./stringField";
import TextAreaField from "./textAreaField";

function ItemRowReasons({
  itemData,
  id,
  validationError,
  fieldsConfig,
  updateItem,
  updateItemValidationError,
  classes,
  disabled,
}) {
  const getValidationError = fieldName =>
    validationError ? validationError[fieldName] : undefined;
  return (
    <>
      {fieldsConfig.whoAgreedReturn && (
        <StringField
          itemData={itemData}
          classes={classes}
          id={id}
          fieldName="whoAgreedReturn"
          config={fieldsConfig.whoAgreedReturn}
          error={getValidationError("whoAgreedReturn")}
          updateItem={updateItem}
          updateItemValidationError={updateItemValidationError}
          disabled={disabled}
        />
      )}
      {fieldsConfig.reasonForRefund && (
        <TextAreaField
          itemData={itemData}
          classes={classes}
          id={id}
          fieldName="reasonForRefund"
          config={fieldsConfig.reasonForRefund}
          error={getValidationError("reasonForRefund")}
          updateItem={updateItem}
          updateItemValidationError={updateItemValidationError}
          disabled={disabled}
        />
      )}
      {fieldsConfig.reasonForReturn && (
        <TextAreaField
          itemData={itemData}
          classes={classes}
          id={id}
          fieldName="reasonForReturn"
          config={fieldsConfig.reasonForReturn}
          error={getValidationError("reasonForReturn")}
          updateItem={updateItem}
          updateItemValidationError={updateItemValidationError}
          disabled={disabled}
        />
      )}
      {fieldsConfig.reasonForProductRecall && (
        <TextAreaField
          itemData={itemData}
          classes={classes}
          id={id}
          fieldName="reasonForProductRecall"
          config={fieldsConfig.reasonForProductRecall}
          error={getValidationError("reasonForProductRecall")}
          updateItem={updateItem}
          updateItemValidationError={updateItemValidationError}
          disabled={disabled}
        />
      )}
    </>
  );
}

ItemRowReasons.propTypes = {
  itemData: PropTypes.shape({}),
  id: PropTypes.string,
  reasonIdentifierConfig: PropTypes.shape({}),
  validationError: PropTypes.shape({}),
  fieldsConfig: PropTypes.shape({
    whoAgreedReturn: PropTypes.shape({}),
    reasonForRefund: PropTypes.shape({}),
    reasonForReturn: PropTypes.shape({}),
    reasonForProductRecall: PropTypes.shape({}),
  }),
  caseConfig: PropTypes.shape({}),
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
  classes: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

ItemRowReasons.defaultProps = {
  itemData: {},
  id: "",
  reasonIdentifierConfig: {},
  validationError: {},
  fieldsConfig: {},
  caseConfig: {},
  updateItem: null,
  updateItemValidationError: null,
  classes: {},
  disabled: false,
};

export default ItemRowReasons;
