import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  updateValidationError,
  updateLocalHandlingDataField,
} from "../../../redux";
import {
  getValidationErrorMessage,
  HANDLING_INFORMATION_MAX_LENGTH,
  FieldErrors,
} from "../../../utils/validations/validations";
import Title from "../title/title";
import { getSpdModificationData } from "../../../utils/getSpdModificationData/getSpdModificationData";
import DateAndEditor from "../dateAndEditor";

function HandlingInformation({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handlingInformation } = useSelector(
    state => state.case.localHandlingData || {}
  );
  const { handlingInformationError } = useSelector(
    state => state.validationErrors
  );

  const { localHandlingData = {} } = useSelector(state => state.case);
  const { userData } = useSelector(state => state.user);
  const { modifications = {} } = localHandlingData;
  const getModificationData = field =>
    getSpdModificationData(modifications, `${field}`);

  const handleChange = event => {
    const description = event.target.value;
    if (description.length > HANDLING_INFORMATION_MAX_LENGTH) {
      dispatch(
        updateValidationError({
          handlingInformationError:
            FieldErrors.HANDLING_INFORMATION_OVER_LENGTH,
        })
      );
    } else {
      dispatch(
        updateLocalHandlingDataField(description, "handlingInformation")
      );
      dispatch(updateValidationError({ handlingInformationError: "" }));
    }
  };

  return (
    <div className="handling-section">
      <Title title={t("handlingInformation.handlingInformation")} />
      <Typography className={classes.titleText} noWrap>
        {`${t("handlingInformation.title")} ${t("optional")}`}
      </Typography>
      <TextField
        id="handling-information-input"
        fullWidth
        multiline
        rows="3"
        value={handlingInformation || ""}
        variant="outlined"
        placeholder={t("handlingInformation.placeholder")}
        margin="dense"
        error={!!handlingInformationError}
        helperText={getValidationErrorMessage(handlingInformationError, t)}
        onChange={event => {
          handleChange(event);
        }}
      />
      {getModificationData("handlingInformation") && (
        <DateAndEditor
          modificationData={{
            ...getModificationData("handlingInformation"),
            hideModifierName: true,
          }}
          user={userData}
        />
      )}
    </div>
  );
}

HandlingInformation.propTypes = {
  classes: PropTypes.shape({
    titleText: PropTypes.string,
  }),
};

HandlingInformation.defaultProps = {
  classes: {},
};

export default HandlingInformation;
