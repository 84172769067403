import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CaseStatusTable from "../caseStatus/caseStatus";
import CaseInformation from "../caseInformation/caseInformation";
import BasicInformation from "../basicInformation";
import Attachments from "../attachments/attachments";
import Confirmation from "../confirmation/confirmation";
import Messages from "../messages";
import { Permission, Can, isAllowed, ANY_CUSTOMER } from "../../auth";
import {
  getCaseConfig,
  getEachTypesOfItems,
  isCaseForOriolaByOriola,
} from "../../../utils/case/case";
import AccordionItem from "../accordionItem/accordionItem";
import { addCaseHandlingItems, removeCaseHandlingItems } from "../../../redux";
import { CaseType } from "../../../constants/caseType";
import NMVSError from "../nmvsError/nmvsError";
import {
  isOriolaUser,
  isPharmaceuticalCompany,
  userHasReclamationOriolaAdminRole,
} from "../../auth/permission";

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderBottomColor: "#e7e7e7",
  },
  dividerTransparent: {
    marginTop: theme.spacing(3),
    borderBottomColor: "transparent",
  },
}));

function CaseDetails({ reclamationCase, onTranslateClick }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const userData = useSelector(state => state.user.userData);
  const history = useHistory();
  const { items } = reclamationCase;
  const { caseHandlingItems } = useSelector(state => state.case);
  const { productOrders, shippingBoxes, entireOrders, noItems } =
    getEachTypesOfItems(items);
  const {
    productOrders: selectedProductOrders,
    shippingBoxes: selectedShippingBoxes,
    entireOrders: selectedEntireOrders,
    noItems: selectedNoItems,
  } = getEachTypesOfItems(caseHandlingItems);
  const { caseType, productReturn, reasonIdentifier, nmvsErrorMessage } =
    reclamationCase;
  const {
    DamagedProductConfig,
    ProductReturnReasonIdentifiersConfig,
    SuspectedProductDefectConfig,
  } = useSelector(state => state.fieldsConfig);
  const getSelectedItemsUuid = selectedItems =>
    selectedItems.map(item => item.uuid);
  const caseHandlingUpdateAllowed = isAllowed(
    userData,
    Permission.HANDLING_DATA_UPDATE,
    reclamationCase.customerId
  );
  const currentUserIsOriolaUser = isOriolaUser(userData);
  const currentUserIsPharmaUser = isPharmaceuticalCompany(userData);

  const caseConfig =
    getCaseConfig(
      caseType,
      productReturn,
      reasonIdentifier,
      DamagedProductConfig,
      ProductReturnReasonIdentifiersConfig,
      SuspectedProductDefectConfig
    ) || {};

  const handleItemsSelected = handlingItems => {
    dispatch(addCaseHandlingItems(handlingItems));
  };

  const handleItemsDeselected = handlingItems => {
    dispatch(removeCaseHandlingItems(handlingItems));
  };

  const canHaveProducts = () => !!caseConfig.productOrders;

  const canHaveShippingBoxes = () => !!caseConfig.shippingBoxes;

  const canHaveEntireOrders = () => !!caseConfig.entireOrders;

  const canHaveNoItem = () => !!caseConfig.noItem;
  const canHaveAttachments = () => !!caseConfig.attachments;

  const productsSection = () => {
    if (canHaveProducts()) {
      return (
        <>
          <Divider className={classes.divider} />
          <AccordionItem
            items={productOrders}
            reclamationCase={reclamationCase}
            config={caseConfig.productOrders}
            title="products"
            selectingItemsEnabled={caseHandlingUpdateAllowed}
            selectedItems={getSelectedItemsUuid(selectedProductOrders)}
            handleItemsSelected={handleItemsSelected}
            handleItemsDeselected={handleItemsDeselected}
            caseType={reclamationCase.caseType}
          />
        </>
      );
    }
    return null;
  };

  const shippingBoxesSection = () => {
    if (canHaveShippingBoxes()) {
      return (
        <>
          <Divider className={classes.divider} />
          <AccordionItem
            items={shippingBoxes}
            reclamationCase={reclamationCase}
            config={caseConfig.shippingBoxes}
            title="shippingBoxes"
            selectingItemsEnabled={caseHandlingUpdateAllowed}
            selectedItems={getSelectedItemsUuid(selectedShippingBoxes)}
            handleItemsSelected={handleItemsSelected}
            handleItemsDeselected={handleItemsDeselected}
            caseType={reclamationCase.caseType}
          />
        </>
      );
    }
    return null;
  };

  const entireOrdersSection = () => {
    if (canHaveEntireOrders()) {
      return (
        <>
          <Divider className={classes.divider} />
          <AccordionItem
            items={entireOrders}
            reclamationCase={reclamationCase}
            config={caseConfig.entireOrders}
            title="entireOrders"
            selectingItemsEnabled={caseHandlingUpdateAllowed}
            selectedItems={getSelectedItemsUuid(selectedEntireOrders)}
            handleItemsSelected={handleItemsSelected}
            handleItemsDeselected={handleItemsDeselected}
            caseType={reclamationCase.caseType}
          />
        </>
      );
    }
    return null;
  };

  const noItemSection = () => {
    if (canHaveNoItem()) {
      return (
        <>
          <Divider className={classes.divider} />
          <AccordionItem
            items={noItems}
            reclamationCase={reclamationCase}
            config={caseConfig.noItem}
            title={`caseInformation.reasonIdentifiers.${reclamationCase.reasonIdentifier}`}
            selectingItemsEnabled={caseHandlingUpdateAllowed}
            selectedItems={getSelectedItemsUuid(selectedNoItems)}
            handleItemsSelected={handleItemsSelected}
            handleItemsDeselected={handleItemsDeselected}
            caseType={reclamationCase.caseType}
          />
        </>
      );
    }
    return null;
  };

  const attachmentsSection = () => {
    if (canHaveAttachments()) {
      return (
        <>
          <Divider className={classes.divider} />
          <Attachments
            reclamationCase={reclamationCase}
            existingAttachments={reclamationCase.attachments || []}
            canEdit={userHasReclamationOriolaAdminRole(userData)}
          />
        </>
      );
    }
    return null;
  };

  const nmvsError = () => {
    if (
      currentUserIsOriolaUser === true &&
      caseType === CaseType.SUSPECTED_PRODUCT_DEFECT &&
      nmvsErrorMessage != null
    ) {
      return <NMVSError sfmdXml={nmvsErrorMessage} />;
    }
    return null;
  };

  const caseForOriolaByOriola = isCaseForOriolaByOriola(
    reclamationCase.customerId,
    userData
  );
  return (
    <>
      <div className={classes.container}>
        <CaseStatusTable
          reclamationCase={reclamationCase}
          currentUserIsOriolaUser={currentUserIsOriolaUser}
        />
        <Divider className={classes.divider} />
        <BasicInformation reclamationCase={reclamationCase} />
        <Divider className={classes.divider} />
        <CaseInformation
          reclamationCase={reclamationCase}
          currentUserIsOriolaUser={currentUserIsOriolaUser}
          currentUserIsPharmaUser={currentUserIsPharmaUser}
          onTranslateClick={onTranslateClick}
        />
        {productsSection()}
        {shippingBoxesSection()}
        {entireOrdersSection()}
        {noItemSection()}
        {attachmentsSection()}
        {nmvsError()}
        <Divider className={classes.divider} />
        {caseForOriolaByOriola === false && (
          <Confirmation
            history={history}
            user={userData}
            reclamationCase={reclamationCase}
          />
        )}
        <Divider className={classes.dividerTransparent} />
      </div>
      <Can
        user={userData}
        perform={Permission.SEND_MESSAGE}
        customerContext={ANY_CUSTOMER}
      >
        <Messages reclamationCase={reclamationCase} />
      </Can>
    </>
  );
}

CaseDetails.propTypes = {
  reclamationCase: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    caseType: PropTypes.string,
    productReturn: PropTypes.shape({}),
    customerId: PropTypes.string,
    reasonIdentifier: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
    nmvsErrorMessage: PropTypes.string,
  }),
  onTranslateClick: PropTypes.func,
};

CaseDetails.defaultProps = {
  reclamationCase: {},
  onTranslateClick: null,
};

export default CaseDetails;
