import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoComplete, ErrorMessage } from "@oriola-origo/origo-ui-core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
// eslint-disable-next-line import/no-cycle
import { searchOrganizations, clearOrganizations } from "../../../../redux";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";

function OrganizationSearch({
  handleSelected,
  selectedCustomer,
  error,
  disabled,
  id,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { organizations, searchingOrganizations, organizationSearchError } =
    useSelector(state => state.organization);
  const [displayedError, setDisplayedError] = useState("");
  const fetchingCustomers = useSelector(state => state.customer.fetching);
  const onOrganizationSelected = organization => {
    if (organization != null) {
      handleSelected({
        id: organization.id,
        name: organization.name,
      });
    }
  };

  const onSearchOrganizations = query => {
    if (query != null && query.length >= 3) {
      dispatch(searchOrganizations(query));
    } else {
      handleSelected(null);
      dispatch(clearOrganizations());
    }
  };

  const getLabel = option => (option ? `${option.id} - ${option.name}` : "");

  useEffect(() => {
    if (organizationSearchError) {
      setDisplayedError(t("organizationFetchError"));
    } else if (error) {
      setDisplayedError(getValidationErrorMessage(error, t));
    } else {
      setDisplayedError("");
    }
  }, [organizationSearchError, error, t]);

  return (
    <Box width="32rem">
      <AutoComplete
        options={[...organizations, selectedCustomer]}
        loadingOptions={searchingOrganizations || fetchingCustomers}
        onOptionSelected={(event, value) => onOrganizationSelected(value)}
        getOptionLabel={option => getLabel(option)}
        onSearch={query => onSearchOrganizations(query)}
        value={selectedCustomer}
        onInputChange={event => {
          if (event && event.target) {
            const value = String(event.target.value || "");
            const searchValue = value.toLowerCase();
            onSearchOrganizations(searchValue);
          }
        }}
        getOptionSelected={(option, value) => option.name === value.name}
        placeholder={t("writeOrganizationNameOrId")}
        noOptionsText={t("noOrganizations")}
        loadingText={t("loading")}
        error={!!displayedError}
        id={id}
        disabled={disabled}
      >
        {displayedError && (
          <Box mt={1}>
            <ErrorMessage text={displayedError} />
          </Box>
        )}
      </AutoComplete>
    </Box>
  );
}

OrganizationSearch.propTypes = {
  handleSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selectedCustomer: PropTypes.shape({}),
  error: PropTypes.string,
  id: PropTypes.string,
};

OrganizationSearch.defaultProps = {
  disabled: false,
  selectedCustomer: null,
  error: "",
  id: "",
};

export default OrganizationSearch;
