import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import moment from "moment";
import MessageAction from "./messageAction";
import EventType from "../../../constants/eventType";

const useStyles = makeStyles(theme => ({
  text: {
    ...theme.typography.body2,
    color: OriolaColors.secondaryDarkGray,
    marginRight: theme.spacing(0.5),
  },
  textBold: {
    ...theme.typography.body2,
    color: OriolaColors.secondaryDarkGray,
    marginRight: theme.spacing(0.5),
    fontWeight: "bold",
  },
  textBoldHighlight: {
    ...theme.typography.body2,
    color: OriolaColors.secondaryNavyBlue,
    fontWeight: "bold",
  },
}));

function CaseEvent({ message, type }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { creator, creationTime, info } = message;
  const date = moment(creationTime).format("DD.MM.YYYY, HH:mm");
  let name = "Oriola";
  if (creator.firstName != null && creator.lastName != null) {
    name = `${creator.firstName} ${creator.lastName}`;
  }

  return (
    <MessageAction message={message} additionalMessage={t("reopenCase.reason")}>
      <Typography noWrap className={classes.textBold}>
        {t(`event.${type}`).toUpperCase()}
        &nbsp;
        {/* to remove string from the info and only keep the case number */}
        {type === EventType.COPY_CASE && info.replace(/^\D+/g, "")}
      </Typography>
      <Typography noWrap className={classes.text}>
        {date}
        &nbsp;
        {t("by")}
      </Typography>
      <Typography noWrap className={classes.textBoldHighlight}>
        {name}
      </Typography>
    </MessageAction>
  );
}

CaseEvent.propTypes = {
  message: PropTypes.shape({
    creator: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    creationTime: PropTypes.string.isRequired,
    info: PropTypes.string,
  }).isRequired,
  type: PropTypes.string,
};

CaseEvent.defaultProps = {
  type: "",
};

export default CaseEvent;
