import React from "react";

function NotFound() {
  return (
    <div>
      <h1>NOT FOUND</h1>
      <p>Page not found</p>
    </div>
  );
}

export default NotFound;
