import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Progress } from "@oriola-origo/origo-ui-core";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CaseType } from "../../../constants/caseType";
import {
  fetchCase,
  updateCase,
  clearValidationErrors,
  fetchCustomerById,
  updateContactInfo,
  setRequireMoreInfoFromCustomer,
  setEditedKeyField,
  addNotification,
} from "../../../redux";
import { isAllowed, Permission, isOriolaUser } from "../../auth/permission";
import { transformCustomer } from "../../../utils/customer/customer";
import NotFound from "../../404/404";
import { getCaseConfig } from "../../../utils/case/case";
import { CaseStatus } from "../../../constants/caseStatus";
import { Paths } from "../../../utils/navigation/navigation";

export const shouldDisableInputFields = (userData, customerId, caseAsDraft) => {
  if (isAllowed(userData, Permission.CASE_UPDATE, customerId)) {
    if (caseAsDraft) return false;
    if (isOriolaUser(userData)) return false;
  }
  return true;
};

function FieldsCaseView({
  match,
  ProductDefect,
  ProductDelivery,
  DamagedProduct,
  isNewCase,
  editMode,
  isDraft,
}) {
  const dispatch = useDispatch();
  const { reclamationId } = match.params;
  const {
    sendingCase,
    fetching,
    case: reclamationCase,
  } = useSelector(state => state.case);
  const { caseType, productReturn, reasonIdentifier } = reclamationCase;
  const showProgress = fetching || sendingCase || false;
  const { userData } = useSelector(state => state.user);
  const [isDisabled, setDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const {
    DamagedProductConfig,
    ProductReturnReasonIdentifiersConfig,
    SuspectedProductDefectConfig,
  } = useSelector(state => state.fieldsConfig);
  const caseConfig = getCaseConfig(
    caseType,
    productReturn,
    reasonIdentifier,
    DamagedProductConfig,
    ProductReturnReasonIdentifiersConfig,
    SuspectedProductDefectConfig
  );
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    const isASubmittedCaseOnDraftPage = fetchedCase => {
      if (!editMode && fetchedCase.caseStatus !== CaseStatus.DRAFT) {
        dispatch(
          addNotification({
            title: t("cannotEditSubmittedCase"),
          })
        );
        history.push(Paths.OpenCases);
      }
    };

    if (reclamationId) {
      dispatch(setEditedKeyField(false));
      dispatch(fetchCase(reclamationId)).then(fetchedCase => {
        if (fetchedCase) {
          isASubmittedCaseOnDraftPage(fetchedCase);
          if (fetchedCase.customerId) {
            dispatch(fetchCustomerById(fetchedCase.customerId)).then(
              customer => {
                dispatch(
                  updateContactInfo({ customer: transformCustomer(customer) })
                );
              }
            );
          }
        } else {
          setIsError(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reclamationId, editMode, history]);

  useEffect(() => {
    if (
      reclamationCase.caseStatus ===
      CaseStatus.OPEN_INFORMATION_REQUESTED_FROM_CUSTOMER
    ) {
      dispatch(setRequireMoreInfoFromCustomer(true));
    } else {
      dispatch(setRequireMoreInfoFromCustomer(false));
    }
  }, [dispatch, reclamationCase.caseStatus]);

  // initilize case
  useEffect(() => {
    dispatch(updateCase({}));
    dispatch(clearValidationErrors());
  }, [dispatch]);

  useEffect(() => {
    if (isNewCase) {
      return;
    }
    const disable = shouldDisableInputFields(
      userData,
      reclamationCase.customerId,
      isDraft
    );
    setDisabled(disable);
  }, [userData, reclamationCase.customerId, isDisabled, isNewCase, isDraft]);

  const getCaseComponent = () => {
    const reclamationType = reclamationCase.caseType;
    switch (reclamationType) {
      case CaseType.DAMAGED_PRODUCT:
        return (
          <DamagedProduct
            reclamationCase={reclamationCase}
            disabled={isDisabled}
            caseConfig={caseConfig}
            isNewCase={isNewCase}
            id="new-damaged-product"
          />
        );
      case CaseType.SUSPECTED_PRODUCT_DEFECT:
        return ProductDefect ? (
          <ProductDefect
            reclamationCase={reclamationCase}
            isNewCase={isNewCase}
            disabled={isDisabled}
            caseConfig={caseConfig}
            id="new-product-defect"
          />
        ) : (
          <NotFound />
        );
      case CaseType.PRODUCT_DELIVERY:
        return ProductDelivery ? (
          <ProductDelivery
            reclamationCase={reclamationCase}
            disabled={isDisabled}
            isNewCase={isNewCase}
            caseConfig={caseConfig}
            id="new-product-delivery"
          />
        ) : (
          <NotFound />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      width="80%"
      margin="auto"
      height="calc(100vh - 135px)"
      style={{ overflow: "auto" }}
    >
      {isError && <NotFound />}
      {showProgress === true && <Progress show />}
      {showProgress === false && getCaseComponent()}
    </Box>
  );
}

FieldsCaseView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      reclamationId: PropTypes.string,
    }),
  }),
  ProductDefect: PropTypes.elementType,
  ProductDelivery: PropTypes.elementType,
  DamagedProduct: PropTypes.elementType,
  isNewCase: PropTypes.bool,
  editMode: PropTypes.bool,
  isDraft: PropTypes.bool,
};

FieldsCaseView.defaultProps = {
  match: { params: undefined },
  isNewCase: false,
  editMode: false,
  isDraft: false,
  ProductDefect: undefined,
  ProductDelivery: undefined,
  DamagedProduct: undefined,
};

export default FieldsCaseView;
