import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Dropdown, Button } from "@oriola-origo/origo-ui-core";
import { Box, Divider } from "@material-ui/core";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { CaseType } from "../../../constants/caseType";
import { FieldTitle } from "../../fields/common";
import CaseItemsType from "../../../constants/caseItemsType";
import { getProductDisplayName } from "../../../utils/productUtil/productUtil";
import { filterDeprecatedReasonIdentifier } from "../../../utils/case/case";

const useStyles = makeStyles(theme => ({
  container: {},
  title: {
    ...theme.typography.h4,
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    lineHeight: "150%",
    paddingBottom: theme.spacing(2),
    borderBottom: "1px solid #e7e7e7",
  },
  button: {
    marginRight: theme.spacing(2),
    "&:first-child": {
      marginLeft: "auto",
    },
    "&:disabled": {
      background: theme.palette.grey[500],
    },
  },
  divider: {
    marginTop: theme.spacing(3),
    borderBottomColor: "#e7e7e7",
  },
}));

function SelectCopyType({ reclamationCase, onCancel, onTypeSelected }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [caseType, setCaseType] = useState("");
  const [reasonIdentifier, setReasonIdentifier] = useState("");
  const [reasons, setReasons] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [allowedCaseTypes, setAllowedCaseTypes] = useState([]);

  const {
    SuspectedProductDefectReasonIdentifiers,
    DamagedProductReasonIdentifiers,
    ProductReturnReasonIdentifiers,
    DamagedProductConfig,
  } = useSelector(state => state.fieldsConfig);

  useEffect(() => {
    let initialAllowedCaseTypes = [
      CaseType.SUSPECTED_PRODUCT_DEFECT,
      CaseType.DAMAGED_PRODUCT,
      CaseType.PRODUCT_DELIVERY,
    ];
    if (reclamationCase?.caseType) {
      initialAllowedCaseTypes = initialAllowedCaseTypes.filter(
        allowedCaseType => allowedCaseType !== reclamationCase.caseType
      );
    }
    if (
      [CaseType.PRODUCT_DELIVERY, CaseType.DAMAGED_PRODUCT].includes(
        reclamationCase?.caseType
      )
    ) {
      initialAllowedCaseTypes = [CaseType.SUSPECTED_PRODUCT_DEFECT];
    }
    setAllowedCaseTypes(initialAllowedCaseTypes);
  }, [reclamationCase]);

  useEffect(() => {
    const items = reclamationCase?.items || [];
    const validItems = items.filter(
      x => x.type === CaseItemsType.PRODUCT_ORDER
    );
    setProducts(validItems);
    setSelectedProduct(validItems[0]);
  }, [reclamationCase]);

  useEffect(() => {
    if (caseType === CaseType.SUSPECTED_PRODUCT_DEFECT) {
      setReasons(Object.values(SuspectedProductDefectReasonIdentifiers));
    }
    if (caseType === CaseType.DAMAGED_PRODUCT) {
      const tmpReasonIdentifier = reclamationCase?.reasonIdentifier || "";
      const tmpReasons = filterDeprecatedReasonIdentifier(
        Object.values(DamagedProductReasonIdentifiers),
        true,
        tmpReasonIdentifier,
        DamagedProductConfig
      );
      setReasons(tmpReasons);
    }
    if (caseType === CaseType.PRODUCT_DELIVERY) {
      setReasons(Object.values(ProductReturnReasonIdentifiers));
    }
  }, [
    SuspectedProductDefectReasonIdentifiers,
    DamagedProductReasonIdentifiers,
    ProductReturnReasonIdentifiers,
    caseType,
    DamagedProductConfig,
    reclamationCase,
  ]);

  const renderCaseTypeSelector = () => (
    <Box width="100%" mt={3} marginLeft="auto">
      <FieldTitle title={t("caseType")} type="selection" />
      <Dropdown
        value={caseType}
        onFormatOptionLabel={x => t(`caseTypes.${x}`)}
        options={allowedCaseTypes}
        onSelectOption={x => {
          setCaseType(x);
        }}
        showSelectNone={false}
        selectNoneLabel={t("selectNone")}
        id="copy-case-type"
      />
    </Box>
  );

  const renderReasonIdentifierSelector = () => (
    <Box width="100%" mt={3}>
      <FieldTitle
        title={t("caseInformation.reasonIdentifier")}
        type="selection"
      />
      <Dropdown
        value={reasonIdentifier}
        emptySelectionLabel={t("caseInformation.reasonIdentifierPlaceholder")}
        onFormatOptionLabel={x => t(`caseInformation.reasonIdentifiers.${x}`)}
        options={reasons}
        onSelectOption={x => {
          setReasonIdentifier(x);
        }}
        showSelectNone={false}
        selectNoneLabel={t("selectNone")}
        id="copy-case-reason-identifier"
      />
    </Box>
  );

  const renderProductSelector = () => (
    <Box width="100%" mt={3}>
      <FieldTitle title={t("caseItemFields.product")} type="selection" />
      <Dropdown
        value={selectedProduct}
        onFormatOptionLabel={x => getProductDisplayName(x.product || {})}
        options={products}
        onSelectOption={x => {
          setSelectedProduct(x);
        }}
        showSelectNone={false}
        selectNoneLabel={t("selectNone")}
        id="copy-case-product"
      />
    </Box>
  );

  const renderButtons = () => (
    <Box display="flex" mt={3}>
      <Button
        className={classes.button}
        variant="secondary"
        onClick={onCancel}
        id="copy-case-cancel"
      >
        {t("cancel")}
      </Button>
      <Button
        className={classes.button}
        onClick={() => {
          onTypeSelected(caseType, reasonIdentifier, selectedProduct);
        }}
        id="copy-case-next"
        disabled={!caseType || !reasonIdentifier || !selectedProduct}
      >
        {t("next")}
      </Button>
    </Box>
  );

  return (
    <div className={classes.container}>
      <div className={classes.title}>{t("copyCase.title")}</div>
      {renderCaseTypeSelector()}
      {renderReasonIdentifierSelector()}
      {renderProductSelector()}
      <Divider className={classes.divider} />
      {renderButtons()}
    </div>
  );
}

SelectCopyType.propTypes = {
  reclamationCase: PropTypes.shape({
    caseType: PropTypes.string,
    productReturn: PropTypes.bool,
    reasonIdentifier: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onCancel: PropTypes.func,
  onTypeSelected: PropTypes.func,
};

SelectCopyType.defaultProps = {
  reclamationCase: {},
  onCancel: null,
  onTypeSelected: null,
};

export default SelectCopyType;
