/* eslint-disable import/no-extraneous-dependencies */
import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import { AnalyticsUtil } from "@oriola-origo/origo-common-client-lib";
import loggerMiddleware from "../logger";
import createRootReducer from "../createRootReducer";
import analyticsMiddleware from "../analytics";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

export const history = createBrowserHistory();

const configureStore = preloadedState => {
  const middlewares = [loggerMiddleware, thunkMiddleware];

  if (AnalyticsUtil.isAnalyticsEnabled() === true) {
    middlewares.push(analyticsMiddleware);
  }

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, sentryReduxEnhancer];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const composedEnhancers = composeEnhancers(...enhancers);
  const rootReducer = createRootReducer(history);
  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
};
export default configureStore;
