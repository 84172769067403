import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SelectMenu from "./selectMenu";
import { CaseType, getCaseTypeLocalized } from "../../../constants/caseType";
import { isOriolaUser, isPharmaceuticalCompany } from "../../auth/permission";

export const ALL_VALUE = "all";

function CaseTypeSelect({ caseType, onCaseTypeSelected, ...containerProps }) {
  const { t } = useTranslation();
  const all = t("caseStatusFilter.all");
  const { userData } = useSelector(state => state.user);

  const options = [{ value: ALL_VALUE, label: all }].concat(
    Object.values(CaseType)
      .map(value => ({ value, label: getCaseTypeLocalized(t, value, true) }))
      .filter(typeVal => {
        if (isPharmaceuticalCompany(userData) && !isOriolaUser(userData)) {
          return typeVal.value === CaseType.SUSPECTED_PRODUCT_DEFECT;
        }
        return true;
      })
  );

  const handleCaseTypeSelected = value => {
    if (value === ALL_VALUE) {
      onCaseTypeSelected(null);
    } else {
      onCaseTypeSelected(value);
    }
  };

  return (
    <SelectMenu
      width="100%"
      optionValue={caseType || options[0].value}
      options={options}
      onSelectOption={handleCaseTypeSelected}
      label={t("caseType")}
      {...containerProps}
    />
  );
}

CaseTypeSelect.propTypes = {
  caseType: PropTypes.string,
  onCaseTypeSelected: PropTypes.func.isRequired,
};

CaseTypeSelect.defaultProps = {
  caseType: null,
};

export default CaseTypeSelect;
