import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CaseType } from "../../../constants/caseType";
// eslint-disable-next-line import/no-cycle
import { ContactInfo, CaseDescriptionInput } from "../index";
import {
  Attachments,
  ProductReturn,
  CaseItems,
  ReasonIdentifier,
  InformationConfirmation,
  ReturnPackagesAmount,
} from "../common";
import {
  filterDeprecatedReasonIdentifier,
  shouldShowSubmitFields,
} from "../../../utils/case/case";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "green",
    },
  },
  container: {
    padding: theme.spacing(4),
    minHeight: 580,
    marginLeft: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
    textTransform: "uppercase",
    fontSize: "1rem",
    color: "#2b3e5b",
  },
  warningTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textTransform: "uppercase",
    fontSize: "1rem",
    color: theme.palette.text.disabled,
  },
  rowContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  selectInput: {
    minWidth: "32rem",
  },
  controlContainer: {
    display: "flex",
    alignItems: "baseline",
    flexGrow: 1,
  },
  inputField: {
    width: "32rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

function DamagedProductFields({
  reclamationCase,
  isNewCase,
  disabled,
  caseConfig,
  editMode,
  isConvertedCase,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const fieldsConfig = useSelector(state => state.fieldsConfig);
  const { DamagedProductReasonIdentifiers = {}, DamagedProductConfig } =
    fieldsConfig;
  const reasons = filterDeprecatedReasonIdentifier(
    Object.values(DamagedProductReasonIdentifiers),
    editMode,
    reclamationCase.reasonIdentifier,
    DamagedProductConfig
  );
  const { userData } = useSelector(state => state.user);
  const contactInfo = useSelector(state => state.contactInfo);
  const [attachmentError, setAttachmentError] = useState(null);

  return (
    <div>
      <ContactInfo
        customerId={reclamationCase.customerId}
        deliveryAddressId={reclamationCase.deliveryAddressId}
        contactPerson={reclamationCase.contactPersonName}
        phoneNumber={reclamationCase.contactPhoneNumber}
        disabled={disabled}
        isNewCase={isNewCase}
      />
      <Box mb={3}>
        <Divider className={classes.divider} />
      </Box>

      <Typography className={classes.title} variant="h5" color="secondary">
        {t("caseInformation.caseInformation")}
      </Typography>
      <ProductReturn mb={3} editMode={editMode} disabled={disabled} />
      <ReasonIdentifier
        reclamationCase={reclamationCase}
        reasons={reasons}
        disabled={disabled}
        editMode={editMode}
      />
      {caseConfig && caseConfig.caseDescription && (
        <CaseDescriptionInput
          classes={classes}
          reclamationCase={reclamationCase}
          disabled={disabled}
          caseConfig={caseConfig}
        />
      )}
      <CaseItems
        disabled={disabled}
        caseConfig={caseConfig}
        isConvertedCase={isConvertedCase}
      />
      <Box mb={3}>
        <Attachments
          reclamationCase={reclamationCase}
          id="reclamation-file-upload"
          disabled={disabled}
          existingAttachments={reclamationCase.attachments || []}
          attachmentError={attachmentError}
          setAttachmentError={setAttachmentError}
        />
      </Box>
      {shouldShowSubmitFields(reclamationCase, contactInfo, userData) &&
        !isConvertedCase && (
          <>
            <Typography
              className={classes.title}
              variant="h5"
              color="secondary"
            >
              {t("submit")}
            </Typography>
            <ReturnPackagesAmount />
            <InformationConfirmation />
          </>
        )}
    </div>
  );
}

DamagedProductFields.propTypes = {
  history: PropTypes.shape({}),
  reclamationCase: PropTypes.shape({
    caseType: PropTypes.string,
    customerId: PropTypes.string,
    deliveryAddressId: PropTypes.string,
    contactPersonName: PropTypes.string,
    contactPhoneNumber: PropTypes.string,
    reasonIdentifier: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isNewCase: PropTypes.bool,
  disabled: PropTypes.bool,
  editMode: PropTypes.bool,
  caseConfig: PropTypes.shape({
    caseDescription: PropTypes.string,
    returnPackagesAmount: PropTypes.bool,
    informationConfirmation: PropTypes.bool,
  }),
  isConvertedCase: PropTypes.bool,
};

DamagedProductFields.defaultProps = {
  reclamationCase: { caseType: CaseType.DAMAGED_PRODUCT },
  isNewCase: false,
  disabled: false,
  editMode: false,
  caseConfig: {},
  isConvertedCase: false,
  history: {},
};

export default DamagedProductFields;
