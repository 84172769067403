import { RestService } from "../../components/common";
import { ensureTrailingSlash } from "../../utils/url/url";

export const ProfitCenter = Object.freeze({
  FETCH_STARTED: "FETCH_PROFIT_CENTERS_STARTED",
  FETCH_FINISHED: "FETCH_PROFIT_CENTERS_FINISHED",
  ALL_PROFIT_CENTERS_FETCH_FINISHED: "ALL_PROFIT_CENTERS_FETCH_FINISHED",
  ALL_SUPPLIERS_FETCH_FINISHED: "ALL_SUPPLIERS_FETCH_FINISHED",
  FETCH_ERROR: "FETCH_PROFIT_CENTERS_ERROR",
  CLEAR: "CLEAR_PROFIT_CENTERS",
});

// -- ACTIONS

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_RECLAMATION_SERVICE);
const profitCenterSearchApi =
  process.env.REACT_APP_RECLAMATION_PROFIT_CENTER_SEARCH_API;
const getProfitCenterApi =
  process.env.REACT_APP_RECLAMATION_GET_PROFIT_CENTER_API;
const getSupplierApi = process.env.REACT_APP_RECLAMATION_GET_SUPPLIER_API;

const LIMIT = 50;

export const searchProfitCenters =
  (queryText = "") =>
  async dispatch => {
    const path = `${baseUrl}${profitCenterSearchApi}?query=${queryText}&limit=${LIMIT}`;

    try {
      // start
      dispatch({ type: ProfitCenter.FETCH_STARTED });

      const result = await RestService.get(path);

      const overallProfitCenterCount = result.totalResults;
      const foundProfitCenters = result.results;
      dispatch({
        type: ProfitCenter.FETCH_FINISHED,
        payload: {
          profitCenters: foundProfitCenters,
          overallProfitCenterCount,
        },
      });

      return foundProfitCenters;
    } catch (error) {
      dispatch({ type: ProfitCenter.FETCH_ERROR, payload: error });
      return error;
    }
  };

export const clearProfitCenters = () => dispatch => {
  dispatch({
    type: ProfitCenter.CLEAR,
  });
};

export const getAllProfitCenters = () => async (dispatch, getState) => {
  const path = `${baseUrl}${getProfitCenterApi}`;
  try {
    const { fetching } = getState().profitCenters;
    if (fetching) {
      return [];
    }
    // start
    dispatch({ type: ProfitCenter.FETCH_STARTED });

    const result = await RestService.get(path);

    dispatch({
      type: ProfitCenter.ALL_PROFIT_CENTERS_FETCH_FINISHED,
      payload: {
        allProfitCenters: result,
      },
    });

    return result;
  } catch (error) {
    dispatch({ type: ProfitCenter.FETCH_ERROR, payload: error });
    return error;
  }
};

export const getAllSuppliers = () => async (dispatch, getState) => {
  const path = `${baseUrl}${getSupplierApi}`;
  try {
    const fetching = getState().profitCenters.fetchingSupplier;
    if (fetching) {
      return [];
    }
    // start
    dispatch({ type: ProfitCenter.FETCH_STARTED });

    const result = await RestService.get(path);

    dispatch({
      type: ProfitCenter.ALL_SUPPLIERS_FETCH_FINISHED,
      payload: {
        allSuppliers: result,
      },
    });

    return result;
  } catch (error) {
    dispatch({ type: ProfitCenter.FETCH_ERROR, payload: error });
    return error;
  }
};

// -- REDUCER --

const INIT_STATE = {
  // list of profitCenters
  profitCenters: [],
  allProfitCenters: [],
  allSuppliers: [],
  fetching: false,
  fetchError: null,
  fetchingSupplier: false,
};

export const profitCentersReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case ProfitCenter.FETCH_STARTED:
      return { ...state, fetching: true, fetchError: null };
    case ProfitCenter.FETCH_FINISHED: {
      const { profitCenters, overallProfitCenterCount } = action.payload;
      return {
        ...state,
        fetching: false,
        profitCenters,
        overallProfitCenterCount,
      };
    }
    case ProfitCenter.ALL_PROFIT_CENTERS_FETCH_FINISHED: {
      const { allProfitCenters } = action.payload;
      return { ...state, fetching: false, allProfitCenters };
    }
    case ProfitCenter.ALL_SUPPLIERS_FETCH_FINISHED: {
      const { allSuppliers } = action.payload;
      return { ...state, fetchingSupplier: false, allSuppliers };
    }
    case ProfitCenter.FETCH_ERROR:
      return { ...state, fetching: false, fetchError: action.payload };
    case ProfitCenter.CLEAR:
      return { ...state, profitCenters: [] };
    default:
      return state;
  }
};
