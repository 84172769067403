export const CasesFilters = Object.freeze({
  UPDATE_FILTERS: "UPDATE_FILTERS",
});

export const setFetchFilters = filters => ({
  type: CasesFilters.UPDATE_FILTERS,
  payload: filters,
});

// -- REDUCER --

const INIT_STATE = {};

export const casesFilterReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case CasesFilters.UPDATE_FILTERS:
      return action.payload;
    default:
      return state;
  }
};
