import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    marginBottom: theme.spacing(3),
  },
  title: {
    ...theme.typography.subtitle1,
    fontWeight: "700",
    textTransform: "uppercase",
  },
}));

function Title({ title, children }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant="h3" color="secondary">
        {title}
      </Typography>
      {children}
    </div>
  );
}

Title.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

Title.defaultProps = {
  title: "",
  children: null,
};

export default Title;
