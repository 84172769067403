import { useEffect } from "react";
import PropTypes from "prop-types";
import AsyncPoller from "../../../utils/asyncPoller/asyncPoller";
import {
  fetchAndReserveCurrentCaseHandler,
  unreserveCurrentCaseHandler,
} from "../../../utils/case/case";

const asyncPoller = new AsyncPoller();
const CASE_RESERVE_POLL_TIMEOUT_MS = 30000;

function CaseHandlerUpdater({ reclamationCase, onCaseHandlerUpdated }) {
  const refreshCaseHandler = reclamationId => {
    // sanity
    if (reclamationId == null) {
      return;
    }

    fetchAndReserveCurrentCaseHandler(reclamationId)
      .then(caseHandler => {
        onCaseHandlerUpdated(caseHandler);
      })
      // eslint-disable-next-line no-console
      .catch(error => console.error("refreshCaseHandler error", error));
  };

  const unreserveCaseHandler = reclamationId => {
    // sanity
    if (reclamationId == null) {
      return;
    }

    unreserveCurrentCaseHandler(reclamationId)
      .then(() => onCaseHandlerUpdated(null))
      // eslint-disable-next-line no-console
      .catch(error => console.error("unreserveCaseHandler error", error));
  };

  useEffect(() => {
    const { reclamationId } = reclamationCase || {};

    // initial refresh
    refreshCaseHandler(reclamationId);

    // start polling
    asyncPoller.run(
      () => refreshCaseHandler(reclamationId),
      CASE_RESERVE_POLL_TIMEOUT_MS
    );

    return () => {
      unreserveCaseHandler(reclamationId);
      asyncPoller.clear();
    };
    // We are interested only tracking the case changes hence the eslint disable of the debs
  }, [reclamationCase]); // eslint-disable-line

  return null;
}

CaseHandlerUpdater.propTypes = {
  reclamationCase: PropTypes.shape({}),
  onCaseHandlerUpdated: PropTypes.func,
};

CaseHandlerUpdater.defaultProps = {
  reclamationCase: {},
  onCaseHandlerUpdated: null,
};

export default CaseHandlerUpdater;
