import React from "react";
import {
  submitCase,
  addNotification,
  hideDialog,
  SendingStatus,
} from "../../../redux";
import { CaseCreatedIcon } from "../../../images";
import {
  handleUpdateAttachmentsError,
  isUpdateAttachmentsError,
} from "../../../utils/updateAttachments/updateAttachments";

const doSubmitCase = (t, dispatch, reclamationCase, onSubmitted) => {
  dispatch(hideDialog());
  dispatch(submitCase(reclamationCase)).then(result => {
    if (result && result.status === SendingStatus.SUCCESS) {
      // inform user about successful submit
      dispatch(
        addNotification({
          title: t("submitSuccessful.title"),
          primaryText: `${t("caseNumber")}: ${result.data.caseNumber}`,
          icon: <CaseCreatedIcon />,
        })
      );

      if (onSubmitted) {
        onSubmitted(result.data);
      }
    } else if (result && result.status === SendingStatus.SENDING) {
      dispatch(
        addNotification({
          title: t("duplicateSavingCase"),
        })
      );
    } else if (isUpdateAttachmentsError(result)) {
      handleUpdateAttachmentsError(dispatch, t);
    } else {
      dispatch(
        addNotification({
          title: t("submitFailed.title"),
          type: "error",
        })
      );
    }
  });
};

const confirmAndSubmitCase = (t, dispatch, reclamationCase, onSubmitted) => {
  doSubmitCase(t, dispatch, reclamationCase, onSubmitted);
};
export default confirmAndSubmitCase;
