import React from "react";
import { NotificationPopups } from "@oriola-origo/origo-ui-core";
import { useSelector, useDispatch } from "react-redux";
import { dismissNotification } from "../../../redux";

function NotificationPopupsComponent() {
  const notifications = useSelector(
    state => state.notificationPopups.notifications
  );
  const dispatch = useDispatch();
  const onClose = id => {
    dispatch(dismissNotification(id));
  };
  return (
    <NotificationPopups
      notifications={notifications}
      onClose={onClose}
      id="notification-popup"
    />
  );
}

export default NotificationPopupsComponent;
