import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import DetailRow from "../detailRow";
import getModifierStrOrNull from "./getModifierStrOrNull";
import getValueIfNotNull from "./getValueIfNotNull";
import { isCustomer } from "../../../auth/permission";

function CompensationPermissionView({
  isPermissionFromPharmaCompanyRequiredForCompensation,
  userData,
  modifications,
  ...containerProps
}) {
  const { t } = useTranslation();
  if (
    isCustomer(userData) &&
    isPermissionFromPharmaCompanyRequiredForCompensation === false
  ) {
    return null;
  }
  return (
    <Box {...containerProps}>
      <Typography variant="subtitle2">
        {t("compensationPermission.title").toUpperCase()}
      </Typography>
      <Box mt={3}>
        <DetailRow
          keyName={t("compensationPermission.title")}
          value={getValueIfNotNull(
            isPermissionFromPharmaCompanyRequiredForCompensation,
            isPermissionFromPharmaCompanyRequiredForCompensation
              ? t("compensationPermission.permissionRequired")
              : t("compensationPermission.permissionNotRequired")
          )}
          secondaryValue={getModifierStrOrNull(
            isPermissionFromPharmaCompanyRequiredForCompensation != null,
            modifications,
            "isPermissionFromPharmaCompanyRequiredForCompensation"
          )}
        />
      </Box>
    </Box>
  );
}

CompensationPermissionView.propTypes = {
  isPermissionFromPharmaCompanyRequiredForCompensation: PropTypes.bool,
  userData: PropTypes.shape({}).isRequired,
  modifications: PropTypes.shape({}),
};

CompensationPermissionView.defaultProps = {
  isPermissionFromPharmaCompanyRequiredForCompensation: null,
  modifications: null,
};

export default CompensationPermissionView;
