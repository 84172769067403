import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { FIXED_ROW_HEIGHT } from "./constant";

const useStyles = makeStyles(theme => ({
  productRow: {
    borderBottom: "solid 1px #e7e7e7",
    height: FIXED_ROW_HEIGHT,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  solvedClass: {
    "& p": {
      color: theme.palette.text.disabled,
    },
  },
  clearRowSelection: {
    borderLeftColor: "transparent",
  },
  productRowContent: {
    paddingTop: theme.spacing(0.5),
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
}));

function TableRow({ linkto, children, style, isSolved }) {
  const classes = useStyles();
  const clearClass = classes.clearRowSelection;
  return (
    <Link to={linkto} style={style}>
      <div
        className={`${classes.productRow} ${clearClass} ${
          isSolved ? classes.solvedClass : ""
        }`}
      >
        <div className={classes.productRowContent}>{children}</div>
      </div>
    </Link>
  );
}

TableRow.propTypes = {
  linkto: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.shape({}),
  isSolved: PropTypes.bool,
};

TableRow.defaultProps = {
  linkto: "",
  children: null,
  style: {},
  isSolved: false,
};

export default TableRow;
