import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SelectMenu from "./selectMenu";
import EventQueueTarget from "../../../constants/eventQueueTarget";
import EventSubQueueTarget from "../../../constants/eventSubQueueTarget";

export const ALL_VALUE = "all";

function HandlerSelect({ handler, onHandlerSelected, ...containerProps }) {
  const { t } = useTranslation();
  const all = t("caseStatusFilter.all");

  const getEventQueueTargetLabel = value =>
    value ? t(`queues.${value}`) : "-";

  const getEventSubQueueTargetLabel = value =>
    value ? t(`subQueues.returnDepartment.${value}`) : "-";

  const subOptions = Object.values(
    EventSubQueueTarget.RETURN_DEPARTMENT_SUBQUEUES
  ).map(value => ({
    value,
    label: getEventSubQueueTargetLabel(value),
    subOption: true,
  }));

  const options = [{ value: ALL_VALUE, label: all }].concat(
    Object.values(EventQueueTarget).reduce((acc, value) => {
      if (value === EventQueueTarget.RETURN_DEPARTMENT) {
        acc.push({
          value,
          label: getEventQueueTargetLabel(value),
        });

        acc.push(...subOptions);
      } else {
        acc.push({
          value,
          label: getEventQueueTargetLabel(value),
        });
      }
      return acc;
    }, [])
  );

  const handleHandlerSelected = value => {
    if (value === ALL_VALUE) {
      onHandlerSelected(null);
    } else {
      Object.values(EventSubQueueTarget.RETURN_DEPARTMENT_SUBQUEUES).includes(
        value
      )
        ? onHandlerSelected(EventQueueTarget.RETURN_DEPARTMENT, value)
        : onHandlerSelected(value);
    }
  };

  return (
    <SelectMenu
      width="100%"
      optionValue={handler || options[0].value}
      options={options}
      onSelectOption={handleHandlerSelected}
      label={t("handler")}
      {...containerProps}
    />
  );
}

HandlerSelect.propTypes = {
  handler: PropTypes.string,
  onHandlerSelected: PropTypes.func.isRequired,
};

HandlerSelect.defaultProps = {
  handler: null,
};

export default HandlerSelect;
