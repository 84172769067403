import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-cycle
import { updateCase, updateValidationError } from "../../../../redux";
import {
  getValidationErrorMessage,
  FieldErrors,
} from "../../../../utils/validations/validations";
import { StatefullTextField } from "../../../common";
import { FieldTitle } from "..";

function CaseDescriptionInput({
  classes,
  reclamationCase,
  disabled,
  title,
  subtitle,
  placeholder,
  caseConfig,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { caseDescriptionError } = useSelector(state => state.validationErrors);
  return (
    <div className={classes.rowContainer}>
      <FieldTitle
        title={title || t("caseInformation.caseDescription")}
        subtitle={subtitle}
      />
      <StatefullTextField
        error={!!caseDescriptionError}
        helperText={getValidationErrorMessage(caseDescriptionError, t, {
          maxLength: caseConfig.caseDescription.maxLength,
        })}
        data-error-name="caseDescriptionError"
        fullWidth
        multiline
        rows="3"
        initialValue={reclamationCase.caseDescription || ""}
        variant="outlined"
        placeholder={
          placeholder || t("caseInformation.caseDescriptionPlaceholder")
        }
        margin="dense"
        disabled={disabled}
        onChange={description => {
          if (
            caseConfig &&
            caseConfig.caseDescription &&
            description.length > caseConfig.caseDescription.maxLength
          ) {
            dispatch(
              updateValidationError({
                caseDescriptionError: FieldErrors.OVER_LENGTH,
              })
            );
          } else {
            dispatch(updateCase({ caseDescription: description }));
            dispatch(updateValidationError({ caseDescriptionError: "" }));
          }
        }}
        id="new-case-description"
      />
    </div>
  );
}

CaseDescriptionInput.propTypes = {
  classes: PropTypes.shape({
    rowContainer: PropTypes.string,
  }),
  reclamationCase: PropTypes.shape({
    caseDescription: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  subtitle: PropTypes.string,
  caseConfig: PropTypes.shape({
    caseDescription: PropTypes.shape({
      maxLength: PropTypes.number,
    }),
  }),
};

CaseDescriptionInput.defaultProps = {
  disabled: false,
  caseConfig: {},
  classes: {},
  reclamationCase: {},
  title: "",
  subtitle: "",
  placeholder: "",
};

CaseDescriptionInput.displayName = "CaseDescriptionInput";

export default CaseDescriptionInput;
