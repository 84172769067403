/* eslint-disable react/no-array-index-key */
import React from "react";
import { AlertDialog } from "@oriola-origo/origo-ui-core";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  contentText: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.disabled,
  },
}));

function Dialog() {
  const { dialogData } = useSelector(state => state.dialog);
  const classes = useStyles();
  const descriptionArr = (dialogData.description || "").split("\n");
  const boldDescriptionArr = (dialogData.boldDescription || "").split("\n");
  const renderIcon = () => (
    <Box py={5} display="flex" alignItems="center" justifyContent="center">
      <Box>{dialogData.icon}</Box>
    </Box>
  );

  const renderDescription = () => (
    <Box>
      {descriptionArr.map((text, i) => (
        <Typography
          key={`dialog-text${i}`}
          className={classes.contentText}
          paragraph
        >
          {text}
        </Typography>
      ))}
      {boldDescriptionArr.map((text, i) => (
        <Typography
          style={{ fontWeight: 600 }}
          key={`dialog-text${i}`}
          className={classes.contentText}
          paragraph
        >
          {text}
        </Typography>
      ))}
    </Box>
  );

  return (
    <AlertDialog
      open={dialogData.open}
      onCancel={dialogData.onCancel}
      onOk={dialogData.onOk}
      title={dialogData.title}
      subTitle={dialogData.subTitle}
      cancelButtonText={dialogData.cancelButtonText}
      okButtonText={dialogData.okButtonText}
      disableBackdropClick={dialogData.disableBackdropClick || false}
      hideCancelButton={dialogData.hideCancelButton === true}
      hideOkButton={dialogData.hideOkButton === true}
      maxWidth="xs"
    >
      <Box mb={8}>
        {dialogData.icon && renderIcon()}
        <Box>{dialogData.description != null && renderDescription()}</Box>
      </Box>
    </AlertDialog>
  );
}

export default Dialog;
