import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Progress } from "@oriola-origo/origo-ui-core";
import { makeStyles } from "@material-ui/core/styles";
import { InfinityList } from "../common";
import { FIXED_ROW_HEIGHT, ROW_SPACING } from "./constant";

const useStyles = makeStyles(theme => ({
  tableBody: {
    paddingLeft: "1rem",
  },
  progress: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noCases: {
    padding: "0.8rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  notFoundText: {
    color: "#757070",
    marginTop: theme.spacing(15),
  },
}));

function TableBody({
  cases,
  visibleItemCount,
  overallCount,
  fetching,
  rowRenderer,
  onLoadMore,
  emptyText,
  minimumBatchSize,
}) {
  const classes = useStyles();
  return (
    <div className={classes.tableBody}>
      {cases.length > 0 && (
        <InfinityList
          items={cases}
          visibleItemCount={visibleItemCount}
          itemOverallCount={overallCount}
          rowHeight={FIXED_ROW_HEIGHT}
          rowSpacing={ROW_SPACING}
          showLoader={fetching}
          onRenderRow={rowRenderer}
          onLoad={onLoadMore}
          minimumBatchSize={minimumBatchSize}
        />
      )}
      {cases.length === 0 &&
        (fetching ? (
          <div className={classes.progress}>
            <Progress size={55} show={fetching} />
          </div>
        ) : (
          <div className={classes.noCases}>
            <Typography className={classes.notFoundText} variant="body1">
              {emptyText}
            </Typography>
          </div>
        ))}
    </div>
  );
}

TableBody.propTypes = {
  cases: PropTypes.arrayOf(PropTypes.shape({})),
  visibleItemCount: PropTypes.number,
  overallCount: PropTypes.number,
  fetching: PropTypes.bool,
  rowRenderer: PropTypes.func,
  onLoadMore: PropTypes.func,
  emptyText: PropTypes.string,
  minimumBatchSize: PropTypes.number,
};

TableBody.defaultProps = {
  visibleItemCount: 10,
  minimumBatchSize: null,
  overallCount: null,
  fetching: false,
  rowRenderer: null,
  onLoadMore: null,
  emptyText: "",
  cases: [],
};

export default TableBody;
