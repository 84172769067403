/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { WarningMessage } from "@oriola-origo/origo-ui-core";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import Box from "@oriola-origo/core/lib/Box";
// eslint-disable-next-line import/no-cycle
import { fetchProducts, clearProducts } from "../../../../redux";
import { formatProductCompact } from "../../../../utils/productUtil/productUtil";
import { getProductWarnings } from "./getProductWarnings";
import MissingProductDialog from "./missingProductDialog";
import { ProductSearchAutocomplete } from "./styledProductSearchComponents";
import { renderCustomPopper, renderOptions } from "./productSearchHelper";
import Delay from "../../../../utils/delay/delay";

interface ProductSearchProps {
  onSelect: (product: any) => void;
  product: any;
  id: string;
  caseType: string;
  productReturn: any;
  disabled: boolean;
  updateProductData: () => void;
  switchProductComponent: (value: boolean) => void;
}

const debouncer = new Delay();

function ProductSearch({
  onSelect,
  updateProductData,
  switchProductComponent,
  product = {},
  id = "",
  caseType = "",
  productReturn = {},
  disabled = false,
}: Readonly<ProductSearchProps>) {
  const { products, fetching } = useSelector((state: any) => state.products);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [warnings, setWarnings] = useState<Array<any>>([]);
  const [input, setInput] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openMissingProductDialog, setOpenMissingProductDialog] =
    useState(false);

  useEffect(() => {
    dispatch(clearProducts());
  }, [dispatch]);

  useEffect(() => {
    const newWarnings = getProductWarnings(
      t,
      product,
      caseType,
      productReturn,
      i18n.language
    );
    setWarnings(newWarnings);
  }, [t, product, caseType, productReturn, i18n.language]);

  const fetchProductsOnChange = (queryText: string) => {
    dispatch(fetchProducts(0, 20, queryText));
  };

  const handleProductSelect = (userSelectedProduct: any) => {
    const newWarnings = getProductWarnings(
      t,
      userSelectedProduct,
      caseType,
      productReturn,
      i18n.language
    );
    setWarnings(newWarnings);
    onSelect(userSelectedProduct);
  };

  const getOptionRow = (productOption: any) =>
    formatProductCompact(productOption, i18n.language, t);

  const onInputChange = (newValue: string, fetchingFromApi: boolean) => {
    setInput(newValue);
    if (newValue.length >= 3) {
      if (fetchingFromApi) {
        debouncer.run(() => fetchProductsOnChange(newValue), 200);
      }
    }
  };

  return (
    <Box id="product-search-wrapper">
      <ProductSearchAutocomplete
        id={`${id}-search`}
        fullWidth
        disabled={disabled}
        loading={fetching}
        loadingText={t("searching")}
        options={products}
        value={
          product.materialId ||
          (product.customerAddedProduct && product.productName)
            ? product
            : null
        }
        filterOptions={options => options}
        getOptionLabel={option =>
          typeof option === "string" ? option : getOptionRow(option)
        }
        label={!input ? t("productSearchPlaceholder") : ""}
        onInputChange={(_, e) => {
          const isChange = _?.type === "change";
          onInputChange(e, !!e && isChange);
        }}
        onChange={(_, e: any) => {
          setSelectedProduct(e);
          handleProductSelect(e);
        }}
        popupIcon={
          <FontIcon color="secondary" fontSize="medium" colorTone="light">
            search
          </FontIcon>
        }
        PopperComponent={props =>
          renderCustomPopper(
            props,
            setOpenMissingProductDialog,
            switchProductComponent,
            t,
            input,
            selectedProduct,
            fetching
          )
        }
        renderOption={renderOptions}
      />
      {warnings.map(warning => (
        <WarningMessage
          key={warning.id}
          id={warning.id}
          mt={1}
          text={warning.text}
          fullWidth
        />
      ))}
      <MissingProductDialog
        open={openMissingProductDialog}
        handleCloseDialog={() => {
          setOpenMissingProductDialog(false);
        }}
        handleSubmit={updateProductData}
        switchProductComponent={switchProductComponent}
      />
    </Box>
  );
}

export default ProductSearch;
