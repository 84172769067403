import React from "react";
import PropTypes from "prop-types";
import { MuiDatePicker } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import moment from "moment";
import TitleComponent from "../titleComponent/titleComponent";

function TitleDate({
  title,
  onChange,
  value,
  id,
  placeholder,
  children,
  error,
  ...containerProps
}) {
  const { i18n } = useTranslation();

  return (
    <TitleComponent title={title} {...containerProps}>
      <MuiDatePicker
        error={error}
        id={id}
        value={value ? moment(value) : null}
        onChange={onChange}
        locale={i18n.language}
        placeholder={placeholder}
        helperText={null}
      />
      {children}
    </TitleComponent>
  );
}

TitleDate.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.string,
};

TitleDate.defaultProps = {
  title: "",
  onChange: null,
  value: null,
  error: null,
  id: "",
  placeholder: null,
  children: null,
};

export default TitleDate;
