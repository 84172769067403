class AsyncPoller {
  constructor() {
    this.timer = null;
  }

  // must be cleared before new run is initialized
  run = (asyncFunc, timeOutMs) => {
    if (this.timer != null) {
      clearTimeout(this.timer);
    }

    // return promise with new time out
    return new Promise((resolve, reject) => {
      this.timer = setInterval(async () => {
        try {
          const result = await asyncFunc();
          resolve(result);
        } catch (err) {
          reject(err);
        }
      }, timeOutMs);
    });
  };

  clear = () => {
    clearTimeout(this.timer);
    this.timer = null;
  };
}
export default AsyncPoller;
