import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "@oriola-origo/origo-ui-core";
import CourierIds from "../../../../constants/couriers";
import { Posti as POSTI, Postnord as POSTNORD } from "../../../../images";
// eslint-disable-next-line import/no-cycle
import { updateCase, updateValidationError } from "../../../../redux";

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(5),
  },
  titleText: {
    ...theme.typography.subtitle2,
    color: "#757070",
  },
  radioGroup: {
    flexDirection: "row",
  },
  courier: {
    width: "154px",
    height: "65px",
    borderRadius: "4px",
    marginLeft: 0,
  },
  title: {
    ...theme.typography.body1,
    marginTop: "0.5rem",
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(6),
  },
}));

function CourierSelect({ value, disabled, error, ...containerProps }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const getLabel = currenOption => {
    if (currenOption === CourierIds.POSTNORD) {
      return <POSTNORD />;
    }
    if (currenOption === CourierIds.POSTI) {
      return <POSTI />;
    }
    return null;
  };
  const onValueChanged = e => {
    dispatch(updateValidationError({ courierCompanyError: null }));
    dispatch(updateCase({ courierCompanyId: e.target.value }));
  };

  return (
    <>
      <Box>
        <Typography className={classes.titleText} noWrap>
          {t("courier.title")}
        </Typography>
        <Typography className={classes.title}>
          {t("courier.infoOne")}
        </Typography>
        <Typography className={classes.title}>
          {t("courier.infoTwo")}
        </Typography>
      </Box>
      <Box className={classes.container} {...containerProps}>
        <RadioGroup
          className={classes.radioGroup}
          onChange={e => onValueChanged(e)}
          value={value || ""}
        >
          {Object.values(CourierIds).map(courier => (
            <FormControlLabel
              id="courier-select"
              disabled={disabled}
              key={`courier-${courier}`}
              className={classes.courier}
              style={
                value === courier
                  ? { border: "1px solid #2B3E5B" }
                  : { border: "1px solid #A29791" }
              }
              value={courier}
              control={<Radio />}
              label={getLabel(courier)}
            />
          ))}
        </RadioGroup>
        {error && (
          <Box mt={0.5}>
            <ErrorMessage text={error} />
          </Box>
        )}
      </Box>
    </>
  );
}

CourierSelect.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};

CourierSelect.defaultProps = {
  error: null,
  value: "",
  disabled: false,
};

export default CourierSelect;
