import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Typography, Box, FormControlLabel, Radio } from "@material-ui/core";
import { OriolaColors, FileList, Progress } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import UserType from "../../../constants/userType";
import {
  downloadAttachment,
  getAttachmentFileOpts,
} from "../../../utils/attachments/attachments";
import { Attachment } from "../../../images";
import { getMessageCreatorDetails } from "../../../utils/message";

const useStyles = makeStyles(theme => ({
  date: {
    ...theme.typography.body2,
    color: OriolaColors.secondaryDarkGray,
    marginBottom: theme.spacing(0.5),
  },
  name: {
    ...theme.typography.body2,
    color: OriolaColors.secondaryDarkGray,
    fontWeight: "bold",
    textDecoration: "none",
    marginBottom: theme.spacing(1),
  },
  internalTitle: {
    fontWeight: "bold",
  },
  message: {
    ...theme.typography.body1,
    color: OriolaColors.secondaryNavyBlue,
    whiteSpace: "pre-line",
  },
  checkbox: {
    padding: 0,
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
  },
  label: {
    fontSize: 12,
  },
}));

export const MessageColor = {
  CUSTOMER_BG: OriolaColors.White,
  CUSTOMER_BORDER: "#E0DCDA",
  ORIOLA_BG: "#FFE9C6",
  ORIOLA_BORDER: "#FFD48D",
  PRINCIPAL_BG: OriolaColors.secondaryNavyBlue,
  PRINCIPAL_BORDER: OriolaColors.secondaryNavyBlue,
};

export const MessageAlign = Object.freeze({
  Left: "left",
  Right: "right",
});

export const getMessageColorProps = creator => {
  const messageCreatorType = creator.type;
  switch (messageCreatorType) {
    case UserType.ORIOLA:
      return {
        bgColor: MessageColor.ORIOLA_BG,
        borderColor: MessageColor.ORIOLA_BORDER,
        textColor: OriolaColors.secondaryNavyBlue,
        readColor: OriolaColors.secondaryNavyBlue,
      };
    case UserType.PRINCIPAL:
      return {
        bgColor: MessageColor.PRINCIPAL_BG,
        borderColor: MessageColor.PRINCIPAL_BORDER,
        textColor: OriolaColors.White,
        readColor: OriolaColors.White,
      };
    default:
      return {
        bgColor: MessageColor.CUSTOMER_BG,
        borderColor: MessageColor.CUSTOMER_BORDER,
        textColor: OriolaColors.secondaryNavyBlue,
        readColor: OriolaColors.secondaryNavyBlue,
      };
  }
};

export const getMessageAlignProps = messageAlign => {
  const alignRight = messageAlign === MessageAlign.Right;
  const justifyContent = alignRight ? "flex-end" : "flex-start";
  const textAlign = alignRight ? "right" : "left";
  return {
    justifyContent,
    textAlign,
  };
};

export const isOwnMessage = (message, userData) =>
  message.creator.userId === userData.userId;

export const getRecipientStr = (recipientType, t) => {
  switch (recipientType) {
    case UserType.CUSTOMER:
      return t("message.toPharmacy");
    case UserType.ORIOLA:
      return t("message.toOriola");
    case UserType.PRINCIPAL:
      return t("message.toPrincipal");
    default:
      return "-";
  }
};

function Message({
  messageAlign,
  message,
  additionalMessage,
  showReadStatus,
  updatingReadStatus,
  updateReadStatusError,
  onModifyMessage,
  messageCreatorIdentities,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { creator, creationTime, read } = message;
  const date = moment(creationTime).format("DD.MM.YYYY, HH:mm");
  const recipientType = (message.recipient || {}).type;
  const recipient = getRecipientStr(recipientType, t);
  const dateAndRecipient = `${date} ${recipient}`;

  const handleAttachmentClick = attachment => {
    const attachmentList = (message.attachments || []).slice(0);

    const index = attachmentList.findIndex(entry => entry.id === attachment.id);
    if (index >= 0) {
      const targetAttachment = attachmentList[index];
      downloadAttachment(
        targetAttachment.id,
        message.reclamationId,
        targetAttachment.contentType
      );
    }
  };

  const colorProps = getMessageColorProps(message.creator);
  const alignProps = getMessageAlignProps(messageAlign);
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={alignProps.justifyContent}
      key={message.id}
    >
      <Box maxWidth="70%">
        <Typography align={alignProps.textAlign} className={classes.date}>
          {dateAndRecipient}
        </Typography>
        <Typography align={alignProps.textAlign} className={classes.name}>
          {getMessageCreatorDetails(creator, messageCreatorIdentities)}
        </Typography>
        <Box
          bgcolor={colorProps.bgColor}
          px={2}
          pt={2}
          pb={showReadStatus ? 1 : 2}
          borderRadius={8}
          border={`1px solid ${colorProps.borderColor}`}
        >
          {additionalMessage && (
            <Typography
              className={classes.internalTitle}
              style={{ color: colorProps.textColor }}
            >
              {additionalMessage.toUpperCase()}
            </Typography>
          )}
          <Typography
            className={classes.message}
            style={{ color: colorProps.textColor, wordWrap: "break-word" }}
          >
            {message.message}
          </Typography>
          {message.attachments && message.attachments.length > 0 && (
            <FileList
              className={classes.list}
              mt={1}
              files={message.attachments}
              getFileOpts={attachment =>
                getAttachmentFileOpts(
                  attachment,
                  () => handleAttachmentClick(attachment),
                  t
                )
              }
              onRightButtonClick={(e, attachment) =>
                handleAttachmentClick(attachment)
              }
              attachmentImg={<Attachment />}
              emptyListText={t("noAttachments")}
            />
          )}
          {showReadStatus && (
            <Box
              mt={1}
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
            >
              <FormControlLabel
                checked={read}
                style={{ color: colorProps.readColor }}
                control={
                  updatingReadStatus ? (
                    <Progress show size={20} />
                  ) : (
                    <Radio
                      size="small"
                      className={classes.checkbox}
                      style={{ color: colorProps.readColor }}
                      onClick={() => onModifyMessage(!read)}
                    />
                  )
                }
                label={
                  <Typography
                    className={classes.label}
                    style={{ color: colorProps.readColor }}
                  >
                    {read ? t("message.read") : t("message.unread")}
                  </Typography>
                }
                labelPlacement="start"
              />
              {updateReadStatusError && (
                <Typography variant="body2" color="error" align="right">
                  {t("message.sendingFailed")}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

Message.propTypes = {
  messageAlign: PropTypes.string,
  message: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
    creationTime: PropTypes.string,
    creator: PropTypes.shape({
      userId: PropTypes.string,
      type: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        fileName: PropTypes.string,
        contentType: PropTypes.string,
      })
    ),
    recipient: PropTypes.shape({
      type: PropTypes.string,
    }),
    read: PropTypes.bool,
    reclamationId: PropTypes.string,
  }).isRequired,
  additionalMessage: PropTypes.string,
  showReadStatus: PropTypes.bool,
  updatingReadStatus: PropTypes.bool,
  updateReadStatusError: PropTypes.shape({}),
  onModifyMessage: PropTypes.func,
  messageCreatorIdentities: PropTypes.shape({}),
};

Message.defaultProps = {
  messageAlign: MessageAlign.Right,
  showReadStatus: false,
  updatingReadStatus: false,
  updateReadStatusError: null,
  onModifyMessage: null,
  messageCreatorIdentities: {},
  additionalMessage: "",
};

export default Message;
