import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { WarningMessage } from "@oriola-origo/origo-ui-core";
import { makeStyles } from "@material-ui/core/styles";
import { EditWarningIcon } from "../../../../images";
// eslint-disable-next-line import/no-cycle
import {
  updateCase,
  updateValidationError,
  setRequireMoreInfoFromCustomer,
  showDialog,
  hideDialog,
  setEditedKeyField,
} from "../../../../redux";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";
import { YesNoSelection, YesNoSelectionValues } from "../../../common";
import { CaseType } from "../../../../constants/caseType";

const useStyles = makeStyles(() => ({
  italic: {
    fontStyle: "italic",
  },
}));
function ProductReturn({ disabled, editMode, ...containerProps }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { editedKeyField } = useSelector(state => state.case);
  const { productReturn, caseType } = useSelector(state => state.case.case);
  const { returnProductError } = useSelector(state => state.validationErrors);

  const getProductReturnValue = () => {
    if (productReturn == null || productReturn.returnValue == null) {
      return "";
    }
    return productReturn.returnValue === true
      ? YesNoSelectionValues.Yes
      : YesNoSelectionValues.No;
  };

  const updateProductReturnInEditMode = value => {
    dispatch(hideDialog());
    if (value && !productReturn.amount) {
      dispatch(setRequireMoreInfoFromCustomer(true));
      dispatch(
        updateCase({ productReturn: { returnValue: value }, confirm: false })
      );
    } else {
      dispatch(setRequireMoreInfoFromCustomer(false));
      dispatch(
        updateCase({
          productReturn: { returnValue: value, amount: productReturn.amount },
          confirm: true,
        })
      );
    }
  };

  const onChangeInEditMode = value => {
    if (caseType === CaseType.PRODUCT_DELIVERY && !editedKeyField) {
      dispatch(
        showDialog({
          open: true,
          description: t("editWarningDialog.description"),
          boldDescription: t("editWarningDialog.boldDescription"),
          icon: (
            <div>
              <EditWarningIcon color="primary" />
            </div>
          ),
          title: t("editWarningDialog.title"),
          cancelButtonText: t("editWarningDialog.cancel"),
          okButtonText: t("editWarningDialog.confirm"),
          onCancel: () => dispatch(hideDialog()),
          onOk: () => {
            updateProductReturnInEditMode(value);
            dispatch(setEditedKeyField(true));
          },
        })
      );
    } else {
      updateProductReturnInEditMode(value);
    }
  };

  return (
    <Box {...containerProps}>
      <YesNoSelection
        id="new-case-product-return"
        disabled={disabled}
        title={t("productReturnInfo")}
        error={!!returnProductError}
        errorText={getValidationErrorMessage(returnProductError, t)}
        value={getProductReturnValue()}
        onValueChanged={event => {
          const value = event.target.value === YesNoSelectionValues.Yes;
          dispatch(updateValidationError({ returnProductError: "" }));
          if (editMode) {
            onChangeInEditMode(value);
          } else {
            dispatch(
              updateCase({
                productReturn: { returnValue: value },
                confirm: false,
              })
            );
          }
        }}
      />
      {productReturn &&
        productReturn.returnValue === false &&
        caseType === CaseType.DAMAGED_PRODUCT && (
          <Box mb={2} className={classes.italic} width="100%">
            <WarningMessage
              text={
                <Typography component="span">
                  {t("mightAskReturn")}
                  <br />
                  <br />
                  {t("addPicture1")}
                  <br />
                  <br />
                  {t("addPicture2")}
                </Typography>
              }
              type="warning"
            />
          </Box>
        )}
    </Box>
  );
}

ProductReturn.propTypes = {
  disabled: PropTypes.bool,
  editMode: PropTypes.bool,
  hideConfirm: PropTypes.bool,
};

ProductReturn.defaultProps = {
  disabled: false,
  editMode: false,
  hideConfirm: false,
};

ProductReturn.displayName = "ProductReturn";

export default ProductReturn;
