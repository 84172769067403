export const CaseType = Object.freeze({
  DAMAGED_PRODUCT: "DAMAGED_PRODUCT",
  SUSPECTED_PRODUCT_DEFECT: "SUSPECTED_PRODUCT_DEFECT",
  PRODUCT_DELIVERY: "PRODUCT_DELIVERY",
});

export const getCaseTypeLocalized = (t, caseType, dropdown) => {
  switch (caseType) {
    case CaseType.SUSPECTED_PRODUCT_DEFECT:
      return dropdown ? t("productDefectDropdown") : t("productDefectAbbr");
    case CaseType.DAMAGED_PRODUCT:
      return dropdown ? t("damagedProductDropdown") : t("damagedProductAbbr");
    case CaseType.PRODUCT_DELIVERY:
      return dropdown ? t("productReturnDropdown") : t("productReturnAbbr");
    default:
      return "-";
  }
};
