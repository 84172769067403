export const ensureTrailingSlash = path => {
  if (path) {
    if (!path.endsWith("/")) {
      path += "/";
    }
  }
  return path;
};

export const getUrlParamsFromObject = targetObject =>
  Object.keys(targetObject || {}).reduce((acc, key) => {
    const value = targetObject[key];
    if (value) {
      const param = `${key}=${value}`;
      acc = acc.length ? `${acc}&${param}` : param;
    }
    return acc;
  }, "");

export const getObjectFromUrlParams = urlParams => {
  // sanity
  if (urlParams == null || urlParams.length === 0) {
    return {};
  }

  if (urlParams.startsWith("?")) {
    urlParams = urlParams.substring(1);
  }

  return urlParams.split("&").reduce((acc, param) => {
    const keyValue = param.split("=");
    const key = keyValue[0];
    const value = keyValue[1];
    acc[key] = value;
    return acc;
  }, {});
};
