import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button, Typography, Box } from "@material-ui/core";
import Title from "../title/title";
import AccordionItemList from "./accordionItemList";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiAccordionDetails-root": {
      display: "block",
      paddingRight: 0,
      paddingLeft: 0,
    },
    "& .MuiButtonBase-root.MuiAccordionSummary-root": {
      display: "flex",
      padding: 0,
    },
    "& .MuiAccordion-root": {
      "border-bottom": "1px solid rgba(0, 0, 0, .125)",
      boxShadow: "none",
      "&:before": {
        display: "none",
      },
    },
    "& .MuiAccordion-root:last-child": {
      border: "none",
    },
    "& .Mui-expanded": {
      margin: "auto",
    },
  },
}));

function AccordionItem({
  items,
  reclamationCase,
  config,
  title,
  selectingItemsEnabled,
  selectedItems,
  handleItemsSelected,
  handleItemsDeselected,
  caseType,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expandSettings, setExpandSettings] = useState(
    Array.from({ length: items.length }).fill(true)
  );
  const [allExpanded, setAllExpanded] = useState(true);
  const [allSelected, setAllSelected] = useState(true);
  const unhandledItems = items.filter(item => !item.handlingData);

  const handleExpandChange = index => (event, isExpanded) => {
    const expandSettingsCopy = [...expandSettings];
    expandSettingsCopy[index] = isExpanded;
    if (expandSettingsCopy.indexOf(true) < 0) {
      setAllExpanded(false);
    }
    if (expandSettingsCopy.indexOf(false) < 0) {
      setAllExpanded(true);
    }
    setExpandSettings(expandSettingsCopy);
  };

  useEffect(() => {
    if (unhandledItems.length === selectedItems.length) {
      setAllSelected(true);
    } else if (selectedItems.length === 0) {
      setAllSelected(false);
    }
  }, [unhandledItems, selectedItems]);

  const getTitle = () => {
    if (title === "products") {
      if (!config?.product && config?.missingProduct) {
        return t("missingProducts");
      }
      return t("products");
    }
    return t(title);
  };

  const expandAll = () => {
    setExpandSettings(Array.from({ length: items.length }).fill(true));
    setAllExpanded(true);
  };

  const collapseAll = () => {
    setExpandSettings(Array.from({ length: items.length }).fill(false));
    setAllExpanded(false);
  };

  const selectAll = () => {
    handleItemsSelected(items);
    setAllSelected(true);
  };

  const unSelectAll = () => {
    handleItemsDeselected(items);
    setAllSelected(false);
  };

  const renderExpandButtons = () =>
    items.length > 0 &&
    (allExpanded ? (
      <Button onClick={collapseAll}>{t("collapseAll")}</Button>
    ) : (
      <Button onClick={expandAll}>{t("expandAll")}</Button>
    ));

  const renderSelectAllButtons = () => {
    if (selectingItemsEnabled && unhandledItems.length > 0) {
      return allSelected ? (
        <Button onClick={unSelectAll}>{t("unSelectAll")}</Button>
      ) : (
        <Button onClick={selectAll}>{t("selectAll")}</Button>
      );
    }
    return null;
  };

  return (
    <div className={classes.root} id={`${title}-container`}>
      <div className="no-print">
        <Title title={getTitle()}>
          <Box display="flex" marginLeft="auto">
            {renderSelectAllButtons()}
            {renderExpandButtons()}
          </Box>
        </Title>
      </div>
      {items.length > 0 ? (
        <AccordionItemList
          items={items}
          reclamationCase={reclamationCase}
          config={config}
          title={title}
          expandSettings={expandSettings}
          handleExpandChange={handleExpandChange}
          selectingItemsEnabled={selectingItemsEnabled}
          selectedItems={selectedItems}
          handleItemsSelected={handleItemsSelected}
          handleItemsDeselected={handleItemsDeselected}
          caseType={caseType}
        />
      ) : (
        <Typography>{t(`no${title}`)}</Typography>
      )}
    </div>
  );
}

AccordionItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  config: PropTypes.shape({
    product: PropTypes.shape({}),
    missingProduct: PropTypes.shape({}),
  }),
  reclamationCase: PropTypes.shape({}),
  title: PropTypes.string,
  selectingItemsEnabled: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  handleItemsSelected: PropTypes.func,
  handleItemsDeselected: PropTypes.func,
  caseType: PropTypes.string,
};

const noop = () => {
  /* noop */
};

AccordionItem.defaultProps = {
  items: [],
  config: {},
  selectingItemsEnabled: false,
  selectedItems: [],
  handleItemsSelected: noop,
  handleItemsDeselected: noop,
  reclamationCase: {},
  title: null,
  caseType: null,
};

export default AccordionItem;
