/* eslint-disable default-param-last */
export const Dialog = Object.freeze({
  UPDATE_DIALOG_DATA: "UPDATE_DIALOG_DATA",
});

// -- ACTIONS --

export const showDialog = dialogData => {
  const data = dialogData || {};
  return {
    type: Dialog.UPDATE_DIALOG_DATA,
    payload: { ...data, open: true },
  };
};

export const hideDialog = () => (dispatch, getState) => {
  const { dialogData } = getState().dialog;
  dispatch({
    type: Dialog.UPDATE_DIALOG_DATA,
    payload: { ...dialogData, open: false },
  });
};

// -- REDUCER --

const INIT_STATE = {
  dialogData: { open: false },
};

export const dialogReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Dialog.UPDATE_DIALOG_DATA:
      return { ...state, dialogData: action.payload };
    default:
      return state;
  }
};
