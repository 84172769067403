import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Box, IconButton } from "@material-ui/core";
import { IconTextButton } from "@oriola-origo/origo-ui-core";
import { Add } from "@material-ui/icons";
// eslint-disable-next-line import/no-cycle
import { FieldTitle } from "..";
import { StatefullTextField } from "../../../common";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";
import { updateSerialNumber, removeSerialNumber } from "../../../../redux";
import { OriolaColors } from "../../../../theme";
import { Trashcan } from "../../../../images";

function UniqueSerialNumbers({
  classes,
  error,
  updateItem,
  config,
  itemData,
  disabled,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const uniqueSerialNumbers = itemData.uniqueSerialNumber || [];
    if (uniqueSerialNumbers.length === 0) {
      dispatch(updateItem({ ...itemData, uniqueSerialNumber: [""] }));
    }
  }, [dispatch, updateItem, itemData]);

  const addSerialNumber = () => {
    dispatch(
      updateItem({
        ...itemData,
        uniqueSerialNumber: [...(itemData.uniqueSerialNumber || []), ""],
      })
    );
  };

  const onRemoved = arrayIndex => {
    dispatch(removeSerialNumber(arrayIndex));
  };

  const renderUniqueSerialNumber = (serialNumber, arrayIndex) => (
    <div>
      <FieldTitle
        title={t("caseItemFields.uniqueSerialNumber")}
        optional={config.optional}
      />
      <div className={classes.controlContainer}>
        <StatefullTextField
          error={!!error && !serialNumber.length}
          helperText={getValidationErrorMessage(error, t)}
          initialValue={serialNumber}
          variant="outlined"
          margin="dense"
          width="300"
          onChange={value => {
            dispatch(updateSerialNumber(value, arrayIndex));
          }}
          id={`unique-serial-number-${arrayIndex}`}
          disabled={disabled}
        />
        {arrayIndex !== 0 && (
          <IconButton
            disabled={disabled}
            onClick={() => onRemoved()}
            size="small"
          >
            <Trashcan color="primary" />
          </IconButton>
        )}
      </div>
      {itemData.uniqueSerialNumber.length - 1 === arrayIndex && (
        <Box
          display="flex"
          justifyContent="flex-end"
          id="add-new-unique-serial-number"
          mr={2}
        >
          <IconTextButton
            text={t("addNew")}
            icon={<Add style={{ color: OriolaColors.primaryOrange }} />}
            onClick={() => addSerialNumber()}
            disabled={disabled}
          />
        </Box>
      )}
    </div>
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {itemData.uniqueSerialNumber &&
        itemData.uniqueSerialNumber.map((u, index) =>
          renderUniqueSerialNumber(u, index)
        )}
    </>
  );
}

UniqueSerialNumbers.propTypes = {
  itemData: PropTypes.shape({
    uniqueSerialNumber: PropTypes.shape([]),
  }),
  classes: PropTypes.shape({
    controlContainer: PropTypes.string,
  }),
  config: PropTypes.shape({
    optional: PropTypes.bool,
  }),
  error: PropTypes.string,
  updateItem: PropTypes.func,
  disabled: PropTypes.bool,
};

UniqueSerialNumbers.defaultProps = {
  itemData: {},
  classes: {},
  config: {},
  error: "",
  updateItem: null,
  disabled: false,
};

export default UniqueSerialNumbers;
