import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
// eslint-disable-next-line import/no-cycle
import { FieldTitle } from "..";
import { StatefullTextField } from "../../../common";
import {
  getValidationErrorMessage,
  FieldErrors,
} from "../../../../utils/validations/validations";

function AlertCode({
  itemData,
  classes,
  id,
  config,
  error,
  updateItem,
  updateItemValidationError,
  disabled,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showDescription, setShowDescription] = useState(false);

  return (
    <div>
      <FieldTitle
        title={t("caseItemFields.alertCode.title")}
        optional={config.optional}
        optionalLabelHeight={config.optional}
      />
      <div className={classes.alertCode}>
        <StatefullTextField
          error={!!error}
          helperText={getValidationErrorMessage(error, t)}
          initialValue={itemData.alertCode || ""}
          variant="outlined"
          margin="dense"
          width="300"
          onChange={alertCode => {
            dispatch(
              updateItemValidationError(itemData.uuid, { alertCode: "" })
            );
            if (alertCode.length > config.maxLength) {
              dispatch(
                updateItemValidationError(itemData.uuid, {
                  alertCode: FieldErrors.OVER_LENGTH,
                })
              );
            } else {
              dispatch(
                updateItem({
                  ...itemData,
                  alertCode,
                })
              );
            }
          }}
          id={`${id}-alert-code`}
          onFocus={() => setShowDescription(true)}
          onBlur={() => setShowDescription(false)}
          disabled={disabled}
        />
        {showDescription === true && (
          <Typography>{t("caseItemFields.alertCode.description")}</Typography>
        )}
      </div>
    </div>
  );
}

AlertCode.propTypes = {
  itemData: PropTypes.shape({
    alertCode: PropTypes.string,
    uuid: PropTypes.string,
  }),
  classes: PropTypes.shape({
    alertCode: PropTypes.string,
  }),
  id: PropTypes.string,
  config: PropTypes.shape({
    optional: PropTypes.bool,
    maxLength: PropTypes.number,
  }),
  error: PropTypes.string,
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
  disabled: PropTypes.bool,
};

AlertCode.defaultProps = {
  itemData: {},
  classes: {},
  id: "",
  config: {},
  error: "",
  updateItem: null,
  updateItemValidationError: null,
  disabled: false,
};

export default AlertCode;
