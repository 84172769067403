import { ReclamationRoles as Roles } from "@oriola-origo/origo-common-client-lib";
import { ANY_CUSTOMER } from "./permission";

// "Global" roles that apply to all customers
const GLOBAL_ROLES = [
  Roles.RECLAMATION_ORIOLA_VIEW,
  Roles.RECLAMATION_ORIOLA_EDIT,
  Roles.RECLAMATION_ORIOLA_ADMIN,
  Roles.RECLAMATION_PRINCIPAL_VIEW,
  Roles.RECLAMATION_PRINCIPAL_EDIT,
];

const mapGlobalRoles = userRoleData => {
  const rolesByOrgId = userRoleData.rolesByOrgId.slice(0);
  rolesByOrgId.push({
    orgId: ANY_CUSTOMER,
    roles: userRoleData.roles.filter(r => GLOBAL_ROLES.includes(r)),
  });

  return {
    roles: userRoleData.roles,
    rolesByOrgId,
  };
};

export default mapGlobalRoles;
