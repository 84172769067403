import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { IconTextButton, WarningMessage } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Box, FormHelperText, Collapse } from "@material-ui/core";
import { TransitionGroup } from "react-transition-group";
import { More } from "../../../../images";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";
// eslint-disable-next-line import/no-cycle
import CaseItemsType from "../../../../constants/caseItemsType";
// eslint-disable-next-line import/no-cycle
import {
  addItem,
  removeItem,
  updateItem,
  updateItemValidationError,
  updateValidationError,
} from "../../../../redux";
import { DeleteItemButton, FieldTitle } from "..";
import {
  getProductOrderFieldsConfig,
  getEntireOrderFieldsConfig,
  getShippingBoxFieldsConfig,
  getNoItemFieldsConfig,
  hasEntireOrders,
  hasProductOrders,
  hasShippingBoxes,
  hasNoItem,
  onlyOneItemAllowed,
  getEachTypesOfItems,
  noValidItem,
} from "../../../../utils/case/case";
import ItemRow from "./itemRow";

const useStyles = makeStyles(theme => ({
  rowContainer: {
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    position: "relative",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  itemHeaderContainer: {
    display: "flex",
    alignItems: "end",
    justifyContent: "space-between",
  },
  fade: {
    animationName: "$fade",
    animationDuration: ".6s",
    animationFillMode: "forwards",
  },
  "@keyframes fade": { from: { opacity: 0.2 }, to: { opacity: 1 } },
}));

function CaseItems({ disabled, caseConfig, isConvertedCase }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reclamationCase = useSelector(state => state.case.case);
  const { caseStatus, items } = reclamationCase;
  const [productOrders, setProductOrders] = useState([]);
  const [entireOrders, setEntireOrders] = useState([]);
  const [shippingBoxes, setShippingBoxes] = useState([]);
  const [noItems, setNoItems] = useState([]);
  const { itemsError, noRequiredItemError } = useSelector(
    state => state.validationErrors
  );
  useEffect(() => {
    const {
      productOrders: newProductOrders,
      entireOrders: newEntireOrders,
      shippingBoxes: newShippingBoxes,
      noItems: newNoItems,
    } = getEachTypesOfItems(items);
    setProductOrders(newProductOrders);
    setEntireOrders(newEntireOrders);
    setShippingBoxes(newShippingBoxes);
    setNoItems(newNoItems);
  }, [items]);

  if (!caseConfig) {
    return null;
  }

  if (noValidItem(items, caseConfig)) {
    if (hasProductOrders(caseConfig)) {
      dispatch(addItem(CaseItemsType.PRODUCT_ORDER));
    } else if (hasShippingBoxes(caseConfig)) {
      dispatch(addItem(CaseItemsType.SHIPPING_BOX));
    } else if (hasEntireOrders(caseConfig)) {
      dispatch(addItem(CaseItemsType.ENTIRE_ORDER));
    } else if (hasNoItem(caseConfig)) {
      dispatch(addItem(CaseItemsType.NO_ITEM));
    }
  }

  const renderAddNewItemButton = (itemType, translationKey, id, length) => {
    if (onlyOneItemAllowed(caseConfig)) {
      return null;
    }
    const title = t(`caseItems.${translationKey}.addItem`);
    return (
      <Box>
        <IconTextButton
          disabled={disabled}
          style={{ textTransform: "none" }}
          icon={<More color="primary" />}
          text={`${title} (${length})`}
          onClick={() => {
            dispatch(updateValidationError({ noRequiredItemError: "" }));
            dispatch(addItem(itemType));
          }}
          textStyle={{ fontSize: "1rem" }}
          id={`new-case-add-new-${id}`}
        />
      </Box>
    );
  };

  const renderItemHeader = (id, title, rowNumber, translationKey, item) => {
    if (onlyOneItemAllowed(caseConfig)) {
      return null;
    }

    return (
      <div className={classes.itemHeaderContainer}>
        <FieldTitle title={`${title} ${rowNumber}`} />
        <DeleteItemButton
          id={id}
          title={`${title} ${rowNumber}`}
          description={t(`caseItems.${translationKey}.removeItem.description`)}
          caseStatus={caseStatus}
          handleDelete={() => {
            dispatch(removeItem(item.uuid));
          }}
          disabled={disabled}
        />
      </div>
    );
  };

  const renderWarning = (id, warningText) => {
    if (warningText) {
      return (
        <WarningMessage id={`${id}-warning`} fullWidth text={warningText} />
      );
    }
    return null;
  };

  const renderItemRow = ({
    item,
    rowNumber,
    id,
    translationKey,
    fieldsConfig,
    warningText,
  }) => {
    const title = t(`caseItems.${translationKey}.title`);
    return (
      <div
        data-row-name={`${translationKey}${rowNumber}`}
        key={item.uuid}
        className={classes.fade}
      >
        {renderItemHeader(id, title, rowNumber, translationKey, item)}
        <div className={classes.rowContainer}>
          {renderWarning(id, warningText)}
          <ItemRow
            disabled={disabled}
            key={`order-row-${item.uuid}`}
            itemData={item}
            id={id}
            uuid={item.uuid}
            rowNumber={rowNumber}
            fieldsConfig={fieldsConfig}
            caseConfig={caseConfig}
            validationError={itemsError[item.uuid]}
            updateItem={updateItem}
            updateItemValidationError={updateItemValidationError}
          />
        </div>
        <Divider className={classes.divider} />
      </div>
    );
  };

  const renderItems = ({
    itemsData,
    itemType,
    id,
    translationKey,
    fieldsConfig,
    warningText,
  }) => {
    const itemLength = itemsData.length;

    return (
      <div>
        {renderAddNewItemButton(itemType, translationKey, id, itemLength)}
        <Divider className={classes.divider} />
        <div>
          <TransitionGroup id={`${id}-container`}>
            {(itemsData || []).map((item, i) => {
              const itemId = `new-case-${id}-${i}`;
              const rowNumber = itemsData.length - i;
              return (
                <Collapse key={rowNumber}>
                  {renderItemRow({
                    item,
                    rowNumber,
                    itemsData,
                    id: itemId,
                    translationKey,
                    fieldsConfig,
                    itemLength,
                    warningText,
                  })}
                </Collapse>
              );
            })}
          </TransitionGroup>
        </div>
      </div>
    );
  };

  const { productReturn } = reclamationCase;

  return (
    <div>
      {hasProductOrders(caseConfig) &&
        renderItems({
          itemsData: productOrders,
          itemType: CaseItemsType.PRODUCT_ORDER,
          id: "product-order",
          translationKey: "productOrders",
          fieldsConfig: getProductOrderFieldsConfig(caseConfig),
        })}
      {hasEntireOrders(caseConfig) &&
        renderItems({
          itemsData: entireOrders,
          itemType: CaseItemsType.ENTIRE_ORDER,
          id: "entire-order",
          translationKey: "entireOrders",
          fieldsConfig: getEntireOrderFieldsConfig(caseConfig),
        })}
      {hasShippingBoxes(caseConfig) &&
        !isConvertedCase &&
        renderItems({
          itemsData: shippingBoxes,
          itemType: CaseItemsType.SHIPPING_BOX,
          id: "shipping-box",
          translationKey: "shippingBoxes",
          fieldsConfig: getShippingBoxFieldsConfig(caseConfig),
          warningText:
            productReturn && productReturn.returnValue === true
              ? t("anestheticsWarning")
              : undefined,
        })}
      {hasNoItem(caseConfig) &&
        renderItems({
          itemsData: noItems,
          itemType: CaseItemsType.NO_ITEM,
          id: "no-item",
          translationKey: "noItems",
          fieldsConfig: getNoItemFieldsConfig(caseConfig),
        })}
      {noRequiredItemError && (
        <FormHelperText
          error={!!noRequiredItemError}
          style={{ fontSize: "1rem" }}
        >
          {getValidationErrorMessage(noRequiredItemError, t)}
        </FormHelperText>
      )}
    </div>
  );
}

CaseItems.propTypes = {
  reasonIdentifierConfig: PropTypes.shape({}),
  caseConfig: PropTypes.shape({}),
  disabled: PropTypes.bool,
  isConvertedCase: PropTypes.bool,
};

CaseItems.defaultProps = {
  disabled: false,
  isConvertedCase: false,
  caseConfig: {},
  reasonIdentifierConfig: {},
};

export default CaseItems;
