/* eslint-disable default-param-last */
export const ContactInfo = Object.freeze({
  UPDATE_CONTACT_INFO: "CONTACT_INFO_UPDATE",
});

// -- ACTIONS
export const updateContactInfo = stateValue => dispatch =>
  dispatch({
    type: ContactInfo.UPDATE_CONTACT_INFO,
    payload: stateValue,
  });
// -- REDUCER --

const DEFAULT_STATE = {
  contactPerson: "",
  customer: "",
  phoneNumber: "",
  deliveryAddress: "",
};

export const contactInfoReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ContactInfo.UPDATE_CONTACT_INFO:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
