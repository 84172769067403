import { FieldErrors } from "../../../../utils/validations/validations";

const getProductReturnToSellableError = localHandlingData => {
  if (typeof localHandlingData.productReturnToSellable === "undefined") {
    return {
      productReturnToSellableError: FieldErrors.PRODUCT_SELLABLE_MANDATORY,
    };
  }
  return null;
};

export default getProductReturnToSellableError;
