import { RestService } from "../../components/common";
import { ensureTrailingSlash } from "../../utils/url/url";
import Delay from "../../utils/delay/delay";

const delay = new Delay();

export const Organization = Object.freeze({
  SEARCH_STARTED: "ORGANIZATION_SEARCH_STARTED",
  SEARCH_FINISHED: "ORGANIZATION_SEARCH_FINISHED",
  SEARCH_ERROR: "ORGANIZATION_SEARCH_ERROR",
});

const apiUrl = ensureTrailingSlash(process.env.REACT_APP_RECLAMATION_SERVICE);

// -- ACTIONS --

const doSearch = async (dispatch, query) => {
  try {
    dispatch({ type: Organization.SEARCH_STARTED });

    const path = `${apiUrl}organization/v1/search?query=${query}`;
    const organizations = await RestService.get(path);
    dispatch({ type: Organization.SEARCH_FINISHED, payload: organizations });

    return Promise.resolve(organizations);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("searchOrganizations", error);
    dispatch({ type: Organization.SEARCH_ERROR, payload: error });
    return Promise.reject(error);
  }
};

export const searchOrganizations = query => async dispatch =>
  delay.run(() => doSearch(dispatch, query), 1000);

export const clearOrganizations = () => dispatch => {
  // cancel any requests
  delay.clear();

  // clear results
  dispatch({
    type: Organization.SEARCH_FINISHED,
    payload: [],
  });
};

// -- REDUCER --

const INIT_STATE = {
  organizations: [],
  searchingOrganizations: false,
  organizationSearchError: null,
};

export const organizationReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case Organization.SEARCH_STARTED:
      return {
        ...state,
        searchingOrganizations: true,
        organizationSearchError: null,
      };
    case Organization.SEARCH_FINISHED:
      return {
        ...state,
        searchingOrganizations: false,
        organizations: action.payload,
      };
    case Organization.SEARCH_ERROR:
      return {
        ...state,
        searchingOrganizations: false,
        organizationSearchError: action.payload,
      };
    default:
      return state;
  }
};
