import React from "react";
import { Box, MenuItem, Typography } from "@material-ui/core";

const renderMatchedItemsList = ({
  matchedProfitCenters,
  matchedSuppliers,
  classes,
  onProfitCenterSelect,
  onSupplierSelect,
  t,
}) => (
  <Box mt={2}>
    <Typography variant="subtitle1" className={classes.title}>
      {t("principals")}
    </Typography>
    <Box>
      {matchedProfitCenters.map(profitCenter => (
        <MenuItem
          key={profitCenter.number}
          onClick={() => {
            onProfitCenterSelect(profitCenter);
          }}
        >
          <Typography className={classes.listItem} variant="body1">
            {`${profitCenter.number} - ${profitCenter.name}`}
          </Typography>
        </MenuItem>
      ))}
    </Box>
    <Typography variant="subtitle1" className={classes.title}>
      {t("suppliers")}
    </Typography>
    <Box>
      {matchedSuppliers.map(supplier => (
        <MenuItem
          key={supplier.number}
          onClick={() => {
            onSupplierSelect(supplier);
          }}
        >
          <Typography className={classes.listItem} variant="body1">
            {`${supplier.number} - ${supplier.name}`}
          </Typography>
        </MenuItem>
      ))}
    </Box>
  </Box>
);

export default renderMatchedItemsList;
