import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as TrashcanIcon } from "../../../images/trashcan2/trashcan2.svg";
import CaseIcon from "./caseIcon";

function RequestToDisposeIcon({ ...props }) {
  const { t } = useTranslation();
  return (
    <CaseIcon tooltip={t("disposalPermit")} {...props}>
      <TrashcanIcon width="10px" height="100%" />
    </CaseIcon>
  );
}

export default RequestToDisposeIcon;
