/* eslint-disable */
import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import Can from "./can";

function ProtectedRoute({
  path,
  exact,
  component,
  failComponent,
  user,
  requiredPermission,
  customerContext,
}) {
  return (
    <Can
      user={user}
      perform={requiredPermission}
      customerContext={customerContext}
      ok={() => <Route path={path} exact={exact} component={component} />}
      nok={() => <Route path={path} exact={exact} component={failComponent} />}
    />
  );
}

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  component: PropTypes.elementType.isRequired,
  failComponent: PropTypes.elementType.isRequired,
  user: PropTypes.shape({}),
  requiredPermission: PropTypes.string,
  customerContext: PropTypes.string,
};

ProtectedRoute.defaultProps = {
  exact: false,
  requiredPermission: null,
  user: null,
  customerContext: null,
};

export default ProtectedRoute;
