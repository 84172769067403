import { showDialog, hideDialog } from "../../../redux";
import closeAndPrintCaseDetails from "./closeAndPrintCaseDetails";
import { Print } from "../../../images";

const openCaseDetailsPrintDialog = (t, dispatch, reclamationCase) => {
  dispatch(
    showDialog({
      open: true,
      title: t("printDialog.title"),
      subTitle: `${t("printDialog.subTitle")} ${reclamationCase.caseNumber}`,
      description: t("printDialog.description"),
      cancelButtonText: t("printDialog.cancel"),
      okButtonText: t("printDialog.confirm"),
      onCancel: () => dispatch(hideDialog()),
      onOk: () => {
        closeAndPrintCaseDetails(t, dispatch, reclamationCase.reclamationId);
      },
      icon: Print,
      disableBackdropClick: true,
    })
  );
};

export default openCaseDetailsPrintDialog;
