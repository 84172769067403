import moment from "moment";
import i18n from "i18next";

const dateFormatString = "DD.MM.YYYY";
const dateTimeFormatString = "DD.MM.YYYY, HH.mm";

// TODO: Passing format as parameter should be useless as all API:s should use dateformat ISO8601.
// However currently product api uses different dateformat so to suppress warnings we pass in the format.

const isValidDate = (date, format) =>
  date !== undefined && moment(date, format).isValid();

// Parameters are the date to be formatted and the format that the date is in currently (defaults to formats that moment accepts)
export const getFormattedDate = (date, format) =>
  isValidDate(date, format)
    ? moment(date, format).format(dateFormatString)
    : i18n.t("noDate");

export const getFormattedDateTime = (date, format) =>
  isValidDate(date, format)
    ? moment(date, format).format(dateTimeFormatString)
    : i18n.t("noDate");
