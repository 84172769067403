import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line import/no-cycle
import OrderNumber from "./orderNumber";
import DateReceived from "./dateReceived";
import InvoiceNumber from "./invoiceNumber";
import StringField from "./stringField";
import TextAreaField from "./textAreaField";
import ItemRowReasons from "./itemRowReasons";
import ItemRowProducts from "./itemRowProducts";
import ExpectedReceiveDate from "./expectedReceiveDate";
import ShippingBoxNumber from "./shippingBoxNumber";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
  },
  rowContainer: {
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  flexRowContainer: {
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexWrap: "wrap",
    "& > div": {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  },
  flexRowField: {
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexWrap: "wrap",
    "& > div": {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
      width: 250,
    },
  },
  stringField: {
    width: 425,
    "& input[disabled]": {
      background: theme.palette.grey[100],
    },
  },
  productCodeField: {
    "& input[disabled]": {
      background: theme.palette.grey[100],
    },
  },
  controlContainer: {
    display: "flex",
    alignItems: "baseline",
    flexGrow: 1,
  },
  instruction: {
    fontSize: "12px",
  },
  findProduct: {
    color: "#757070",
    marginRight: theme.spacing(0.5),
  },
  findProductButton: {
    color: "#2B3E5B",
    fontWeight: 700,
    cursor: "pointer",
    zIndex: "1",
    marginRight: "20px",
  },
  productInput: {
    zIndex: "1",
  },
  greyBackground: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "280px",
    },
    "&::before": {
      content: '""',
      backgroundColor: "#F8F8F8",
      position: "absolute",
      height: "calc(100% + 20px)",
      top: "-10px",
      width: "calc(100% + 20px)",
      left: " -16px",
      zIndex: "1",
    },
  },
  alertCode: {
    "&:focus-within": {
      color: theme.palette.primary.main,
    },
  },
}));

function ItemRow({
  itemData,
  id,
  validationError,
  fieldsConfig,
  caseConfig,
  uuid,
  updateItem,
  updateItemValidationError,
  disabled,
}) {
  const classes = useStyles();
  const getValidationError = fieldName =>
    validationError ? validationError[fieldName] : undefined;
  return (
    <div data-error-name={uuid} id={id}>
      {fieldsConfig.orderNumber && (
        <OrderNumber
          itemData={itemData}
          classes={classes}
          id={id}
          config={fieldsConfig.orderNumber}
          error={getValidationError("orderNumber")}
          updateItem={updateItem}
          updateItemValidationError={updateItemValidationError}
          disabled={disabled}
        />
      )}
      {fieldsConfig.serialShippingContainerCode && (
        <ShippingBoxNumber
          itemData={itemData}
          classes={classes}
          id={id}
          fieldName="serialShippingContainerCode"
          config={fieldsConfig.serialShippingContainerCode}
          error={getValidationError("serialShippingContainerCode")}
          updateItem={updateItem}
          updateItemValidationError={updateItemValidationError}
          validLength={fieldsConfig.serialShippingContainerCode.validLength}
          disabled={disabled}
        />
      )}
      {fieldsConfig.dateReceived && (
        <DateReceived
          itemData={itemData}
          classes={classes}
          id={id}
          config={fieldsConfig.dateReceived}
          error={getValidationError("dateReceived")}
          updateItem={updateItem}
          updateItemValidationError={updateItemValidationError}
          disabled={disabled}
        />
      )}
      {fieldsConfig.expectedReceiveDate && (
        <ExpectedReceiveDate
          id={id}
          itemData={itemData}
          config={fieldsConfig.expectedReceiveDate}
          error={getValidationError("expectedReceiveDate")}
          updateItem={updateItem}
          updateItemValidationError={updateItemValidationError}
          disabled={disabled}
        />
      )}
      {fieldsConfig.invoiceNumber && (
        <InvoiceNumber
          itemData={itemData}
          classes={classes}
          id={id}
          config={fieldsConfig.invoiceNumber}
          error={getValidationError("invoiceNumber")}
          updateItem={updateItem}
          updateItemValidationError={updateItemValidationError}
          disabled={disabled}
        />
      )}
      <ItemRowProducts
        itemData={itemData}
        id={id}
        validationError={validationError}
        fieldsConfig={fieldsConfig}
        caseConfig={caseConfig}
        updateItem={updateItem}
        updateItemValidationError={updateItemValidationError}
        classes={classes}
        disabled={disabled}
      />
      {fieldsConfig.salesRepresentative && (
        <StringField
          itemData={itemData}
          classes={classes}
          id={id}
          fieldName="salesRepresentative"
          config={fieldsConfig.salesRepresentative}
          error={getValidationError("salesRepresentative")}
          updateItem={updateItem}
          updateItemValidationError={updateItemValidationError}
          disabled={disabled}
        />
      )}
      <ItemRowReasons
        itemData={itemData}
        id={id}
        validationError={validationError}
        fieldsConfig={fieldsConfig}
        updateItem={updateItem}
        updateItemValidationError={updateItemValidationError}
        classes={classes}
        disabled={disabled}
      />
      {fieldsConfig.moreInfo && (
        <TextAreaField
          itemData={itemData}
          classes={classes}
          id={id}
          fieldName="moreInfo"
          config={fieldsConfig.moreInfo}
          error={getValidationError("moreInfo")}
          updateItem={updateItem}
          updateItemValidationError={updateItemValidationError}
          disabled={disabled}
        />
      )}
    </div>
  );
}

ItemRow.propTypes = {
  itemData: PropTypes.shape({}),
  id: PropTypes.string,
  reasonIdentifierConfig: PropTypes.shape({}),
  validationError: PropTypes.shape({}),
  fieldsConfig: PropTypes.shape({
    orderNumber: PropTypes.shape({}),
    serialShippingContainerCode: PropTypes.shape({
      validLength: PropTypes.number,
    }),
    dateReceived: PropTypes.shape({}),
    expectedReceiveDate: PropTypes.shape({}),
    invoiceNumber: PropTypes.shape({}),
    salesRepresentative: PropTypes.shape({}),
    moreInfo: PropTypes.shape({}),
    alertCode: PropTypes.shape({}),
    uniqueSerialNumber: PropTypes.shape({}),
    pricePerUnitCharged: PropTypes.shape({}),
    pricePerUnit: PropTypes.shape({}),
    product: PropTypes.shape({}),
  }),
  caseConfig: PropTypes.shape({}),
  uuid: PropTypes.string,
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
  validLength: PropTypes.number,
  disabled: PropTypes.bool,
};

ItemRow.defaultProps = {
  itemData: {},
  id: "",
  reasonIdentifierConfig: {},
  validationError: {},
  fieldsConfig: {},
  caseConfig: {},
  uuid: "",
  updateItem: null,
  updateItemValidationError: null,
  validLength: 0,
  disabled: false,
};

export default ItemRow;
