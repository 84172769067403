import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Button } from "@oriola-origo/origo-ui-core";
import Typography from "@material-ui/core/Typography";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(1),
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "8px",
    borderColor: "#d5d8de",
    minWidth: "300px",
    maxWidth: "400px",
    minHeight: "600px",
    boxShadow: "none",
    boxSizing: "border-box",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.8),
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    margin: "auto",

    "&:hover": {
      boxShadow:
        "12px 45px 20px -30px rgb(2120 112 11 / 10%), 0 30px 25px -44px rgb(224 177 118 / 60%)",
      transition:
        "all .15s cubic-bezier(.5,.1,.8,.35),box-shadow .15s cubic-bezier(.5,.1,.8,.35)",
    },
    "& #magnifier, & #rotateArrow": {
      animationIterationCount: "infinite",
      animationTimingFunction: "linear",
      animationPlayState: "paused",
    },
    "& #rotateArrow": {
      animationName: "$spin",
      animationDuration: "1s",
      transformOrigin: "78px 66px",
    },
    "& #magnifier": {
      animationName: "$move",
      animationDuration: ".6s",
      position: "absolute",
    },
    "&:hover #rotateArrow, &:hover #magnifier": {
      animationPlayState: "running",
    },
  },
  list: {
    paddingLeft: theme.spacing(2),
    minHeight: "180px",
    maxHeight: "300px",
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  header: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: "flex",
    alignItems: "flex-end",
  },
  description: {
    marginBottom: theme.spacing(2.5),
    "&:last-child": {
      marginBottom: 0,
    },
    fontSize: ".9rem",
    lineHeight: "20px",
    fontFamily: "verdana",
  },
  "@keyframes spin": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  },
  "@keyframes move": {
    "0%, 100%": { transform: "translateY(-2px)" },
    "50%": { transform: "translateY(5px)" },
  },
  btn: {
    width: "184px",
    height: "44px",
  },
  cardContent: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingBottom: 0,
    },
  },
  subDescription: {
    ...theme.typography.body2,
    marginTop: "0.5rem",
    color: theme.palette.text.disabled,
    textAlign: "center",
    fontStyle: "italic",
    width: "100%",
    height: "auto",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
}));

function Category({
  icon,
  header,
  descriptionsKey,
  subDescription,
  actionComponent,
  onSelect,
  buttonId,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const descriptions = t(descriptionsKey, { returnObjects: true }) || [];
  return (
    <Card className={classes.card} raised={false}>
      <CardContent className={classes.cardContent}>
        <div className={classes.icon}>{icon}</div>
        <Typography
          className={classes.header}
          variant="subtitle2"
          color="textPrimary"
        >
          {header}
        </Typography>
        <ul className={classes.list}>
          {descriptions.map((description, index) => (
            <li className={classes.description} key={description}>
              <Trans i18nKey={`${descriptionsKey}.${index}`} />
            </li>
          ))}
        </ul>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button className={classes.btn} id={buttonId} onClick={onSelect}>
          {actionComponent}
        </Button>
      </CardActions>
      {subDescription && (
        <Typography className={classes.subDescription}>
          <Trans i18nKey={subDescription} />
        </Typography>
      )}
    </Card>
  );
}

Category.propTypes = {
  icon: PropTypes.element.isRequired,
  header: PropTypes.string.isRequired,
  actionComponent: PropTypes.node.isRequired,
  onSelect: PropTypes.func,
  buttonId: PropTypes.string,
  descriptionsKey: PropTypes.string,
  subDescription: PropTypes.string,
};

Category.defaultProps = {
  onSelect: null,
  buttonId: "",
  descriptionsKey: "",
  subDescription: "",
};

export default Category;
