import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const CssTextField = withStyles({
  root: {
    "& .MuiFormHelperText-root.Mui-error": {
      marginLeft: 0,
    },
    "& .MuiFormHelperText-root": {
      fontSize: "1rem",
    },
  },
})(TextField);

export default CssTextField;
