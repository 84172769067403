import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@material-ui/core";
// eslint-disable-next-line import/no-cycle
import { updateCase, updateValidationError } from "../../../../redux";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";
import { YesNoSelection, YesNoSelectionValues } from "../../../common";

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(5),
  },
}));

function StatementRequiredRadio({ disabled }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isStatementRequired } = useSelector(state => state.case.case);
  const { isStatementRequiredError } = useSelector(
    state => state.validationErrors
  );
  const getisStatementRequiredValue = () => {
    if (isStatementRequired == null) {
      return "";
    }
    return isStatementRequired === true
      ? YesNoSelectionValues.Yes
      : YesNoSelectionValues.No;
  };

  return (
    <Box
      className={classes.formControl}
      mt={2}
      data-error-name="isStatementRequiredError"
    >
      <YesNoSelection
        id="new-case-statement-required"
        disabled={disabled}
        title={t("isStatementRequired")}
        error={!!isStatementRequiredError}
        errorText={getValidationErrorMessage(isStatementRequiredError, t)}
        value={getisStatementRequiredValue()}
        onValueChanged={event => {
          const value = event.target.value === YesNoSelectionValues.Yes;
          dispatch(updateValidationError({ isStatementRequiredError: "" }));
          dispatch(updateCase({ isStatementRequired: value }));
        }}
      />
    </Box>
  );
}

StatementRequiredRadio.propTypes = {
  disabled: PropTypes.bool,
};

StatementRequiredRadio.defaultProps = {
  disabled: false,
};

StatementRequiredRadio.displayName = "StatementRequiredRadio";

export default StatementRequiredRadio;
