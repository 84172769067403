import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Progress, Button } from "@oriola-origo/origo-ui-core";
import { useSelector } from "react-redux";
import {
  PrinterSmallIcon,
  ChangeOueueIcon,
  EditCaseIcon,
  CopyCaseIcon,
} from "../../../images";
import { CaseType } from "../../../constants/caseType";
import { CaseStatus } from "../../../constants/caseStatus";
import CaseItemsType from "../../../constants/caseItemsType";
import { isOriolaUser, isCustomerEditRole } from "../../auth/permission";
import printButtonsEnabled from "./printButtonsEnabled";

function CaseViewButtons({
  reclamationCase,
  fetchingPdfUrl,
  user,
  onQueueButtonClick,
  onEditCaseButtonClick,
  onCopyCaseButtonClick,
  onPrintButtonClick,
  onChangeSubStatusClick,
  onPrintCaseDetailsClick,
}) {
  const { t } = useTranslation();
  const { items } = reclamationCase;
  const { CaseCreationConfig } = useSelector(state => state.fieldsConfig);
  const { SPD_CASE_ENABLED } = CaseCreationConfig || {};

  const { editingCopiedCase } = useSelector(state => state.case);
  if (editingCopiedCase) {
    return null;
  }

  const currentUserIsOriolaUser = isOriolaUser(user);
  const { returnDocumentButtonEnabled, printCaseDetailsButtonEnabled } =
    printButtonsEnabled(reclamationCase, user);
  const isCustomerEditingSolvedCase =
    isCustomerEditRole(user) &&
    reclamationCase.caseStatus === CaseStatus.SOLVED;

  const renderSetSubStatusButton = () => {
    if (!currentUserIsOriolaUser) {
      return null;
    }
    return (
      <Button
        id="set-substatus-button"
        variant="transparent"
        onClick={onChangeSubStatusClick}
      >
        <Box display="flex" alignItems="center">
          <ChangeOueueIcon />
          <Box ml={1}>
            <Typography variant="body1">
              {t("changeSubStatus.changeStatus")}
            </Typography>
          </Box>
        </Box>
      </Button>
    );
  };

  const renderQueueChangeButton = () => {
    if (!currentUserIsOriolaUser) {
      return null;
    }

    if (
      reclamationCase.caseType === CaseType.SUSPECTED_PRODUCT_DEFECT &&
      reclamationCase.caseStatus !== CaseStatus.OPEN_SUBMITTED
    ) {
      return null;
    }

    return (
      <Button
        id="queue-button"
        variant="transparent"
        onClick={onQueueButtonClick}
      >
        <Box display="flex" alignItems="center">
          <ChangeOueueIcon />
          <Box ml={1}>
            <Typography variant="body1">{t("changeQueue.title")}</Typography>
          </Box>
        </Box>
      </Button>
    );
  };

  const renderEditButtons = () => {
    if (!currentUserIsOriolaUser && !isCustomerEditRole(user)) {
      return null;
    }
    // Customers should not edit solved cases
    if (isCustomerEditingSolvedCase) {
      return null;
    }
    // Existing logic - Cannot edit solved SPD cases
    if (
      reclamationCase.caseType === CaseType.SUSPECTED_PRODUCT_DEFECT &&
      reclamationCase.caseStatus !== CaseStatus.OPEN_SUBMITTED
    ) {
      return null;
    }

    return (
      <Button
        id="edit-case"
        variant="transparent"
        onClick={onEditCaseButtonClick}
      >
        <Box display="flex" alignItems="center">
          <EditCaseIcon />
          <Box ml={1}>
            <Typography variant="body1">{t("editCase.editCase")}</Typography>
          </Box>
        </Box>
      </Button>
    );
  };

  const renderReturnDocumentButton = () => {
    if (returnDocumentButtonEnabled === false) {
      return null;
    }

    return (
      <Button
        id="print-button"
        variant="transparent"
        onClick={onPrintButtonClick}
        disabled={fetchingPdfUrl === true}
      >
        <Box display="flex" alignItems="center">
          {fetchingPdfUrl !== true && <PrinterSmallIcon />}
          <Progress size={24} show={fetchingPdfUrl || false} />
          <Box ml={1}>
            <Typography variant="body1">{t("printDocument")}</Typography>
          </Box>
        </Box>
      </Button>
    );
  };

  const renderPrintCaseDetailsButton = () => {
    if (printCaseDetailsButtonEnabled === false) {
      return null;
    }
    return (
      <Button
        id="print-case-details"
        data-testid="print-case-details"
        variant="transparent"
        onClick={() => {
          onPrintCaseDetailsClick();
        }}
      >
        <Box display="flex" alignItems="center">
          <PrinterSmallIcon />
          <Box ml={1}>
            <Typography variant="body1">{t("printCaseDetails")}</Typography>
          </Box>
        </Box>
      </Button>
    );
  };

  const renderCopyCaseButton = () => {
    if (SPD_CASE_ENABLED !== true) {
      return null;
    }
    if (!currentUserIsOriolaUser) {
      return null;
    }
    const productCount = items.filter(
      x => x.type === CaseItemsType.PRODUCT_ORDER
    ).length;
    if (productCount === 0) {
      return null;
    }
    return (
      <Button
        id="copy-case-button"
        variant="transparent"
        onClick={onCopyCaseButtonClick}
      >
        <Box display="flex" alignItems="center">
          <CopyCaseIcon />
          <Box ml={1}>
            <Typography variant="body1">{t("copyCase.copyCase")}</Typography>
          </Box>
        </Box>
      </Button>
    );
  };

  return (
    <div className="no-print">
      <Box display="flex" justifyContent="flex-end" mb={3}>
        {renderSetSubStatusButton()}
        {renderCopyCaseButton()}
        {renderQueueChangeButton()}
        {renderEditButtons()}
        {renderReturnDocumentButton()}
        {renderPrintCaseDetailsButton()}
      </Box>
    </div>
  );
}

CaseViewButtons.propTypes = {
  reclamationCase: PropTypes.shape({
    caseType: PropTypes.string,
    caseStatus: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  fetchingPdfUrl: PropTypes.bool,
  user: PropTypes.shape({}),
  history: PropTypes.shape({}),
  onQueueButtonClick: PropTypes.func,
  onEditCaseButtonClick: PropTypes.func,
  onPrintButtonClick: PropTypes.func,
  onCopyCaseButtonClick: PropTypes.func,
  onChangeSubStatusClick: PropTypes.func,
  onPrintCaseDetailsClick: PropTypes.func,
};

CaseViewButtons.defaultProps = {
  reclamationCase: {},
  fetchingPdfUrl: false,
  user: {},
  history: {},
  onQueueButtonClick: null,
  onEditCaseButtonClick: null,
  onPrintButtonClick: null,
  onCopyCaseButtonClick: null,
  onChangeSubStatusClick: null,
  onPrintCaseDetailsClick: null,
};

export default CaseViewButtons;
