import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { TooltipTypography } from "@oriola-origo/origo-ui-core";
import { getProductDisplayName } from "../../utils/productUtil/productUtil";
import { getEachTypesOfItems } from "../../utils/case/case";
import CaseIcons from "./caseIcons/caseIcons";

const useStyles = makeStyles(theme => ({
  productName: {
    paddingRight: theme.spacing(2),
  },
  columnValueText: {
    color: theme.palette.text.primary,
    width: "100%",
    ...theme.typography.button,
    textTransform: "none",
  },
}));

function CaseItems({ reclamation, isOriolaUser, isPrincipalUser }) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const items = reclamation.items || [];
  const { productOrders, entireOrders, shippingBoxes } =
    getEachTypesOfItems(items);
  const itemCount = items.length;

  const getShowingInfo = () => {
    let thisShowingInfo = "";
    if (itemCount === 0) {
      thisShowingInfo = "-";
    } else if (itemCount > 1) {
      thisShowingInfo = t("casesTable.multipleItems");
    } else if (productOrders && productOrders.length === 1) {
      const product =
        productOrders[0].product || productOrders[0].missingProduct || {};
      thisShowingInfo = getProductDisplayName(product, i18n.language) || "-";
    } else if (shippingBoxes && shippingBoxes.length === 1) {
      thisShowingInfo = t("casesTable.shippingBox");
    } else if (entireOrders && entireOrders.length === 1) {
      thisShowingInfo = t("casesTable.entireOrder");
    } else {
      thisShowingInfo = "-";
    }
    return thisShowingInfo;
  };
  const showingInfo = getShowingInfo();

  return (
    <div className={classes.productName}>
      <TooltipTypography
        className={classes.columnValueText}
        color="textPrimary"
        noWrap
        tooltip={showingInfo}
      >
        {showingInfo}
      </TooltipTypography>
      <CaseIcons
        reclamation={reclamation}
        display="flex"
        isOriolaUser={isOriolaUser}
        isPrincipalUser={isPrincipalUser}
      />
    </div>
  );
}

CaseItems.propTypes = {
  reclamation: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isOriolaUser: PropTypes.bool,
  isPrincipalUser: PropTypes.bool,
};

CaseItems.defaultProps = {
  reclamation: {},
  isOriolaUser: false,
  isPrincipalUser: false,
};

export default CaseItems;
