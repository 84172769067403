import React from "react";
import PropTypes from "prop-types";
import { Box, TextField } from "@material-ui/core";

function CompactTitleInput({
  title,
  error,
  helperText,
  onChange,
  value,
  disabled,
  id,
  textFieldProps,
  ...containerProps
}) {
  return (
    <Box {...containerProps}>
      <TextField
        style={{ width: "100%", margin: 0 }}
        id={id}
        label={title}
        error={error}
        helperText={helperText}
        value={value}
        variant="outlined"
        margin="dense"
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
        {...textFieldProps}
      />
    </Box>
  );
}

CompactTitleInput.propTypes = {
  maxWidth: PropTypes.string,
  title: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.node,
  textFieldProps: PropTypes.shape({}),
};

CompactTitleInput.defaultProps = {
  maxWidth: "100%",
  title: null,
  error: false,
  helperText: null,
  onChange: null,
  value: null,
  disabled: false,
  id: null,
  children: null,
  textFieldProps: null,
};

export default CompactTitleInput;
