import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { IconTextLabel } from "@oriola-origo/origo-ui-core";
import Typography from "@material-ui/core/Typography";
import { ArrowRight } from "../../../images";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  headingTitle: {
    ...theme.typography.body1,
    fontSize: "1.25rem",
    marginTop: theme.spacing(1),
  },
}));

function CaseHeading({ leftText, rightText, children }, ref) {
  const classes = useStyles();

  return (
    <div ref={ref}>
      <div className="no-print">
        <div className={classes.container}>
          <Typography className={classes.headingTitle}>{leftText}</Typography>
          <IconTextLabel variant="h4" icon={<ArrowRight />} text={rightText} />
          {children}
        </div>
      </div>
    </div>
  );
}

CaseHeading.propTypes = {
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  children: PropTypes.node,
};

CaseHeading.defaultProps = {
  leftText: "",
  rightText: "",
  children: null,
};

export default React.forwardRef(CaseHeading);
