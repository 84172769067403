import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(0.5),
    "&:nth-child(odd)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  item: {
    flexBasis: "40%",
    flexShrink: 0,
    flexGrow: 0,
    marginRight: theme.spacing(2),
  },
  value: {
    flexGrow: 1,
  },
  m1: {
    margin: theme.spacing(1),
  },
}));

function Row({
  rowLeft,
  rowRight,
  style,
  rowButtonComponent,
  rowRightComponent,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderRowRight = () => {
    if (rowRightComponent) {
      return rowRightComponent;
    }
    return (
      <>
        {rowRight[0]}
        {rowRight[1] && (
          <>
            <span className={classes.m1}>|</span>
            {rowRight[1]}
          </>
        )}
      </>
    );
  };

  return (
    rowLeft &&
    rowRight && (
      <div className={classes.container} style={style}>
        <Typography variant="subtitle2" className={classes.item}>
          {t(rowLeft[0])}
          {rowLeft[1] && (
            <>
              <span className={classes.m1}>|</span>
              {t(rowLeft[1])}
            </>
          )}
        </Typography>
        <Typography className={classes.value}>{renderRowRight()}</Typography>
        {rowButtonComponent && <Box ml={0.5}>{rowButtonComponent}</Box>}
      </div>
    )
  );
}

Row.propTypes = {
  rowLeft: PropTypes.arrayOf(PropTypes.string),
  rowRight: PropTypes.arrayOf(PropTypes.node),
  style: PropTypes.shape({}),
  rowButtonComponent: PropTypes.node,
  rowRightComponent: PropTypes.node,
};

Row.defaultProps = {
  rowLeft: [],
  rowRight: [],
  style: {},
  rowButtonComponent: null,
  rowRightComponent: null,
};

export default Row;
