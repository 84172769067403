import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { Progress } from "@oriola-origo/origo-ui-core";
import Delay from "../../../utils/delay/delay";
import { Search } from "../../../images";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 800,
  },
  searchField: {
    backgroundColor: "#f5f4f3",
    borderRadius: 10,
    "& fieldset": {
      borderRadius: 10,
      border: "solid 2px rgba(203, 203, 203, 0.2)",
    },
  },
  optionText: {
    color: theme.palette.text.disabled,
    textTransform: "none",
  },
  popupIndicator: {
    transform: "rotate(0deg)",
  },
  searchFieldContainer: {
    height: "2.5rem",
  },
  autocompleteText: {
    ...theme.typography.caption,
  },
}));

const debouncer = new Delay();

function AutoComplete({
  id,
  onSelect,
  placeholder,
  onChange,
  options,
  getOptionName,
  value: pValue,
  error,
  fetching,
  disabled,
  ...rest
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(pValue);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(fetching);
  }, [fetching, setLoading]);

  const onInputChange = (newValue, fetchFromApi) => {
    setInputValue(newValue);
    if (fetchFromApi) {
      setLoading(true);
      debouncer.run(() => onChange(newValue), 200);
    }
  };

  const renderInput = params => (
    <TextField
      {...params}
      error={error}
      className={classes.searchField}
      placeholder={placeholder}
      id="outlined-dense"
      variant="outlined"
      size="small"
      fullWidth
    />
  );

  const renderOptionText = text => (
    <Typography
      className={classes.optionText}
      color="textPrimary"
      variant="button"
    >
      {text}
    </Typography>
  );

  return (
    <div className={classes.root} {...rest}>
      <Autocomplete
        loading={loading}
        loadingText={renderOptionText(`${t("searching")}...`)}
        noOptionsText={renderOptionText(t("noSearchResults"))}
        classes={{
          popupIndicator: classes.popupIndicator,
          root: classes.searchFieldContainer,
          inputRoot: classes.searchFieldContainer,
          input: classes.autocompleteText,
        }}
        id={id}
        inputValue={inputValue}
        onInputChange={(e, val) => {
          const isChange = (e || {}).type === "change";
          onInputChange(val, val != null && isChange);
        }}
        value={value}
        onChange={(e, val) => {
          setValue(val);
          onSelect(val);
        }}
        getOptionSelected={(option, val) =>
          option.materialId === val.materialId
        }
        options={value && options.length === 0 ? [value] : options} // to dismiss the invalid value warning
        renderInput={params => renderInput(params)}
        popupIcon={loading ? <Progress size={20} show /> : <Search />}
        getOptionLabel={option => getOptionName(option)}
        renderOption={option => {
          const text = getOptionName(option);
          return renderOptionText(text);
        }}
        filterOptions={x => x}
        disabled={disabled}
      />
    </div>
  );
}

AutoComplete.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.shape({}),
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  getOptionName: PropTypes.func,
  error: PropTypes.bool,
  fetching: PropTypes.bool,
  disabled: PropTypes.bool,
};

AutoComplete.defaultProps = {
  onSelect: null,
  onChange: null,
  placeholder: "",
  options: [],
  getOptionName: null,
  fetching: false,
  error: false,
  value: null,
  disabled: false,
};

export default AutoComplete;
