import React from "react";
import { useTranslation } from "react-i18next";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import IconButton from "@oriola-origo/core/lib/IconButton";
import { Tooltip } from "@material-ui/core";
import CaseIconV2 from "./caseIconV2";

function PhotographRequestedIcon() {
  const { t } = useTranslation();
  return (
    <CaseIconV2>
      <Tooltip title={t("productHandlingInstructions.requestToPhoto")}>
        <span>
          <IconButton size="small" disabled sx={{ padding: "2px" }}>
            <FontIcon
              color="primary"
              baseClassName="material-icons-outlined"
              colorTone="main"
              fontSize="small"
            >
              photo
            </FontIcon>
          </IconButton>
        </span>
      </Tooltip>
    </CaseIconV2>
  );
}

export default PhotographRequestedIcon;
