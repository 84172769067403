import React from "react";
import { useTranslation } from "react-i18next";
import CaseLabel from "./caseLabel";

function SFMDLabel({ ...props }) {
  const { t } = useTranslation();
  return (
    <CaseLabel
      label={t("sfmd")}
      tooltip={t("caseInformation.reasonIdentifiers.FALSIFIED_MEDICINE")}
      {...props}
    />
  );
}

export default SFMDLabel;
