/* eslint-disable @typescript-eslint/no-explicit-any */
import AutoComplete from "@oriola-origo/core/lib/Autocomplete";
import { styled } from "@oriola-origo/core/lib/styles";
import Box from "@oriola-origo/core/lib/Box";
import Typography from "@oriola-origo/core/lib/Typography";

export const ProductSearchAutocomplete = styled(AutoComplete)(() => ({
  width: "100%",
  maxWidth: 800,
  "& .MuiAutocomplete-popupIndicator": { transform: "none" },
}));

export const NoProductOrRelevantFound = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: theme.palette.common.white,
  height: theme.spacing(5.5),
  borderLeft: `1px solid ${theme.palette.grey[300]}`,
  borderRight: `1px solid ${theme.palette.grey[300]}`,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: `0 0 ${theme.spacing(0.5)} ${theme.spacing(0.5)}`,
  marginTop: 0,
}));

export const LinkToDialog = styled(Typography)(({ theme }) => ({
  component: "span",
  variant: "body2",
  color: "secondary",
  cursor: "pointer",
  textDecoration: "underline",
  fontWeight: "600",
  marginLeft: theme.spacing(0.5),
}));
