import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Checkbox, FormControlLabel } from "@oriola-origo/origo-ui-core";
import { Box } from "@material-ui/core";
import {
  updateCase,
  provideRequiredInformation,
  addNotification,
  updateValidationError,
} from "../../../redux";
import Title from "../title/title";
import ButtonBar from "../../editCase/buttonBar/buttonBar";
import { isOriolaUser } from "../../auth/permission";
import {
  YesNoSelection,
  YesNoSelectionValues,
  StatefullTextField,
} from "../../common";
import {
  getValidationErrorMessage,
  RETURN_NUMBER_MIN_LENGTH,
  RETURN_NUMBER_MAX_LENGTH,
  FieldErrors,
} from "../../../utils/validations/validations";
import { Paths } from "../../../utils/navigation/navigation";
import { CaseStatus } from "../../../constants/caseStatus";
import CourierSelect from "../../fields/common/courierSelect/courierSelect";

const useStyles = makeStyles(theme => ({
  confirmation: {
    marginBottom: theme.spacing(5),
  },
  titleText: {
    ...theme.typography.subtitle2,
    color: "#757070",
    marginBottom: theme.spacing(1),
  },
  error: {
    fontSize: "1rem",
    color: theme.palette.error.main,
  },
  textField: {
    height: theme.spacing(5),
    width: 300,
  },
}));

function Confirmation({ reclamationCase, user, history }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { confirm, productReturn, caseStatus, certify } = reclamationCase;
  const { returnNumberError, returnConfirmError, courierCompanyError } =
    useSelector(state => state.validationErrors);
  const currentUserIsCustomerUser = !isOriolaUser(user);

  const handleCheckBox = e => {
    const value = e.target.checked;
    dispatch(updateValidationError({ returnConfirmError: "" }));
    dispatch(updateCase({ confirm: value }));
  };

  const handleReturnNumberChange = newValue => {
    dispatch(updateValidationError({ returnNumberError: "" }));
    dispatch(
      updateCase({
        productReturn: { ...productReturn, amount: parseInt(newValue, 10) },
      })
    );
  };

  const onSaveCase = async () => {
    const errorState = {};
    if (
      !productReturn.amount ||
      productReturn.amount < RETURN_NUMBER_MIN_LENGTH ||
      productReturn.amount > RETURN_NUMBER_MAX_LENGTH
    ) {
      errorState.returnNumberError = FieldErrors.RETURN_AMOUNT_INCORRECT;
    }
    if (!confirm) {
      errorState.returnConfirmError = FieldErrors.RETURN_CONFIRMATION;
    }
    if (Object.keys(errorState).length > 0) {
      return dispatch(updateValidationError(errorState));
    }

    return dispatch(provideRequiredInformation(reclamationCase)).then(
      result => {
        if (result && result.status === "SUCCESS") {
          dispatch(
            addNotification({
              title: t("saveEditedCaseSuccessfully.title"),
              primaryText: t("saveEditedCaseSuccessfully.description"),
            })
          );
          history.push(
            Paths.Case.replace(":reclamationId", reclamationCase.reclamationId)
          );
        } else if (result && result.status === "SENDING") {
          dispatch(
            addNotification({
              title: t("duplicateSavingCase"),
            })
          );
        } else {
          dispatch(
            addNotification({
              title: t("saveEditedCaseFailed.title"),
              primaryText: t("saveEditedCaseFailed.description"),
              type: "error",
            })
          );
        }
      }
    );
  };

  if (
    currentUserIsCustomerUser &&
    caseStatus === CaseStatus.OPEN_INFORMATION_REQUESTED_FROM_CUSTOMER
  ) {
    return (
      <div>
        <div className={classes.confirmation}>
          <Title title={t("confirmation")} />
          <YesNoSelection
            id="new-case-product-return"
            title={t("productReturnInfo")}
            value={YesNoSelectionValues.Yes}
            disabled
          />
          <Box my={3.5}>
            <CourierSelect
              mt={3}
              value={reclamationCase.courierCompanyId}
              error={getValidationErrorMessage(courierCompanyError, t)}
              id="return-courier-select"
            />
            <Typography className={`${classes.titleText}`} noWrap>
              {t("productReturnDetail")}
            </Typography>
            <StatefullTextField
              inputProps={{
                min: RETURN_NUMBER_MIN_LENGTH,
                max: RETURN_NUMBER_MAX_LENGTH,
              }}
              initialValue={productReturn.amount || ""}
              focused
              error={!!returnNumberError}
              helperText={getValidationErrorMessage(returnNumberError, t)}
              onChange={handleReturnNumberChange}
              type="number"
              variant="outlined"
              className={`${classes.textField}`}
              margin="dense"
              size="medium"
              id="return-amount-field"
            />
          </Box>
          <Typography
            className={
              productReturn.returnValue
                ? classes.titleText
                : classes.titleTextDefault
            }
            noWrap
          >
            {t("confirmation")}
          </Typography>
          <FormControlLabel
            className={classes.checkboxContainer}
            control={
              <Checkbox
                data-error-name="confirm"
                onChange={handleCheckBox}
                checked={confirm}
              />
            }
            id="new-case-confirm"
            disabled={false}
            label={
              productReturn && productReturn.returnValue
                ? t("confirmDescriptionYes")
                : t("confirmDescriptionNo")
            }
          />
          <FormHelperText
            data-error-name="confirmError"
            className={classes.error}
            id="new-case-confirm-helper-text"
          >
            {getValidationErrorMessage(returnConfirmError, t)}
          </FormHelperText>
        </div>
        <ButtonBar onSave={onSaveCase} />
      </div>
    );
  }

  return (
    <div className={classes.root} id="case-status">
      <Title title={t("confirmation")} />
      {certify && (
        <FormControlLabel
          className={classes.checkboxContainer}
          control={<Checkbox name="confirm" checked={certify} />}
          id="new-case-confirm"
          disabled
          label={t("returnOfSuspectedInfectiousProduct.confirmCheckBox")}
        />
      )}
      <FormControlLabel
        className={classes.checkboxContainer}
        control={<Checkbox data-error-name="confirm" checked={confirm} />}
        id="new-case-confirm"
        disabled
        label={
          productReturn && productReturn.returnValue
            ? t("confirmDescriptionYes")
            : t("confirmDescriptionNo")
        }
      />
    </div>
  );
}

Confirmation.propTypes = {
  reclamationCase: PropTypes.shape({
    confirm: PropTypes.bool,
    reclamationId: PropTypes.string,
    productReturn: PropTypes.shape({
      amount: PropTypes.number,
      returnValue: PropTypes.bool,
    }),
    caseStatus: PropTypes.string,
    certify: PropTypes.bool,
    courierCompanyId: PropTypes.string,
  }),
  user: PropTypes.PropTypes.shape({}),
  history: PropTypes.PropTypes.shape({
    push: PropTypes.func,
  }),
};

Confirmation.defaultProps = {
  reclamationCase: {},
  user: {},
  history: {
    push: null,
  },
};

export default Confirmation;
