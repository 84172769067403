import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as TruckIcon } from "../../../images/truck/truck.svg";
import CaseIcon from "./caseIcon";

function RequestToSendIcon({ ...props }) {
  const { t } = useTranslation();
  return (
    <CaseIcon tooltip={t("requestToSend")} {...props}>
      <TruckIcon width="14px" height="100%" />
    </CaseIcon>
  );
}

export default RequestToSendIcon;
