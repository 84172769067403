import { OriolaTheme, OriolaColors } from "@oriola-origo/origo-ui-core";

OriolaTheme.overrides.MuiTextField.root = {
  ...OriolaTheme.overrides.MuiTextField.root,
  "& label": {
    color: OriolaColors.Grey,
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
    transform: "translate(14px, 13px) scale(0.875)",
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.75)",
  },
};

OriolaTheme.overrides.MuiSelect = {
  root: {
    fontSize: "0.875rem",
  },
};

export { OriolaTheme, OriolaColors };
