import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import DetailRow from "../detailRow";
import getModifierStrOrNull from "./getModifierStrOrNull";

function ConclusionView({ conclusion, modifications, ...containerProps }) {
  const { t } = useTranslation();

  return (
    <Box {...containerProps}>
      <Typography variant="subtitle2">
        {t("conclusionRowTitle").toUpperCase()}
      </Typography>
      <Box mt={3}>
        <DetailRow
          keyName={t("conclusionRowTitle")}
          value={conclusion.conclusion}
          secondaryValue={getModifierStrOrNull(
            conclusion.conclusion != null,
            modifications,
            "conclusion.conclusion"
          )}
        />
      </Box>
    </Box>
  );
}

ConclusionView.propTypes = {
  conclusion: PropTypes.shape({
    conclusion: PropTypes.string,
  }).isRequired,
  modifications: PropTypes.shape({}),
};

ConclusionView.defaultProps = {
  modifications: null,
};

export default ConclusionView;
