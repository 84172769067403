import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TooltipTypography } from "@oriola-origo/origo-ui-core";

const useStyles = makeStyles(theme => ({
  container: {
    paddingRight: theme.spacing(2),
  },
  columnValueText: {
    color: theme.palette.text.primary,
    width: "100%",
    ...theme.typography.button,
    textTransform: "none",
  },
}));

function Customer({ singleCase, ...rest }) {
  const classes = useStyles();
  return (
    <div {...rest} className={classes.container}>
      <TooltipTypography
        className={classes.columnValueText}
        noWrap
        tooltip={singleCase.customerName}
      >
        {singleCase.customerName}
      </TooltipTypography>
      <Typography className={classes.columnValueText}>
        {singleCase.customerId}
      </Typography>
    </div>
  );
}

Customer.propTypes = {
  singleCase: PropTypes.shape({
    customerName: PropTypes.string,
    customerId: PropTypes.string,
  }),
};

Customer.defaultProps = {
  singleCase: {},
};

export default Customer;
