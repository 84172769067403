const EventQueueTarget = Object.freeze({
  RETURN_DEPARTMENT: "RETURN_DEPARTMENT",
  INVOICING_DEPARTMENT: "INVOICING_DEPARTMENT",
  CUSTOMER_SERVICE_DEPARTMENT: "CUSTOMER_SERVICE_DEPARTMENT",
  SCC: "SCC",
  TRANSLATIONS: "TRANSLATIONS",
  TRANSPORTATION: "TRANSPORTATION",
});

export default EventQueueTarget;
