import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { Dropdown, OriolaColors } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { isOriolaUser, isPharmaceuticalCompany } from "../../auth/permission";
import {
  getCustomerRecipient,
  getOriolaRecipient,
  getPrincipalRecipient,
  getSpdRecipientsOriola,
  getSpdRecipientsPrincipal,
} from "./recipients";
import { CaseStatus } from "../../../constants/caseStatus";
import { CaseType } from "../../../constants/caseType";

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: OriolaColors.white,
  },
}));

function SelectMessageRecipient({
  messageRecipient,
  onMessageRecipientChanged,
  userData,
  reclamationCase,
  errorText,
  ...containerProps
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (reclamationCase == null) {
      return;
    }

    const caseIsSolved = reclamationCase.caseStatus === CaseStatus.SOLVED;

    let newOptions = [];
    if (isOriolaUser(userData)) {
      newOptions =
        reclamationCase.caseType === CaseType.SUSPECTED_PRODUCT_DEFECT
          ? getSpdRecipientsOriola(reclamationCase)
          : [getCustomerRecipient(reclamationCase)];
    } else if (isPharmaceuticalCompany(userData)) {
      if (caseIsSolved === false) {
        newOptions = getSpdRecipientsPrincipal(reclamationCase);
      } else {
        newOptions = [getCustomerRecipient(reclamationCase)];
      }
    } else if (caseIsSolved === false) {
      newOptions = [
        getOriolaRecipient(),
        getPrincipalRecipient(reclamationCase),
      ];
    } else {
      newOptions = [getPrincipalRecipient(reclamationCase)];
    }

    setOptions(newOptions.filter(Boolean));
  }, [userData, reclamationCase, setOptions]);

  const sortOptions = (a, b) => a.orgName.localeCompare(b.orgName);

  return (
    <Box {...containerProps}>
      <Dropdown
        id="message-recipient-select"
        value={messageRecipient || ""}
        emptySelectionLabel={t("message.selectRecipient")}
        onFormatOptionLabel={option => `${option.orgName}`}
        options={options.slice(0).sort(sortOptions)}
        onSelectOption={onMessageRecipientChanged}
        showSelectNone={false}
        selectNoneLabel={t("selectNone")}
        error={errorText != null}
        errorText={errorText}
        inputProps={{
          classes: { root: classes.input },
        }}
      />
    </Box>
  );
}

SelectMessageRecipient.propTypes = {
  messageRecipient: PropTypes.shape({}),
  onMessageRecipientChanged: PropTypes.func,
  userData: PropTypes.shape({}).isRequired,
  reclamationCase: PropTypes.shape({
    caseStatus: PropTypes.string,
    caseType: PropTypes.string,
  }),
  errorText: PropTypes.string,
};

SelectMessageRecipient.defaultProps = {
  reclamationCase: null,
  errorText: null,
  onMessageRecipientChanged: null,
  messageRecipient: null,
};

export default SelectMessageRecipient;
