import { hideDialog, addNotification } from "../../../redux";
import { fetchProductReturnPdfUrl } from "../../../redux/case/case";

const closeAndPrintCaseDetails = (t, dispatch, reclamationId) => {
  dispatch(hideDialog());
  dispatch(fetchProductReturnPdfUrl(reclamationId))
    .then(response => {
      window.open(response.url);
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error("Error when fetching prduct return document", error);
      dispatch(
        addNotification({
          title: t("fetchProductReturnDoumentFailed.title"),
          primaryText: t("fetchProductReturnDoumentFailed.description"),
          type: "error",
        })
      );
    });
};

export default closeAndPrintCaseDetails;
