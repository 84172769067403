import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import useHasScrolledPast from "../../../hooks/useHasScrolledPast";
import useWindowResize from "../../../hooks/useWindowResize";
import {
  APP_HEADER_HEIGHT,
  NAVIGATION_PANE_WIDTH,
} from "../../layout/layout/layout";

// eslint-disable-next-line import/no-cycle
import {
  CASE_VIEW_MAX_WIDTH_PX,
  CASE_VIEW_WIDTH_PERCENTAGE,
} from "../caseView";

const useStyles = makeStyles(theme => ({
  root: () => ({
    height: 50,
    padding: `0 ${theme.spacing(4)}px`,
    backgroundColor: OriolaColors.secondaryNavyBlue,
    position: "fixed",
    top: APP_HEADER_HEIGHT,
    left: NAVIGATION_PANE_WIDTH,
    right: 0,
    display: "flex",
    alignItems: "center",
    zIndex: 100,
  }),
  inner: {
    width: CASE_VIEW_WIDTH_PERCENTAGE,
    maxWidth: CASE_VIEW_MAX_WIDTH_PX,
    margin: "0 auto",
  },
  arrow: {
    width: 8,
    height: 8,
    display: "inline-block",
    borderRight: "3px solid #fff",
    borderBottom: "3px solid #fff",
    transform: "rotate(-45deg)",
    margin: `0 ${theme.spacing(1.25)}px 0 ${theme.spacing(1)}px`,
  },
  text: {
    display: "inline-block",
    fontWeight: 700,
    fontSize: 16,
    color: OriolaColors.white,
  },
}));

function FloatingCaseHeading({
  containerElement,
  visibleAfterElement,
  leftText,
  rightText,
}) {
  const hasScrolledPastTarget = useHasScrolledPast(
    visibleAfterElement,
    containerElement
  );
  const [isVisible, setIsVisible] = useState(false);
  const [left, setLeft] = useState(0);
  const classes = useStyles({ left });

  const adjustContainerLeft = useCallback(() => {
    if (containerElement.current) {
      const containerLeft = containerElement.current.offsetLeft;
      setLeft(() => containerLeft);
    }
  }, [containerElement]);

  useWindowResize(adjustContainerLeft);

  useEffect(() => {
    if (containerElement.current && visibleAfterElement.current) {
      if (hasScrolledPastTarget) {
        adjustContainerLeft();
      }
      setIsVisible(hasScrolledPastTarget);
    }
  }, [
    hasScrolledPastTarget,
    containerElement,
    visibleAfterElement,
    adjustContainerLeft,
  ]);

  const render = () => {
    if (!isVisible) {
      return null;
    }

    return (
      <div className={classes.root}>
        <div className={classes.inner}>
          <Typography className={classes.text}>{leftText}</Typography>
          <div className={classes.arrow} />
          <Typography className={classes.text}>{rightText}</Typography>
        </div>
      </div>
    );
  };

  return containerElement?.current
    ? createPortal(render(), containerElement.current)
    : null;
}

FloatingCaseHeading.propTypes = {
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  containerElement: PropTypes.shape({
    current: PropTypes.shape({
      offsetLeft: PropTypes.number,
    }),
  }),
  visibleAfterElement: PropTypes.shape({
    current: PropTypes.shape({}),
  }),
};

FloatingCaseHeading.defaultProps = {
  leftText: "",
  rightText: "",
  containerElement: null,
  visibleAfterElement: null,
};

export default FloatingCaseHeading;
