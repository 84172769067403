import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(0.5),
    "&:nth-child(odd)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  title: {
    flexBasis: "40%",
    flexShrink: 0,
    flexGrow: 0,
    marginRight: theme.spacing(2),
  },
}));

function RowComponent({
  rowTitleKey,
  rowValueComponent,
  style,
  rowButtonComponent,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.container} style={style}>
      <Box className={classes.title}>
        {rowTitleKey && (
          <Typography variant="subtitle2">{t(rowTitleKey)}</Typography>
        )}
      </Box>
      <Box flexGrow={1}>{rowValueComponent}</Box>
      {rowButtonComponent && <Box ml={0.5}>{rowButtonComponent}</Box>}
    </div>
  );
}

RowComponent.propTypes = {
  rowTitleKey: PropTypes.string,
  rowValueComponent: PropTypes.node,
  style: PropTypes.shape({}),
  rowButtonComponent: PropTypes.node,
};

RowComponent.defaultProps = {
  style: {},
  rowButtonComponent: null,
  rowTitleKey: null,
  rowValueComponent: null,
};

export default RowComponent;
