import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { Progress } from "@oriola-origo/origo-ui-core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import QueueChange from "./queueChange";
import CaseEvent from "./CaseEvent";
import EventType from "../../../constants/eventType";
import Message, { isOwnMessage, MessageAlign } from "./message";
import NotificationSent from "./notificationSent";
import { modifyMessage } from "../../../redux/message/message";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#f5f5f5",
  },
  messageList: {
    "& > div:first-child": {
      animationDelay: ".5s",
      animation: "$shake .88s cubic-bezier(.36,.07,.19,.97)",
      transform: "translate3d(0, 0, 0)",
    },
  },
  "@keyframes shake": {
    "10%, 90%": {
      transform: "translate3d(-7px, 0, 0)",
    },
    "20%, 80%": {
      transform: "translate3d(8px, 0, 0)",
    },
    "30%, 50%, 70%": {
      transform: "translate3d(-9px, 0, 0)",
    },
    "40%, 60%": {
      transform: "translate3d(9px, 0, 0)",
    },
  },
}));

function NoteList({ userData, reclamationCase, notes, fetching }) {
  const classes = useStyles();
  const { sendingMessageStatusIds, messageStatusSendErrors } = useSelector(
    state => state.message
  );
  const dispatch = useDispatch();

  const onModifyMessage = (eventId, status, attachments) => {
    dispatch(
      modifyMessage(reclamationCase.reclamationId, eventId, status, attachments)
    );
  };

  const renderNote = message => {
    const ownMessage = isOwnMessage(message, userData);

    const { type } = message;
    if (type === EventType.QUEUE_CHANGE) {
      return (
        <QueueChange
          message={message}
          updatingReadStatus={sendingMessageStatusIds[message.id] === true}
          updateReadStatusError={messageStatusSendErrors[message.id]}
          onModifyMessage={status =>
            onModifyMessage(message.id, status, message.attachments)
          }
        />
      );
    }
    if (type === EventType.NOTES) {
      return <NotificationSent message={message} />;
    }
    if (type === EventType.MESSAGE) {
      return (
        <Message
          messageAlign={ownMessage ? MessageAlign.Right : MessageAlign.Left}
          message={message}
          showReadStatus={false}
        />
      );
    }
    return <CaseEvent message={message} type={type} />;
  };

  const renderProgress = () => (
    <Box p={3} display="flex" alignItems="center" justifyContent="center">
      <Progress show />
    </Box>
  );

  return (
    <Box className={classes.messageList} my={3}>
      {fetching === false &&
        notes.map(message => (
          <Box key={message.id} mb={3}>
            {renderNote(message)}
          </Box>
        ))}
      {fetching === true && renderProgress()}
    </Box>
  );
}

NoteList.propTypes = {
  userData: PropTypes.shape({}).isRequired,
  notes: PropTypes.shape([]).isRequired,
  fetching: PropTypes.bool.isRequired,
  reclamationCase: PropTypes.shape({
    reclamationId: PropTypes.string,
  }),
};

NoteList.defaultProps = {
  reclamationCase: {},
};

export default NoteList;
