import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { AlertDialog, OriolaColors } from "@oriola-origo/origo-ui-core";
import { Box, TextField, Typography } from "@material-ui/core";
import { StatefullTextField } from "../../common";

const useStyles = makeStyles(theme => ({
  title: {
    ...theme.typography.subtitle2,
    weight: "700",
    color: OriolaColors.secondaryNavyBlue,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    ...theme.typography.subtitle2,
    weight: "700",
    color: OriolaColors.secondaryGray,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  readTextField: {
    backgroundColor: "#F5F5F5",
  },
  writeTextField: {
    marginBottom: theme.spacing(2),
  },
}));

export const TranslateField = Object.freeze({
  ADVERSE_REACTION: "ADVERSE_REACTION",
  DESCRIPTION_OF_DEFECT: "DESCRIPTION_OF_DEFECT",
  IS_CUSTOMER_COMPENSATED_DESCRIPTION: "IS_CUSTOMER_COMPENSATED_DESCRIPTION",
});

function TranslateFieldDialog({
  open,
  fieldType,
  originalText,
  translatedText,
  onCancel,
  onTranslate,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue("");
  }, [setValue]);

  const getTitleByType = () => {
    switch (fieldType) {
      case TranslateField.ADVERSE_REACTION:
        return t("caseInformation.adverseEvent.adverseEventDescription");
      case TranslateField.DESCRIPTION_OF_DEFECT:
        return t("caseInformation.defectDetectedBy.describe");
      case TranslateField.IS_CUSTOMER_COMPENSATED_DESCRIPTION:
        return t("caseInformation.howWasCustomerCompensated.title");
      default:
        return "";
    }
  };

  const title = getTitleByType();
  return (
    <AlertDialog
      id="translate-field-dialog"
      open={open}
      onCancel={onCancel}
      onOk={() => onTranslate(fieldType, value)}
      title={t("translate.dialogTitle")}
      cancelButtonText={t("cancel")}
      okButtonText={t("save")}
      disableBackdropClick
      maxWidth="lg"
    >
      <Box p={0} width="800px">
        <Typography className={classes.title}>{title}</Typography>
        <TextField
          className={classes.readTextField}
          disabled
          multiline
          rows={10}
          fullWidth
          variant="outlined"
          value={originalText || ""}
        />
        <Typography className={classes.subtitle}>
          {`${t(
            "translate.dialogTextFieldTitlePrefix"
          )} ${title.toLocaleLowerCase()}`}
        </Typography>
        <StatefullTextField
          className={classes.writeTextField}
          multiline
          rows={10}
          placeholder={t("translate.dialogTextFieldPlaceholder")}
          fullWidth
          variant="outlined"
          initialValue={translatedText || ""}
          onChange={updatedValue => setValue(updatedValue)}
        />
      </Box>
    </AlertDialog>
  );
}

TranslateFieldDialog.propTypes = {
  open: PropTypes.bool,
  fieldType: PropTypes.string,
  originalText: PropTypes.string,
  translatedText: PropTypes.string,
  onCancel: PropTypes.func,
  onTranslate: PropTypes.func,
};

TranslateFieldDialog.defaultProps = {
  open: false,
  fieldType: "",
  originalText: "",
  translatedText: "",
  onCancel: null,
  onTranslate: null,
};

export default TranslateFieldDialog;
