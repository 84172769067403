import React from "react";
import { IconTextButton } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Trashcan, DeleteIcon } from "../../../../images";
import { CaseStatus } from "../../../../constants/caseStatus";
// eslint-disable-next-line import/no-cycle
import { showDialog, hideDialog } from "../../../../redux";

function DeleteItemButton({
  caseStatus,
  handleDelete,
  id,
  title,
  description,
  disabled,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const confirmDeletion = () => {
    if (
      caseStatus === CaseStatus.OPEN_SUBMITTED ||
      caseStatus === CaseStatus.OPEN_INFORMATION_REQUESTED_FROM_CUSTOMER ||
      caseStatus === CaseStatus.SOLVED
    ) {
      dispatch(
        showDialog({
          open: true,
          description,
          icon: (
            <div>
              <DeleteIcon color="primary" />
            </div>
          ),
          title,
          cancelButtonText: t("confirmLeavinPage.cancel"),
          okButtonText: t("delete"),
          onCancel: () => dispatch(hideDialog()),
          onOk: () => {
            dispatch(hideDialog());
            handleDelete();
          },
        })
      );
    } else {
      handleDelete();
    }
  };

  return (
    <IconTextButton
      style={{ textTransform: "none" }}
      icon={<Trashcan color="primary" />}
      text={t("delete")}
      id={`${id}-delete`}
      onClick={confirmDeletion}
      textStyle={{ fontSize: "1rem" }}
      disabled={disabled}
    />
  );
}

DeleteItemButton.propTypes = {
  caseStatus: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

DeleteItemButton.defaultProps = {
  caseStatus: "",
  disabled: false,
};

export default DeleteItemButton;
