import {
  Authorizer,
  ReclamationRoles as Roles,
} from "@oriola-origo/origo-common-client-lib";

export const ANY_CUSTOMER = "ANY_CUSTOMER";

export const AllRolesArray = [
  Roles.RECLAMATION_CUSTOMER_EDIT,
  Roles.RECLAMATION_CUSTOMER_VIEW,
  Roles.RECLAMATION_ORIOLA_EDIT,
  Roles.RECLAMATION_ORIOLA_ADMIN,
  Roles.RECLAMATION_ORIOLA_VIEW,
];

export const Permission = Object.freeze({
  CASE_CREATE: "CASE_CREATE",
  CASE_UPDATE: "CASE_UPDATE",
  DELETE_ATTACHMENT: "DELETE_ATTACHMENT",
  HANDLING_DATA_UPDATE: "HANDLING_DATA_UPDATE",
  HANDLING_DATA_ORIOLA_VIEW: "HANDLING_DATA_ORIOLA_VIEW",
  SEND_MESSAGE: "SEND_MESSAGE",
  HANDLING_DATA_PRINCIPAL_VIEW: "HANDLING_DATA_PRINCIPAL_VIEW",
  HANDLING_DATA_CUSTOMER_VIEW: "HANDLING_DATA_CUSTOMER_VIEW",
});

const reclamationOriolaEditPermissions = [
  Permission.CASE_CREATE,
  Permission.CASE_UPDATE,
  Permission.HANDLING_DATA_UPDATE,
  Permission.HANDLING_DATA_ORIOLA_VIEW,
  Permission.SEND_MESSAGE,
  Permission.HANDLING_DATA_PRINCIPAL_VIEW,
  Permission.HANDLING_DATA_CUSTOMER_VIEW,
];
const roleToPermissionMap = {
  [Roles.RECLAMATION_CUSTOMER_EDIT]: {
    static: [
      Permission.CASE_CREATE,
      Permission.CASE_UPDATE,
      Permission.SEND_MESSAGE,
      Permission.HANDLING_DATA_CUSTOMER_VIEW,
    ],
  },
  [Roles.RECLAMATION_CUSTOMER_VIEW]: {
    static: [Permission.HANDLING_DATA_CUSTOMER_VIEW],
  }, // case view permission is implicit
  [Roles.RECLAMATION_ORIOLA_EDIT]: {
    static: [...reclamationOriolaEditPermissions],
  },
  [Roles.RECLAMATION_ORIOLA_ADMIN]: {
    static: [...reclamationOriolaEditPermissions, Permission.DELETE_ATTACHMENT],
  },
  [Roles.RECLAMATION_ORIOLA_VIEW]: {
    static: [
      Permission.HANDLING_DATA_ORIOLA_VIEW,
      Permission.HANDLING_DATA_PRINCIPAL_VIEW,
      Permission.HANDLING_DATA_CUSTOMER_VIEW,
    ],
  }, // case view permission is implicit
  [Roles.RECLAMATION_PRINCIPAL_EDIT]: {
    static: [
      Permission.HANDLING_DATA_PRINCIPAL_VIEW,
      Permission.HANDLING_DATA_UPDATE,
      Permission.SEND_MESSAGE,
    ],
  },
  [Roles.RECLAMATION_PRINCIPAL_VIEW]: {
    static: [Permission.HANDLING_DATA_PRINCIPAL_VIEW],
  },
};

const authorizer = new Authorizer(roleToPermissionMap, ANY_CUSTOMER);

export const isAllowed = (user, permission, customerContext, data) =>
  authorizer.isAllowed(user, permission, customerContext, data);

export const isOriolaUser = user => {
  if (user == null) {
    return false;
  }

  const { roles } = user;
  return (
    roles.includes(Roles.RECLAMATION_ORIOLA_EDIT) ||
    roles.includes(Roles.RECLAMATION_ORIOLA_VIEW) ||
    roles.includes(Roles.RECLAMATION_ORIOLA_ADMIN)
  );
};

export const userHasReclamationOriolaAdminRole = user => {
  if (user == null) {
    return false;
  }

  const { roles } = user;
  return roles.includes(Roles.RECLAMATION_ORIOLA_ADMIN);
};

export const isCustomer = user => {
  if (user == null) {
    return false;
  }

  const { roles } = user;
  return (
    roles.includes(Roles.RECLAMATION_CUSTOMER_EDIT) ||
    roles.includes(Roles.RECLAMATION_CUSTOMER_VIEW)
  );
};

export const isCustomerEditRole = user => {
  if (!user) {
    return false;
  }

  const { roles } = user;
  return roles.includes(Roles.RECLAMATION_CUSTOMER_EDIT);
};

export const isPharmaceuticalCompany = user => {
  if (user == null) {
    return false;
  }

  const { roles } = user;
  return (
    roles.includes(Roles.RECLAMATION_PRINCIPAL_EDIT) ||
    roles.includes(Roles.RECLAMATION_PRINCIPAL_VIEW)
  );
};
export const isPharmaceuticalCompanyEditRole = user => {
  if (user == null) {
    return false;
  }

  const { roles } = user;
  return roles.includes(Roles.RECLAMATION_PRINCIPAL_EDIT);
};

export const getOrganizationIdsByRoles = (userData, supportedRoles) => {
  const { rolesByOrgId } = userData || { rolesByOrgId: [] };
  const organizationIds = rolesByOrgId.reduce((acc, next) => {
    const organizationRoles = next.roles;
    if (organizationRoles.some(role => supportedRoles.includes(role))) {
      return acc.concat(next.orgId);
    }
    return acc;
  }, []);

  return organizationIds.filter(id => id !== ANY_CUSTOMER);
};
