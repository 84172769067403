/* eslint-disable default-param-last */
export const NotificationPopups = Object.freeze({
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  DISMISS_NOTIFICATION: "DISMISS_NOTIFICATION",
  CLEAR_NOTIFICATIONS: "CLEAR_NOTIFICATIONS",
});

// all setTimeout timers
let timers = {};

export const addNotification =
  ({
    id = Date.now(), // use timestamp as default id
    type,
    icon,
    title,
    primaryText,
    secondaryText,
    autoHideDuration = 3000,
  }) =>
  dispatch => {
    dispatch({
      type: NotificationPopups.ADD_NOTIFICATION,
      payload: {
        id,
        type,
        icon,
        title,
        primaryText,
        secondaryText,
        autoHideDuration,
      },
    });
    if (autoHideDuration > 0) {
      timers[id] = setTimeout(() => {
        dispatch({
          type: NotificationPopups.DISMISS_NOTIFICATION,
          payload: {
            id,
          },
        });
      }, autoHideDuration);
    }
  };

export const dismissNotification = id => dispatch => {
  dispatch({
    type: NotificationPopups.DISMISS_NOTIFICATION,
    payload: {
      id,
    },
  });
  clearTimeout(timers[id]);
  delete timers[id];
};

export const clearNotifications = () => dispatch => {
  dispatch({
    type: NotificationPopups.CLEAR_NOTIFICATIONS,
  });
  // clear all timers
  Object.keys(timers).forEach(id => {
    clearTimeout(timers[id]);
  });
  timers = {};
};

const INIT_STATE = {
  notifications: [],
};

export const notificationPopupsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case NotificationPopups.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case NotificationPopups.DISMISS_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.id !== action.payload.id
        ),
      };
    case NotificationPopups.CLEAR_NOTIFICATIONS:
      return { ...state, notifications: [] };
    default:
      return state;
  }
};
