import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
// eslint-disable-next-line import/no-cycle
import { ButtonBar } from "..";
import { CaseHeading } from "../../common";
import { SuspectedProductDefectFields } from "../../fields";
import { Paths, getDraftPath } from "../../../utils/navigation/navigation";
import scrollToError from "../../../utils/scrollToView/scrollToView";
import {
  confirmAndDeleteCase,
  confirmAndSubmitCase,
  confirmAndCancelCreate,
  saveDraftCase,
  openCaseDetailsPrintDialog,
} from "../caseActions";
import {
  validateDraftReclamationCase,
  validateSPDFields,
} from "../../../utils/validations/validations";
import { setValidationError, updateValidationError } from "../../../redux";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4),
    minHeight: 580,
    marginLeft: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(3),
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textTransform: "uppercase",
    fontSize: "1rem",
    color: "#2b3e5b",
  },
}));

function ProductDefect({
  history,
  reclamationCase,
  isNewCase,
  disabled,
  id,
  caseConfig,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contactInfo = useSelector(state => state.contactInfo);
  const { userData } = useSelector(state => state.user);

  const validateDraft = () => {
    let success = true;
    const errorState = validateDraftReclamationCase(
      reclamationCase,
      contactInfo
    );

    dispatch(setValidationError(errorState));
    if (Object.keys(errorState).length > 0) {
      success = false;
    }
    return success;
  };

  const validateSubmit = () => {
    const errorState = {};
    validateSPDFields({
      contactInfo,
      reclamationCase,
      errorState,
      caseConfig,
      userData,
    });

    dispatch(updateValidationError(errorState));
    dispatch(setValidationError(errorState));
    scrollToError(errorState);
    return Object.keys(errorState).length === 0;
  };

  const onSubmitCase = () => {
    if (validateSubmit()) {
      confirmAndSubmitCase(t, dispatch, reclamationCase, result => {
        if (result.productReturn.returnValue) {
          history.push(`/case/${result.reclamationId}`);
          openCaseDetailsPrintDialog(t, dispatch, result);
        } else {
          history.push(Paths.OpenCases);
        }
      });
    }
  };

  const onSaveDraft = () => {
    if (validateDraft()) {
      saveDraftCase(t, dispatch, reclamationCase, result => {
        if (isNewCase === true) {
          history.push(getDraftPath(result.reclamationId));
        }
      });
    }
  };

  const buttonBar = displayMandatoryFieldsInfo => (
    <ButtonBar
      displayMandatoryFieldsInfo={displayMandatoryFieldsInfo}
      onSaveDraft={onSaveDraft}
      onSubmit={() => onSubmitCase()}
      onDeleteDraft={() =>
        confirmAndDeleteCase(t, dispatch, reclamationCase, () =>
          history.push(Paths.Drafts)
        )
      }
      onCancel={() =>
        confirmAndCancelCreate(t, dispatch, () => history.goBack())
      }
      isNewCase={isNewCase}
    />
  );

  const topButtonBar = () => buttonBar(true);

  const bottomButtonBar = () => buttonBar(false);

  return (
    <Paper className={classes.container} id={id}>
      <CaseHeading
        leftText={t("newCase")}
        rightText={t("category.productDefect.header")}
      />
      {disabled ? (
        <Typography variant="subtitle2" className={classes.warningTitle}>
          {t("caseInformation.notEditableWarning")}
        </Typography>
      ) : (
        topButtonBar()
      )}
      <SuspectedProductDefectFields
        reclamationCase={reclamationCase}
        isNewCase={isNewCase}
        disabled={disabled}
        caseConfig={caseConfig}
      />
      {!disabled && bottomButtonBar()}
    </Paper>
  );
}

ProductDefect.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }),
  reclamationCase: PropTypes.shape({}).isRequired,
  isNewCase: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  caseConfig: PropTypes.shape({}),
};

ProductDefect.defaultProps = {
  isNewCase: false,
  disabled: false,
  id: "",
  caseConfig: {},
  history: {
    push: null,
    goBack: null,
  },
};
export default withRouter(ProductDefect);
