import React from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "@oriola-origo/origo-ui-core";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
// eslint-disable-next-line import/no-cycle
import { updateContactInfo } from "../../../../redux";
import { FieldTitle } from "..";

function DeliveryAddressSelect({ contactInfo, disabled, classes }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className={classes.selectContainer}>
      <FieldTitle
        title={t("contactInformation.deliveryAddress")}
        type="selection"
      />
      <Dropdown
        disabled={disabled}
        className={classes.dropdown}
        value={contactInfo.deliveryAddress || ""}
        emptySelectionLabel={t("select")}
        onFormatOptionLabel={a => a.address}
        options={
          (contactInfo &&
            contactInfo.customer &&
            contactInfo.customer.deliveryAddresses) ||
          []
        }
        onSelectOption={x => {
          dispatch(updateContactInfo({ deliveryAddress: x }));
        }}
        showSelectNone
        selectNoneLabel={t("selectNone")}
        id="contact-info-delivery-address"
      />
    </div>
  );
}

DeliveryAddressSelect.propTypes = {
  contactInfo: PropTypes.shape({
    deliveryAddress: PropTypes.string,
    customer: PropTypes.shape({
      deliveryAddresses: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    selectContainer: PropTypes.string,
    dropdown: PropTypes.string,
  }),
};

DeliveryAddressSelect.defaultProps = {
  contactInfo: {},
  classes: {},
  disabled: false,
};

export default DeliveryAddressSelect;
