import React from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-cycle
import { updateContactInfo, updateValidationError } from "../../../../redux";
import {
  getValidationErrorMessage,
  FieldErrors,
  validatePhoneNumber,
} from "../../../../utils/validations/validations";

function NewPhoneNumberField({
  contactInfo,
  disabled,
  newPhoneNumberOption,
  setNewPhoneNumberRef,
  classes,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { phoneNumberError } = useSelector(state => state.validationErrors);
  const isNewPhoneNumberItem = item =>
    (item && item.id) === newPhoneNumberOption.id;
  const showNewPhoneNumberField = () =>
    isNewPhoneNumberItem(contactInfo.phoneNumber) ||
    (contactInfo.customer &&
      contactInfo.customer.phoneNumbers &&
      contactInfo.customer.phoneNumbers.length === 0) ||
    false;

  return (
    showNewPhoneNumberField() && (
      <div
        className={classes.selectContainer}
        data-error-name="phoneNumberError"
      >
        <div className={classes.controlContainer}>
          <TextField
            disabled={disabled}
            value={
              isNewPhoneNumberItem(contactInfo.phoneNumber)
                ? contactInfo.phoneNumber.number
                : ""
            }
            variant="outlined"
            placeholder="+358 40 123 4567"
            className={classes.textField}
            margin="dense"
            onChange={event => {
              newPhoneNumberOption.number = event.target.value;
              if (!validatePhoneNumber(newPhoneNumberOption.number)) {
                dispatch(
                  updateValidationError({
                    phoneNumberError: FieldErrors.PHONE_NUMBER_INVALID,
                  })
                );
              } else {
                dispatch(updateValidationError({ phoneNumberError: "" }));
              }
              dispatch(
                updateContactInfo({
                  phoneNumber: newPhoneNumberOption,
                })
              );
            }}
            inputRef={input => setNewPhoneNumberRef(input)}
            error={!!phoneNumberError}
            helperText={getValidationErrorMessage(phoneNumberError, t)}
            id="contact-info-new-phone-number"
          />
        </div>
      </div>
    )
  );
}

NewPhoneNumberField.propTypes = {
  contactInfo: PropTypes.shape({
    phoneNumber: PropTypes.string,
    customer: PropTypes.shape({
      phoneNumbers: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  disabled: PropTypes.bool,
  newPhoneNumberOption: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    number: PropTypes.string,
  }),
  setNewPhoneNumberRef: PropTypes.func,
  classes: PropTypes.shape({
    selectContainer: PropTypes.string,
    controlContainer: PropTypes.string,
    textField: PropTypes.string,
  }),
};

NewPhoneNumberField.defaultProps = {
  contactInfo: {},
  classes: {},
  disabled: false,
  newPhoneNumberOption: {},
  setNewPhoneNumberRef: null,
};

export default NewPhoneNumberField;
