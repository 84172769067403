import {
  addNotification,
  editCase,
  fetchMessages,
  updateMessageAndQueueList,
} from "../../../redux";
import EventQueueTarget from "../../../constants/eventQueueTarget";
import {
  handleOptimisticLockError,
  isOptimisticLockError,
} from "../../../utils/optimisticLock/optimisticLock";
import {
  handleUpdateAttachmentsError,
  isUpdateAttachmentsError,
} from "../../../utils/updateAttachments/updateAttachments";

const saveEditingCase = ({
  dispatch,
  reclamationCase,
  requireMoreInfoFromCustomer,
  t,
  onSaved,
}) => {
  const { productReturn, reclamationId, queue } = reclamationCase;
  dispatch(editCase(reclamationCase, requireMoreInfoFromCustomer)).then(
    result => {
      if (result && result.status === "SUCCESS") {
        dispatch(
          addNotification({
            title: t("saveEditedCaseSuccessfully.title"),
            primaryText: t("saveEditedCaseSuccessfully.description"),
          })
        );
        if (
          productReturn.returnValue &&
          !productReturn.amount &&
          queue !== EventQueueTarget.RETURN_DEPARTMENT
        ) {
          dispatch(
            addNotification({
              title: t("changeQueueSuccessfully.title"),
              primaryText: t("changeQueueSuccessfully.description"),
            })
          );
          dispatch(fetchMessages(reclamationId)).then(() => {
            dispatch(updateMessageAndQueueList());
          });
        }
        onSaved();
      } else if (result && result.status === "SENDING") {
        dispatch(
          addNotification({
            title: t("duplicateSavingCase"),
          })
        );
      } else if (isOptimisticLockError(result)) {
        // sending failed, check if it's optimistic lock and open dialog to inform the user
        handleOptimisticLockError(dispatch, t);
      } else if (isUpdateAttachmentsError(result)) {
        handleUpdateAttachmentsError(dispatch, t);
      } else {
        dispatch(
          addNotification({
            title: t("saveEditedCaseFailed.title"),
            primaryText: t("saveEditedCaseFailed.description"),
            type: "error",
          })
        );
      }
    }
  );
};

export default saveEditingCase;
