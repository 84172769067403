import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { ReactComponent as NewMessageIcon } from "../../../images/newMessage/newMessage.svg";

function NewMessageLabel() {
  const { t } = useTranslation();

  return (
    <Box
      px={0.5}
      height={24}
      display="inline-flex"
      flexDirection="row"
      alignItems="center"
      bgcolor="#FFE9C6"
      border="1px solid #FFD48D"
      borderRadius={4}
      fontWeight="bold"
      textAlign="center"
      color="#FFA000"
      fontSize={12}
    >
      <NewMessageIcon />
      <Box ml={0.5}>{t("new")}</Box>
    </Box>
  );
}

NewMessageLabel.propTypes = {};

NewMessageLabel.defaultProps = {};

export default NewMessageLabel;
