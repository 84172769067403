import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { WarningMessage } from "@oriola-origo/origo-ui-core";
import {
  isOriolaUser,
  isCustomer,
  isPharmaceuticalCompany,
} from "../../auth/permission";
import { CaseType } from "../../../constants/caseType";
import {
  getSafeTubeInfoTextPerLanguage,
  getSafeTubeInfoFromCaseProducts,
} from "../../../utils/productUtil/productUtil";

function CaseHeaderWarnings({
  reclamationCase,
  caseHandler,
  userData,
  fieldsConfig,
}) {
  const { t, i18n } = useTranslation();
  const currentUserIsOriolaUser = isOriolaUser(userData);
  const currentUserIsCustomer = isCustomer(userData);
  const SuspectedProductDefectReasonIdentifiers =
    fieldsConfig.SuspectedProductDefectReasonIdentifiers || {};

  const getHandledWarningText = () => {
    const { firstName, lastName } = caseHandler;
    const { modificationTime } = reclamationCase;

    return `${t("thisCaseIsCurrentlyHandledBy")} ${firstName} ${lastName}, ${t(
      "lastSaved"
    ).toLowerCase()} ${moment(modificationTime).format("DD.MM.YYYY")} ${t(
      "atTime"
    ).toLowerCase()} ${moment(modificationTime).format("HH.mm")}.`;
  };

  const warningsArray = [];

  // infectious
  const showInfectiousWarning =
    reclamationCase.caseType === CaseType.SUSPECTED_PRODUCT_DEFECT &&
    reclamationCase.suspectedInfectiousProduct &&
    (currentUserIsCustomer || currentUserIsOriolaUser);
  if (showInfectiousWarning) {
    warningsArray.push({
      id: "infectious-warning",
      text: t("infectiousWarning"),
    });
  }

  // safe product
  const safeTubeProductAdded = (reclamationCase.items || []).some(item => {
    if (item.product) {
      return item.product.safeTubeNeededInReturning === true;
    }
    return false;
  });
  const showSafeTubeWarning =
    safeTubeProductAdded &&
    reclamationCase.caseType === CaseType.SUSPECTED_PRODUCT_DEFECT;
  if (showSafeTubeWarning) {
    const safeTubeInfoTextInLang = getSafeTubeInfoTextPerLanguage(
      getSafeTubeInfoFromCaseProducts(reclamationCase.items),
      i18n.language
    );
    warningsArray.push({
      id: "safe-tube-info",
      text: safeTubeInfoTextInLang?.value || t("safeTubeInfo"),
    });
  }

  // handling warning
  const showCaseHandledWarning = caseHandler
    ? caseHandler.userId !== userData.userId
    : false;
  if (showCaseHandledWarning && currentUserIsOriolaUser) {
    warningsArray.push({
      id: "handling-warning",
      text: getHandledWarningText(),
    });
  }

  // version mismatch warning
  const showVersionMismatchWarning = caseHandler
    ? reclamationCase.version !== caseHandler.version
    : false;
  if (showVersionMismatchWarning) {
    warningsArray.push({
      id: "case-edited-warning",
      text: t("caseEditedWarning"),
    });
  }

  // sfmd
  const showSFMDWarningToPrincipalUser =
    reclamationCase.reasonIdentifier ===
      SuspectedProductDefectReasonIdentifiers.FALSIFIED_MEDICINE &&
    isPharmaceuticalCompany(userData);
  if (showSFMDWarningToPrincipalUser) {
    warningsArray.push({
      id: "sfmd-return-warning",
      text: t("sfmdWarningToPrincipal"),
    });
  }

  // Inform principal of SPD case converted to other case type
  const shouldInformConvertedSPDToPrincipal =
    reclamationCase.caseType === CaseType.SUSPECTED_PRODUCT_DEFECT &&
    reclamationCase.relatedCases &&
    !reclamationCase.sourceReclamationId;
  if (
    shouldInformConvertedSPDToPrincipal &&
    isPharmaceuticalCompany(userData)
  ) {
    warningsArray.push({
      id: "converted-case-information",
      text: t("convertedCaseInformation.toPrincipal"),
    });
  }

  if (warningsArray.length) {
    return (
      <Box mb={2}>
        {warningsArray.map((warning, index) => (
          <WarningMessage
            id={warning.id}
            key={warning.id}
            mt={index ? 1 : 0}
            text={warning.text}
            fullWidth
          />
        ))}
      </Box>
    );
  }
  return null;
}

CaseHeaderWarnings.propTypes = {
  reclamationCase: PropTypes.shape({
    caseType: PropTypes.string,
    suspectedInfectiousProduct: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    version: PropTypes.string,
    relatedCases: PropTypes.arrayOf(PropTypes.shape({})),
    sourceReclamationId: PropTypes.string,
    reasonIdentifier: PropTypes.string,
    modificationTime: PropTypes.string,
  }),
  caseHandler: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userId: PropTypes.string,
    version: PropTypes.string,
  }),
  userData: PropTypes.shape({
    userId: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
  fieldsConfig: PropTypes.shape({
    SuspectedProductDefectReasonIdentifiers: PropTypes.shape({}),
  }),
};

CaseHeaderWarnings.defaultProps = {
  fieldsConfig: {},
  reclamationCase: {
    caseType: "",
    suspectedInfectiousProduct: false,
    items: [],
    relatedCases: [],
    sourceReclamationId: "",
    reasonIdentifier: "",
    modificationTime: "",
  },
  caseHandler: {},
  userData: {
    roles: [],
  },
};

export default CaseHeaderWarnings;
