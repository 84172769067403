/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../images";
import "./popup.css";

function GenericPopup({
  show,
  onClose,
  title,
  iconType,
  content,
  cancelTitle,
  confirmTitle,
  onCancel,
  onConfirm,
}) {
  const { t } = useTranslation();
  const ICONS = {
    camera: require("../../../images/camera.svg").default,
  };
  return (
    show && (
      <div className="generic-popup">
        <div className="backdrop" onClick={onClose} />
        <div className="generic-popup__content">
          <div className="generic-popup__content__header">
            {t(title)}
            <button type="button" onClick={onClose} aria-label="Close">
              <CloseIcon />
            </button>
          </div>
          <img src={ICONS[iconType]} alt="" />
          <p>{t(content)}</p>
          <div className="generic-popup__content__controls">
            <button type="button" onClick={onCancel}>
              {t(cancelTitle)}
            </button>
            <button type="button" onClick={onConfirm}>
              {t(confirmTitle)}
            </button>
          </div>
        </div>
      </div>
    )
  );
}

GenericPopup.propTypes = {
  iconType: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  confirmTitle: PropTypes.string,
  cancelTitle: PropTypes.string,
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

GenericPopup.defaultProps = {
  iconType: "",
  title: "",
  content: "",
  confirmTitle: "",
  cancelTitle: "",
  onCancel: null,
  onConfirm: null,
  onClose: null,
  show: false,
};

export default GenericPopup;
