import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Box, Typography, Checkbox } from "@material-ui/core";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
import DateAndEditor from "../dateAndEditor";
import { getValidationErrorMessage } from "../../../utils/validations/validations";

const useStyles = makeStyles(theme => ({
  checkbox: {
    color: OriolaColors.secondaryNavyBlue,
    "&.Mui-checked": {
      color: OriolaColors.secondaryNavyBlue,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    padding: 0,
  },
  checkboxError: {
    color: OriolaColors.errorRed,
  },
  helperText: {
    ...theme.typography.button,
    textTransform: "none",
    color: OriolaColors.errorRed,
    marginTop: theme.spacing(1),
  },
}));

function CheckboxText({
  id,
  checked,
  validationError,
  disabled,
  text,
  modificationData,
  onChange,
  dateAndEditorId,
  user,
  ...containerProps
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  let textColor = OriolaColors.secondaryNavyBlue;
  if (validationError != null) {
    textColor = OriolaColors.errorRed;
  } else if (disabled) {
    textColor = OriolaColors.secondaryDarkGray;
  }

  return (
    <Box id={id} {...containerProps}>
      <Box display="flex" alignItems="flex-start">
        <Checkbox
          className={`${classes.checkbox} ${
            validationError != null ? classes.checkboxError : null
          }`}
          disabled={disabled}
          checked={checked}
          size="small"
          value={checked}
          onChange={onChange}
        />
        <Box mx={1} py={0.4}>
          <Typography
            className={classes.text14}
            style={{
              color: textColor,
            }}
          >
            {text}
          </Typography>
          {checked && (
            <DateAndEditor
              id={dateAndEditorId}
              modificationData={modificationData}
              user={user}
            />
          )}
        </Box>
      </Box>
      {validationError != null && (
        <Typography className={classes.helperText}>
          {getValidationErrorMessage(validationError, t)}
        </Typography>
      )}
    </Box>
  );
}

CheckboxText.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  validationError: PropTypes.shape({}),
  disabled: PropTypes.bool,
  text: PropTypes.string,
  modificationData: PropTypes.shape({}),
  onChange: PropTypes.func,
  dateAndEditorId: PropTypes.string,
  user: PropTypes.shape({}),
};

CheckboxText.defaultProps = {
  id: null,
  checked: false,
  validationError: null,
  disabled: false,
  text: "",
  modificationData: null,
  onChange: null,
  dateAndEditorId: null,
  user: null,
};

export default CheckboxText;
