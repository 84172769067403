import { addNotification, updateTranslations } from "../../../redux";
import {
  handleOptimisticLockError,
  isOptimisticLockError,
} from "../../../utils/optimisticLock/optimisticLock";
import { TranslateField } from "../translateFieldDialog/translateFieldDialog";

const UPDATE_SUCCESS = "SUCCESS";

const doHandleUpdateResult = (dispatch, t, result) => {
  if (result.status === UPDATE_SUCCESS) {
    dispatch(
      addNotification({
        title: t("translate.traslationSendSuccessTitle"),
        primaryText: t("translate.traslationSendSuccessDescription"),
      })
    );
  } else if (isOptimisticLockError(result)) {
    handleOptimisticLockError(dispatch, t);
  } else {
    dispatch(
      addNotification({
        title: t("translate.traslationSendErrorTitle"),
        primaryText: t("translate.traslationSendErrorDescription"),
        type: "error",
      })
    );
  }
};

const handleTranslationUpdate = (
  dispatch,
  t,
  reclamationCase,
  fieldType,
  text
) => {
  const { translations } = reclamationCase;

  if (fieldType === TranslateField.ADVERSE_REACTION) {
    const suspectedAdverseReaction = { description: text };
    dispatch(
      updateTranslations({
        ...reclamationCase,
        translations: { ...translations, suspectedAdverseReaction },
      })
    ).then(result => doHandleUpdateResult(dispatch, t, result));
  } else if (fieldType === TranslateField.DESCRIPTION_OF_DEFECT) {
    dispatch(
      updateTranslations({
        ...reclamationCase,
        translations: { ...translations, caseDescription: text },
      })
    ).then(result => doHandleUpdateResult(dispatch, t, result));
  } else if (fieldType === TranslateField.IS_CUSTOMER_COMPENSATED_DESCRIPTION) {
    const isCustomerCompensated = {
      description: text,
    };
    dispatch(
      updateTranslations({
        ...reclamationCase,
        translations: { ...translations, isCustomerCompensated },
      })
    ).then(result => doHandleUpdateResult(dispatch, t, result));
  }
};

export default handleTranslationUpdate;
