import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormHelperText } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import { Dropdown } from "@oriola-origo/origo-ui-core";
import PropTypes from "prop-types";
import { StatefullTextField, radioButton } from "../../../common";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";

const AGE_MAX = 150; // No-one will ever live above 150

function AgeSelection({
  id,
  classes,
  ageGroup,
  ageYears,
  ageUnknown,
  onDataChange,
  ageError,
  ageGroupOptions,
  disabled,
}) {
  const { t } = useTranslation();
  const AgeInputMethods = Object.freeze({
    AGE_YEARS: "AGE_YEARS",
    AGE_GROUP: "AGE_GROUP",
    AGE_UNKNOWN: "AGE_UNKNOWN",
  });
  const [ageInputMethod, setAgeInputMethod] = useState("");
  useEffect(() => {
    if (ageGroup) {
      setAgeInputMethod(AgeInputMethods.AGE_GROUP);
    } else if (ageYears || ageYears === 0) {
      setAgeInputMethod(AgeInputMethods.AGE_YEARS);
    } else if (ageUnknown) {
      setAgeInputMethod(AgeInputMethods.AGE_UNKNOWN);
    }
  }, [ageGroup, ageYears, ageUnknown, AgeInputMethods]);

  const handleAgeInputMethodChange = value => {
    setAgeInputMethod(value);
    if (value === AgeInputMethods.AGE_UNKNOWN) {
      onDataChange({ ageUnknown: true });
    } else {
      onDataChange({ ageGroup: undefined, ageYears: undefined });
    }
  };
  const handleAgeGroupSelected = value => {
    onDataChange({ ageGroup: value });
  };

  const handleAgeYearsChange = value => {
    onDataChange({ ageYears: value });
  };

  const renderAgeGroupInput = () => (
    <Dropdown
      id={`${id}-group`}
      data-error-name="ageGroup"
      error={!!ageError}
      className={classes.dropdown}
      value={ageGroup}
      emptySelectionLabel={t("select")}
      onFormatOptionLabel={x => t(`ageGroup.${x}`)}
      options={ageGroupOptions}
      onSelectOption={handleAgeGroupSelected}
      disabled={disabled}
    />
  );

  const renderAgeYearsInput = () => (
    <StatefullTextField
      id={`${id}-years`}
      error={!!ageError}
      className={classes.inputField}
      initialValue={ageYears}
      variant="outlined"
      type="number"
      margin="dense"
      inputProps={{ min: 0, max: AGE_MAX }}
      size="small"
      onChange={handleAgeYearsChange}
      disabled={disabled}
    />
  );

  const renderAgeInput = () => (
    <div className={classes.rowContainer}>
      <div className={classes.inputLabelContainer}>
        <Typography className={classes.inputLabel} variant="subtitle2" noWrap>
          {t(`caseInformation.ageInputMethod.${ageInputMethod}`)}
        </Typography>
      </div>
      {ageInputMethod === AgeInputMethods.AGE_GROUP && renderAgeGroupInput()}
      {ageInputMethod === AgeInputMethods.AGE_YEARS && renderAgeYearsInput()}
    </div>
  );

  const renderAgeSelection = () => (
    <>
      <div className={classes.rowContainer} data-error-name="age">
        <div className={classes.inputLabelContainer}>
          <Typography className={classes.inputLabel} variant="subtitle2">
            {t("caseInformation.ageInfo")}
          </Typography>
        </div>
        <RadioGroup
          id={`${id}-input-method`}
          row
          name="ageInputMethod"
          value={ageInputMethod || null}
          onChange={event => handleAgeInputMethodChange(event.target.value)}
        >
          {Object.values(AgeInputMethods).map(value =>
            radioButton(
              value,
              t(`caseInformation.ageInputMethod.${value}`),
              value,
              disabled
            )
          )}
        </RadioGroup>
      </div>
      {ageInputMethod &&
        ageInputMethod !== AgeInputMethods.AGE_UNKNOWN &&
        renderAgeInput()}
      <FormHelperText name="ageError" className={classes.errorText}>
        {getValidationErrorMessage(ageError, t)}
      </FormHelperText>
    </>
  );

  return renderAgeSelection();
}

AgeSelection.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.shape({}),
  ageGroup: PropTypes.string,
  ageYears: PropTypes.number,
  ageUnknown: PropTypes.bool,
  onDataChange: PropTypes.func,
  ageError: PropTypes.string,
  ageGroupOptions: PropTypes.shape([]),
  disabled: PropTypes.bool,
};

export default AgeSelection;
