import React, { useState } from "react";
import Dialog from "@oriola-origo/core/lib/Dialog";
import DialogContent from "@oriola-origo/core/lib/DialogContent";
import DialogActions from "@oriola-origo/core/lib/DialogActions";
import DialogTitle from "@oriola-origo/core/lib/DialogTitle";
import Button from "@oriola-origo/core/lib/Button";
import Typography from "@oriola-origo/core/lib/Typography";
import Box from "@oriola-origo/core/lib/Box";
import TextField from "@oriola-origo/core/lib/TextField";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import { styled } from "@oriola-origo/core/lib/styles";
import { useTranslation } from "react-i18next";

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const StyledDialogText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(3),
}));

interface MissingProductDialogProps {
  open: boolean;
  handleCloseDialog: () => void;
  handleSubmit: (product: {
    materialId?: string;
    vnr?: string;
    productName?: string;
  }) => void;
  switchProductComponent: (value: boolean) => void;
}

function MissingProductDialog({
  open,
  handleCloseDialog,
  handleSubmit,
  switchProductComponent,
}: Readonly<MissingProductDialogProps>) {
  const { t } = useTranslation();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [productInDialog, setProductInDialog] = useState({});

  const clearStateAndCallCloseHandler = () => {
    setProductInDialog({});
    setSubmitDisabled(true);
    handleCloseDialog();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCloseDialog = (_: any, reason: string) => {
    if (reason !== "backdropClick") {
      clearStateAndCallCloseHandler();
      switchProductComponent(false);
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} id="missing-product-dialog">
      <DialogTitle
        onCloseButtonClick={() => {
          clearStateAndCallCloseHandler();
          switchProductComponent(false);
        }}
      >
        {t("addMissingProduct.dialogTitle")}
      </DialogTitle>
      <DialogContent sx={{ maxWidth: "800px", minWidth: "400px" }}>
        <Box display="flex" justifyContent="center" mb={4}>
          <FontIcon sx={{ fontSize: 120 }}>playlist_add</FontIcon>
        </Box>
        <StyledDialogText>{t("addMissingProduct.dialogText")}</StyledDialogText>
        <Box display="flex" sx={{ flexDirection: "column" }}>
          <StyledTextField
            id="dialog-product-number"
            label={t("addMissingProduct.productNumber")}
            autoComplete="off"
            onChange={e => {
              setProductInDialog({
                ...productInDialog,
                materialId: e.target.value,
              });
            }}
          />
          <StyledTextField
            id="dialog-product-vnr"
            label={t("addMissingProduct.productVNROrMSI")}
            autoComplete="off"
            onChange={e => {
              setProductInDialog({
                ...productInDialog,
                vnr: e.target.value,
              });
            }}
          />
          <StyledTextField
            id="dialog-product-name"
            label={t("addMissingProduct.productName")}
            multiline
            required
            onChange={e => {
              if (!e.target.value) {
                setSubmitDisabled(true);
              } else {
                setProductInDialog({
                  ...productInDialog,
                  productName: e.target.value,
                });
                setSubmitDisabled(false);
              }
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            clearStateAndCallCloseHandler();
            switchProductComponent(false);
          }}
        >
          {t("cancel")}
        </Button>
        <Button
          id="dialog-submit-button"
          disabled={submitDisabled}
          variant="contained"
          color="secondary"
          onClick={() => {
            handleSubmit(productInDialog);
            clearStateAndCallCloseHandler();
          }}
        >
          {t("addMissingProduct.dialogSubmit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MissingProductDialog;
