import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Box,
  Select,
  InputLabel,
  makeStyles,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { Button } from "@oriola-origo/origo-ui-core";
import { styled } from "@material-ui/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "../../../../images";
import { StyledFormControl } from "../selectMenu";
import { getAllProfitCenters, getAllSuppliers } from "../../../../redux";
import SelectedFilters from "./selectedFilters";
import renderMatchedItemsList from "./matchedItemsList";
import renderSelectedItems from "./selectedItems";

const CssTextField = styled(TextField)({
  "& .MuiFilledInput-root": {
    backgroundColor: "#F4F3F2",
    borderRadius: "50px",
  },
  "& .MuiInputLabel-filled.MuiInputLabel-marginDense": {
    fontSize: "12px",
  },
  "& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after": {
    borderBottom: "none",
  },
  "& input": {
    paddingLeft: "20px",
  },
  "& .MuiFormLabel-root": {
    paddingLeft: "10px",
  },
});

const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
};

const useStyles = makeStyles(theme => ({
  button: {
    width: "50%",
    height: "40px",
    borderRadius: 0,
  },
  title: {
    color: "#757070",
    marginBottom: theme.spacing(1),
    textTransform: "uppercase",
  },
  listItem: {
    lineHeight: "150%",
    cursor: "pointer",
  },
  selectedItem: {
    display: "flex",
    borderRadius: "30px",
    marginBottom: theme.spacing(0.4),
    lineHeight: "100%",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: "25px",
    marginRight: theme.spacing(0.5),
    "& p": {
      lineHeight: "25px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "400px",
      overflow: "hidden",
    },
  },
  selectedProfitCenter: {
    background: theme.palette.text.primary,
    color: "white",
  },
  selectedSupplier: {
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.text.primary}`,
  },
  selectedItemFilter: {
    borderRadius: "30px",
    lineHeight: "100%",
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  selectedProfitCenterFilter: {
    background: theme.palette.text.primary,
    color: "white",
  },
  selectedSupplierFilter: {
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.text.primary}`,
  },
}));

function PrincipalAndSupplierFilter({
  currentProfitCenterFilters,
  currentSupplierFilters,
  onFiltersSelected,
  ...containerProps
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedProfitCenters, setSelectedProfitCenters] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [matchedProfitCenters, setMatchedProfitCenters] = useState([]);
  const [matchedSuppliers, setMatchedSuppliers] = useState([]);
  const [selectedProfitCenterFilter, setSelectedProfitCentersFilter] = useState(
    []
  );
  const [selectedSupplierFilter, setSelectedSupplierFilter] = useState([]);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const { allProfitCenters, allSuppliers } = useSelector(
    state => state.profitCenters
  );

  useEffect(() => {
    if (allProfitCenters.length === 0) {
      dispatch(getAllProfitCenters());
    }
    if (allSuppliers.length === 0) {
      dispatch(getAllSuppliers());
    }
  }, [allProfitCenters, allSuppliers, dispatch]);

  const handleCancel = e => {
    setOpen(false);
    e.stopPropagation();
    setSelectedProfitCenters([...selectedProfitCenterFilter]);
    setSelectedSuppliers([...selectedSupplierFilter]);
  };

  const onSearchTextChanged = text => {
    setSearchText(text);
  };

  useEffect(() => {
    const getMatchedItems = (items, selectedItems) =>
      items.filter(item => {
        // eslint-disable-next-line no-restricted-syntax
        for (const selectedItem of selectedItems) {
          if (selectedItem.number === item.number) {
            return false;
          }
        }
        return (
          item.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
          item.number.indexOf(searchText) > -1
        );
      });
    if (searchText == null || searchText.length === 0) {
      setMatchedProfitCenters([]);
      setMatchedSuppliers([]);
    } else {
      setMatchedProfitCenters(
        getMatchedItems(allProfitCenters, selectedProfitCenters)
      );
      setMatchedSuppliers(getMatchedItems(allSuppliers, selectedSuppliers));
    }
  }, [
    allProfitCenters,
    searchText,
    selectedProfitCenters,
    allSuppliers,
    selectedSuppliers,
  ]);

  useEffect(() => {
    let currentProfitCenterFilterItems = [];
    let currentSupplierFilterItems = [];
    if (
      currentProfitCenterFilters &&
      allProfitCenters &&
      allProfitCenters.length > 0
    ) {
      currentProfitCenterFilterItems = allProfitCenters.filter(
        x => currentProfitCenterFilters.indexOf(x.number) > -1
      );
    }

    if (currentSupplierFilters && allSuppliers && allSuppliers.length > 0) {
      currentSupplierFilterItems = allSuppliers.filter(
        x => currentSupplierFilters.indexOf(x.number) > -1
      );
    }
    setSelectedProfitCenters(currentProfitCenterFilterItems);
    setSelectedProfitCentersFilter(currentProfitCenterFilterItems);
    setSelectedSuppliers(currentSupplierFilterItems);
    setSelectedSupplierFilter(currentSupplierFilterItems);
  }, [
    currentProfitCenterFilters,
    currentSupplierFilters,
    allProfitCenters,
    allSuppliers,
  ]);

  const getNumberStringOfItems = items => items.map(x => x.number).join(",");

  const handleOk = e => {
    setOpen(false);
    e.stopPropagation();
    setSelectedProfitCentersFilter(selectedProfitCenters);
    setSelectedSupplierFilter(selectedSuppliers);
    onFiltersSelected(
      getNumberStringOfItems(selectedProfitCenters),
      getNumberStringOfItems(selectedSuppliers)
    );
  };

  const onProfitCenterSelect = profitCenter => {
    setSearchText("");
    setSelectedProfitCenters([...selectedProfitCenters, profitCenter]);
  };

  const onSupplierSelect = supplier => {
    setSearchText("");
    setSelectedSuppliers([...selectedSuppliers, supplier]);
  };

  const removeSelectedProfitCenter = profitCenter => {
    setSelectedProfitCenters(
      selectedProfitCenters.filter(pc => pc.number !== profitCenter.number)
    );
  };

  const removeSelectedSupplier = supplier => {
    setSelectedSuppliers(
      selectedSuppliers.filter(pc => pc.number !== supplier.number)
    );
  };

  const renderButtons = () => (
    <Box mt={1} mb={-1} display="flex" flexDirection="row" alignItems="center">
      <Button
        className={classes.button}
        variant="secondary"
        onClick={handleCancel}
      >
        {t("cancel")}
      </Button>
      <Button className={classes.button} variant="primary" onClick={handleOk}>
        {t("ok")}
      </Button>
    </Box>
  );

  const renderSearchBar = () => (
    <Box mt={2}>
      <CssTextField
        label={t("searchPrincipalOrSupplier")}
        inputStyle={{ backgroundColor: "red" }}
        size="small"
        variant="filled"
        fullWidth
        value={searchText}
        onChange={e => onSearchTextChanged(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {searchText !== "" && (
                <IconButton
                  size="small"
                  onClick={() => onSearchTextChanged("")}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );

  return (
    <Box {...containerProps} width={400}>
      <StyledFormControl
        variant="outlined"
        size="small"
        fullWidth
        focused={open}
      >
        <InputLabel>{t("pharmaceuticalCompany")}</InputLabel>
        <Select
          onOpen={() => {
            setOpen(true);
          }}
          open={open}
          MenuProps={MenuProps}
          sx={{ height: "500px" }}
          label={t("pharmaceuticalCompany")}
          multiple
          value={[...selectedProfitCenterFilter, ...selectedSupplierFilter]}
          renderValue={items => (
            <SelectedFilters items={items} classes={classes} />
          )}
        >
          <Box width={520} tabIndex={null}>
            <Box pl={2} pr={2} height={400} sx={{ overflowY: "scroll" }}>
              {renderSearchBar()}
              <Box>
                {searchText == null || searchText.length === 0
                  ? renderSelectedItems({
                      selectedProfitCenters,
                      selectedSuppliers,
                      removeSelectedProfitCenter,
                      removeSelectedSupplier,
                      classes,
                    })
                  : renderMatchedItemsList({
                      matchedProfitCenters,
                      matchedSuppliers,
                      classes,
                      onProfitCenterSelect,
                      onSupplierSelect,
                      t,
                    })}
              </Box>
            </Box>
            {renderButtons()}
          </Box>
        </Select>
      </StyledFormControl>
    </Box>
  );
}

PrincipalAndSupplierFilter.propTypes = {
  onFiltersSelected: PropTypes.func,
  currentProfitCenterFilters: PropTypes.string,
  currentSupplierFilters: PropTypes.string,
};

PrincipalAndSupplierFilter.defaultProps = {
  onFiltersSelected: null,
  currentProfitCenterFilters: "",
  currentSupplierFilters: "",
};

export default PrincipalAndSupplierFilter;
