import { addNotification, saveDraft } from "../../../redux";
import {
  handleOptimisticLockError,
  isOptimisticLockError,
} from "../../../utils/optimisticLock/optimisticLock";

import {
  isUpdateAttachmentsError,
  handleUpdateAttachmentsError,
} from "../../../utils/updateAttachments/updateAttachments";

const saveDraftCase = (t, dispatch, reclamationCase, onSaved) => {
  dispatch(saveDraft(reclamationCase)).then(result => {
    if (result && result.status === "SUCCESS") {
      dispatch(
        addNotification({
          title: t("saveDraftSuccessfully.title"),
          primaryText: t("saveDraftSuccessfully.description"),
        })
      );

      if (onSaved) {
        onSaved(result.data);
      }
    } else if (result && result.status === "SENDING") {
      dispatch(
        addNotification({
          title: t("duplicateSavingCase"),
        })
      );
    } else if (isOptimisticLockError(result)) {
      handleOptimisticLockError(dispatch, t);
    } else if (isUpdateAttachmentsError(result)) {
      handleUpdateAttachmentsError(dispatch, t);
    } else {
      dispatch(
        addNotification({
          title: t("saveDraftFailed.title"),
          primaryText: t("saveDraftFailed.description"),
          type: "error",
        })
      );
    }
  });
};
export default saveDraftCase;
