import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  TextField,
  IconButton,
  Box,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import TitleComponent from "../titleComponent/titleComponent";
import { Copy } from "../../../images";

function TitleInput({
  title,
  error,
  onChange,
  value,
  disabled,
  copyButtonDisabled,
  id,
  children,
  inputClassName,
  rows,
  placeholder,
  enableCopyButton,
  label,
  helperText,
  InputLabelProps,
  ...containerProps
}) {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const multiline = rows !== 1;
  return (
    <TitleComponent
      title={title}
      style={{ marginTop: "0px" }}
      {...containerProps}
    >
      <Box position="relative">
        <TextField
          style={{ width: "100%", margin: 0 }}
          id={id}
          error={error}
          value={value}
          variant="outlined"
          margin="dense"
          disabled={disabled}
          onChange={e => onChange(e.target.value)}
          className={inputClassName}
          rows={rows}
          multiline={multiline}
          placeholder={placeholder}
          label={label}
          helperText={helperText}
          InputLabelProps={InputLabelProps}
          InputProps={{
            style: {
              paddingRight: multiline && enableCopyButton ? 25 : 0,
            },
            endAdornment: (
              <InputAdornment position="end">
                {enableCopyButton === true && (
                  <Box
                    height="100%"
                    position="absolute"
                    right={0}
                    bottom={0}
                    display="flex"
                    alignItems={rows === 1 ? "center" : "flex-end"}
                    pr={0.5}
                    pb={rows === 1 ? 0 : 0.5}
                  >
                    <Tooltip
                      title={copied ? t("copied") : t("copy")}
                      placement="top"
                    >
                      <IconButton
                        size="small"
                        disabled={copyButtonDisabled}
                        onClick={() => {
                          navigator.clipboard.writeText(value);
                          setCopied(true);
                          setTimeout(() => {
                            setCopied(false);
                          }, 1000);
                        }}
                      >
                        <Copy />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {children}
    </TitleComponent>
  );
}

TitleInput.propTypes = {
  title: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  copyButtonDisabled: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.node,
  inputClassName: PropTypes.string,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  enableCopyButton: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  InputLabelProps: PropTypes.shape({}),
  maxWidth: PropTypes.string,
};

TitleInput.defaultProps = {
  maxWidth: "100%",
  rows: 1,
  disabled: false,
  enableCopyButton: false,
  copyButtonDisabled: false,
  InputLabelProps: null,
  inputClassName: null,
  title: null,
  error: false,
  onChange: null,
  value: null,
  id: null,
  placeholder: null,
  label: null,
  helperText: null,
  children: null,
};

export default TitleInput;
