/* eslint-disable default-param-last */
import { RestService } from "../../components/common";
import { ensureTrailingSlash } from "../../utils/url/url";
import mapProductsFromApiSearchData from "../../utils/productUtil/productDataMapper";

export const Product = Object.freeze({
  FETCH_STARTED: "FETCH_PRODUCTS_STARTED",
  FETCH_FINISHED: "FETCH_PRODUCTS_FINISHED",
  FETCH_ERROR: "FETCH_PRODUCTS_ERROR",
  CLEAR: "CLEAR_PRODUCTS",
});

// -- ACTIONS

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_RECLAMATION_SERVICE);
const productSearchApi = process.env.REACT_APP_ECOM_RECLAMATION_SEARCH_API;

export const fetchProducts =
  (startIndex, stopIndex, queryText) => async (dispatch, getState) => {
    const pageSize = stopIndex - startIndex + 1;
    const query = queryText || "";

    const path = `${baseUrl}${productSearchApi}?query=${query}&pageSize=${pageSize}&startIndex=${startIndex}`;

    try {
      // start
      dispatch({ type: Product.FETCH_STARTED });

      const result = await RestService.get(path);

      const overallProductCount = result.overallCount;
      const hits = mapProductsFromApiSearchData(result.hits);
      let foundProducts = hits;
      // append if not starting from zero index
      if (startIndex > 0) {
        // get existing and append results
        const products = getState().product.products.slice(0);
        foundProducts = [...products, ...hits];
      }

      dispatch({
        type: Product.FETCH_FINISHED,
        payload: {
          products: foundProducts,
          overallProductCount,
        },
      });

      return foundProducts;
    } catch (error) {
      // TODO: error handling
      dispatch({ type: Product.FETCH_ERROR, payload: error });
      return null;
    }
  };

export const clearProducts = () => dispatch => {
  dispatch({
    type: Product.CLEAR,
  });
};

// -- REDUCER --

const INIT_STATE = {
  // list of products
  products: [],
  fetching: false,
  fetchError: null,
};

export const productsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Product.FETCH_STARTED:
      return { ...state, fetching: true, fetchError: null };
    case Product.FETCH_FINISHED: {
      const { products, overallProductCount } = action.payload;
      return { ...state, fetching: false, products, overallProductCount };
    }
    case Product.FETCH_ERROR:
      return { ...state, fetching: false, fetchError: action.payload };
    case Product.CLEAR:
      return { ...state, products: [] };
    default:
      return state;
  }
};
