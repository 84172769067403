import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Hidden } from "@material-ui/core";
import { OrigoLink } from "@oriola-origo/origo-ui-core";
// eslint-disable-next-line import/no-cycle
import { FieldTitle } from "..";
import { StatefullTextField } from "../../../common";
import {
  getValidationErrorMessage,
  FieldErrors,
} from "../../../../utils/validations/validations";
import { EcomUrls } from "../../../../utils/navigation/navigation";

function OrderNumber({
  itemData,
  classes,
  id,
  config,
  error,
  updateItem,
  updateItemValidationError,
  disabled,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openLinkInANewTab = () => {
    window.open(EcomUrls.OrdersInvoicesHistory, "_blank").focus();
  };

  return (
    <div className={classes.rowContainer}>
      <FieldTitle
        title={t("caseItemFields.orderNumber")}
        optional={config.optional}
      />
      <div className={classes.controlContainer}>
        <StatefullTextField
          error={!!error}
          helperText={getValidationErrorMessage(error, t, {
            maxLength: config.maxLength,
          })}
          initialValue={itemData.orderNumber || ""}
          variant="outlined"
          placeholder={t("caseInformation.orderNumberPlaceholder")}
          margin="dense"
          width="300"
          onChange={orderNumber => {
            dispatch(
              updateItemValidationError(itemData.uuid, { orderNumber: "" })
            );
            if (orderNumber.length > config.maxLength) {
              dispatch(
                updateItemValidationError(itemData.uuid, {
                  orderNumber: FieldErrors.OVER_LENGTH,
                })
              );
            } else {
              dispatch(
                updateItem({
                  ...itemData,
                  orderNumber,
                })
              );
            }
          }}
          id={`${id}-order-number`}
          disabled={disabled}
        />
        <Hidden smDown>
          <OrigoLink
            onClick={openLinkInANewTab}
            {...t("caseInformation.openOrderHistoryLink", {
              returnObjects: true,
            })}
          />
        </Hidden>
      </div>
    </div>
  );
}

OrderNumber.propTypes = {
  itemData: PropTypes.shape({
    orderNumber: PropTypes.string,
    uuid: PropTypes.string,
  }),
  classes: PropTypes.shape({
    rowContainer: PropTypes.string,
    controlContainer: PropTypes.string,
  }),
  id: PropTypes.string,
  config: PropTypes.shape({
    optional: PropTypes.bool,
    maxLength: PropTypes.number,
  }),
  error: PropTypes.string,
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
  disabled: PropTypes.bool,
};

OrderNumber.defaultProps = {
  itemData: {},
  classes: {},
  id: "",
  config: {},
  error: "",
  updateItem: null,
  updateItemValidationError: null,
  disabled: false,
};

export default OrderNumber;
