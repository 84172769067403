import React from "react";
import PropTypes from "prop-types";
import Row from "./row";

function ConditionalRow({ condition, rowTitleKey, rowValue }) {
  if (condition) {
    return <Row rowLeft={[rowTitleKey]} rowRight={[rowValue]} />;
  }
  return null;
}

ConditionalRow.propTypes = {
  condition: PropTypes.bool,
  rowTitleKey: PropTypes.string,
  rowValue: PropTypes.string,
};

ConditionalRow.defaultProps = {
  condition: false,
  rowTitleKey: null,
  rowValue: null,
};

export default ConditionalRow;
