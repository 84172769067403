import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Typography, Box, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DetailRow from "./detailRow";
import { getFormattedDate } from "../../../utils/date/date";
import { CaseType } from "../../../constants/caseType";
import { RejectionReasons } from "../compensation/rejectionReason";

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
  },
  divider: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    borderBottomColor: "#e7e7e7",
  },
}));

function DefaultHandlingData({ handlingData, isOriolaUser }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { caseType, productReturn } = useSelector(state => state.case.case);
  const isReturningProducts = productReturn?.returnValue;
  const yesNoDash = value => {
    if (value == null) {
      return "-";
    }
    return value === true ? t("yes") : t("no");
  };

  const shouldDisplay = () => {
    if (isOriolaUser) {
      return caseType === CaseType.PRODUCT_DELIVERY
        ? isReturningProducts
        : true;
    }
    return caseType !== CaseType.PRODUCT_DELIVERY;
  };

  const renderProductReturns = () => (
    <Box>
      {shouldDisplay() && (
        <Typography variant="subtitle2" className={classes.title}>
          {t("productReturns.productReturns")}
        </Typography>
      )}
      {caseType === CaseType.DAMAGED_PRODUCT && (
        <DetailRow
          keyName={t("productReturns.productReturnRequired")}
          value={yesNoDash(handlingData.productReturnRequired)}
        />
      )}
      {caseType === CaseType.DAMAGED_PRODUCT && (
        <DetailRow
          keyName={t("productReturns.productsHaveBeenReturnedToOriola")}
          value={yesNoDash(handlingData.productsHaveBeenReturned)}
        />
      )}
      {caseType === CaseType.PRODUCT_DELIVERY && shouldDisplay() && (
        <DetailRow
          keyName={t("productReturns.productsHaveBeenReturnedToStock")}
          value={yesNoDash(handlingData.productReturnToSellable)}
        />
      )}
    </Box>
  );

  const renderCompensationRejected = () => {
    const reasonValue = handlingData.compensationRejectionReason;
    let reasonTxt = "-";
    if (reasonValue != null) {
      reasonTxt = t(`compensationRejectReasons.${reasonValue}`);
      if (reasonValue === RejectionReasons.Other) {
        const description =
          handlingData.compensationRejectionDescription || "-";
        reasonTxt = `${reasonTxt}: ${description}`;
      }
    }

    return <DetailRow keyName={t("reasonForRejection")} value={reasonTxt} />;
  };

  const renderCompensations = compensations =>
    compensations.map((compensation, index) => (
      <Box key={compensation.compensationNumber}>
        <DetailRow
          keyName={t("compensationNumber")}
          value={compensation.compensationNumber}
        />
        {isOriolaUser && (
          <DetailRow
            keyName={t("identifierCode")}
            value={compensation.identifierCode}
          />
        )}
        <DetailRow
          keyName={t("compensationDate")}
          value={getFormattedDate(compensation.compensationDate)}
        />
        {isOriolaUser && (
          <DetailRow keyName={t("handler")} value={compensation.handler} />
        )}
        {index !== compensations.length - 1 && (
          <Divider className={classes.divider} />
        )}
      </Box>
    ));

  const renderHandlingInformation = () => (
    <Box>
      <Typography variant="subtitle2" className={classes.title}>
        {t("handlingInformation.handlingInformation")}
      </Typography>
      <DetailRow
        keyName={t("handlingInformation.title")}
        value={handlingData.handlingInformation || "-"}
      />
      <DetailRow
        keyName={t("handlingInformation.handlerName")}
        value={handlingData.caseHandler.userName}
      />
    </Box>
  );

  return (
    <Box my={3}>
      {renderProductReturns()}
      <Box>
        <Typography variant="subtitle2" className={classes.title}>
          {t("compensation.compensation")}
        </Typography>
        <DetailRow
          keyName={t("compensationIsPermitted")}
          value={yesNoDash(handlingData.compensationPermitted)}
        />
        {handlingData.compensationPermitted
          ? renderCompensations(handlingData.compensations)
          : renderCompensationRejected()}
      </Box>
      {isOriolaUser && renderHandlingInformation()}
    </Box>
  );
}

DefaultHandlingData.propTypes = {
  handlingData: PropTypes.shape({
    productReturnRequired: PropTypes.bool,
    productsHaveBeenReturned: PropTypes.bool,
    productReturnToSellable: PropTypes.bool,
    compensationRejectionReason: PropTypes.string,
    compensationRejectionDescription: PropTypes.string,
    handlingInformation: PropTypes.string,
    caseHandler: PropTypes.shape({
      userName: PropTypes.string,
    }),
    compensationPermitted: PropTypes.bool,
    compensations: PropTypes.shape({}),
  }),
  isOriolaUser: PropTypes.bool,
};

DefaultHandlingData.defaultProps = {
  handlingData: {},
  isOriolaUser: false,
};

export default DefaultHandlingData;
