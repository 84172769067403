/* eslint-disable @typescript-eslint/no-explicit-any */
import { saveEditingCase } from "../../editCase/caseActions";
import { modifyMessage } from "../../../redux/message/message";
import { addNotification } from "../../../redux";

export const removeAttachmentFromCase = (
  attachmentId: string,
  reclamationCase: any,
  requireMoreInfoFromCustomer: any,
  t: any,
  dispatch: any
) => {
  const modifiedAttachments = reclamationCase.attachments.filter(
    (attachment: any) => attachment.id !== attachmentId
  );

  saveEditingCase({
    dispatch,
    reclamationCase: {
      ...reclamationCase,
      attachments: modifiedAttachments,
    },
    requireMoreInfoFromCustomer,
    t,
    onSaved: () => {},
  });
};
export const removeAttachmentFromMessage = (
  deleteAttachmentId: string,
  messages: any,
  reclamationCase: any,
  t: any,
  dispatch: any
) => {
  const targetMessage = messages.filter((message: any) =>
    message.attachments?.some(
      (attachment: any) => attachment.id === deleteAttachmentId
    )
  )[0];
  const modifiedAttachments = targetMessage.attachments.filter(
    (attachment: any) => attachment.id !== deleteAttachmentId
  );

  dispatch(
    modifyMessage(
      reclamationCase.reclamationId,
      targetMessage.id,
      targetMessage.read,
      modifiedAttachments
    )
  );
  dispatch(
    (addNotification as any)({
      title: t("saveEditedCaseSuccessfully.title"),
      primaryText: t("saveEditedCaseSuccessfully.description"),
    })
  );
};
