/* eslint-disable */
import { AttachmentService } from "@oriola-origo/origo-common-client-lib";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
import { Tooltip, Typography, ButtonBase, Box } from "@material-ui/core";
import { isOfficeFile } from "../case/case";
import { ensureTrailingSlash } from "../url/url";

export const getAttachmentFileOpts = (
  attachment,
  openAttachmentFn,
  t,
  isDeleteMode = false
) => {
  const name = attachment.origFileName;

  // edit mode
  let highlightColor = "null";
  let rightButtonText = null;
  // normal?
  highlightColor = OriolaColors.LightGreen;
  rightButtonText = isDeleteMode ? t("delete") : t("openAttachment");

  const browserAgent = navigator.userAgent;
  const isFox = browserAgent?.indexOf("Firefox") != -1;

  return {
    name: (
      <ButtonBase
        style={{ width: isFox ? "-moz-available" : "-webkit-fill-available" }}
        onClick={() => openAttachmentFn()}
      >
        <Tooltip title={name}>
          <Box width="100%" display="flex">
            <Typography noWrap>{name}</Typography>
          </Box>
        </Tooltip>
      </ButtonBase>
    ),
    rightButtonText,
    highlightColor,
  };
};

export const downloadAttachment = async (
  attachmentId,
  reclamationId,
  contentType
) => {
  const baseUrl = ensureTrailingSlash(
    process.env.REACT_APP_RECLAMATION_SERVICE
  );
  const reclamationApi = process.env.REACT_APP_RECLAMATION_API;
  const attachmentService = new AttachmentService(
    `${baseUrl}${reclamationApi}`
  );
  const res = await attachmentService.getAttachments(
    reclamationId,
    attachmentId
  );
  let target = "_blank";
  if (/Edg/.test(navigator.userAgent) && isOfficeFile(contentType)) {
    target = "_self";
  }
  window.open(res.url, target);
};
