import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { OriolaColors, NavigationPane } from "@oriola-origo/origo-ui-core";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Paths } from "../../../utils/navigation/navigation";
import { NewCaseIcon, MyCasesIcon } from "../../../images";
import { getObjectFromUrlParams } from "../../../utils/url/url";

function LeftNavigationPane({
  loadingPanel,
  allowedAddNewCase,
  location,
  history,
}) {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState(Paths.NewCase);
  const { organizationId } = getObjectFromUrlParams(location.search);

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes("newCase")) {
      setSelectedId(Paths.NewCase);
    } else {
      setSelectedId(Paths.Cases);
    }
  }, [location]);

  const newCaseBtn = {
    id: Paths.NewCase,
    label: t("newCase"),
    renderIcon: selected =>
      selected ? <NewCaseIcon color={OriolaColors.White} /> : <NewCaseIcon />,
    props: { id: "new-case-button" },
  };
  const myCaseBtn = {
    id: Paths.Cases,
    label: t("myCases"),
    renderIcon: selected =>
      selected ? <MyCasesIcon color={OriolaColors.White} /> : <MyCasesIcon />,
    props: { id: "cases-button" },
  };
  const buttons = [myCaseBtn];

  if (allowedAddNewCase) {
    buttons.unshift(newCaseBtn);
  }

  const handleNavigationPaneButtonClick = id => {
    let historyEntry = id;
    if (id === Paths.Cases && organizationId) {
      historyEntry = `${id}?organizationId=${organizationId}`;
    }
    history.push(historyEntry);
  };

  return (
    <NavigationPane
      className="no-print"
      buttons={loadingPanel ? [] : buttons}
      onButtonClick={handleNavigationPaneButtonClick}
      selectedButtonId={selectedId}
    />
  );
}

LeftNavigationPane.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  loadingPanel: PropTypes.bool,
  allowedAddNewCase: PropTypes.bool,
};

LeftNavigationPane.defaultProps = {
  location: {},
  history: {},
  loadingPanel: false,
  allowedAddNewCase: false,
};

export default withRouter(LeftNavigationPane);
