/* eslint-disable default-param-last */
import { RestService } from "../../components/common";
import { ensureTrailingSlash } from "../../utils/url/url";
import {
  transformCustomer,
  customerComparator,
} from "../../utils/customer/customer";

export const Customer = Object.freeze({
  FETCH_STARTED: "CUSTOMER_FETCH_STARTED",
  FETCH_FINISHED: "CUSTOMER_FETCH_FINISHED",
  FETCH_ERROR: "CUSTOMER_FETCH_ERROR",
  UPDATE_CUSTOMERS: "CUSTOMER_UPDATE",
});

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_RECLAMATION_SERVICE);
const customerApi = ensureTrailingSlash(process.env.REACT_APP_CUSTOMER_API);

// -- ACTIONS

export const fetchCustomers = customerIds => async dispatch => {
  const path = `${baseUrl}${customerApi}?customerIds=${customerIds.join(",")}`;

  try {
    dispatch({
      type: Customer.FETCH_STARTED,
    });

    const customers = await RestService.get(path);

    dispatch({
      type: Customer.FETCH_FINISHED,
    });

    dispatch({
      type: Customer.UPDATE_CUSTOMERS,
      payload: customers,
    });

    return customers;
  } catch (error) {
    // TODO: error handling
    dispatch({ type: Customer.FETCH_ERROR, payload: error });
  }
  return null;
};

export const fetchCustomerById = customerId => async dispatch => {
  const path = `${baseUrl}${customerApi}?customerIds=${customerId}`;

  try {
    dispatch({ type: Customer.FETCH_STARTED });

    const customers = await RestService.get(path);

    dispatch({ type: Customer.FETCH_FINISHED });

    return customers.length === 1 ? customers[0] : null;
  } catch (error) {
    dispatch({ type: Customer.FETCH_ERROR, payload: error });
    return Promise.reject(error);
  }
};

// -- REDUCER --

const DEFAULT_STATE = {
  fetching: false,
  fetchError: null,
  customers: [],
};

export const customerReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Customer.FETCH_STARTED:
      return { ...state, fetching: true, fetchError: null };
    case Customer.FETCH_ERROR:
      return { ...state, fetching: false, fetchError: action.payload };
    case Customer.FETCH_FINISHED:
      return { ...state, fetching: false };
    case Customer.UPDATE_CUSTOMERS: {
      const customers = (action.payload || []).map(transformCustomer);
      customers.sort(customerComparator);
      return { ...state, customers };
    }
    default:
      return state;
  }
};
