import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NewMessageLabel from "./caseIcons/newMessageLabel";
import { isOriolaUser, isPharmaceuticalCompany } from "../auth/permission";

const useStyles = makeStyles(theme => ({
  messageCount: {
    color: theme.palette.text.primary,
    width: "100%",
    ...theme.typography.button,
    textTransform: "none",
  },
}));

function MessagesCell({ reclamationCase, userData, ...rest }) {
  const classes = useStyles();
  const {
    allMessagesCount,
    customerMessagesCount,
    customerUnreadMessagesCount,
    principalMessagesCount,
    principalUnreadMessagesCount,
    oriolaUnreadMessagesCount,
  } = reclamationCase;
  const currentUserIsOriolaUser = isOriolaUser(userData);
  const currentUserIsPrincipal = isPharmaceuticalCompany(userData);
  let messageCount = customerMessagesCount;
  let unreadMessageCount = customerUnreadMessagesCount;
  if (currentUserIsOriolaUser) {
    messageCount = allMessagesCount;
    unreadMessageCount = oriolaUnreadMessagesCount;
  } else if (currentUserIsPrincipal) {
    messageCount = principalMessagesCount;
    unreadMessageCount = principalUnreadMessagesCount;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" {...rest}>
      <Box mb={0.5}>
        <Typography className={classes.messageCount}>{messageCount}</Typography>
      </Box>
      {unreadMessageCount !== 0 && <NewMessageLabel />}
    </Box>
  );
}

MessagesCell.propTypes = {
  reclamationCase: PropTypes.shape({
    allMessagesCount: PropTypes.number,
    customerMessagesCount: PropTypes.number,
    customerUnreadMessagesCount: PropTypes.number,
    principalMessagesCount: PropTypes.number,
    principalUnreadMessagesCount: PropTypes.number,
    oriolaUnreadMessagesCount: PropTypes.number,
  }).isRequired,
  userData: PropTypes.shape({}).isRequired,
};

MessagesCell.defaultProps = {};

export default MessagesCell;
