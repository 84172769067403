import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { WarningMessage } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  updateLocalHandlingDataField,
  updateValidationError,
} from "../../../redux";
import { TitleInput } from "../../common";
import {
  getValidationErrorMessage,
  FieldErrors,
} from "../../../utils/validations/validations";
import { getSpdModificationData } from "../../../utils/getSpdModificationData/getSpdModificationData";
import DateAndEditor from "../dateAndEditor";
import { Attachments } from "../../fields/common";
import Title from "../title/title";

const useStyles = makeStyles(() => ({
  italic: {
    fontStyle: "italic",
  },
  disabled: {
    filter: "grayscale(1)",
  },
}));
function Conclusion({ disabled, user }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { localHandlingData = {}, case: reclamationCase } = useSelector(
    state => state.case
  );
  const { conclusion = {}, modifications = {} } = localHandlingData;
  const { conclusionError } = useSelector(state => state.validationErrors);
  const [existingAttachments, setExistingAttachments] = useState([]);
  const [attachmentError, setAttachmentError] = useState(null);
  const setConclusion = value => {
    dispatch(updateLocalHandlingDataField(value, "conclusion", "conclusion"));
    dispatch(updateValidationError({ conclusionError: "" }));
  };

  const handleOnAttachmentsUpload = attachments => {
    dispatch(
      updateLocalHandlingDataField(attachments, "conclusion", "attachments")
    );
  };
  useEffect(() => {
    const attachments =
      (localHandlingData.conclusion &&
        localHandlingData.conclusion.attachments) ||
      [];
    return setExistingAttachments(attachments);
  }, [localHandlingData, reclamationCase, dispatch]);

  return (
    <Box id="conclusion" className="handling-section">
      <Title title={t("conclusion.title")} />
      <Box
        mb={2}
        className={`${classes.italic} ${disabled ? classes.disabled : ""}`}
      >
        <WarningMessage text={t("conclusion.warning")} type="warning" />
      </Box>
      <TitleInput
        mt={4}
        error={
          !!conclusionError &&
          [FieldErrors.CONCLUSION_OVER_LENGTH, FieldErrors.MANDATORY].includes(
            conclusionError
          )
        }
        disabled={disabled}
        title={t("conclusion.subtitle")}
        rows={4}
        value={conclusion.conclusion || ""}
        onChange={setConclusion}
        placeholder={t("conclusion.placeholder")}
        helperText={getValidationErrorMessage(conclusionError, t)}
        enableCopyButton
      />
      {getSpdModificationData(modifications, "conclusion.conclusion") && (
        <DateAndEditor
          id="conclusion-date-editor-value"
          modificationData={getSpdModificationData(
            modifications,
            "conclusion.conclusion"
          )}
          user={user}
        />
      )}
      <Attachments
        reclamationCase={reclamationCase}
        id="conclusion-attachments-upload"
        disabled={disabled}
        existingAttachments={existingAttachments}
        onAttachmentsUpload={handleOnAttachmentsUpload}
        attachmentError={attachmentError}
        setAttachmentError={setAttachmentError}
      />
    </Box>
  );
}

Conclusion.propTypes = {
  disabled: PropTypes.bool,
  modifications: PropTypes.shape({}),
  user: PropTypes.shape({}).isRequired,
};

Conclusion.defaultProps = {
  disabled: false,
  modifications: {},
};
export default Conclusion;
