const CaseSubStatus = Object.freeze({
  AWAITING_CUSTOMER_ACTION: "AWAITING_CUSTOMER_ACTION",
  AWAITING_BILLING: "AWAITING_BILLING",
  AWAITING_RETURN: "AWAITING_RETURN",
  UNDER_INVESTIGATION: "UNDER_INVESTIGATION",
  UNDER_INVESTIGATION_AT_PRINCIPAL: "UNDER_INVESTIGATION_AT_PRINCIPAL",
  REQUEST_ADDITION_INFORMATION_FROM_CUSTOMER:
    "REQUEST_ADDITION_INFORMATION_FROM_CUSTOMER",
});

export default CaseSubStatus;
