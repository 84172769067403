class Delay {
  constructor() {
    this.timer = undefined;
  }

  run = (func, timeOutMs) => {
    // Calls passed function after passed delay.
    // Clears any previous delay calls.
    clearTimeout(this.timer);

    // return promise with new time out
    return new Promise((resolve, reject) => {
      this.timer = setTimeout(async () => {
        try {
          const result = await func();
          resolve(result);
        } catch (err) {
          reject(err);
        }
      }, timeOutMs);
    });
  };

  clear = () => {
    clearTimeout(this.timer);
  };
}
export default Delay;
