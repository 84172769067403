import React from "react";
import { Dropdown } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import { FieldTitle } from "../index";

function StorageSelect({
  options,
  classes,
  error,
  disabled,
  value,
  id,
  onSelectOption,
}) {
  const { t } = useTranslation();
  return (
    <div className={classes.selectContainer}>
      <FieldTitle title={t("stored")} type="selection" />
      <Dropdown
        error={!!error}
        errorText={error}
        disabled={disabled}
        className={classes.selectField}
        value={value}
        emptySelectionLabel={t("select")}
        options={options}
        onFormatOptionLabel={val => t(`storageTemperature.${val}`)}
        onSelectOption={onSelectOption}
        showSelectNone={false}
        id={`${id}-storage`}
      />
    </div>
  );
}

StorageSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.shape({
    selectContainer: PropTypes.string,
    selectField: PropTypes.string,
  }),
  error: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  id: PropTypes.string,
  onSelectOption: PropTypes.func,
};

StorageSelect.defaultProps = {
  options: [],
  classes: {},
  error: "",
  disabled: false,
  value: "",
  id: "",
  onSelectOption: null,
};

export default StorageSelect;
