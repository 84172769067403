import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CaseType } from "../../../constants/caseType";
import ButtonBar from "../buttonBar/buttonBar";
import { ProductDeliveryFields } from "../../fields";
import { CaseHeading } from "../../common";
import { Paths } from "../../../utils/navigation/navigation";
import { validateEditableFields } from "../../../utils/validations/validations";
import { updateValidationError } from "../../../redux";
import scrollToError from "../../../utils/scrollToView/scrollToView";
import { saveEditingCase, cancelEditing } from "../caseActions";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "green",
    },
  },
  container: {
    padding: theme.spacing(4),
    minHeight: 580,
    marginLeft: theme.spacing(1),
  },
}));

function ProductDeliveryEdit({
  history,
  reclamationCase,
  disabled,
  id,
  caseConfig,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.user);
  const { requireMoreInfoFromCustomer } = useSelector(state => state.case);
  const contactInfo = useSelector(state => state.contactInfo);

  const validate = () => {
    let success = true;
    const errorState = {};
    validateEditableFields({
      contactInfo,
      reclamationCase,
      errorState,
      caseConfig,
    });
    dispatch(updateValidationError(errorState));
    if (Object.keys(errorState).length > 0) {
      success = false;
    }
    scrollToError(errorState);
    return success;
  };

  const onSaveCase = async () => {
    const validateResult = validate();
    if (validateResult) {
      saveEditingCase({
        dispatch,
        reclamationCase,
        requireMoreInfoFromCustomer,
        t,
        onSaved: () => {
          history.push(
            Paths.Case.replace(":reclamationId", reclamationCase.reclamationId)
          );
        },
      });
    }
  };

  const onCancel = () => {
    cancelEditing({
      dispatch,
      t,
      onCancelled: () => {
        history.push(
          Paths.Case.replace(":reclamationId", reclamationCase.reclamationId)
        );
      },
    });
  };

  return (
    <Paper className={classes.container} id={id}>
      <CaseHeading
        leftText={t("case")}
        rightText={t("category.productOrDelivery.header")}
      />
      <ButtonBar onSave={onSaveCase} onCancel={onCancel} />
      <ProductDeliveryFields
        reclamationCase={reclamationCase}
        isNewCase={false}
        disabled={disabled}
        editMode
        userData={userData}
        caseConfig={caseConfig}
      />
      <ButtonBar onSave={onSaveCase} onCancel={onCancel} />
    </Paper>
  );
}

ProductDeliveryEdit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  reclamationCase: PropTypes.shape({
    caseType: PropTypes.string,
    reclamationId: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  caseConfig: PropTypes.shape({}),
};

ProductDeliveryEdit.defaultProps = {
  reclamationCase: { caseType: CaseType.DAMAGED_PRODUCT },
  disabled: false,
  history: {},
  id: "",
  caseConfig: {},
};

export default withRouter(ProductDeliveryEdit);
