import { v4 as uuid } from "uuid";
import { AttachmentService } from "@oriola-origo/origo-common-client-lib";
import CaseItemsType from "../../constants/caseItemsType";
import { CaseType } from "../../constants/caseType";
import { baseUrl, reclamationApi } from "./constants";
// eslint-disable-next-line import/no-cycle
import { StoreCaseErrorType } from "..";
import {
  filterCaseItems,
  getCaseConfig,
  isItemHandled,
} from "../../utils/case/case";
import Operation from "../../constants/operation";
import { CaseStatus } from "../../constants/caseStatus";

const objectPath = require("object-path");

export const getInitItems = caseType => {
  if (caseType === CaseType.PRODUCT_DELIVERY) {
    return [
      { uuid: uuid(), type: CaseItemsType.PRODUCT_ORDER },
      { uuid: uuid(), type: CaseItemsType.SHIPPING_BOX },
      { uuid: uuid(), type: CaseItemsType.NO_ITEM },
    ];
  }
  if (caseType === CaseType.DAMAGED_PRODUCT) {
    return [{ uuid: uuid(), type: CaseItemsType.PRODUCT_ORDER }];
  }
  if (caseType === CaseType.SUSPECTED_PRODUCT_DEFECT) {
    return [
      {
        uuid: uuid(),
        uniqueSerialNumber: [""],
        type: CaseItemsType.PRODUCT_ORDER,
      },
    ];
  }
  return [];
};

export const getOriginalAttachments = (attachments = []) =>
  attachments.filter(x => !x.file);
export const getDeletedAttachments = (attachments = []) =>
  attachments.filter(x => x.deleted);
export const getNewAttachments = (attachments = []) =>
  attachments.filter(x => x.deleted !== true && x.file);
export const getOldAttachments = (attachments = []) =>
  attachments.filter(x => x.deleted !== true && !x.file);

export const sanitizeProductReturn = (productReturn = {}) => {
  if (productReturn.returnValue) {
    return {
      returnValue: productReturn.returnValue,
      amount: productReturn.amount ? productReturn.amount : undefined,
    };
  }
  return { returnValue: false };
};

export const setNested = (targetObject, value, ...fieldPath) => {
  objectPath.set(targetObject, fieldPath, value);
};

export const deleteNested = (targetObject, ...fieldPath) => {
  objectPath.del(targetObject, fieldPath);
};

export const updateAttachments = async (
  attachments,
  reclamationId,
  reclamationVersion
) => {
  try {
    const attachmentService = new AttachmentService(
      `${baseUrl}${reclamationApi}/`
    );
    const deletedAttachments = getDeletedAttachments(attachments);
    const newAttachments = getNewAttachments(attachments);
    let modifiedCase;
    if (deletedAttachments.length > 0) {
      modifiedCase = await attachmentService.deleteAttachmentsWithVersion(
        reclamationId,
        reclamationVersion,
        deletedAttachments
      );
    }
    const createdAttachments = await attachmentService.createAttachments(
      reclamationId,
      newAttachments
    );

    // Checking the new files uploaded to S3
    const promises = createdAttachments.map(attachment =>
      attachmentService.getAttachments(reclamationId, attachment.attachment.id)
    );
    const uploadedAttachments = await Promise.all(promises);
    // When some new files arrived in S3 as corrupted, delete them and throw error
    if (uploadedAttachments.some(attachment => attachment.fileSize === 0)) {
      await attachmentService.deleteAttachmentsWithVersion(
        reclamationId,
        reclamationVersion,
        createdAttachments.map(
          createdAttachment => createdAttachment.attachment
        )
      );
      throw new Error(StoreCaseErrorType.UPDATE_ATTACHMENTS);
    }

    const oldAttachments = getOldAttachments(attachments);

    const finalAttachments = [
      ...oldAttachments,
      ...createdAttachments.map(
        createdAttachment => createdAttachment.attachment
      ),
    ];
    const version = modifiedCase ? modifiedCase.version : reclamationVersion;
    return { version, attachments: finalAttachments };
  } catch (error) {
    throw new Error(StoreCaseErrorType.UPDATE_ATTACHMENTS);
  }
};

export const getUnhandledItems = (items, reclamationCase = {}) =>
  items.filter(item => !isItemHandled(item, reclamationCase));

export const getOperationOfPreSaving = operation => {
  if (operation === Operation.SUBMIT) {
    return Operation.SAVE_DRAFT;
  }
  if (operation === Operation.COPY) {
    return Operation.COPY_SAVE_DRAFT;
  }
  return operation;
};

/**
 * Remove product rows without any data.
 */
const removeDummyRows = caze => {
  const disregardFields = ["type", "uuid"];

  return {
    ...caze,
    items: (caze.items || []).filter(p =>
      Object.keys(p)
        .filter(k => !disregardFields.includes(k))
        .some(k => p[k])
    ),
  };
};

export const prepareCaseData = ({
  contactInfo,
  caseData,
  operation,
  fieldsConfig,
}) => {
  const selectedCustomer = contactInfo.customer || {};
  const { caseType, productReturn, reasonIdentifier } = caseData;
  const {
    DamagedProductConfig,
    ProductReturnReasonIdentifiersConfig,
    SuspectedProductDefectConfig,
  } = fieldsConfig;

  const caseConfig = getCaseConfig(
    caseType,
    productReturn,
    reasonIdentifier,
    DamagedProductConfig,
    ProductReturnReasonIdentifiersConfig,
    SuspectedProductDefectConfig
  );
  const mappedCaseData = removeDummyRows(caseData);
  const filteredItems = filterCaseItems(mappedCaseData.items, caseConfig);

  mappedCaseData.items = filteredItems;

  if (
    caseData.caseStatus ===
      CaseStatus.OPEN_INFORMATION_REQUESTED_FROM_CUSTOMER &&
    operation === Operation.PROVIDE_INFORMATION_BY_CUSTOMER
  ) {
    return {
      ...mappedCaseData,
      operation,
    };
  }

  if (operation === Operation.COPY) {
    mappedCaseData.confirm = true;
  }

  return {
    ...mappedCaseData,
    productReturn: sanitizeProductReturn(productReturn),
    customerId: selectedCustomer.customerId,
    customerName: selectedCustomer.name,
    contactPersonName: contactInfo.contactPerson,
    contactPhoneNumber: contactInfo.phoneNumber.id
      ? contactInfo.phoneNumber.number // new phone number option
      : contactInfo.phoneNumber, // a phone number from the list
    deliveryAddressId: contactInfo.deliveryAddress
      ? contactInfo.deliveryAddress.ShipTo
      : "",
    deliveryAddress: contactInfo.deliveryAddress
      ? contactInfo.deliveryAddress.address
      : "",
    attachments: getOriginalAttachments(caseData.attachments),
    // if a user is submitting a case, the first request operation should be save_draft
    // and the then second request with attachments is submit
    operation: getOperationOfPreSaving(operation),
  };
};
