import { showDialog, hideDialog } from "../../redux";

export const HTTP_ERROR_PRECONDITION_FAILED = 412;

const openCasePage = () => {
  const currentPath = window.location.href;
  const casePath = currentPath.replace("edit", "case");
  window.location.replace(casePath);
};

export const isOptimisticLockStatus = status =>
  status === HTTP_ERROR_PRECONDITION_FAILED;

export const isOptimisticLockError = error => {
  const { data } = error || {};
  const { status } = data || {};
  return isOptimisticLockStatus(status);
};

export const handleOptimisticLockError = (
  dispatch,
  t,
  error = "saveFailedOptimisticLock"
) => {
  dispatch(
    showDialog({
      open: true,
      title: t(`${error}.title`),
      description: t(`${error}.description`),
      hideCancelButton: true,
      disableBackdropClick: true,
      onCancel: () => {
        dispatch(hideDialog());
        openCasePage();
      },
      onOk: () => {
        dispatch(hideDialog());
        openCasePage();
      },
    })
  );
};
