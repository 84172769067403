import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { TooltipTypography } from "@oriola-origo/origo-ui-core";
import { getEachTypesOfItems } from "../../utils/case/case";

const useStyles = makeStyles(theme => ({
  columnValueText: {
    color: theme.palette.text.primary,
    width: "100%",
    ...theme.typography.button,
    textTransform: "none",
  },
}));

function ProductsNumber({ items, ...rest }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { productOrders, shippingBoxes } = getEachTypesOfItems(items);
  const itemCount = items.length;
  const getShowingInfo = () => {
    let thisShowingInfo = "";
    if (itemCount === 0) {
      thisShowingInfo = "-";
    } else if (itemCount > 1) {
      thisShowingInfo = t("multiple");
    } else if (productOrders && productOrders.length === 1) {
      const product =
        productOrders[0].product || productOrders[0].missingProduct || {};
      thisShowingInfo = product.materialId;
    } else if (shippingBoxes && shippingBoxes.length === 1) {
      thisShowingInfo = shippingBoxes[0].serialShippingContainerCode;
    } else {
      thisShowingInfo = "-";
    }
    return thisShowingInfo;
  };
  const showingInfo = getShowingInfo();
  return (
    <div {...rest}>
      <TooltipTypography
        className={classes.columnValueText}
        color="textPrimary"
        noWrap
        tooltip={showingInfo}
      >
        {showingInfo}
      </TooltipTypography>
    </div>
  );
}

ProductsNumber.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({
        materialId: PropTypes.string,
      }),
      missingProduct: PropTypes.shape({
        materialId: PropTypes.string,
      }),
      serialShippingContainerCode: PropTypes.string,
    })
  ),
};

ProductsNumber.defaultProps = {
  items: [],
};

export default ProductsNumber;
