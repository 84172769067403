import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  cell: {},
}));

function TableCell({ children, ...rest }) {
  const classes = useStyles();
  return (
    <div {...rest} className={classes.cell}>
      {children}
    </div>
  );
}

TableCell.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape({}),
};

TableCell.defaultProps = {
  children: null,
  style: {},
};

export default TableCell;
