import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Dialog from "@oriola-origo/core/lib/Dialog";
import DialogContent from "@oriola-origo/core/lib/DialogContent";
import DialogActions from "@oriola-origo/core/lib/DialogActions";
import DialogTitle from "@oriola-origo/core/lib/DialogTitle";
import Button from "@oriola-origo/core/lib/Button";
import Typography from "@oriola-origo/core/lib/Typography";
import Box from "@oriola-origo/core/lib/Box";
import RadioGroup from "@oriola-origo/core/lib/RadioGroup";
import CaseSubStatus from "../../../constants/caseSubStatus";

const OPEN = "open;";
function SetSubStatusDialog({
  open,
  reclamationCase,
  handleCloseDialog,
  handleChangeSubStatus,
}) {
  const { t } = useTranslation();
  const [subStatusValue, setSubStatusValue] = useState(
    reclamationCase.caseSubStatus || OPEN
  );

  const subStatusToText = targetSubStatus => {
    if (targetSubStatus === OPEN) {
      return t("changeSubStatus.open");
    }
    return t(`changeSubStatus.${targetSubStatus}`);
  };

  const doHandleChangeSubStatus = () => {
    handleChangeSubStatus(subStatusValue === OPEN ? null : subStatusValue);
  };

  const onCloseDialog = (event, reason) => {
    if (reason !== "backdropClick") {
      handleCloseDialog();
    }
  };

  const getOptions = () =>
    [OPEN]
      .concat(Object.values(CaseSubStatus))
      .map(target => ({ value: target, label: subStatusToText(target) }));

  return (
    <OriolaThemeProvider>
      <Dialog open={open} onClose={onCloseDialog} id="change-queue-dialog">
        <DialogTitle onCloseButtonClick={() => handleCloseDialog()}>
          {t("changeSubStatus.changeStatus")}
        </DialogTitle>
        <DialogContent sx={{ minWidth: "500px" }}>
          <Typography
            sx={theme => ({
              fontSize: "16px",
              paddingBottom: theme.spacing(1),
              color: theme.palette.text.secondary,
            })}
          >
            {`${t("changeSubStatus.currentStatus")}: ${subStatusToText(
              reclamationCase.caseSubStatus || OPEN
            )}`}
          </Typography>
          <Box mb={4}>
            <RadioGroup
              value={subStatusValue}
              onClick={e => setSubStatusValue(e.target.value)}
              items={getOptions()}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseDialog}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={doHandleChangeSubStatus}
          >
            {t("changeSubStatus.changeStatus")}
          </Button>
        </DialogActions>
      </Dialog>
    </OriolaThemeProvider>
  );
}

SetSubStatusDialog.propTypes = {
  open: PropTypes.bool,
  reclamationCase: PropTypes.shape({
    caseSubStatus: PropTypes.string,
  }),
  handleCloseDialog: PropTypes.func,
  handleChangeSubStatus: PropTypes.func,
};

SetSubStatusDialog.defaultProps = {
  open: false,
  reclamationCase: {},
  handleCloseDialog: null,
  handleChangeSubStatus: null,
};

export default SetSubStatusDialog;
