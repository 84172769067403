import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Menu, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
import { useSelector } from "react-redux";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/styles";
import { CaseStatus } from "../../../constants/caseStatus";
import { isOriolaUser, isPharmaceuticalCompany } from "../../auth/permission";
import CaseSubStatus from "../../../constants/caseSubStatus";

export const STATUS_BUTTON_VALUE = Object.freeze({
  ALL: "all",
  DRAFT: "draft",
  OPEN: "open",
  SOLVED: "solved",
});

const OPEN = "ALL";
const OPEN_WITHOUT_SUB_STATUS = "NONE";

const valueToCaseStatus = value => {
  switch (value) {
    case STATUS_BUTTON_VALUE.DRAFT:
      return CaseStatus.DRAFT;
    case STATUS_BUTTON_VALUE.OPEN:
      return CaseStatus.OPEN_SUBMITTED;
    case STATUS_BUTTON_VALUE.SOLVED:
      return CaseStatus.SOLVED;
    case STATUS_BUTTON_VALUE.ALL:
      return CaseStatus.ALL;
    default:
      return null;
  }
};

const caseStatusToValue = filter => {
  if (filter === null) {
    return STATUS_BUTTON_VALUE.OPEN;
  }
  switch (filter) {
    case CaseStatus.DRAFT:
      return STATUS_BUTTON_VALUE.DRAFT;
    case CaseStatus.OPEN_SUBMITTED:
      return STATUS_BUTTON_VALUE.OPEN;
    case CaseStatus.SOLVED:
      return STATUS_BUTTON_VALUE.SOLVED;
    default:
      return STATUS_BUTTON_VALUE.ALL;
  }
};

const buttonRootStyle = {
  "&$selected": {
    backgroundColor: OriolaColors.NavyBlue,
    color: OriolaColors.White,
    "&:hover": {
      backgroundColor: OriolaColors.NavyBlue,
      color: OriolaColors.White,
    },
  },
  color: OriolaColors.NavyBlue,
  textTransform: "none",
  borderColor: "rgba(0, 0, 0, 0.23)",
  width: "119px",
  height: "40px",
};

const StyledToggleButton = withStyles({
  root: {
    ...buttonRootStyle,
  },
  selected: {},
})(ToggleButton);

const StyledToggleMenuButton = withStyles({
  root: {
    ...buttonRootStyle,
    paddingRight: 4,
  },
  selected: {},
})(ToggleButton);

const useStyles = makeStyles(() => ({
  highlightMenuItem: {
    color: OriolaColors.secondaryNavyBlue,
    fontWeight: "bold",
  },
}));

function CaseStatusButtonGroup({
  caseStatus,
  caseSubStatus,
  onCaseStatusSelected,
  userIsOriolaUser,
  ...containerProps
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userData } = useSelector(state => state.user);
  const [statusValue, setStatusValue] = useState(caseStatus);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const openSubStatuses = Object.values(CaseSubStatus);

  useEffect(() => {
    setStatusValue(caseStatus || CaseStatus.OPEN_SUBMITTED);
  }, [caseStatus, setStatusValue]);

  const handleButtonClick = (e, newValue) => {
    // non oriola user
    if (userIsOriolaUser === false) {
      if (newValue !== null) {
        onCaseStatusSelected(valueToCaseStatus(newValue));
        setStatusValue(valueToCaseStatus(newValue));
      }
      return;
    }

    // oriola user with menu support
    if (newValue == null && statusValue === CaseStatus.OPEN_SUBMITTED) {
      // open menu if Open is clicked even if selected
      setAnchorEl(e.currentTarget);
      setMenuOpen(true);
    } else if (newValue != null) {
      // value changed, other than open
      if (newValue !== STATUS_BUTTON_VALUE.OPEN) {
        onCaseStatusSelected(valueToCaseStatus(newValue), null);
      } else {
        setAnchorEl(e.currentTarget);
        setMenuOpen(true);
      }
      setStatusValue(valueToCaseStatus(newValue));
    }
  };

  const handleMenuSelected = subStatusEntry => {
    const newCaseSubStatus = subStatusEntry === OPEN ? null : subStatusEntry;
    onCaseStatusSelected(statusValue, newCaseSubStatus);
    setMenuOpen(false);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    setStatusValue(CaseStatus.OPEN_SUBMITTED);
    onCaseStatusSelected(CaseStatus.OPEN_SUBMITTED, caseSubStatus);
  };

  const getSubStatusText = value => {
    const openText = t("changeSubStatus.open");
    const openWithoutSubStatusText = t("openCasesWithoutSubStatus");
    const subStatusText = t(`changeSubStatus.${value}`);

    if (value === OPEN) {
      return openText;
    }
    if (value === OPEN_WITHOUT_SUB_STATUS) {
      return openWithoutSubStatusText;
    }
    return `${openText} - ${subStatusText}`;
  };

  const renderOpenButton = () => {
    if (userIsOriolaUser === false) {
      return (
        <StyledToggleButton value={STATUS_BUTTON_VALUE.OPEN}>
          {t("caseStatusFilter.open")}
        </StyledToggleButton>
      );
    }

    const selected = caseStatusToValue(caseStatus) === STATUS_BUTTON_VALUE.OPEN;
    return (
      <StyledToggleMenuButton value={STATUS_BUTTON_VALUE.OPEN}>
        <Box flexGrow={1}>{t("caseStatusFilter.open")}</Box>
        <ArrowDropDownIcon color={selected ? "white" : "action"} />
      </StyledToggleMenuButton>
    );
  };

  return (
    <Box {...containerProps}>
      <ToggleButtonGroup
        value={caseStatusToValue(statusValue) || STATUS_BUTTON_VALUE.OPEN}
        exclusive
        onChange={handleButtonClick}
      >
        {renderOpenButton()}
        {(!isPharmaceuticalCompany(userData) || isOriolaUser(userData)) && (
          <StyledToggleButton value={STATUS_BUTTON_VALUE.DRAFT}>
            {t("caseStatusFilter.draft")}
          </StyledToggleButton>
        )}
        <StyledToggleButton value={STATUS_BUTTON_VALUE.SOLVED}>
          {t("caseStatusFilter.solved")}
        </StyledToggleButton>
        <StyledToggleButton value={STATUS_BUTTON_VALUE.ALL}>
          {t("caseStatusFilter.all")}
        </StyledToggleButton>
      </ToggleButtonGroup>
      {userIsOriolaUser && (
        <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
          {[OPEN, OPEN_WITHOUT_SUB_STATUS]
            .concat(openSubStatuses)
            .map(subStatusEntry => (
              <MenuItem
                className={
                  subStatusEntry === caseSubStatus
                    ? classes.highlightMenuItem
                    : null
                }
                key={subStatusEntry}
                onClick={() => handleMenuSelected(subStatusEntry)}
              >
                {getSubStatusText(subStatusEntry)}
              </MenuItem>
            ))}
        </Menu>
      )}
    </Box>
  );
}

CaseStatusButtonGroup.propTypes = {
  caseStatus: PropTypes.string,
  caseSubStatus: PropTypes.string,
  onCaseStatusSelected: PropTypes.func.isRequired,
  userIsOriolaUser: PropTypes.bool,
};

CaseStatusButtonGroup.defaultProps = {
  caseStatus: null,
  caseSubStatus: null,
  userIsOriolaUser: false,
};

export default CaseStatusButtonGroup;
