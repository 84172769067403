/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModificationData } from "../../../../interfaces/case";

export interface NewReturnAddressFormat {
  company: string;
  contactName: string;
  phoneNumber: string;
  address: string;
  country: string;
}
export interface ReturnAddressV2Props {
  user: any;
  disabled: boolean;
  returnAddress: NewReturnAddressFormat;
  isReturnToPrincipalRequired: boolean;
  getModificationData: (field: string) => any;
  setIsAuthorizedRecipientConfirmed: (value: any) => void;
}
export enum ReturnAddressSubfield {
  Company = "company",
  ContactName = "contactName",
  PhoneNumber = "phoneNumber",
  Address = "address",
  Country = "country",
}

export interface ReturnAddressModificationData {
  company?: ModificationData;
  contactName?: ModificationData;
  phoneNumber?: ModificationData;
  address?: ModificationData;
  country?: ModificationData;
  userId?: string;
  userName?: string;
  userType?: string;
  modificationTime?: string;
}
