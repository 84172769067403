import { RestService } from "../../components/common";
import { ensureTrailingSlash } from "../../utils/url/url";

export const FieldsConfigActions = Object.freeze({
  FETCH_STARTED: "FETCH_FIELDS_CONFIG_STARTED",
  FETCH_FINISHED: "FETCH_FIELDS_FETCH_FINISHED",
  FETCH_ERROR: "FETCH_FIELDS_FETCH_ERROR",
});

export const INIT_STATE = {};

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_RECLAMATION_SERVICE);
const reclamationApi = process.env.REACT_APP_RECLAMATION_API;

export const fetchFieldsConfig = () => async dispatch => {
  const path = `${baseUrl}${reclamationApi}/config`;
  try {
    dispatch({
      type: FieldsConfigActions.FETCH_STARTED,
    });

    const reclamationConfig = await RestService.get(path);
    dispatch({
      type: FieldsConfigActions.FETCH_FINISHED,
      payload: reclamationConfig,
    });
  } catch (error) {
    dispatch({ type: FieldsConfigActions.FETCH_ERROR, payload: error });
  }
};
export const fieldsConfigReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case FieldsConfigActions.FETCH_FINISHED:
      return { ...state, ...action.payload };
    case FieldsConfigActions.FETCH_STARTED:
    case FieldsConfigActions.FETCH_ERROR:
      return state;
    default:
      return state;
  }
};
