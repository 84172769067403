import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "@oriola-origo/origo-ui-core";
import PropTypes from "prop-types";
import { isAllowed, Permission } from "../../../auth/permission";
// eslint-disable-next-line import/no-cycle
import CustomerSearch from "./customerSearch";
import { FieldTitle } from "..";

function CustomerSelect({
  contactInfo,
  userData,
  disabled,
  customers,
  handleCustomerSelected,
  classes,
  oriolaUser,
  title,
}) {
  const { t } = useTranslation();
  const [editableCustomers, setEditableCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");

  useEffect(() => {
    if (contactInfo.customer && contactInfo.customer.customerId) {
      setSelectedCustomer(
        editableCustomers.find(
          c => c.customerId === contactInfo.customer.customerId
        )
      );
    }
  }, [contactInfo, editableCustomers]);

  useEffect(() => {
    const data = (customers || []).filter(c =>
      isAllowed(userData, Permission.CASE_CREATE, c.customerId)
    );

    disabled ? setEditableCustomers(customers) : setEditableCustomers(data);
  }, [userData, customers, disabled]);

  const renderCustomerSelection = () => (
    <Dropdown
      className={classes.dropdown}
      value={selectedCustomer}
      emptySelectionLabel={t("select")}
      onFormatOptionLabel={c => `${c.customerId} ${c.name}`}
      options={editableCustomers}
      onSelectOption={handleCustomerSelected}
      showSelectNone={false}
      selectNoneLabel={t("selectNone")}
      id="contact-info-customer"
      disabled={disabled}
    />
  );

  const renderCustomerSearch = () => (
    <CustomerSearch
      handleSelected={handleCustomerSelected}
      disabled={disabled}
    />
  );

  return (
    <div>
      <FieldTitle title={t(title)} type="selection" />
      {oriolaUser === false && renderCustomerSelection()}
      {oriolaUser === true && renderCustomerSearch()}
    </div>
  );
}

CustomerSelect.propTypes = {
  contactInfo: PropTypes.shape({
    customer: PropTypes.shape({
      customerId: PropTypes.string,
    }),
  }),
  disabled: PropTypes.bool,
  userData: PropTypes.shape({}),
  customers: PropTypes.arrayOf(PropTypes.shape({})),
  handleCustomerSelected: PropTypes.func,
  classes: PropTypes.shape({
    dropdown: PropTypes.string,
  }),
  oriolaUser: PropTypes.bool,
  title: PropTypes.string,
};

CustomerSelect.defaultProps = {
  contactInfo: {},
  classes: {},
  disabled: false,
  userData: {},
  customers: [],
  handleCustomerSelected: null,
  oriolaUser: false,
  title: "",
};

export default CustomerSelect;
