import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import Message, { MessageAlign } from "./message";

const MESSAGE_ACTION = {
  BG: "#E0DCDA",
  BORDER: "#A0A9B6",
};

function MessageAction({
  message,
  additionalMessage,
  children,
  showReadStatus,
  updatingReadStatus,
  updateReadStatusError,
  onModifyMessage,
}) {
  return (
    <>
      {message.message && (
        <Message
          messageAlign={MessageAlign.Right}
          message={message}
          additionalMessage={additionalMessage}
          showReadStatus={showReadStatus}
          updatingReadStatus={updatingReadStatus}
          updateReadStatusError={updateReadStatusError}
          onModifyMessage={onModifyMessage}
        />
      )}
      <Box display="flex" justifyContent="center" marginTop="24px">
        <Box
          bgcolor={MESSAGE_ACTION.BG}
          border={`1px solid ${MESSAGE_ACTION.BORDER}`}
          borderRadius="31px"
          display="flex"
          padding={1.5}
          marginX="auto"
        >
          {children}
        </Box>
      </Box>
    </>
  );
}

MessageAction.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
    creator: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    creationTime: PropTypes.string,
    recipient: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
  children: PropTypes.node,
  additionalMessage: PropTypes.string,
  showReadStatus: PropTypes.bool,
  updatingReadStatus: PropTypes.bool,
  updateReadStatusError: PropTypes.shape({}),
  onModifyMessage: PropTypes.func,
};

MessageAction.defaultProps = {
  children: null,
  additionalMessage: "",
  showReadStatus: false,
  updatingReadStatus: false,
  updateReadStatusError: null,
  onModifyMessage: null,
};

export default MessageAction;
