/* eslint-disable default-param-last */
import {
  auth,
  rest as RestService,
} from "@oriola-origo/origo-common-client-lib";
import { ensureTrailingSlash } from "../../utils/url/url";

export const User = Object.freeze({
  USER_SIGN_IN: "USER_SIGN_IN",
  USER_SIGN_OUT: "USER_SIGN_OUT",
  SET_SESSION_ID: "USER_SET_SESSION_ID",
  SET_TOKEN_DATA: "USER_SET_TOKEN_DATA",
  UPDATE_USER_DATA: "UPDATE_USER_DATA",
});

export const setTokenData = tokenData => ({
  type: User.SET_TOKEN_DATA,
  payload: tokenData,
});

// -- ACTIONS --

export const getRefreshedToken = () => async (dispatch, getState) => {
  // get current token data
  const { tokenData } = getState().user;

  // check if expired
  if (auth.isTokenExpired(tokenData)) {
    // refresh token data
    const { sessionId } = getState().user;
    const result = await auth.fetchTokenData(sessionId);
    dispatch(setTokenData(result));

    // return token
    return Promise.resolve(result);
  }

  // return token directly since it is still valid
  return Promise.resolve(tokenData);
};

export const updateUserData = userData => ({
  type: User.UPDATE_USER_DATA,
  payload: userData,
});

export const fetchUserDataFromOrigo = userId => async (dispatch, getState) => {
  const origoBaseUrl = ensureTrailingSlash(process.env.REACT_APP_ORIGO_URL);
  const userDataApi = ensureTrailingSlash(
    process.env.REACT_APP_ORIGO_USER_DATA_API
  );
  const path = `${origoBaseUrl}api/v1/${userDataApi}${userId}`;
  try {
    const { tokenData } = getState().user;
    const config = RestService.createConfig(tokenData.accessToken);
    const origoUserData = await RestService.get(path, config);
    const defaultOrganization = origoUserData.organizations
      ? origoUserData.organizations.find(
          organization => organization.isDefault === true
        )
      : null;
    dispatch(
      updateUserData({
        phoneNumber: origoUserData.phoneNumber,
        language: origoUserData.language,
        defaultOrganizationId: defaultOrganization
          ? defaultOrganization.id
          : null,
      })
    );
    return origoUserData;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return {};
  }
};

export const setSessionId = sessionId => ({
  type: User.SET_SESSION_ID,
  payload: sessionId,
});

export const userSignOut = () => ({
  type: User.USER_SIGN_OUT,
});

export const userSignIn = signInData => ({
  type: User.USER_SIGN_IN,
  payload: signInData,
});

// -- REDUCER --

const INIT_STATE = {
  userData: {},
  signedIn: false,
  sessionId: null,
  tokenData: null,
};

export const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case User.SET_SESSION_ID:
      return { ...state, sessionId: action.payload };
    case User.SET_TOKEN_DATA:
      return { ...state, tokenData: action.payload };
    case User.USER_SIGN_OUT:
      return {
        ...state,
        signedIn: false,
        sessionId: null,
        tokenData: null,
        userData: null,
      };
    case User.USER_SIGN_IN: {
      const { sessionId, userData, tokenData } = action.payload;
      return { ...state, sessionId, userData, tokenData, signedIn: true };
    }
    case User.UPDATE_USER_DATA: {
      return { ...state, userData: { ...state.userData, ...action.payload } };
    }
    default:
      return state;
  }
};
