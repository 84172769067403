import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import SelectCopyType from "./selectCopyType";
import EditCopyCase from "./editCopiedCase";
import { mapCaseToCopiedCase } from "../../../redux";
import { cancelEditingCopiedCase } from "../../../redux/case/case";
import { CloseIcon } from "../../../images";

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

function CopyCaseView({
  reclamationCase,
  copiedCaseTypeSelected,
  setCopiedCaseTypeSelected,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onCancel = () => {
    setCopiedCaseTypeSelected(false);
    dispatch(cancelEditingCopiedCase());
  };

  return (
    <div className={classes.container}>
      <Button
        variant="transparent"
        className={classes.closeButton}
        onClick={onCancel}
      >
        <CloseIcon />
      </Button>
      {copiedCaseTypeSelected ? (
        <EditCopyCase
          reclamationCase={reclamationCase}
          onCancel={onCancel}
          onSubmitCallback={() => {
            setCopiedCaseTypeSelected(false);
          }}
        />
      ) : (
        <SelectCopyType
          reclamationCase={reclamationCase}
          onCancel={onCancel}
          onTypeSelected={(
            caseType,
            reasonIdentifier,
            selectedProductOrder
          ) => {
            setCopiedCaseTypeSelected(true);
            dispatch(
              mapCaseToCopiedCase(
                caseType,
                reasonIdentifier,
                selectedProductOrder
              )
            );
          }}
        />
      )}
    </div>
  );
}

CopyCaseView.propTypes = {
  reclamationCase: PropTypes.shape({}),
  copiedCaseTypeSelected: PropTypes.bool,
  setCopiedCaseTypeSelected: PropTypes.func,
};

CopyCaseView.defaultProps = {
  reclamationCase: {},
  copiedCaseTypeSelected: false,
  setCopiedCaseTypeSelected: null,
};

export default CopyCaseView;
