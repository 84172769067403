import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { RadioButtonSelection, TitleInput } from "../../common";
import {
  updateLocalHandlingDataField,
  updateValidationError,
} from "../../../redux";
import DateAndEditor from "../dateAndEditor";
import { getValidationErrorMessage } from "../../../utils/validations/validations";
import { getSpdModificationData } from "../../../utils/getSpdModificationData/getSpdModificationData";
import Compensations from "../compensation/compensations";
import Title from "../title/title";
import ResetButton from "../../common/resetButton/resetButton";

const useStyles = makeStyles(theme => ({
  titleAndResetButton: {
    display: "flex",
    justifyContent: "space-between",
  },
  resetButtonOriola: {
    position: "absolute",
    right: theme.spacing(11),
    top: theme.spacing(4),
  },
}));
function CompensationInformation({ user, disabled, ...containerProps }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { localHandlingData = {} } = useSelector(state => state.case);
  const { spdCustomerCompensationIsCompensatedError } = useSelector(
    state => state.validationErrors
  );
  const { compensationInformation = {}, modifications = {} } =
    localHandlingData;

  const { isCustomerCompensated, compensations } = compensationInformation;
  const targetCompensations = compensations
    ? compensations.map((compensation, index) => ({
        ...compensation,
        index,
      }))
    : [];

  const getIsCompensated = () => {
    if (isCustomerCompensated == null) {
      return "";
    }
    return isCustomerCompensated ? "true" : "false";
  };

  const setIsCompensated = updatedValue => {
    // clear validation errors
    dispatch(
      updateValidationError({ spdCustomerCompensationIsCompensatedError: null })
    );

    const booleanValue = updatedValue === "true";

    dispatch(
      updateLocalHandlingDataField(
        booleanValue,
        "compensationInformation",
        "isCustomerCompensated"
      )
    );
  };

  const resetIsCompensated = () => {
    dispatch(
      updateValidationError({ spdCustomerCompensationIsCompensatedError: null })
    );

    dispatch(
      updateLocalHandlingDataField(
        null,
        "compensationInformation",
        "isCustomerCompensated"
      )
    );
  };

  const setCompensationInformationText = updatedValue => {
    dispatch(
      updateLocalHandlingDataField(
        updatedValue,
        "compensationInformation",
        "compensationInformation"
      )
    );
  };

  const getModificationData = field =>
    getSpdModificationData(modifications, `compensationInformation.${field}`);
  const hasModificationData = field => !!getModificationData(field);

  const isCompensatedValue = getIsCompensated();

  const getRelevantCompensationIndex = (compensationArray, compensation) =>
    compensationArray.findIndex(c => c.index === compensation.index);

  return (
    <Box
      id="compensation-information"
      className="handling-section"
      {...containerProps}
    >
      <div className={classes.titleAndResetButton}>
        <Title title={t("compensationInformation.title")} />
        <ResetButton
          className={classes.resetButtonOriola}
          onClick={resetIsCompensated}
          disabled={!isCompensatedValue}
        />
      </div>
      <RadioButtonSelection
        disabled={disabled}
        value={isCompensatedValue}
        button1Value="true"
        button2Value="false"
        onChecked={setIsCompensated}
        button1LabelText={t("compensationInformation.customerIsCompensated")}
        button2LabelText={t("compensationInformation.customerIsNotCompensated")}
        button1LabelChildren={
          isCustomerCompensated === true ? (
            <DateAndEditor
              id="compensation-information-is-compensated"
              modificationData={getModificationData("isCustomerCompensated")}
              user={user}
            />
          ) : null
        }
        button2LabelChildren={
          isCustomerCompensated === false ? (
            <DateAndEditor
              id="compensation-information-is-not-compensated"
              modificationData={getModificationData("isCustomerCompensated")}
              user={user}
            />
          ) : null
        }
        error={spdCustomerCompensationIsCompensatedError != null}
        helperText={getValidationErrorMessage(
          spdCustomerCompensationIsCompensatedError,
          t
        )}
      />
      {isCustomerCompensated === true && (
        <Box ml={4}>
          <Box mr={1}>
            <Compensations
              compensations={targetCompensations}
              onCompensationUpdated={updatedCompensation => {
                const index = getRelevantCompensationIndex(
                  targetCompensations,
                  updatedCompensation
                );
                dispatch(
                  updateLocalHandlingDataField(
                    updatedCompensation,
                    "compensationInformation",
                    "compensations",
                    index
                  )
                );
              }}
              onCompensationRemoved={updatedCompensation => {
                const index = getRelevantCompensationIndex(
                  targetCompensations,
                  updatedCompensation
                );
                dispatch(
                  updateLocalHandlingDataField(
                    undefined,
                    "compensationInformation",
                    "compensations",
                    index
                  )
                );
              }}
              onAddNewCompensation={newCompensation => {
                const nextIndex = targetCompensations.length;
                dispatch(
                  updateLocalHandlingDataField(
                    newCompensation,
                    "compensationInformation",
                    "compensations",
                    nextIndex
                  )
                );
              }}
            />
          </Box>
        </Box>
      )}
      <Box mt={2}>
        <TitleInput
          disabled={disabled}
          mt={4}
          title={t("compensationInformation.title")}
          secondaryTitle={t("optional")}
          value={compensationInformation.compensationInformation || ""}
          onChange={setCompensationInformationText}
          rows={3}
          enableCopyButton
        />
        {hasModificationData("compensationInformation") && (
          <DateAndEditor
            id="compensation-information-info"
            modificationData={getModificationData("compensationInformation")}
            user={user}
          />
        )}
      </Box>
    </Box>
  );
}

CompensationInformation.propTypes = {
  user: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
};

CompensationInformation.defaultProps = {
  disabled: false,
};

export default CompensationInformation;
