import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getCaseTypeLocalized } from "../../constants/caseType";

const useStyles = makeStyles(theme => ({
  columnValueText: {
    color: theme.palette.text.primary,
    width: "100%",
    ...theme.typography.button,
    textTransform: "none",
  },
}));

function CaseTypeCell({ singleCase, ...rest }) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div {...rest}>
      <Typography className={classes.columnValueText} color="textPrimary">
        {getCaseTypeLocalized(t, singleCase.caseType)}
      </Typography>
    </div>
  );
}

CaseTypeCell.propTypes = {
  singleCase: PropTypes.shape({
    caseType: PropTypes.string,
  }),
};

CaseTypeCell.defaultProps = {
  singleCase: {},
};

export default CaseTypeCell;
