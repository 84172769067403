import React from "react";
import PropTypes from "prop-types";
import { TooltipTypography } from "@oriola-origo/origo-ui-core";
import useStyles from "./caseIconsStyles";

function CaseLabel({ label, tooltip, grayed }) {
  const classes = useStyles(grayed)();
  return (
    <TooltipTypography
      disabled={false}
      className={classes.caseLabel}
      tooltip={tooltip || label}
      variant="body2"
    >
      {label}
    </TooltipTypography>
  );
}

CaseLabel.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
  grayed: PropTypes.bool,
};

CaseLabel.defaultProps = {
  label: "",
  tooltip: "",
  grayed: false,
};

export default CaseLabel;
