import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-cycle
import { updateCase, updateValidationError } from "../../../../redux";
import {
  YesNoSelection,
  YesNoSelectionValues,
  getYesNoSelectValues,
  StatefullTextField,
} from "../../../common";
import {
  getValidationErrorMessage,
  FieldErrors,
} from "../../../../utils/validations/validations";
import { FieldTitle } from "..";

function IsCustomerCompensated({
  classes,
  reclamationCase,
  disabled,
  caseConfig,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isCustomerCompensated = reclamationCase.isCustomerCompensated || {};
  const { isCustomerCompensatedError, howWasCustomerCompensatedError } =
    useSelector(state => state.validationErrors);
  const compensationConfig = caseConfig.isCustomerCompensated;
  const compensationDescriptionConfig = compensationConfig.description;
  return (
    <>
      <div
        className={classes.rowContainer}
        data-error-name="isCustomerCompensatedError"
      >
        <YesNoSelection
          id="new-case-has-customer-been-compensated"
          disabled={disabled}
          title={t("caseInformation.isCustomerCompensated.title")}
          error={!!isCustomerCompensatedError}
          errorText={getValidationErrorMessage(isCustomerCompensatedError, t)}
          value={getYesNoSelectValues(isCustomerCompensated.code)}
          onValueChanged={event => {
            const value = event.target.value === YesNoSelectionValues.Yes;
            dispatch(updateValidationError({ isCustomerCompensatedError: "" }));
            dispatch(
              updateCase({
                isCustomerCompensated: {
                  code: value,
                  description: value ? isCustomerCompensated.description : "",
                },
              })
            );
          }}
        />
      </div>
      {isCustomerCompensated && isCustomerCompensated.code && (
        <div className={classes.formControl}>
          <FieldTitle
            title={t("caseInformation.howWasCustomerCompensated.title")}
            optional={compensationDescriptionConfig.optional}
          />
          <StatefullTextField
            error={!!howWasCustomerCompensatedError}
            helperText={getValidationErrorMessage(
              howWasCustomerCompensatedError,
              t,
              {
                maxLength: compensationDescriptionConfig.maxLength,
              }
            )}
            data-error-name="howWasCustomerCompensatedError"
            fullWidth
            multiline
            rows="3"
            initialValue={isCustomerCompensated.description || ""}
            variant="outlined"
            placeholder={t(
              "caseInformation.howWasCustomerCompensated.placeholder"
            )}
            margin="dense"
            disabled={disabled}
            onChange={value => {
              if (
                compensationDescriptionConfig &&
                value.length > compensationDescriptionConfig.maxLength
              ) {
                dispatch(
                  updateValidationError({
                    howWasCustomerCompensatedError: FieldErrors.OVER_LENGTH,
                  })
                );
              } else {
                dispatch(
                  updateCase({
                    isCustomerCompensated: {
                      ...isCustomerCompensated,
                      description: value,
                    },
                  })
                );
                dispatch(
                  updateValidationError({ howWasCustomerCompensatedError: "" })
                );
              }
            }}
            id="new-case-how-was-customer-compensated"
          />
        </div>
      )}
    </>
  );
}

IsCustomerCompensated.propTypes = {
  classes: PropTypes.shape({
    rowContainer: PropTypes.string,
    formControl: PropTypes.string,
  }),
  reclamationCase: PropTypes.shape({
    isCustomerCompensated: PropTypes.shape({
      code: PropTypes.bool,
      description: PropTypes.string,
    }),
  }),
  disabled: PropTypes.bool,
  caseConfig: PropTypes.shape({
    isCustomerCompensated: PropTypes.shape({
      description: PropTypes.shape({
        maxLength: PropTypes.number,
        optional: PropTypes.bool,
      }),
    }),
  }),
};

IsCustomerCompensated.defaultProps = {
  disabled: false,
  caseConfig: {},
  reclamationCase: {
    isCustomerCompensated: {},
  },
  classes: {},
};

IsCustomerCompensated.displayName = "IsCustomerCompensated";

export default IsCustomerCompensated;
