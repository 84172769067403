import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { Progress } from "@oriola-origo/origo-ui-core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Message, { MessageAlign } from "./message";
import UserType from "../../../constants/userType";
import { isOriolaUser } from "../../auth/permission";
import { modifyMessage } from "../../../redux/message/message";
import { isOwnOrganizationMessage } from "../../../utils/message";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#f5f5f5",
  },
  messageList: {
    "& > div:first-child": {
      animationDelay: ".5s",
      animation: "$shake .88s cubic-bezier(.36,.07,.19,.97)",
      transform: "translate3d(0, 0, 0)",
    },
  },
  "@keyframes shake": {
    "10%, 90%": {
      transform: "translate3d(-7px, 0, 0)",
    },
    "20%, 80%": {
      transform: "translate3d(8px, 0, 0)",
    },
    "30%, 50%, 70%": {
      transform: "translate3d(-9px, 0, 0)",
    },
    "40%, 60%": {
      transform: "translate3d(9px, 0, 0)",
    },
  },
}));

function MessageList({
  userData,
  reclamationCase,
  messages,
  fetching,
  messageCreatorIdentities,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { sendingMessageStatusIds, messageStatusSendErrors } = useSelector(
    state => state.message
  );

  const onModifyMessage = (eventId, status, attachments) => {
    dispatch(
      modifyMessage(reclamationCase.reclamationId, eventId, status, attachments)
    );
  };

  const renderMessage = message => {
    const ownOrganizationMessage = isOwnOrganizationMessage(message, userData);
    const messageAlign = ownOrganizationMessage
      ? MessageAlign.Right
      : MessageAlign.Left;
    const messageIsForOriola = message.recipient.type === UserType.ORIOLA;

    return (
      <Message
        messageAlign={messageAlign}
        message={message}
        showReadStatus={isOriolaUser(userData) && messageIsForOriola === true}
        updatingReadStatus={sendingMessageStatusIds[message.id] === true}
        updateReadStatusError={messageStatusSendErrors[message.id]}
        onModifyMessage={status =>
          onModifyMessage(message.id, status, message.attachments)
        }
        messageCreatorIdentities={messageCreatorIdentities}
      />
    );
  };

  const renderProgress = () => (
    <Box p={3} display="flex" alignItems="center" justifyContent="center">
      <Progress show />
    </Box>
  );

  return (
    <Box className={classes.messageList} my={3}>
      {fetching === false &&
        messages.map(message => (
          <Box key={message.id} mb={3}>
            {renderMessage(message)}
          </Box>
        ))}
      {fetching === true && renderProgress()}
    </Box>
  );
}

MessageList.propTypes = {
  userData: PropTypes.shape({}).isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  reclamationCase: PropTypes.shape({
    reclamationId: PropTypes.string,
  }),
  messageCreatorIdentities: PropTypes.shape({}),
};

MessageList.defaultProps = {
  reclamationCase: {},
  messageCreatorIdentities: {},
};

export default MessageList;
