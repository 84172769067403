import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Dropdown } from "@oriola-origo/origo-ui-core";
import { useSelector, useDispatch } from "react-redux";
// eslint-disable-next-line import/no-cycle
import OrganizationSearch from "../organizationSearch/organizationSearch";
import { FieldTitle } from "..";
import { isAllowed, Permission, isOriolaUser } from "../../../auth/permission";

const useStyles = makeStyles(theme => ({
  dropdown: {
    width: "32rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

function TransferToAnotherCustomer({
  itemData,
  error,
  id,
  updateItem,
  updateItemValidationError,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const customers = useSelector(state => state.customer.customers);
  const [editableCustomers, setEditableCustomers] = useState([]);
  const { userData } = useSelector(state => state.user);
  const oriolaUser = isOriolaUser(userData);
  const handleCustomerSelected = customer => {
    dispatch(
      updateItemValidationError(itemData.uuid, {
        transferToAnotherCustomer: "",
      })
    );

    dispatch(
      updateItem({
        ...itemData,
        transferToAnotherCustomer: customer,
      })
    );
  };

  useEffect(() => {
    const data = (customers || [])
      .filter(c => isAllowed(userData, Permission.CASE_CREATE, c.customerId))
      .map(customer => ({
        id: customer.customerId,
        name: customer.name,
      }));
    setEditableCustomers(data);
  }, [userData, customers]);

  const renderCustomerSelection = () => (
    <Dropdown
      className={classes.dropdown}
      value={itemData.transferToAnotherCustomer}
      emptySelectionLabel={t("select")}
      onFormatOptionLabel={c => `${c.id} ${c.name}`}
      options={editableCustomers}
      onSelectOption={handleCustomerSelected}
      showSelectNone={false}
      selectNoneLabel={t("selectNone")}
      id={`${id}-dropdown`}
    />
  );

  const renderCustomerSearch = () => (
    <OrganizationSearch
      handleSelected={handleCustomerSelected}
      selectedCustomer={itemData.transferToAnotherCustomer}
      error={error}
      id={`${id}-search`}
    />
  );

  return (
    <div>
      <FieldTitle
        title={t("caseItemFields.transferCustomer")}
        type="selection"
      />
      {oriolaUser === false && renderCustomerSelection()}
      {oriolaUser === true && renderCustomerSearch()}
    </div>
  );
}

TransferToAnotherCustomer.propTypes = {
  itemData: PropTypes.shape({
    transferToAnotherCustomer: PropTypes.shape({}),
    uuid: PropTypes.string,
  }),
  error: PropTypes.string,
  id: PropTypes.string,
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
  classes: PropTypes.shape({}),
  contactInfo: PropTypes.shape({}),
};

TransferToAnotherCustomer.defaultProps = {
  contactInfo: {},
  classes: {},
  itemData: {},
  error: "",
  id: "",
  updateItem: null,
  updateItemValidationError: null,
};

export default TransferToAnotherCustomer;
