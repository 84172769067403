import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  headerRow: {
    marginTop: theme.spacing(4),
    paddingLeft: "1rem",
    paddingRight: "1.6rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    borderBottom: "solid 1px #e7e7e7",
    paddingTop: "0.4rem",
    paddingBottom: "0.8rem",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "50px",
    },
    overflow: "hidden",
  },
  columnHeaderText: {
    color: theme.palette.text.disabled,
    width: "100%",
    ...theme.typography.button,
    textTransform: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

function TableHeader({ headers }) {
  const classes = useStyles();

  const shownHeaders = headers.filter(x => x.show);

  return (
    <div className={classes.headerRow}>
      {shownHeaders.map(header => (
        <div
          className={classes.columnCaseNumber}
          key={header.title1}
          style={{ width: header.width }}
        >
          <Typography className={classes.columnHeaderText}>
            {header.title1}
          </Typography>
          <Typography className={classes.columnHeaderText}>
            {header.title2}
          </Typography>
        </div>
      ))}
    </div>
  );
}

TableHeader.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      title1: PropTypes.string,
      title2: PropTypes.string,
      width: PropTypes.string,
      show: PropTypes.bool,
    })
  ),
};

TableHeader.defaultProps = {
  headers: [],
};

export default TableHeader;
