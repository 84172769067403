import {
  deleteDraft,
  addNotification,
  showDialog,
  hideDialog,
} from "../../../redux";

const doDeleteCase = (t, dispatch, reclamationCase, onDeleted) => {
  dispatch(deleteDraft(reclamationCase)).then(result => {
    // close the dialog
    dispatch(hideDialog());

    // process
    if (result && result.status === "SUCCESS") {
      dispatch(
        addNotification({
          title: t("deleteDraftSuccessfully.title"),
          primaryText: t("deleteDraftSuccessfully.description"),
        })
      );

      if (onDeleted) {
        onDeleted();
      }
    } else {
      dispatch(
        addNotification({
          title: t("deleteDraftFailed.title"),
          primaryText: t("deleteDraftFailed.description"),
          type: "error",
        })
      );
    }
  });
};

const confirmAndDeleteCase = (t, dispatch, reclamationCase, onDeleted) => {
  dispatch(
    showDialog({
      open: true,
      title: t("confirmDelete.title"),
      description: t("confirmDelete.description"),
      cancelButtonText: t("confirmDelete.cancel"),
      okButtonText: t("confirmDelete.delete"),
      onCancel: () => dispatch(hideDialog()),
      onOk: () => doDeleteCase(t, dispatch, reclamationCase, onDeleted),
    })
  );
};
export default confirmAndDeleteCase;
