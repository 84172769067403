import { makeStyles } from "@material-ui/core/styles";
import { OriolaColors } from "@oriola-origo/origo-ui-core";

const useStyles = isGrayed =>
  makeStyles(theme => {
    const styles = {
      caseLabel: {
        backgroundColor: "#FFE9C6",
        border: "1px solid #FFD48D",
        borderRadius: 4,
        marginTop: theme.spacing(1),
        color: theme.palette.primary.main,
        height: 24,
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "24px",
        marginRight: theme.spacing(1),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        minWidth: "16px",
        "& svg": {
          fill: OriolaColors.Orange,
        },
      },
    };
    if (isGrayed) {
      // eslint-disable-next-line prefer-destructuring
      styles.caseLabel.backgroundColor = theme.palette.grey[200];
      // eslint-disable-next-line prefer-destructuring
      styles.caseLabel.borderColor = theme.palette.grey[200];
      styles.caseLabel.color = OriolaColors.secondaryDarkGray;
      styles.caseLabel["& svg"].fill = OriolaColors.secondaryDarkGray;
    } else {
      styles.caseLabel.color = `${theme.palette.primary.main} !important`;
    }
    return styles;
  });

export default useStyles;
