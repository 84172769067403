import React from "react";
import PropTypes from "prop-types";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import { Box } from "@material-ui/core";
import { getEachTypesOfItems } from "../../../utils/case/case";
import { CaseStatus } from "../../../constants/caseStatus";
import NarcoticsLabel from "./narcoticsLabel";
import SFMDLabel from "./sfmdLabel";
import RequestToCompensateIcon from "./requestToCompensateIcon";
import RequestToDisposeIcon from "./requestToDisposeIcon";
import RequestToSendIcon from "./requestToSendIcon";
import OtherRequestIcon from "./otherRequestIcon";
import NonReturningNarcoticsLabel from "./nonReturningNarcoticsLabel";
import TrackingCodeAddedIcon from "./trackingCodeIcon";
import PhotographRequestIcon from "./photographRequestIcon";
import WaitingComplaintReportIcon from "./waitingComplaintReportIcon";

const { isDisplayNarcoticsWarning } = ProductUtil;

function CaseIcons({ reclamation, isOriolaUser, isPrincipalUser, ...rest }) {
  const {
    isSFMD,
    productReturn,
    isRequestToSendTheProduct,
    isRequestToDispose,
    isRequestOther,
    isRequestToCompensate,
  } = reclamation;
  const isTrackingCodeAdded =
    !!reclamation.items[0]?.handlingData?.trackingCode;
  const isTrackingCodeReadConfirmed =
    reclamation.items[0]?.handlingData?.isTrackingCodeReadConfirmed;
  const { productOrders = [] } = getEachTypesOfItems(reclamation.items || []);
  const hasNarcoticsProduct = productOrders.some(p =>
    isDisplayNarcoticsWarning(p.product || p.missingProduct || {})
  );
  const isReturningProducts = !!productReturn && productReturn.returnValue;
  const isSolved = reclamation.caseStatus === CaseStatus.SOLVED;
  const isPhotoRequest =
    !!reclamation.items[0]?.handlingData?.productHandlingInstructions
      ?.isPhotographRequested;
  const isPhotoRequestFulfilled =
    !!reclamation.items[0]?.handlingData?.productHandlingInformation
      ?.isPhotographsTaken;
  const isWaitingPrincipalInstructions =
    reclamation.items[0]?.handlingData?.isOriolaWaitingForInstructions === true;
  const isWaitingComplaintReport =
    reclamation.isStatementRequired &&
    (!reclamation.items[0]?.handlingData?.conclusion ||
      (!reclamation.items[0]?.handlingData?.conclusion?.conclusion &&
        !reclamation.items[0]?.handlingData?.conclusion?.attachments?.length));
  const props = { grayed: isSolved };

  const renderNarcoticsLabel = () => {
    if (hasNarcoticsProduct) {
      if (isReturningProducts) {
        return <NarcoticsLabel {...props} />;
      }
      return <NonReturningNarcoticsLabel {...props} />;
    }
    return null;
  };

  const shouldShowPhotoRequestIcon =
    isPhotoRequest &&
    isWaitingPrincipalInstructions &&
    isOriolaUser &&
    !isPhotoRequestFulfilled;

  return (
    <Box {...rest}>
      {renderNarcoticsLabel()}
      {isSFMD && <SFMDLabel {...props} />}
      {isRequestToCompensate && <RequestToCompensateIcon {...props} />}
      {isRequestToDispose && <RequestToDisposeIcon {...props} />}
      {isRequestToSendTheProduct && <RequestToSendIcon {...props} />}
      {isRequestOther && <OtherRequestIcon {...props} />}
      {isTrackingCodeAdded && !isTrackingCodeReadConfirmed && (
        <TrackingCodeAddedIcon {...props} />
      )}
      {shouldShowPhotoRequestIcon && <PhotographRequestIcon />}
      {isWaitingComplaintReport && isPrincipalUser && (
        <WaitingComplaintReportIcon />
      )}
    </Box>
  );
}

CaseIcons.propTypes = {
  reclamation: PropTypes.shape({
    isSFMD: PropTypes.bool,
    productReturn: PropTypes.shape({
      returnValue: PropTypes.bool,
    }),
    isRequestToSendTheProduct: PropTypes.bool,
    isRequestToDispose: PropTypes.bool,
    isRequestOther: PropTypes.bool,
    isRequestToCompensate: PropTypes.bool,
    isStatementRequired: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        handlingData: PropTypes.shape({
          trackingCode: PropTypes.string,
          isTrackingCodeReadConfirmed: PropTypes.bool,
          productHandlingInstructions: PropTypes.shape({
            isPhotographRequested: PropTypes.bool,
          }),
          productHandlingInformation: PropTypes.shape({
            isPhotographsTaken: PropTypes.bool,
          }),
          isOriolaWaitingForInstructions: PropTypes.bool,
          conclusion: PropTypes.shape({
            conclusion: PropTypes.string,
            attachments: PropTypes.arrayOf(PropTypes.shape({})),
          }),
        }),
      })
    ),
    caseStatus: PropTypes.string,
  }),
  isOriolaUser: PropTypes.bool,
  isPrincipalUser: PropTypes.bool,
};

CaseIcons.defaultProps = {
  reclamation: {},
  isOriolaUser: false,
  isPrincipalUser: false,
};

export default CaseIcons;
