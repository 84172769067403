import { useCallback, useEffect } from "react";

const useWindowResize = listener => {
  const resizeListener = useCallback(listener, [listener]);

  useEffect(() => {
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [resizeListener]);
};

export default useWindowResize;
