import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AutoSizer, InfiniteLoader, List } from "react-virtualized";
import { Progress } from "@oriola-origo/origo-ui-core";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    "& a": {
      textDecoration: "none",
    },
  },
  list: {
    outline: "none",
  },
  progress: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function InfinityList({
  items,
  visibleItemCount,
  itemOverallCount,
  rowHeight,
  rowSpacing,
  showLoader,
  onRenderRow,
  onLoad,
  minimumBatchSize,
}) {
  const classes = useStyles();
  const overallRowHeight = rowHeight + rowSpacing;
  const listHeight = overallRowHeight * visibleItemCount;

  return (
    <div className={classes.root}>
      <div style={{ height: listHeight, width: "100%" }}>
        <InfiniteLoader
          isRowLoaded={({ index }) => !!items[index]}
          loadMoreRows={({ startIndex, stopIndex }) =>
            onLoad(startIndex, stopIndex)
          }
          rowCount={itemOverallCount || 1000}
          minimumBatchSize={minimumBatchSize || visibleItemCount}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height }) => (
                <List
                  className={classes.list}
                  height={height}
                  width={width}
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                  rowCount={items.length}
                  rowHeight={overallRowHeight}
                  rowRenderer={onRenderRow}
                  style={{ overflowY: "overlay" }}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </div>
      <div className={classes.progress}>
        <Progress size={25} show={showLoader} />
      </div>
    </div>
  );
}

InfinityList.propTypes = {
  // TODO: Fix this
  //  items: PropTypes.shape([]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  rowHeight: PropTypes.number.isRequired,
  onRenderRow: PropTypes.func.isRequired,
  rowSpacing: PropTypes.number,
  onLoad: PropTypes.func,
  visibleItemCount: PropTypes.number,
  itemOverallCount: PropTypes.number,
  showLoader: PropTypes.bool,
  minimumBatchSize: PropTypes.number,
};

InfinityList.defaultProps = {
  onLoad: null,
  rowSpacing: 0,
  visibleItemCount: 10,
  itemOverallCount: 10,
  showLoader: false,
  minimumBatchSize: null,
};

export default InfinityList;
