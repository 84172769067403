import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { RadioButtonSelection } from "../../common";
import {
  updateLocalHandlingDataField,
  updateValidationError,
} from "../../../redux";
import { getValidationErrorMessage } from "../../../utils/validations/validations";
import DateAndEditor from "../dateAndEditor";
import { getSpdModificationData } from "../../../utils/getSpdModificationData/getSpdModificationData";
import Title from "../title/title";
import ResetButton from "../../common/resetButton/resetButton";

export const ProductDisposers = Object.freeze({
  ORIOLA: "ORIOLA",
  CUSTOMER: "CUSTOMER",
});

const useStyles = makeStyles(theme => ({
  titleAndResetButton: {
    display: "flex",
    justifyContent: "space-between",
  },
  resetButtonOriola: {
    position: "absolute",
    right: theme.spacing(11),
    top: theme.spacing(4),
  },
}));

function CompensationPermission({
  user,
  disabled,
  currentUserIsOriolaUser,
  ...containerProps
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { localHandlingData = {} } = useSelector(state => state.case);
  const { spdPermissionRequiredFromPharmaCompany } = useSelector(
    state => state.validationErrors
  );
  const {
    isPermissionFromPharmaCompanyRequiredForCompensation,
    modifications = {},
  } = localHandlingData;

  const setPermissionRequired = updatedValue => {
    // clear validation errors
    dispatch(
      updateValidationError({ spdPermissionRequiredFromPharmaCompany: null })
    );

    const booleanValue = updatedValue === "true";
    dispatch(
      updateLocalHandlingDataField(
        booleanValue,
        "isPermissionFromPharmaCompanyRequiredForCompensation"
      )
    );
  };

  const resetPermissionRequired = () => {
    dispatch(
      updateValidationError({ spdPermissionRequiredFromPharmaCompany: null })
    );

    dispatch(
      updateLocalHandlingDataField(
        null,
        "isPermissionFromPharmaCompanyRequiredForCompensation"
      )
    );
  };

  const getPermissionRequired = () => {
    if (isPermissionFromPharmaCompanyRequiredForCompensation == null) {
      return "";
    }
    return isPermissionFromPharmaCompanyRequiredForCompensation === true
      ? "true"
      : "false";
  };

  const permissionRequiredValue = getPermissionRequired();
  const permissionModificationData = getSpdModificationData(
    modifications,
    "isPermissionFromPharmaCompanyRequiredForCompensation"
  );
  return (
    <Box
      id="compensation-permission"
      className="handling-section"
      {...containerProps}
    >
      <div className={classes.titleAndResetButton}>
        <Title title={t("compensationPermission.title")} />
        {currentUserIsOriolaUser && (
          <ResetButton
            className={classes.resetButtonOriola}
            onClick={resetPermissionRequired}
            disabled={!permissionRequiredValue}
          />
        )}
      </div>
      <RadioButtonSelection
        mt={3}
        disabled={disabled}
        value={permissionRequiredValue}
        button1Value="true"
        button2Value="false"
        onChecked={setPermissionRequired}
        title={t("compensationPermission.permissionTitle")}
        button1LabelText={t("compensationPermission.permissionRequired")}
        button2LabelText={t("compensationPermission.permissionNotRequired")}
        button1LabelChildren={
          isPermissionFromPharmaCompanyRequiredForCompensation === true ? (
            <DateAndEditor
              id="compensation-permission-required"
              modificationData={permissionModificationData}
              user={user}
            />
          ) : null
        }
        button2LabelChildren={
          isPermissionFromPharmaCompanyRequiredForCompensation === false ? (
            <DateAndEditor
              id="compensation-permission-not-required"
              modificationData={permissionModificationData}
              user={user}
            />
          ) : null
        }
        error={spdPermissionRequiredFromPharmaCompany != null}
        helperText={getValidationErrorMessage(
          spdPermissionRequiredFromPharmaCompany,
          t
        )}
      />
    </Box>
  );
}

CompensationPermission.propTypes = {
  user: PropTypes.shape({}),
  disabled: PropTypes.bool,
  currentUserIsOriolaUser: PropTypes.bool,
};

CompensationPermission.defaultProps = {
  disabled: false,
  user: {},
  currentUserIsOriolaUser: false,
};

export default CompensationPermission;
