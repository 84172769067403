import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { OriolaColors, OriolaTheme, Button } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { CaseType } from "../../../constants/caseType";
import {
  reopenCase,
  addNotification,
  submitConclusion,
  SendingStatus,
  updateValidationError,
  updateMessageAndQueueList,
  fetchMessages,
} from "../../../redux";
import { isPharmaceuticalCompany } from "../../auth/permission";
import Conclusion from "../conclusion/conclusion";
import { CaseCreatedIcon } from "../../../images";
import ReopenCaseDialog from "../reopenCaseDialog";
import { getConclusionErrors } from "./handlingDataValidation/suspectedProductDefectErrors";

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  caseHandlingMessage: {
    color: OriolaColors.DarkGrey,
    marginTop: theme.spacing(8),
    textAlign: "center",
  },
  caseHandlingMessageTitle: {
    ...OriolaTheme.typography.subtitle1,
    fontWeight: "normal",
    lineHeight: "24px",
  },
  caseHandlingMessageDetail: {
    ...OriolaTheme.typography.body2,
    fontSize: "14px",
    lineHeight: "24px",
  },
  reopenCase: {
    marginTop: theme.spacing(3),
    marginLeft: "auto",
    marginRight: "auto",
  },
  saveButton: {
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.2)",
    width: "50%",
    fontWeight: 700,
    marginTop: theme.spacing(3),
  },
  contentText: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.disabled,
  },
}));

function CompensationHandlingSolved({
  reclamationCase,
  currentUserIsOriolaUser,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.user);
  const { localHandlingData } = useSelector(state => state.case);
  const currentUserIsPharmaceuticalCompany = isPharmaceuticalCompany(userData);
  const [isEmptyConclusion, setIsEmptyConclusion] = useState(false);
  const [reopenCaseDialog, setReopenCaseDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    const { items } = reclamationCase;
    const { handlingData } = items[0];
    const { conclusion } = handlingData;
    if (!conclusion || !conclusion.conclusion) {
      setIsEmptyConclusion(true);
    } else {
      setIsEmptyConclusion(false);
    }
    dispatch(updateMessageAndQueueList());
    dispatch(fetchMessages(reclamationCase.reclamationId));
  }, [reclamationCase, dispatch]);

  const handleReopenCaseReason = () => {
    dispatch(
      reopenCase(
        reclamationCase.reclamationId,
        reclamationCase.version,
        message
      )
    ).then(res => {
      setReopenCaseDialog(false);
      if (res.status === "SUCCESS") {
        window.location.reload();
      } else {
        dispatch(
          addNotification({
            title: t("reopenFail.title"),
            primaryText: t("reopenFail.description"),
            type: "error",
          })
        );
      }
    });
  };

  const validateConclusionData = () => {
    const arrErrors = [];
    arrErrors.push(
      ...getConclusionErrors(reclamationCase, localHandlingData, true)
    );

    const errs = arrErrors.filter(x => x);
    const errorState = errs.reduce((acc, x) => ({ ...acc, ...x }), {});
    dispatch(updateValidationError(errorState));
    return errs.length === 0;
  };

  const saveHandlingData = (closeCase = false) => {
    if (validateConclusionData() === true) {
      setLoading(true);
      dispatch(submitConclusion(closeCase)).then(result => {
        if (result && result.status === SendingStatus.SUCCESS) {
          dispatch(
            addNotification({
              title: t("handlingInformation.savedSuccessfully"),
              icon: <CaseCreatedIcon />,
            })
          );
        }
        setLoading(false);
      });
    }
  };

  const renderReopenCaseButton = () => (
    <div className={classes.caseHandlingMessage}>
      <Typography className={classes.caseHandlingMessageTitle}>
        {t("caseHandlingSolved.title")}
      </Typography>
      <Button
        className={classes.reopenCase}
        onClick={() => setReopenCaseDialog(true)}
        id="reopen-case-button"
      >
        {t("reopenCase.button")}
      </Button>
      <ReopenCaseDialog
        open={reopenCaseDialog}
        handleCloseDialog={() => setReopenCaseDialog(false)}
        handleContinue={handleReopenCaseReason}
        message={message}
        setMessage={setMessage}
      />
    </div>
  );

  const renderConclusionFieldForPrincipal = () => (
    <>
      <Conclusion
        disabled={isPharmaceuticalCompany(userData) === false}
        user={userData}
      />
      <Box display="flex" justifyContent="flex-end">
        <Button
          onClick={() => saveHandlingData()}
          className={classes.saveButton}
          variant="primary"
          id="save-handling-data-button"
          loading={loading}
          disabled={loading}
        >
          {loading === true ? t("loading") : t("submit")}
        </Button>
      </Box>
    </>
  );

  const renderHandlingSolved = () => {
    const isSpdCase =
      reclamationCase.caseType === CaseType.SUSPECTED_PRODUCT_DEFECT;

    if (
      isSpdCase &&
      reclamationCase.isStatementRequired === true &&
      currentUserIsPharmaceuticalCompany &&
      isEmptyConclusion
    ) {
      return renderConclusionFieldForPrincipal();
    }
    if (currentUserIsOriolaUser) {
      return renderReopenCaseButton();
    }
    return (
      <div className={classes.container}>
        <div className={classes.caseHandlingMessage}>
          <Typography className={classes.caseHandlingMessageTitle}>
            {t("caseHandlingSolved.title")}
          </Typography>
          {currentUserIsOriolaUser === true && isSpdCase === false && (
            <Typography className={classes.caseHandlingMessageDetail}>
              {t("caseHandlingSolved.detail")}
            </Typography>
          )}
        </div>
      </div>
    );
  };

  return <div className={classes.container}>{renderHandlingSolved()}</div>;
}

CompensationHandlingSolved.propTypes = {
  reclamationCase: PropTypes.shape({
    caseStatus: PropTypes.string,
    currentUserIsOriolaUser: PropTypes.bool,
    reclamationId: PropTypes.string,
    version: PropTypes.number,
    caseType: PropTypes.string,
    isStatementRequired: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        handlingData: PropTypes.shape({
          conclusion: PropTypes.shape({
            conclusion: PropTypes.string,
          }),
        }),
      })
    ),
  }),
  currentUserIsOriolaUser: PropTypes.bool,
};

CompensationHandlingSolved.defaultProps = {
  reclamationCase: {},
  currentUserIsOriolaUser: false,
};

export default CompensationHandlingSolved;
