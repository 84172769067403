import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Box,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  makeStyles,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Button, OriolaColors } from "@oriola-origo/origo-ui-core";
import { StyledFormControl } from "./selectMenu";

export const PRODUCT_DETAILS_FILTER = Object.freeze({
  NARCOTIC: "NARCOTIC",
  NONRET_NARCOTIC: "NONRET_NARCOTIC",
  SFMD: "SFMD",
  REQUEST_COMPENSATE: "REQUEST_COMPENSATE",
  REQUEST_DISPOSAL: "REQUEST_DISPOSAL",
  REQUEST_SEND_PRODUCT: "REQUEST_SEND_PRODUCT",
  REQUEST_PHOTO: "REQUEST_PHOTO",
  REQUEST_OTHER: "REQUEST_OTHER",
  NEW_MESSAGE: "NEW_MESSAGE",
  TRACKING_CODE: "TRACKING_CODE",
  WAITING_COMPLAINT_REPORT: "WAITING_COMPLAINT_REPORT",
});

export const PRODUCT_DETAILS_FILTER_PRINCIPAL = Object.freeze({
  WAITING_COMPLAINT_REPORT: "WAITING_COMPLAINT_REPORT",
});

const useStyles = makeStyles(theme => ({
  button: {
    width: "50%",
    height: "40px",
    borderRadius: 0,
  },
  checkbox: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingLeft: 0,
    color: OriolaColors.secondaryNavyBlue,
  },
  selectedBackground: {
    backgroundColor: `${OriolaColors.white} !important`,
  },
}));

function ProductDetailsSelect({
  productDetail,
  onProductDetailsSelect,
  isPrincipalUser,
  ...containerProps
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const options = !isPrincipalUser
    ? Object.keys(PRODUCT_DETAILS_FILTER)
    : Object.keys(PRODUCT_DETAILS_FILTER_PRINCIPAL);
  const [selected, setSelected] = useState([]);
  const [storeSelected, setStoreSelected] = useState([]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  useEffect(() => {
    const values = productDetail ? productDetail.split(",") : [];
    setSelected(values);
    setStoreSelected(values);
  }, [productDetail, setSelected, setStoreSelected]);

  const handleCancel = e => {
    setSelected(storeSelected);
    setOpen(false);
    e.stopPropagation();
  };

  const handleOk = e => {
    onProductDetailsSelect(selected.length ? selected.join(",") : "");
    setOpen(false);
    e.stopPropagation();
  };

  const handleChange = event => {
    const { value } = event.target;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value);
  };

  const MenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  };

  return (
    <Box {...containerProps}>
      <StyledFormControl
        variant="outlined"
        size="small"
        fullWidth
        focused={open}
      >
        <InputLabel>{t("productDetails")}</InputLabel>
        <Select
          multiple
          value={selected}
          renderValue={selectedArr =>
            selectedArr
              .map(selectedVal => t(`productDetailsFilter.${selectedVal}`))
              .join(", ")
          }
          onOpen={() => {
            setOpen(true);
          }}
          open={open}
          onChange={handleChange}
          MenuProps={MenuProps}
          label={t("productDetails")}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                className={classes.checkbox}
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < options.length
                }
              />
            </ListItemIcon>
            <ListItemText primary={t("caseStatusFilter.all")} />
          </MenuItem>
          {options.map(option => (
            <MenuItem
              key={option}
              value={option}
              classes={{ selected: classes.selectedBackground }}
            >
              <ListItemIcon>
                <Checkbox
                  className={classes.checkbox}
                  checked={selected.indexOf(option) > -1}
                />
              </ListItemIcon>
              <ListItemText primary={t(`productDetailsFilter.${option}`)} />
            </MenuItem>
          ))}
          <Box
            mt={1}
            mb={-1}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Button
              className={classes.button}
              variant="secondary"
              onClick={handleCancel}
            >
              {t("cancel")}
            </Button>
            <Button
              className={classes.button}
              variant="primary"
              onClick={handleOk}
            >
              {t("ok")}
            </Button>
          </Box>
        </Select>
      </StyledFormControl>
    </Box>
  );
}

ProductDetailsSelect.propTypes = {
  productDetail: PropTypes.string,
  onProductDetailsSelect: PropTypes.func.isRequired,
  isPrincipalUser: PropTypes.bool,
};

ProductDetailsSelect.defaultProps = {
  productDetail: "",
  isPrincipalUser: false,
};

export default ProductDetailsSelect;
