export const ALLOWED_EXTENSIONS_LIST = [
  "pdf",
  "doc",
  "docx",
  "txt",
  "xls",
  "xlsx",
  "msg",
  "png",
  "jpg",
  "jpeg",
];

export const MIME_TYPES = {
  "application/pdf": ".pdf",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
  "image/jpeg": ".jpg",
  "image/png": ".png",
  "application/vnd. ms-outlook": ".msg",
  "application/vnd.ms-excel": ".xls",
  "application/msword": ".doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    ".docx",
  "text/plain": ".txt",
};
