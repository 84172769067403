import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SelectMenu from "./selectMenu";
import SortType from "../../../constants/sortType";

function SortSelect({ sortType, onSortTypeSelected, ...containerProps }) {
  const { t } = useTranslation();

  const getSortTypeLabel = value => (value ? t(`sort.${value}`) : "-");

  const options = Object.values(SortType).map(value => ({
    value,
    label: getSortTypeLabel(value),
  }));

  const handleSortSelected = value => {
    onSortTypeSelected(value);
  };

  return (
    <SelectMenu
      width="100%"
      optionValue={sortType || SortType.LATEST_MODIFIED}
      options={options}
      onSelectOption={handleSortSelected}
      label={t("sortCases")}
      {...containerProps}
    />
  );
}

SortSelect.propTypes = {
  sortType: PropTypes.string,
  onSortTypeSelected: PropTypes.func.isRequired,
};

SortSelect.defaultProps = {
  sortType: null,
};

export default SortSelect;
