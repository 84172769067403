import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import PropTypes from "prop-types";
import { Paper, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  fetchMessages,
  changeQueue,
  updateMessageAndQueueList,
  addNotification,
  setSubStatus,
} from "../../../redux";
import CaseDetails from "../caseDetails/caseDetails";
import { CaseHeading } from "../../common";
import getLocalizedCaseTypeName from "../../../utils/case";
import {
  isOriolaUser,
  isCustomer,
  isPharmaceuticalCompany,
} from "../../auth/permission";
import { closeAndPrintCaseDetails } from "../../draft/caseActions";
import ChangeQueueDialog from "../changeQueueDialog/changeQueueDialog";
import SetSubStatusDialog from "../setSubStatusDialog/setSubStatusDialog";
import { Paths } from "../../../utils/navigation/navigation";
import {
  handleOptimisticLockError,
  isOptimisticLockError,
} from "../../../utils/optimisticLock/optimisticLock";
import CaseViewButtons from "../caseViewButtons/caseViewButtons";
import CaseHeaderWarnings from "../caseHeaderWarnings/caseHeaderWarnings";
import TranslateFieldDialog from "../translateFieldDialog/translateFieldDialog";
import handleTranslationUpdate from "./handleTranslationUpdate";
// eslint-disable-next-line import/no-cycle
import FloatingCaseHeading from "../floatingCaseHeading/floatingCaseHeading";
import { CaseType } from "../../../constants/caseType";

const useStyles = makeStyles(theme => ({
  caseDetailWithColumn: {
    padding: theme.spacing(4),
    minHeight: 580,
    marginRight: theme.spacing(0.8),
  },
  caseDetail: {
    padding: theme.spacing(4),
    minHeight: 580,
  },
  printHeader: {
    display: "flex",
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
    ...theme.typography.body1,
    fontSize: "1.25rem",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));

function CaseViewContent({
  floatingHeaderContainerElement,
  reclamationCase,
  userData,
  currentCaseHandler,
  fetchingPdfUrl,
  history,
  isAllowedToEditHandlingData,
  onCopyCaseButtonClick,
  fieldsConfig,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const headerElementRef = useRef();
  const [translateDialogData, setTranslateDialogData] = useState({
    open: false,
  });
  const [openChangeQueueDialog, setOpenChangeQueueDialog] = useState(false);
  const [openSetSubStatusDialog, setOpenSetSubStatusDialog] = useState(false);
  const [department, setDepartment] = useState({ queue: "", subQueue: "" });
  const [internalMessage, setInternalMessage] = useState("");
  const currentUserIsOriolaUser = isOriolaUser(userData);
  const currentUserIsCustomer = isCustomer(userData);
  const currentUserIsPrincipal = isPharmaceuticalCompany(userData);
  const { reclamationId, version, queue, subQueue } = reclamationCase;
  const floatingHeaderEnabled =
    currentUserIsOriolaUser ||
    (currentUserIsPrincipal &&
      reclamationCase.caseType === CaseType.SUSPECTED_PRODUCT_DEFECT);
  const printComponentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const handleCloseChangeQueueDialog = () => {
    setDepartment({ queue: "", subQueue: "" });
    setOpenChangeQueueDialog(false);
  };

  const handleChangeQueue = async (
    destinationDepartment,
    message,
    destinationSubQueue
  ) => {
    const sourceQueue = subQueue || queue;
    dispatch(
      changeQueue(
        reclamationId,
        version,
        sourceQueue,
        destinationDepartment,
        message,
        destinationSubQueue
      )
    ).then(res => {
      setOpenChangeQueueDialog(false);
      if (res.status === "SUCCESS") {
        dispatch(
          addNotification({
            title: t("changeQueueSuccessfully.title"),
            primaryText: t("changeQueueSuccessfully.description"),
          })
        );
        if (currentUserIsOriolaUser || currentUserIsCustomer) {
          dispatch(fetchMessages(reclamationId)).then(() => {
            dispatch(updateMessageAndQueueList());
          });
        }
      } else if (isOptimisticLockError(res)) {
        handleOptimisticLockError(dispatch, t);
      } else {
        dispatch(
          addNotification({
            title: t("changeQueueFail.title"),
            primaryText: t("changeQueueFail.description"),
            type: "error",
          })
        );
      }
      setInternalMessage("");
      setDepartment({ queue: "", subQueue: "" });
    });
  };

  const handleCloseSetSubStatusDialog = () => {
    setOpenSetSubStatusDialog(false);
  };

  const handleChangeSubStatus = newSubStatusValue => {
    const updatedCase = {
      ...reclamationCase,
      caseSubStatus: newSubStatusValue,
    };

    dispatch(setSubStatus(updatedCase)).then(result => {
      if (result && result.status === "SUCCESS") {
        handleCloseSetSubStatusDialog();
      } else if (isOptimisticLockError(result)) {
        // sending failed, check if it's optimistic lock and open dialog to inform the user
        handleOptimisticLockError(dispatch, t);
      } else {
        dispatch(
          addNotification({
            title: t("saveEditedCaseFailed.title"),
            primaryText: t("saveEditedCaseFailed.description"),
            type: "error",
          })
        );
      }
    });
  };

  const onOpenTranslateDialogClick = (
    fieldType,
    originalText,
    translatedText
  ) => {
    setTranslateDialogData({
      open: true,
      fieldType,
      originalText,
      translatedText,
    });
  };

  const onTranslateFieldClick = (fieldType, text) => {
    // update to backend
    handleTranslationUpdate(dispatch, t, reclamationCase, fieldType, text);

    // hide dialog
    setTranslateDialogData({ open: false });
  };

  const headingText = [
    reclamationCase.caseNumber,
    getLocalizedCaseTypeName(reclamationCase.caseType, t),
  ].join(" ");

  return (
    <Box width={isAllowedToEditHandlingData ? "100%" : "70%"}>
      {isAllowedToEditHandlingData === false && (
        <CaseHeaderWarnings
          reclamationCase={reclamationCase}
          caseHandler={currentCaseHandler}
          userData={userData}
          fieldsConfig={fieldsConfig}
        />
      )}
      <Paper
        className={
          isAllowedToEditHandlingData
            ? classes.caseDetailWithColumn
            : classes.caseDetail
        }
      >
        <CaseHeading
          ref={headerElementRef}
          leftText={t("cases")}
          rightText={headingText}
        />

        {floatingHeaderEnabled && (
          <FloatingCaseHeading
            containerElement={floatingHeaderContainerElement}
            visibleAfterElement={headerElementRef}
            leftText={t("cases")}
            rightText={headingText}
          />
        )}

        <CaseViewButtons
          reclamationCase={reclamationCase}
          fetchingPdfUrl={fetchingPdfUrl}
          user={userData}
          onQueueButtonClick={() => setOpenChangeQueueDialog(true)}
          onEditCaseButtonClick={() => {
            history.push(
              Paths.EditCase.replace(":reclamationId", reclamationId)
            );
          }}
          onPrintButtonClick={() =>
            closeAndPrintCaseDetails(t, dispatch, reclamationId)
          }
          onCopyCaseButtonClick={onCopyCaseButtonClick}
          onChangeSubStatusClick={() => setOpenSetSubStatusDialog(true)}
          onPrintCaseDetailsClick={() => handlePrint()}
        />
        <Box
          id="case-printable-area"
          className="print-case"
          ref={printComponentRef}
        >
          <Box className="print-only">
            <Box class={classes.printHeader}>{headingText}</Box>
          </Box>
          <CaseDetails
            reclamationCase={reclamationCase}
            id="case-details"
            onTranslateClick={onOpenTranslateDialogClick}
          />
        </Box>
        <ChangeQueueDialog
          open={openChangeQueueDialog}
          handleCloseDialog={handleCloseChangeQueueDialog}
          handleContinue={handleChangeQueue}
          sourceQueue={queue}
          department={department}
          reclamationCase={reclamationCase}
          setDepartment={setDepartment}
          internalMessage={internalMessage}
          setInternalMessage={setInternalMessage}
        />
        <SetSubStatusDialog
          open={openSetSubStatusDialog}
          handleCloseDialog={handleCloseSetSubStatusDialog}
          handleChangeSubStatus={handleChangeSubStatus}
          reclamationCase={reclamationCase}
        />
        <TranslateFieldDialog
          {...translateDialogData}
          onTranslate={onTranslateFieldClick}
          onCancel={() => setTranslateDialogData({ open: false })}
        />
      </Paper>
    </Box>
  );
}

CaseViewContent.propTypes = {
  reclamationCase: PropTypes.shape({
    caseNumber: PropTypes.number,
    caseType: PropTypes.string,
    caseStatus: PropTypes.string,
    caseSubStatus: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    version: PropTypes.string,
    queue: PropTypes.string,
    subQueue: PropTypes.string,
    relatedCases: PropTypes.arrayOf(PropTypes.shape({})),
    reasonIdentifier: PropTypes.string,
    sourceReclamationId: PropTypes.string,
    modificationTime: PropTypes.string,
    reclamationId: PropTypes.string,
  }),
  floatingHeaderContainerElement: PropTypes.shape({}),
  userData: PropTypes.shape({}),
  currentCaseHandler: PropTypes.shape({}),
  fetchingPdfUrl: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isAllowedToEditHandlingData: PropTypes.bool,
  onCopyCaseButtonClick: PropTypes.func,
  fieldsConfig: PropTypes.shape({}),
};

CaseViewContent.defaultProps = {
  reclamationCase: {},
  floatingHeaderContainerElement: {},
  userData: {},
  currentCaseHandler: {},
  fetchingPdfUrl: false,
  history: {},
  isAllowedToEditHandlingData: false,
  onCopyCaseButtonClick: null,
  fieldsConfig: {},
};

export default CaseViewContent;
