import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { EcomUrls } from "../../../utils/navigation/navigation";

const useStyles = makeStyles(() => ({
  placeholder: {
    height: "69px",
    width: "100%",
  },
}));

function OrigoHeader() {
  const classes = useStyles();
  const { tokenData } = useSelector(state => state.user);
  const [loaded, setLoaded] = useState(false);

  let divRef;
  const setRef = ref => {
    divRef = ref;
  };

  useEffect(() => {
    if (!loaded) {
      const script = document.createElement("script");
      script.src = process.env.REACT_APP_ORIGO_HEADER_URL;
      script.async = true;
      divRef.appendChild(script);
      script.onload = () => {
        setLoaded(true);
      };
    }
  }, [tokenData, setLoaded, divRef, loaded]);

  const { Origo } = window;
  return (
    <div ref={setRef}>
      {loaded && (
        <div className="no-print">
          <Origo.Header
            accessToken={tokenData.accessToken}
            // none is active when reclamation is open
            activeMenuItem={null}
            urls={{
              products: EcomUrls.Products,
              reports: EcomUrls.Reports,
              orders: EcomUrls.OrdersInvoices,
              cart: EcomUrls.ShoppingCart,
            }}
          />
        </div>
      )}
      {!loaded && <div className={classes.placeholder} />}
    </div>
  );
}

OrigoHeader.propTypes = {};

OrigoHeader.defaultProps = {};

export default OrigoHeader;
