import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { OriolaTheme } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import { Chip } from "@material-ui/core";
import { getProductDisplayName } from "../../../utils/productUtil/productUtil";
import CaseItemsType from "../../../constants/caseItemsType";
import { removeCaseHandlingItems } from "../../../redux";

const useStyles = makeStyles(theme => ({
  productChipArea: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  productChip: {
    ...OriolaTheme.typography.body2,
    backgroundColor: "#F5F5F5",
    borderRadius: "8px",
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    maxWidth: "400px",
    textOverflow: "ellipsis",
  },
}));

function ProductChips() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { caseHandlingItems: selectedItems = [] } = useSelector(
    state => state.case
  );
  const reclamationCase = useSelector(state => state.case.case);

  const getChipLabelForItem = item => {
    if (item.type === CaseItemsType.PRODUCT_ORDER) {
      return getProductDisplayName(item.product || item.missingProduct || {});
    }
    if (item.type === CaseItemsType.SHIPPING_BOX) {
      return `${t("shippingBox.name")} ${
        item.serialShippingContainerCode || ""
      }`;
    }
    if (item.type === CaseItemsType.ENTIRE_ORDER) {
      return `${t("entireOrder.name")} ${item.orderNumber || ""}`;
    }
    if (item.type === CaseItemsType.NO_ITEM) {
      return t(
        `caseInformation.reasonIdentifiers.${reclamationCase.reasonIdentifier}`
      );
    }
    return null;
  };

  const deselectItem = item => {
    dispatch(removeCaseHandlingItems([item]));
  };

  return (
    /** Fix layout streching by add static width for product card. This should be fixed cleanly when we adopt ods */
    <div
      className={classes.productChipArea}
      id="selected-items"
      style={{ width: "550px" }}
    >
      {selectedItems.map(x => (
        <Chip
          key={`selectedItemChip-${x.uuid}`}
          className={classes.productChip}
          label={getChipLabelForItem(x)}
          onDelete={() => deselectItem(x)}
        />
      ))}
    </div>
  );
}

ProductChips.propTypes = {};

ProductChips.defaultProps = {};

export default ProductChips;
