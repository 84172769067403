import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Title from "../title/title";
import Row from "../row/row";
import CourierIds from "../../../constants/couriers";
import { getCaseConfig } from "../../../utils/case/case";
import { CaseType } from "../../../constants/caseType";
import AdverseReaction from "./adverseReaction";
import TranslateButton from "../translateButton/translateButton";
import { TranslateField } from "../translateFieldDialog/translateFieldDialog";
import EventQueueTarget from "../../../constants/eventQueueTarget";
import RowComponent from "../row/rowComponent";
import TranslatedText from "../translatedText/translatedText";
import ConditionalRow from "../row/conditionalRow";

const objectPath = require("object-path");

const useStyles = makeStyles(() => ({
  root: {},
}));

function CaseInformation({
  reclamationCase,
  currentUserIsOriolaUser,
  currentUserIsPharmaUser,
  onTranslateClick,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    reasonIdentifier,
    productReturn,
    caseDescription,
    caseType,
    suspectedInfectiousProduct,
    suspectedAdverseReaction,
    detectedBy,
    isCustomerCompensated,
    isStatementRequired,
  } = reclamationCase;
  const {
    DamagedProductConfig,
    ProductReturnReasonIdentifiersConfig,
    SuspectedProductDefectConfig,
  } = useSelector(state => state.fieldsConfig);

  const caseConfig =
    getCaseConfig(
      caseType,
      productReturn,
      reasonIdentifier,
      DamagedProductConfig,
      ProductReturnReasonIdentifiersConfig,
      SuspectedProductDefectConfig
    ) || {};

  const reason = reasonIdentifier;
  const willReturn = productReturn ? productReturn.returnValue : false;

  const isConvertedCase = tmpReclamationCase =>
    !!(tmpReclamationCase || {}).sourceReclamationId;

  const getCourierNameById = courierId => {
    if (courierId === CourierIds.POSTNORD) {
      return t("courier.postnord");
    }
    if (courierId === CourierIds.POSTI) {
      return t("courier.posti");
    }
    return "-";
  };

  const getDetectedBy = () => {
    if (detectedBy.code === "OTHER") {
      return t(`caseInformation.detectedBy.${detectedBy.code}`).concat(
        ": ",
        detectedBy.description
      );
    }
    return t(`caseInformation.detectedBy.${detectedBy.code}`);
  };

  const getYesNo = trueFalse => (trueFalse ? t("yes") : t("no"));

  const showTranslateButton = () =>
    currentUserIsOriolaUser &&
    reclamationCase.queue === EventQueueTarget.TRANSLATIONS;

  const showTranslatedText = () =>
    reclamationCase.translationsRequired === true &&
    (currentUserIsOriolaUser || currentUserIsPharmaUser);

  if (Object.keys(caseConfig).length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Title title={t("caseInformation.caseInformation")} />
      <Row
        rowLeft={["caseInformation.reasonIdentifier"]}
        rowRight={[t(`caseInformation.reasonIdentifiers.${reason}`)]}
      />
      {caseType !== CaseType.SUSPECTED_PRODUCT_DEFECT && caseDescription && (
        <Row
          rowLeft={["caseInformation.caseDescription"]}
          rowRight={[caseDescription]}
        />
      )}
      <ConditionalRow
        condition={caseConfig.suspectedInfectiousProduct}
        rowTitleKey="returnOfSuspectedInfectiousProduct.title"
        rowValue={getYesNo(suspectedInfectiousProduct)}
      />
      <ConditionalRow
        condition={caseType !== CaseType.SUSPECTED_PRODUCT_DEFECT}
        rowTitleKey="productReturnInfo"
        rowValue={getYesNo(willReturn)}
      />
      {willReturn && !isConvertedCase(reclamationCase) && (
        <>
          <Row
            rowLeft={["productReturnDetail"]}
            rowRight={[productReturn.amount || "-"]}
          />
          <Row
            rowLeft={["courier.rowTitle"]}
            rowRight={[getCourierNameById(reclamationCase.courierCompanyId)]}
          />
        </>
      )}
      <ConditionalRow
        condition={!!caseConfig.detectedBy}
        rowTitleKey="caseInformation.defectDetectedBy.title"
        rowValue={detectedBy && getDetectedBy()}
      />
      {caseType === CaseType.SUSPECTED_PRODUCT_DEFECT && caseDescription && (
        <RowComponent
          rowTitleKey="caseInformation.defectDetectedBy.describe"
          rowValueComponent={
            <TranslatedText
              originalText={caseDescription}
              translatedText={objectPath.get(
                reclamationCase,
                "translations.caseDescription"
              )}
              hideTranslatedText={showTranslatedText() === false}
            />
          }
          rowButtonComponent={
            showTranslateButton() ? (
              <TranslateButton
                id="case-description-translate-button"
                onClick={() =>
                  onTranslateClick(
                    TranslateField.DESCRIPTION_OF_DEFECT,
                    caseDescription,
                    objectPath.get(
                      reclamationCase,
                      "translations.caseDescription"
                    )
                  )
                }
              />
            ) : null
          }
        />
      )}
      <ConditionalRow
        condition={caseConfig.isCustomerCompensated && !!isCustomerCompensated}
        rowTitleKey="caseInformation.isCustomerCompensated.title"
        rowValue={isCustomerCompensated && getYesNo(isCustomerCompensated.code)}
      />
      {caseConfig.isCustomerCompensated && isCustomerCompensated && (
        <RowComponent
          rowTitleKey="caseInformation.howWasCustomerCompensated.title"
          rowValueComponent={
            <TranslatedText
              originalText={isCustomerCompensated.description}
              translatedText={objectPath.get(
                reclamationCase,
                "translations.isCustomerCompensated.description"
              )}
              hideTranslatedText={showTranslatedText() === false}
            />
          }
          rowButtonComponent={
            showTranslateButton() ? (
              <TranslateButton
                id="customer-compensated-description-translate-button"
                onClick={() =>
                  onTranslateClick(
                    TranslateField.IS_CUSTOMER_COMPENSATED_DESCRIPTION,
                    isCustomerCompensated.description,
                    objectPath.get(
                      reclamationCase,
                      "translations.isCustomerCompensated.description"
                    )
                  )
                }
              />
            ) : null
          }
        />
      )}
      <ConditionalRow
        condition={caseConfig.isStatementRequired}
        rowTitleKey="isStatementRequired"
        rowValue={getYesNo(isStatementRequired)}
      />
      <AdverseReaction
        caseConfig={caseConfig}
        suspectedAdverseReaction={suspectedAdverseReaction}
        onTranslateClick={onTranslateClick}
        translatedText={objectPath.get(
          reclamationCase,
          "translations.suspectedAdverseReaction.description"
        )}
        showTranslateButton={showTranslateButton()}
        showTranslatedText={showTranslatedText()}
      />
    </div>
  );
}

CaseInformation.propTypes = {
  reclamationCase: PropTypes.shape({
    reasonIdentifier: PropTypes.string,
    productReturn: PropTypes.shape({
      returnValue: PropTypes.bool,
      amount: PropTypes.number,
    }),
    caseDescription: PropTypes.string,
    caseType: PropTypes.string,
    suspectedInfectiousProduct: PropTypes.bool,
    suspectedAdverseReaction: PropTypes.shape({
      description: PropTypes.string,
    }),
    detectedBy: PropTypes.shape({
      code: PropTypes.string,
      description: PropTypes.string,
    }),
    isCustomerCompensated: PropTypes.shape({
      code: PropTypes.bool,
      description: PropTypes.string,
    }),
    isStatementRequired: PropTypes.bool,
    courierCompanyId: PropTypes.string,
    translationsRequired: PropTypes.bool,
    translations: PropTypes.shape({
      suspectedAdverseReaction: PropTypes.shape({
        description: PropTypes.string,
      }),
    }),
    queue: PropTypes.string,
    sourceReclamationId: PropTypes.string,
  }),
  onTranslateClick: PropTypes.func,
  currentUserIsOriolaUser: PropTypes.bool,
  currentUserIsPharmaUser: PropTypes.bool,
};

CaseInformation.defaultProps = {
  reclamationCase: {
    translations: {
      suspectedAdverseReaction: {},
    },
  },
  onTranslateClick: null,
  currentUserIsOriolaUser: false,
  currentUserIsPharmaUser: false,
};

export default CaseInformation;
