import React from "react";
import { useTranslation } from "react-i18next";
import CaseLabel from "./caseLabel";

function WaitingComplaintReportIcon() {
  const { t } = useTranslation();
  return (
    <CaseLabel
      label={t("productHandlingInstructions.waitingComplaintReport")}
      tooltip={t("productHandlingInstructions.waitingComplaintReportTooltip")}
      grayed={false}
    />
  );
}

export default WaitingComplaintReportIcon;
