import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import moment from "moment";
import { MuiDatePicker } from "@oriola-origo/origo-ui-core";
// eslint-disable-next-line import/no-cycle
import { FieldTitle } from "..";

function DateSelect({
  title,
  optional,
  date,
  onSelect,
  id,
  error,
  helperText,
  placeholder,
  disableFuture,
  width,
  disabled,
}) {
  const { i18n } = useTranslation();

  return (
    <Box>
      <FieldTitle title={title} type="selection" optional={optional} />
      <Box width={width}>
        <MuiDatePicker
          error={!!error}
          helperText={helperText}
          value={date ? moment(date) : null}
          onChange={onSelect}
          disableFuture={disableFuture}
          locale={i18n.language}
          placeholder={placeholder}
          id={id}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
}

DateSelect.propTypes = {
  date: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  title: PropTypes.string,
  optional: PropTypes.bool,
  id: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  disableFuture: PropTypes.bool,
  width: PropTypes.string,
  disabled: PropTypes.bool,
};

DateSelect.defaultProps = {
  optional: false,
  disableFuture: false,
  date: "",
  title: "",
  id: "",
  error: "",
  helperText: "",
  placeholder: "",
  width: "100%",
  disabled: false,
};

export default DateSelect;
