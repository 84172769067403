import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox, FormControlLabel } from "@oriola-origo/origo-ui-core";
// eslint-disable-next-line import/no-cycle
import { updateCase, updateValidationError } from "../../../../redux";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";
import { CaseType } from "../../../../constants/caseType";

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(5),
  },
  titleText: {
    ...theme.typography.subtitle2,
    color: "#757070",
    marginBottom: theme.spacing(1),
  },
  titleTextDefault: {
    ...theme.typography.subtitle2,
    color: "#757070",
    marginTop: theme.spacing(1),
  },
  label: {
    fontSize: "0.875rem",
  },
  formControlLabel: {
    marginRight: theme.spacing(6),
  },
  error: {
    fontSize: "1rem",
    color: theme.palette.error.main,
  },
  checkboxContainer: {
    marginTop: theme.spacing(1),
  },
}));

function InformationConfirmation({ disabled }) {
  const classes = useStyles();
  const {
    productReturn,
    confirm,
    certify,
    caseType,
    suspectedInfectiousProduct,
    sourceReclamationId,
  } = useSelector(state => state.case.case);
  const { returnConfirmError, certifyError } = useSelector(
    state => state.validationErrors
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  if (!productReturn || sourceReclamationId) {
    return null;
  }
  const handleCheckBox = e => {
    const value = e.target.checked;
    const { name } = e.target;
    if (name === "certify") {
      dispatch(updateValidationError({ certifyError: "" }));
    } else {
      dispatch(updateValidationError({ returnConfirmError: "" }));
    }
    dispatch(updateCase({ [name]: value }));
  };

  const getLableForConfirmation = () => {
    if (caseType === CaseType.SUSPECTED_PRODUCT_DEFECT) {
      return t("confirmDescriptionYes");
    }
    return productReturn.returnValue
      ? t("confirmDescriptionYes")
      : t("confirmDescriptionNo");
  };

  return (
    <div className={classes.formControl}>
      <Typography
        className={
          productReturn.returnValue
            ? classes.titleText
            : classes.titleTextDefault
        }
        noWrap
      >
        {t("confirmation")}
      </Typography>
      {suspectedInfectiousProduct === false && (
        <FormControlLabel
          className={classes.checkboxContainer}
          control={
            <Checkbox
              onChange={handleCheckBox}
              name="certify"
              checked={certify || false}
            />
          }
          id="new-case-certify"
          disabled={disabled}
          label={t("returnOfSuspectedInfectiousProduct.confirmCheckBox")}
          data-error-name="certifyError"
        />
      )}
      {suspectedInfectiousProduct === false && certifyError && (
        <FormHelperText
          className={classes.error}
          id="new-case-certify-helper-text"
        >
          {t(getValidationErrorMessage(certifyError, t))}
        </FormHelperText>
      )}
      <FormControlLabel
        className={classes.checkboxContainer}
        control={
          <Checkbox
            onChange={handleCheckBox}
            name="confirm"
            checked={confirm || false}
          />
        }
        id="new-case-confirm"
        disabled={disabled}
        label={getLableForConfirmation()}
        data-error-name="returnConfirmError"
      />
      {returnConfirmError && (
        <FormHelperText
          className={classes.error}
          id="new-case-confirm-helper-text"
        >
          {getValidationErrorMessage(returnConfirmError, t)}
        </FormHelperText>
      )}
    </div>
  );
}

InformationConfirmation.propTypes = {
  disabled: PropTypes.bool,
};

InformationConfirmation.defaultProps = {
  disabled: false,
};

export default InformationConfirmation;
