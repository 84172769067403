const getPhoneNumbers = c => {
  const phoneNumbers = [];
  const { TelNumber, TelNumber2 } = c || {};
  if (TelNumber) {
    phoneNumbers.push(TelNumber);
  }
  if (TelNumber2 && phoneNumbers.indexOf(TelNumber2) < 0) {
    phoneNumbers.push(TelNumber2);
  }
  return phoneNumbers;
};

export const formatCustomerName = customer => {
  if (!customer) {
    return "";
  }

  return [customer.Name1, customer.Name2].filter(x => x).join(", ");
};

const formatDeliveryAddress = addr => {
  if (!addr) {
    return "";
  }
  const streetAddress = [addr.Street, addr.HouseNum1, addr.HouseNum2]
    .filter(x => x)
    .join(" ");
  const postAddress = [addr.PostCode1, addr.City1].filter(x => x).join(" ");
  return [streetAddress, postAddress].join(", ");
};

export const customerComparator = (c1, c2) => {
  if (c1.customerId === c1.statisticalCustomerId) {
    return -1;
  }
  if (c2.customerId === c2.statisticalCustomerId) {
    return +1;
  }
  const name1 = c1.name;
  const name2 = c2.name;
  return name1.localeCompare(name2, undefined, { sensitivity: "accent" });
};

const transformDeliveryAddress = a => ({
  ...a,
  address: formatDeliveryAddress(a),
});

export const transformCustomer = c =>
  // add formatted name, phone numbers and formatted delivery addresses
  ({
    ...c,
    name: formatCustomerName(c),
    phoneNumbers: getPhoneNumbers(c),
    deliveryAddresses: (c.deliveryAddresses || []).map(
      transformDeliveryAddress
    ),
  });
