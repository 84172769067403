import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { newCase, clearValidationErrors, updateContactInfo } from "../../redux";
import { ProductDefect, ProductDelivery, DamagedProductDraft } from "../draft";
import FieldsCaseView from "../fields/fieldsCaseView/fieldsCaseView";

function NewCaseView({ ...props }) {
  const dispatch = useDispatch();
  const reclamationType = props.match.params.caseType;
  useEffect(() => {
    dispatch(newCase(reclamationType));
    dispatch(clearValidationErrors());
    dispatch(updateContactInfo({ customer: null }));
  }, [dispatch, reclamationType]);

  return (
    <FieldsCaseView
      ProductDefect={ProductDefect}
      ProductDelivery={ProductDelivery}
      DamagedProduct={DamagedProductDraft}
      isNewCase
      {...props}
    />
  );
}

NewCaseView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      caseType: PropTypes.string,
    }),
  }),
};

NewCaseView.defaultProps = {
  match: { params: undefined },
};

export default NewCaseView;
