const Operation = Object.freeze({
  SAVE_DRAFT: "SAVE_DRAFT",
  SUBMIT: "SUBMIT",
  EDIT: "EDIT",
  REOPEN: "REOPEN",
  CLOSE: "CLOSE",
  REQUEST_INFORMATION_FROM_CUSTOMER: "REQUEST_INFORMATION_FROM_CUSTOMER",
  PROVIDE_INFORMATION_BY_CUSTOMER: "PROVIDE_INFORMATION_BY_CUSTOMER",
  SAVE_TRANSLATION: "SAVE_TRANSLATION",
  SET_SUB_STATUS: "SET_SUB_STATUS",
  COPY_SAVE_DRAFT: "COPY_SAVE_DRAFT",
  COPY: "COPY",
});

module.exports = Operation;
