import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";
import OrigoHeader from "../header/origoHeader";
import NotificationPopups from "../notificationPopups/notificationPopups";
import Dialog from "../dialog/dialog";
import LeftNavigationPane from "../navigationPane/leftNavigationPane";
import { isAllowed, Permission, ANY_CUSTOMER } from "../../auth";
import GenericPopup from "../../generic/popup/popup";

const useStyles = makeStyles(theme => ({
  container: {
    overflow: "hidden",
    backgroundColor: theme.palette.background.default,
    "@media print": {
      backgroundColor: "white",
    },
  },
}));

export const APP_HEADER_HEIGHT = 69;
export const NAVIGATION_PANE_WIDTH = 90;

let forceNavigate = false;
function Layout({ children, loadingPanel }) {
  const history = useHistory();
  const { location } = history;
  const classes = useStyles();
  const { userData } = useSelector(state => state.user);
  const [allowedAddNewCase, setAllowedAddNewCase] = useState(false);
  const [unsavedChangesSettings, setUnsavedChangesSettings] = useState({
    showModal: false,
    lastLocation: location,
  });
  useEffect(() => {
    setAllowedAddNewCase(
      isAllowed(userData, Permission.CASE_CREATE, ANY_CUSTOMER)
    );
  }, [userData]);

  const isFormModefied = () =>
    !!document.querySelectorAll(".unsaved-label").length;

  const handleBlockedNavigation = nextLocation => {
    if (!forceNavigate && isFormModefied()) {
      forceNavigate = false;
      setUnsavedChangesSettings({
        showModal: true,
        lastLocation: nextLocation,
      });
      return false;
    }
    forceNavigate = false;
    return true;
  };

  const handleConfirmNavigationClick = () => {
    forceNavigate = true;
    setUnsavedChangesSettings({ ...unsavedChangesSettings, showModal: false });
    history.push(unsavedChangesSettings.lastLocation.pathname);
  };

  const appContentHeight = `calc(100vh - ${APP_HEADER_HEIGHT}px)`;
  return (
    <Box className={classes.container} height="100vh">
      <Prompt when message={handleBlockedNavigation} />
      <GenericPopup
        title="discard_changes"
        content="changes_not_saved"
        iconType="camera"
        show={unsavedChangesSettings.showModal}
        confirmTitle="continue_editing"
        cancelTitle="discard"
        onConfirm={() =>
          setUnsavedChangesSettings({
            ...unsavedChangesSettings,
            showModal: false,
          })
        }
        onCancel={handleConfirmNavigationClick}
        onClose={() =>
          setUnsavedChangesSettings({
            ...unsavedChangesSettings,
            showModal: false,
          })
        }
      />
      <OrigoHeader />
      <Box display="flex" minHeight={appContentHeight}>
        <LeftNavigationPane
          loadingPanel={loadingPanel}
          allowedAddNewCase={allowedAddNewCase}
        />
        <Box width="100%" py={4}>
          {children}
        </Box>
      </Box>
      <NotificationPopups />
      <Dialog />
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  loadingPanel: PropTypes.bool,
};

Layout.defaultProps = {
  loadingPanel: false,
};

export default Layout;
