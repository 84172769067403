import React from "react";
import PropTypes from "prop-types";
import FieldsCaseView from "../fields/fieldsCaseView/fieldsCaseView";
import DamagedProductEdit from "./damagedProductEdit/damagedProductEdit";
import ProductDeliveryEdit from "./productDeliveryEdit/productDeliveryEdit";
import ProductDefectEdit from "./ProductDefectEdit/ProductDefectEdit";

function CaseView(props) {
  return (
    <FieldsCaseView
      DamagedProduct={DamagedProductEdit}
      ProductDelivery={ProductDeliveryEdit}
      ProductDefect={ProductDefectEdit}
      editMode
      {...props}
    />
  );
}

CaseView.propTypes = {
  match: PropTypes.shape({}),
};

CaseView.defaultProps = {
  match: { params: undefined },
};

export default CaseView;
