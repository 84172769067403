import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { getReclamationCasePath } from "../../utils/navigation/navigation";
import { CaseStatus } from "../../constants/caseStatus";
import {
  CaseHandler,
  CaseItems,
  VnrMsi,
  Customer,
  ProductsNumber,
  Dates,
  CaseTypeCell,
  CaseNumber,
  TableCell,
  CaseStatusCell,
  TableBody,
  TableHeader,
  TableRow,
  MessagesCell,
} from "../casesTable";
import Principal from "../casesTable/principal";
import { isOriolaUser, isCustomer } from "../auth/permission";
import { FIXED_ROW_HEIGHT, ROW_SPACING } from "../casesTable/constant";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& p": {
      lineHeight: "200%",
      [theme.breakpoints.down("md")]: {
        lineHeight: "150%",
        overflow: "hidden",
      },
      [theme.breakpoints.down("sm")]: {
        lineHeight: "110%",
        overflow: "hidden",
      },
    },
  },
}));

const ColumnWidth = {
  caseNumber: "10%",
  products: "20%",
  productNro: "12%",
  customer: "25%",
  date: "10%",
  caseStatus: "8%",
  principal: "9%",
  messages: "6%",
};

function CasesTable({
  userData,
  cases,
  onLoadMore,
  fetching,
  overallCount,
  minimumBatchSize,
  filtersVisible,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const baseRef = useRef(null);

  // Count how many rows fits to window
  // NOTE: As row height is defined as constant, this may not work well as it can be more
  // in case where row content takes more rows as expected
  const countVisibleItemsCount = () => {
    const rowHeigth = FIXED_ROW_HEIGHT + ROW_SPACING;
    const windowHeight = window.innerHeight;
    const headerTop = baseRef.current?.offsetTop ?? 0;

    const availableSpaceHeight = windowHeight - (headerTop + rowHeigth);
    const rowsCount = Math.floor(availableSpaceHeight / rowHeigth);

    // Minimum 3 rows displayed
    if (rowsCount < 3) {
      return 3;
    }

    return rowsCount;
  };
  const [resized, setResized] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setResized(true);
    };

    let timeout;
    window.addEventListener("resize", () => {
      clearTimeout(timeout);
      timeout = setTimeout(handleResize, 20);
    });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setResized(true);
  }, [filtersVisible]);

  // If screen is resized => need to re-calculate how many rows fits to window
  if (resized) {
    setResized(false);
  }

  const currentUserIsOriolaUser = isOriolaUser(userData);
  const currentUserIsCustomer = isCustomer(userData);

  const currentUserIsPharmaCompany =
    currentUserIsOriolaUser === false && currentUserIsCustomer === false;
  const headers = [
    {
      title1: t("casesTable.caseNumber"),
      title2: t("casesTable.caseType"),
      width: ColumnWidth.caseNumber,
      show: true,
    },
    {
      title1: currentUserIsPharmaCompany
        ? t("casesTable.product")
        : t("casesTable.productShippingBox"),
      title2: currentUserIsOriolaUser ? t("casesTable.productDetails") : "",
      width: ColumnWidth.products,
      show: true,
    },
    {
      title1: t("casesTable.productNumber"),
      title2: t("vnrmsi"),
      width: ColumnWidth.productNro,
      show: true,
    },
    {
      title1: t("casesTable.customerName"),
      title2: t("casesTable.customerNumber"),
      width: ColumnWidth.customer,
      show: true,
    },
    {
      title1: t("created"),
      title2: t("modified"),
      width: ColumnWidth.date,
      show: true,
    },
    {
      title1: t("casesTable.caseStatus"),
      title2: currentUserIsOriolaUser ? t("handler") : "",
      width: ColumnWidth.caseStatus,
      show: true,
    },
    {
      title1: t("casesTable.principal"),
      title2: currentUserIsOriolaUser ? t("casesTable.principalNo") : "",
      width: ColumnWidth.principal,
      show: true,
    },
    {
      title1: t("messages"),
      width: ColumnWidth.messages,
      show: true,
    },
  ];

  const rowRenderer = ({ key, index, style }) => {
    const singleCase = cases[index] || {};
    const isSolved = singleCase.caseStatus === CaseStatus.SOLVED;
    return (
      <TableRow
        key={key}
        linkto={getReclamationCasePath(
          singleCase.reclamationId,
          singleCase.caseStatus
        )}
        style={style}
        isSolved={isSolved}
      >
        <TableCell style={{ width: ColumnWidth.caseNumber }}>
          <CaseNumber singleCase={singleCase} />
          <CaseTypeCell singleCase={singleCase} />
        </TableCell>
        <TableCell style={{ width: ColumnWidth.products }}>
          <CaseItems
            reclamation={singleCase}
            isOriolaUser={currentUserIsOriolaUser}
            isPrincipalUser={currentUserIsPharmaCompany}
          />
        </TableCell>
        <TableCell style={{ width: ColumnWidth.productNro }}>
          <ProductsNumber items={singleCase.items} />
          <VnrMsi items={singleCase.items} />
        </TableCell>
        <TableCell style={{ width: ColumnWidth.customer }}>
          <Customer singleCase={singleCase} />
        </TableCell>
        <TableCell style={{ width: ColumnWidth.date }}>
          <Dates singleCase={singleCase} />
        </TableCell>
        <TableCell style={{ width: ColumnWidth.caseStatus }}>
          <CaseStatusCell
            singleCase={singleCase}
            currentUserIsOriolaUser={currentUserIsOriolaUser}
            currentUserIsCustomer={currentUserIsCustomer}
          />
          {currentUserIsOriolaUser && <CaseHandler singleCase={singleCase} />}
        </TableCell>
        <TableCell style={{ width: ColumnWidth.principal }}>
          <Principal
            singleCase={singleCase}
            currentUserIsOriolaUser={currentUserIsOriolaUser}
          />
        </TableCell>
        <TableCell
          style={{
            width: ColumnWidth.messages,
            boxSizing: "border-box",
            paddingRight: "2rem",
          }}
        >
          <MessagesCell reclamationCase={singleCase} userData={userData} />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div ref={baseRef} className={classes.root} id="cases-table">
      <TableHeader headers={headers} />
      <TableBody
        cases={cases}
        visibleItemCount={countVisibleItemsCount()}
        overallCount={overallCount}
        fetching={fetching}
        rowRenderer={rowRenderer}
        onLoadMore={onLoadMore}
        emptyText={t("noCaseFound")}
        minimumBatchSize={minimumBatchSize}
      />
    </div>
  );
}

CasesTable.propTypes = {
  userData: PropTypes.shape({}).isRequired,
  cases: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onLoadMore: PropTypes.func,
  overallCount: PropTypes.number,
  fetching: PropTypes.bool,
  minimumBatchSize: PropTypes.number,
  filtersVisible: PropTypes.bool,
};

CasesTable.defaultProps = {
  onLoadMore: null,
  fetching: false,
  overallCount: 0,
  minimumBatchSize: 0,
  filtersVisible: false,
};

export default CasesTable;
