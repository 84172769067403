import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import StringField from "./stringField";

const SHIPPING_BOX_NUMBER_MAX_LENGTH = 18;

function ShippingBoxNumber({ disabled, ...props }) {
  return (
    <StringField
      {...props}
      disabled={disabled}
      onCustomizeValue={value => {
        let safeValue = value || "";
        let diff = safeValue.length - SHIPPING_BOX_NUMBER_MAX_LENGTH;
        if (diff > 0) {
          // is there zeros to remove
          while (diff > 0) {
            if (safeValue[0] === "0") {
              safeValue = safeValue.substring(1);
            } else {
              break;
            }
            diff -= 1;
          }
          return safeValue.substring(0, SHIPPING_BOX_NUMBER_MAX_LENGTH);
        }
        return safeValue;
      }}
    />
  );
}

ShippingBoxNumber.propTypes = {
  disabled: PropTypes.bool,
};

ShippingBoxNumber.defaultProps = {
  disabled: false,
};

export default ShippingBoxNumber;
