import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { OriolaColors, OriolaTheme } from "@oriola-origo/origo-ui-core";
import { CaseStatus } from "../../../constants/caseStatus";
import { updateValidationError } from "../../../redux";
import CompensationHandlingSolved from "./compensationHandlingSolved";
import CompensationHandlingNotSolved from "./compensationHandlingNotSolved";
import {
  getAllHandlingDataErrors,
  getFilledFieldsHandlingDataErrors,
} from "./handlingDataValidation/handlingDataValidation";

const useStyles = makeStyles(theme => ({
  divider: {
    borderBottomColor: "#e7e7e7",
  },
  title: {
    ...OriolaTheme.typography.h4,
    textTransform: "uppercase",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  titleText: {
    ...theme.typography.subtitle2,
    color: "#757070",
    marginBottom: theme.spacing(1),
  },
  caseHandlingMessage: {
    color: OriolaColors.DarkGrey,
    marginTop: theme.spacing(8),
    textAlign: "center",
  },
  caseHandlingMessageTitle: {
    ...OriolaTheme.typography.subtitle1,
    fontWeight: "normal",
    lineHeight: "24px",
  },
  productChipArea: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  productChip: {
    ...OriolaTheme.typography.body2,
    backgroundColor: "#F5F5F5",
    borderRadius: "8px",
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    maxWidth: "400px",
    textOverflow: "ellipsis",
  },
  container: {
    height: "100%",
  },
}));

function CompensationHandling({ currentUserIsOriolaUser }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { case: reclamationCase, localHandlingData } = useSelector(
    state => state.case
  );
  const { caseHandlingItems: selectedItems = [] } = useSelector(
    state => state.case
  );
  const { messages } = useSelector(state => state.message);
  const user = useSelector(state => state.user);
  const { userData } = user;
  const isSolved = reclamationCase.caseStatus === CaseStatus.SOLVED;

  const validateFilledFieldsHandlingData = () => {
    const validationErrors = getFilledFieldsHandlingDataErrors(
      reclamationCase,
      localHandlingData,
      messages,
      userData
    );

    const errorState = validationErrors.reduce(
      (acc, x) => ({ ...acc, ...x }),
      {}
    );
    dispatch(updateValidationError(errorState));

    return validationErrors.length === 0;
  };

  const validateFullHandlingData = () => {
    const validationErrors = getAllHandlingDataErrors(
      reclamationCase,
      localHandlingData
    );

    const errorState = validationErrors.reduce(
      (acc, x) => ({ ...acc, ...x }),
      {}
    );
    dispatch(updateValidationError(errorState));

    return validationErrors.length === 0;
  };

  const noItemsSelected = () => (
    <div className={classes.caseHandlingMessage}>
      <Typography className={classes.caseHandlingMessageTitle}>
        {t("caseHandlingNoItemsSelected")}
      </Typography>
    </div>
  );

  const getContent = () => {
    if (isSolved) {
      return (
        <CompensationHandlingSolved
          reclamationCase={reclamationCase}
          currentUserIsOriolaUser={currentUserIsOriolaUser}
        />
      );
    }
    return selectedItems && selectedItems.length > 0 ? (
      <CompensationHandlingNotSolved
        validateFullHandlingData={validateFullHandlingData}
        validateFilledFieldsHandlingData={validateFilledFieldsHandlingData}
      />
    ) : (
      noItemsSelected()
    );
  };

  return (
    <div className={classes.container} id="case-handling-column">
      {getContent()}
    </div>
  );
}

CompensationHandling.propTypes = {
  currentUserIsOriolaUser: PropTypes.bool,
  reclamationCase: PropTypes.shape({
    caseStatus: PropTypes.string,
  }),
};

CompensationHandling.defaultProps = {
  reclamationCase: {},
  currentUserIsOriolaUser: false,
};

export default CompensationHandling;
