import React from "react";
import { Box, FormHelperText } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
import {
  RadioButtonSelection,
  CompactTitleInput,
  TitleInput,
} from "../../common";
import DateAndEditor from "../dateAndEditor";
// eslint-disable-next-line import/no-cycle
import {
  updateLocalHandlingDataField,
  updateValidationError,
} from "../../../redux";
import CheckBoxWithLabel from "../checkBoxWithLabel";
import {
  getValidationErrorMessage,
  PRODUCT_AMOUNT_RECEIVE_MIN_LENGTH,
  PRODUCT_AMOUNT_RECEIVE_MAX_LENGTH,
} from "../../../utils/validations/validations";
import { getSpdModificationData } from "../../../utils/getSpdModificationData/getSpdModificationData";
import Title from "../title/title";
import ResetButton from "../../common/resetButton/resetButton";

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: "14.2px",
  },
  checkBox: {
    paddingRight: "3px",
  },
  errorText: {
    color: theme.palette.error.main,
  },
  titleAndResetButton: {
    display: "flex",
    justifyContent: "space-between",
  },
  resetButtonOriola: {
    position: "absolute",
    right: theme.spacing(11),
    top: theme.spacing(4),
  },
}));

export const ProductReceivedOrNot = Object.freeze({
  PRODUCT_RECEIVED: "PRODUCT_RECEIVED",
  PRODUCT_NOT_RETURNED: "PRODUCT_NOT_RETURNED",
});

function ProductHandlingInformation({ user, ...containerProps }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { localHandlingData = {} } = useSelector(state => state.case);
  const {
    productHandlingInfoError,
    productReceivedError,
    amountReturnedError,
  } = useSelector(state => state.validationErrors);

  const { productHandlingInformation = {}, modifications = {} } =
    localHandlingData;
  const {
    productReceived,
    isSentToPharmaCompany,
    isDisposedAtOriola,
    isPhotographsTaken,
    amountReturned,
    handlingInformation,
    isOtherWorkRequestCompleted,
  } = productHandlingInformation;

  const productHandlingInfoCheckBoxItem = [
    { key: "isSentToPharmaCompany", value: isSentToPharmaCompany },
    { key: "isDisposedAtOriola", value: isDisposedAtOriola },
    { key: "isPhotographsTaken", value: isPhotographsTaken },
    { key: "isOtherWorkRequestCompleted", value: isOtherWorkRequestCompleted },
  ];

  const updateData = (key, value) => {
    dispatch(
      updateLocalHandlingDataField(value, "productHandlingInformation", key)
    );
  };

  const setProductReceivedValue = updatedValue => {
    updateData("productReceived", updatedValue);
    dispatch(
      updateValidationError({
        productHandlingInfoError: null,
      })
    );
  };

  const resetProductReceivedValue = () => {
    updateData("productReceived", null);
    dispatch(
      updateValidationError({
        productHandlingInfoError: null,
      })
    );
  };

  const onChecked = e => {
    updateData(e.target.name, !!e.target.checked);
    dispatch(updateValidationError({ productReceivedError: "" }));
  };

  const productHandlingInformationModificationData =
    getSpdModificationData(modifications, "productHandlingInformation") || {};

  return (
    <Box
      id="product-handling-information"
      className="handling-section"
      {...containerProps}
    >
      <div className={classes.titleAndResetButton}>
        <Title title={t("productHandlingInformation.title")} />
        <ResetButton
          className={classes.resetButtonOriola}
          onClick={resetProductReceivedValue}
          disabled={!productReceived}
        />
      </div>
      <RadioButtonSelection
        button1Value={ProductReceivedOrNot.PRODUCT_RECEIVED}
        button2Value={ProductReceivedOrNot.PRODUCT_NOT_RETURNED}
        error={productHandlingInfoError != null}
        helperText={getValidationErrorMessage(productHandlingInfoError, t)}
        value={productReceived || ""}
        onChecked={setProductReceivedValue}
        name="productReceived"
        button1LabelText={t(
          "productHandlingInformation.productAlreadyReturned"
        )}
        button2LabelText={t("productHandlingInformation.productNotYetReturned")}
        button1LabelChildren={
          productReceived === ProductReceivedOrNot.PRODUCT_RECEIVED ? (
            <DateAndEditor
              id="product-handling-information-date-editor-returned"
              modificationData={
                productHandlingInformationModificationData.productReceived
              }
              user={user}
            />
          ) : null
        }
        button2LabelChildren={
          productReceived === ProductReceivedOrNot.PRODUCT_NOT_RETURNED ? (
            <DateAndEditor
              id="product-handling-information-date-editor-not-returned"
              modificationData={
                productHandlingInformationModificationData.productReceived
              }
              user={user}
            />
          ) : null
        }
        checkboxColor={OriolaColors.secondaryNavyBlue}
      />
      {productReceived === ProductReceivedOrNot.PRODUCT_RECEIVED && (
        <Box display="flex" pl={4} flexDirection="column" mt={2} mb={1}>
          <Box mb={2}>
            <CompactTitleInput
              error={!!amountReturnedError}
              helperText={getValidationErrorMessage(amountReturnedError, t)}
              width="320px"
              onChange={value => {
                updateData("amountReturned", value);
                dispatch(updateValidationError({ amountReturnedError: "" }));
              }}
              id="amount-returned"
              title={t("productHandlingInformation.productAmountReceived")}
              value={amountReturned}
              textFieldProps={{
                type: "number",
                InputProps: {
                  inputProps: {
                    min: PRODUCT_AMOUNT_RECEIVE_MIN_LENGTH,
                    max: PRODUCT_AMOUNT_RECEIVE_MAX_LENGTH,
                  },
                },
              }}
            />
            {amountReturned && (
              <DateAndEditor
                id="product-handling-information-date-editor-amount-returned"
                modificationData={
                  productHandlingInformationModificationData.amountReturned
                }
                user={user}
              />
            )}
          </Box>
          {productHandlingInfoCheckBoxItem.map(item => (
            <CheckBoxWithLabel
              id={`product-handling-checkbox-${item.key}`}
              key={item.key}
              onChecked={onChecked}
              checked={item.value || false}
              user={user}
              title={t(`productHandlingInformation.${item.key}`)}
              fieldName={item.key}
              modificationData={
                productHandlingInformationModificationData[`${item.key}`]
              }
            />
          ))}
          <FormHelperText className={classes.errorText}>
            {getValidationErrorMessage(productReceivedError, t)}
          </FormHelperText>
        </Box>
      )}
      <Box mt={2}>
        <TitleInput
          title={t("productHandlingInformation.handlingInfo")}
          secondaryTitle={t("optional")}
          rows={3}
          value={handlingInformation || ""}
          onChange={value => {
            updateData("handlingInformation", value);
          }}
          placeholder={t("productHandlingInformation.infoPlaceholder")}
          enableCopyButton
        />
      </Box>
      {productHandlingInformationModificationData.handlingInformation && (
        <DateAndEditor
          id="product-handling-information-date-editor-handling-info"
          modificationData={
            productHandlingInformationModificationData.handlingInformation
          }
          user={user}
        />
      )}
    </Box>
  );
}

ProductHandlingInformation.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

ProductHandlingInformation.defaultProps = {};

export default ProductHandlingInformation;
