import { CaseType } from "../../constants/caseType";

const getLocalizedCaseTypeName = (type, t) => {
  switch (type) {
    case CaseType.SUSPECTED_PRODUCT_DEFECT:
      return t("category.productDefect.header");
    case CaseType.DAMAGED_PRODUCT:
      return t("category.damagedProduct.header");
    case CaseType.PRODUCT_DELIVERY:
      return t("category.productOrDelivery.header");
    default:
      return "-";
  }
};

export default getLocalizedCaseTypeName;
