import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
// eslint-disable-next-line import/no-cycle
import { updateContactInfo } from "../../../../redux";
import { FieldTitle } from "..";

function PhoneNumberSelect({
  contactInfo,
  disabled,
  newPhoneNumberOption,
  classes,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isNewPhoneNumberItem = item =>
    (item && item.id) === newPhoneNumberOption.id;
  return (
    <div className={classes.selectContainer}>
      <FormControl
        margin="normal"
        variant="filled"
        id="contact-info-phone-number"
      >
        <FieldTitle
          title={t("contactInformation.phoneNumber")}
          type="selection"
        />
        <Select
          disabled={disabled}
          className={classes.selectField}
          value={contactInfo.phoneNumber || newPhoneNumberOption}
          onChange={event =>
            dispatch(updateContactInfo({ phoneNumber: event.target.value }))
          }
          input={<OutlinedInput data-error-name="phone" />}
          renderValue={item => {
            const text = isNewPhoneNumberItem(item)
              ? newPhoneNumberOption.text
              : item;
            return (
              <Typography className={classes.selectItem}>{text}</Typography>
            );
          }}
        >
          {(
            (contactInfo.customer && contactInfo.customer.phoneNumbers) ||
            []
          ).map(item => (
            <MenuItem className={classes.selectItem} key={item} value={item}>
              {item}
            </MenuItem>
          ))}
          <MenuItem
            className={classes.newSelectItem}
            key={newPhoneNumberOption.id}
            value={newPhoneNumberOption}
            id="contact-info-new-phone-number-option"
          >
            {newPhoneNumberOption.text}
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

PhoneNumberSelect.propTypes = {
  contactInfo: PropTypes.shape({
    phoneNumber: PropTypes.string,
    customer: PropTypes.shape({
      phoneNumbers: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  disabled: PropTypes.bool,
  newPhoneNumberOption: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  }),
  classes: PropTypes.shape({
    selectContainer: PropTypes.string,
    selectField: PropTypes.string,
    selectItem: PropTypes.string,
    newSelectItem: PropTypes.string,
  }),
};

PhoneNumberSelect.defaultProps = {
  contactInfo: {},
  classes: {},
  disabled: false,
  newPhoneNumberOption: {},
};

export default PhoneNumberSelect;
