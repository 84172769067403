import { RestService } from "../../components/common";
import { createFetchPath } from "../../utils/case/case";
import { CaseStatus } from "../../constants/caseStatus";

export const Cases = Object.freeze({
  FETCH_STARTED: "CASES_FETCH_STARTED",
  FETCH_FINISHED: "CASES_FETCH_FINISHED",
  FETCH_ERROR: "CASES_FETCH_ERROR",
  CASES_LIST_CLEAR: "CASES_LIST_CLEAR",
  FETCH_EXCEL_STARTED: "FETCH_EXCEL_STARTED",
  FETCH_EXCEL_FINISHED: "FETCH_EXCEL_FINISHED",
  FETCH_EXCEL_ERROR: "FETCH_EXCEL_ERROR",
});

export const clearCasesList = () => dispatch => {
  dispatch({
    type: Cases.CASES_LIST_CLEAR,
  });
};

let abortController;
const getCallAbortController = () => {
  abortController = new AbortController();
  return abortController;
};

const abortPreviousCall = () => {
  if (abortController) {
    abortController.abort();
  }
};

const getFilters = filters => {
  if (filters.caseStatus === CaseStatus.ALL) {
    return {
      ...filters,
      caseStatus: null,
    };
  }
  return { ...filters };
};

export const fetchCases =
  (startIndex, pageSize, filters, doAbortPreviousCall = false) =>
  async (dispatch, getState) => {
    try {
      if (doAbortPreviousCall === true) {
        abortPreviousCall();
      }
      const fetchFilters = getFilters(filters);

      dispatch({ type: Cases.FETCH_STARTED });

      const path = createFetchPath(startIndex, pageSize, fetchFilters);
      const result = await RestService.get(path, {
        signal: getCallAbortController().signal,
      });

      let cases = result.items;
      if (startIndex > 0) {
        cases = getState().listCases.cases.concat(cases);
      }

      dispatch({
        type: Cases.FETCH_FINISHED,
        payload: {
          cases,
          overallCount: result.totalCount,
        },
      });

      return cases;
    } catch (error) {
      if (RestService.isCancelError(error) === false) {
        dispatch({ type: Cases.FETCH_ERROR, payload: error });
      }
      return error;
    }
  };

export const fetchExportToExcelUrl = (filters, locale) => async dispatch => {
  try {
    dispatch({ type: Cases.FETCH_EXCEL_STARTED });
    const fetchFilters = getFilters(filters);
    let path = createFetchPath(0, null, fetchFilters, true);
    path = `${path}&locale=${locale}`;
    const res = await RestService.get(path);

    dispatch({ type: Cases.FETCH_EXCEL_FINISHED });

    return res;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("fetchExportToExcelUrl - error", error);
    dispatch({ type: Cases.FETCH_EXCEL_ERROR, payload: error });
    return Promise.reject(error);
  }
};

// -- REDUCER --

const INIT_STATE = {
  cases: [],
  fetchError: null,
  fetching: false,
  overallCount: null,
  fetchingExcelReport: false,
};

export const listCasesReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case Cases.FETCH_STARTED:
      return { ...state, fetching: true, fetchError: null };
    case Cases.FETCH_ERROR:
      return { ...state, fetching: false, fetchError: action.payload };
    case Cases.FETCH_FINISHED: {
      const { cases, overallCount } = action.payload;
      return {
        ...state,
        fetching: false,
        cases,
        overallCount,
      };
    }
    case Cases.CASES_LIST_CLEAR:
      return { ...state, cases: [], overallCount: 0 };
    case Cases.FETCH_EXCEL_STARTED:
      return { ...state, fetchingExcelReport: true };
    case Cases.FETCH_EXCEL_FINISHED:
    case Cases.FETCH_EXCEL_ERROR:
      return { ...state, fetchingExcelReport: false };
    default:
      return state;
  }
};
