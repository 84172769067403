import { ensureTrailingSlash } from "../url/url";
import { CaseType } from "../../constants/caseType";
import { CaseStatus } from "../../constants/caseStatus";

export const Paths = {
  ReclamationsAndRefunds: "/reclamationsRefunds",
  NewCase: "/newCase",
  NewCaseOfType: "/newCase/:caseType",
  NewCaseDamagedProduct: `/newCase/${CaseType.DAMAGED_PRODUCT}`,
  NewCaseDefect: `/newCase/${CaseType.SUSPECTED_PRODUCT_DEFECT}`,
  NewCaseDelivery: `/newCase/${CaseType.PRODUCT_DELIVERY}`,
  Cases: "/cases",
  OpenCases: "/cases?caseStatus=OPEN_SUBMITTED",
  SolvedCases: "/cases?caseStatus=SOLVED",
  Drafts: "/cases?caseStatus=DRAFT",
  Draft: "/draft/:reclamationId",
  Case: "/case/:reclamationId",
  EditCase: "/edit/:reclamationId",
};

export const getDraftPath = reclamationId =>
  Paths.Draft.replace(":reclamationId", reclamationId);

export const getReclamationCasePath = (reclamationId, caseStatus) => {
  if (caseStatus === CaseStatus.DRAFT) {
    return getDraftPath(reclamationId);
  }
  return Paths.Case.replace(":reclamationId", reclamationId);
};

const eComUrl = ensureTrailingSlash(process.env.REACT_APP_ECOM_URL);
export const EcomUrls = {
  Products: `${eComUrl}products`,
  Reports: `${eComUrl}reports`,
  OrdersInvoices: `${eComUrl}ordersInvoices`,
  ShoppingCart: `${eComUrl}shoppingCart`,
  OrdersInvoicesHistory: `${eComUrl}ordersInvoices/history`,
};
