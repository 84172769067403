import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function TranslatedText({ originalText, translatedText, hideTranslatedText }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box>
      <Typography>{originalText}</Typography>
      {hideTranslatedText !== true && (
        <>
          <Typography className={classes.subtitle}>
            {t("translate.translation")}
          </Typography>
          <Typography>{translatedText}</Typography>
        </>
      )}
    </Box>
  );
}

TranslatedText.propTypes = {
  originalText: PropTypes.string,
  translatedText: PropTypes.string,
  hideTranslatedText: PropTypes.bool,
  style: PropTypes.shape({}),
};

TranslatedText.defaultProps = {
  style: {},
  hideTranslatedText: false,
  originalText: "",
  translatedText: "",
};

export default TranslatedText;
