import React from "react";
import PropTypes from "prop-types";
import { Box, Tooltip } from "@material-ui/core";
import useStyles from "./caseIconsStyles";

function CaseIcon({ tooltip, children, grayed }) {
  const classes = useStyles(grayed)();
  return (
    <Tooltip title={tooltip} disabled={!tooltip}>
      <Box className={classes.caseLabel}>{children}</Box>
    </Tooltip>
  );
}

CaseIcon.propTypes = {
  tooltip: PropTypes.string,
  children: PropTypes.element,
  grayed: PropTypes.bool,
};

CaseIcon.defaultProps = {
  tooltip: "",
  grayed: false,
  children: null,
};

export default CaseIcon;
