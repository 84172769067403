import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
import RowNote from "./rowNote";

const useStyles = makeStyles(theme => ({
  details: {
    display: "flex",
    "padding-top": theme.spacing(0.5),
    "padding-bottom": theme.spacing(0.5),
    "padding-left": theme.spacing(0.5),
  },
  detailsColorOdd: {
    "&:nth-child(odd)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  detailsColorEven: {
    "&:nth-child(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  keyName: {
    flexBasis: "40%",
    flexShrink: 0,
    flexGrow: 0,
    marginRight: theme.spacing(2),
  },
  firstValue: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  secondaryValue: {
    ...theme.typography.body2,
    "padding-left": theme.spacing(1),
    marginTop: "auto",
    marginBottom: "auto",
  },
  firstValueMultiLine: {
    paddingRight: theme.spacing(2),
    borderRight: `1px solid ${OriolaColors.secondaryNavyBlue}`,
  },
}));

const EMPTY_VALUE = "-";

function DetailRow({
  keyName,
  value,
  secondaryValue,
  useNthChildOdd,
  useMultilineFormat,
  note,
}) {
  const classes = useStyles();

  const coloringClass =
    useNthChildOdd === true
      ? classes.detailsColorOdd
      : classes.detailsColorEven;

  const firstValueClass = useMultilineFormat
    ? `${classes.firstValue} ${classes.firstValueMultiLine}`
    : classes.firstValue;

  const componentProp = useMultilineFormat ? "pre" : "p";
  return (
    <div className={`${classes.details} ${coloringClass}`}>
      <Typography className={classes.keyName}>{keyName}</Typography>
      <Typography component={componentProp} className={firstValueClass}>
        {value || EMPTY_VALUE}
      </Typography>
      {secondaryValue != null && (
        <Typography className={classes.secondaryValue}>
          {secondaryValue}
        </Typography>
      )}
      {note && <RowNote fontSize="12px" text={note} />}
    </div>
  );
}

DetailRow.propTypes = {
  keyName: PropTypes.string,
  value: PropTypes.string,
  secondaryValue: PropTypes.string,
  useNthChildOdd: PropTypes.bool,
  useMultilineFormat: PropTypes.bool,
  note: PropTypes.string,
};

DetailRow.defaultProps = {
  keyName: "",
  value: EMPTY_VALUE,
  secondaryValue: null,
  useNthChildOdd: true,
  useMultilineFormat: false,
  note: null,
};

export default DetailRow;
