import UserType from "../../../constants/userType";

const isSpdCaseCreatedByOriola = reclamationCase =>
  reclamationCase.customerId === "1";

export const getCustomerRecipient = reclamationCase => {
  const { customerName, customerId } = reclamationCase;

  return {
    type: UserType.CUSTOMER,
    orgId: customerId,
    orgName: customerName,
  };
};

export const getPrincipalRecipient = reclamationCase => {
  const { items } = reclamationCase;

  // set principal
  if (items.length) {
    const product = items[0]?.product;
    if (product) {
      if (product.profitCenter) {
        return {
          type: UserType.PRINCIPAL,
          orgId: product.profitCenter,
          orgName: product.profitCenterName || product.profitCenter || "",
        };
      }
    }
  }
  return null;
};

export const getOriolaRecipient = () => ({
  type: UserType.ORIOLA,
  orgId: "1",
  orgName: "Oriola",
});

export const getSpdRecipientsOriola = reclamationCase =>
  isSpdCaseCreatedByOriola(reclamationCase)
    ? [getPrincipalRecipient(reclamationCase)]
    : [
        getPrincipalRecipient(reclamationCase),
        getCustomerRecipient(reclamationCase),
      ];

export const getSpdRecipientsPrincipal = reclamationCase =>
  isSpdCaseCreatedByOriola(reclamationCase)
    ? [getOriolaRecipient()]
    : [getOriolaRecipient(), getCustomerRecipient(reclamationCase)];
