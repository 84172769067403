import React from "react";
import PropTypes from "prop-types";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Info } from "../../../../images";

const useStyles = makeStyles(theme => ({
  fieldTitle: {
    marginBottom: theme.spacing(1),
  },
  fieldTitleWithInfoIcon: {
    display: "flex",
    marginBottom: theme.spacing(1),
    alignItems: "center",
    "& h6": {
      marginRight: theme.spacing(2),
    },
  },
  selectionTitle: {
    marginBottom: theme.spacing(2),
  },
  selectionTitleWithInfoIcon: {
    display: "flex",
    marginBottom: theme.spacing(1.5),
    alignItems: "center",
    "& h6": {
      marginRight: theme.spacing(2),
    },
  },
  subtitle: {
    ...theme.typography.body1,
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(6),
  },
  title: {
    color: "#757070",
  },
}));

function FieldTitle({
  title,
  subtitle,
  type,
  optional,
  infoIcon,
  optionalLabelHeight,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  let containerClassName = classes.fieldTitle;
  if (type === "selection" && infoIcon) {
    containerClassName = classes.selectionTitleWithInfoIcon;
  } else if (type === "selection") {
    containerClassName = classes.selectionTitle;
  } else if (infoIcon) {
    containerClassName = classes.fieldTitleWithInfoIcon;
  }
  return (
    <Box>
      <div
        className={containerClassName}
        style={{ height: optionalLabelHeight && 38 }}
      >
        <Typography variant="subtitle2" className={classes.title}>
          {title}
          {optional === true && (
            <Typography display="inline"> {t("optional")}</Typography>
          )}
        </Typography>
        {infoIcon && <Info className={classes.infoIcon} />}
      </div>
      {subtitle && (
        <Typography className={classes.subtitle}>{subtitle}</Typography>
      )}
    </Box>
  );
}

FieldTitle.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  infoIcon: PropTypes.bool,
  optional: PropTypes.bool,
  subtitle: PropTypes.string,
  optionalLabelHeight: PropTypes.bool,
};

FieldTitle.defaultProps = {
  type: "field",
  infoIcon: false,
  optional: false,
  subtitle: "",
  optionalLabelHeight: false,
  title: "",
};

export default FieldTitle;
