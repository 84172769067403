import { RestService } from "../../components/common";
import { ensureTrailingSlash } from "../../utils/url/url";
// eslint-disable-next-line import/no-cycle
import { SendingStatus } from "..";
import { updateAttachments } from "../case/caseUtils";

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_RECLAMATION_SERVICE);
const reclamationApi = process.env.REACT_APP_RECLAMATION_API;

export const Message = Object.freeze({
  SEND_STARTED: "MESSAGE_SEND_STARTED",
  SEND_FINISHED: "MESSAGE_SEND_FINISHED",
  SEND_ERROR: "MESSAGE_SEND_ERROR",
  UPDATE_MESSAGE_LIST: "MESSAGE_UPDATE_MESSAGE_LIST",
  FETCH_STARTED: "MESSAGE_FETCH_STARTED",
  FETCH_FINISHED: "MESSAGE_FETCH_FINISHED",
  FETCH_ERROR: "MESSAGE_FETCH_ERROR",
  UPDATE_MESSAGE_AND_QUEUE_LIST_START: "UPDATE_MESSAGE_AND_QUEUE_LIST_START",
  UPDATE_MESSAGE_AND_QUEUE_LIST_FINISHED:
    "UPDATE_MESSAGE_AND_QUEUE_LIST_FINISHED",
  STATUS_SEND_STARTED: "MESSAGE_STATUS_SEND_STARTED",
  STATUS_SEND_FINISHED: "MESSAGE_STATUS_SEND_FINISHED",
  STATUS_SEND_ERROR: "MESSAGE_STATUS_SEND_ERROR",
});

export const fetchMessages = reclamationId => async dispatch => {
  try {
    dispatch({ type: Message.FETCH_STARTED });

    const path = `${baseUrl}${reclamationApi}/${reclamationId}/events`;
    const messages = await RestService.get(path);

    dispatch({ type: Message.UPDATE_MESSAGE_LIST, payload: messages });
    dispatch({ type: Message.FETCH_FINISHED });
  } catch (error) {
    dispatch({ type: Message.FETCH_ERROR, payload: error });
  }
};

export const sendNewEvent =
  (reclamationId, reclamationVersion, event) => async (dispatch, getState) => {
    try {
      dispatch({ type: Message.SEND_STARTED });
      if (event.attachments && event.attachments.length > 0) {
        const updateAttachmentsResult = await updateAttachments(
          event.attachments,
          reclamationId,
          reclamationVersion
        );
        event.attachments = updateAttachmentsResult.attachments;
      }

      const path = `${baseUrl}${reclamationApi}/${reclamationId}/${reclamationVersion}/events`;
      const savedMessage = await RestService.post(path, event);

      const { messages } = getState().message;
      const updatedMessages = [savedMessage].concat(messages);

      dispatch({ type: Message.UPDATE_MESSAGE_LIST, payload: updatedMessages });
      dispatch({ type: Message.SEND_FINISHED });

      return {
        status: SendingStatus.SUCCESS,
        data: savedMessage,
      };
    } catch (error) {
      dispatch({ type: Message.SEND_ERROR, payload: error });
      return {
        status: SendingStatus.ERROR,
        data: error,
      };
    }
  };

// either read or unread or remove attachment
export const modifyMessage =
  (reclamationId, eventId, status, attachments) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: Message.STATUS_SEND_STARTED, payload: { eventId } });

      const path = `${baseUrl}${reclamationApi}/${reclamationId}/events/${eventId}`;
      const updatedMessage = await RestService.put(path, {
        read: status,
        attachments,
      });

      const { messages } = getState().message;
      const updatedMessages = messages.slice(0);
      const index = updatedMessages.findIndex(
        message => message.id === eventId
      );
      if (index !== -1) {
        updatedMessages[index] = updatedMessage;
      }

      dispatch({ type: Message.UPDATE_MESSAGE_LIST, payload: updatedMessages });
      dispatch({ type: Message.STATUS_SEND_FINISHED, payload: { eventId } });

      return {
        status: SendingStatus.SUCCESS,
        data: updatedMessage,
      };
    } catch (error) {
      dispatch({
        type: Message.STATUS_SEND_ERROR,
        payload: { eventId, error },
      });
      return {
        status: SendingStatus.ERROR,
        data: error,
      };
    }
  };

export const updateMessageAndQueueList = () => async dispatch => {
  dispatch({ type: Message.UPDATE_MESSAGE_AND_QUEUE_LIST_START });
  dispatch({ type: Message.UPDATE_MESSAGE_AND_QUEUE_LIST_FINISHED });
};

const INIT_STATE = {
  messages: [],
  sendingMessage: false,
  messageSendError: null,
  fetchingMessages: false,
  messagesFetchError: null,
  updateMessageQueueList: false,
  sendingMessageStatusIds: {},
  messageStatusSendErrors: {},
};

export const messageReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case Message.SEND_STARTED:
      return { ...state, sendingMessage: true, messageSendError: null };
    case Message.SEND_FINISHED:
      return { ...state, sendingMessage: false };
    case Message.SEND_ERROR:
      return {
        ...state,
        sendingMessage: false,
        messageSendError: action.payload,
      };
    case Message.UPDATE_MESSAGE_LIST: {
      return { ...state, messages: action.payload };
    }
    case Message.FETCH_STARTED:
      return { ...state, fetchingMessages: true, messagesFetchError: null };
    case Message.FETCH_FINISHED:
      return { ...state, fetchingMessages: false };
    case Message.FETCH_ERROR:
      return {
        ...state,
        fetchingMessages: false,
        messagesFetchError: action.payload,
      };
    case Message.UPDATE_MESSAGE_AND_QUEUE_LIST_START:
      return { ...state, updateMessageQueueList: true };
    case Message.UPDATE_MESSAGE_AND_QUEUE_LIST_FINISHED:
      return { ...state, updateMessageQueueList: false };
    case Message.STATUS_SEND_STARTED: {
      const { eventId } = action.payload;
      const { sendingMessageStatusIds, messageStatusSendErrors } = state;
      return {
        ...state,
        sendingMessageStatusIds: {
          ...sendingMessageStatusIds,
          [eventId]: true,
        },
        messageStatusSendErrors: {
          ...messageStatusSendErrors,
          [eventId]: null,
        },
      };
    }
    case Message.STATUS_SEND_FINISHED: {
      const { eventId } = action.payload;
      const { sendingMessageStatusIds } = state;

      return {
        ...state,
        sendingMessageStatusIds: {
          ...sendingMessageStatusIds,
          [eventId]: false,
        },
      };
    }
    case Message.STATUS_SEND_ERROR: {
      const { eventId, error } = action.payload;
      const { sendingMessageStatusIds, messageStatusSendErrors } = state;

      return {
        ...state,
        sendingMessageStatusIds: {
          ...sendingMessageStatusIds,
          [eventId]: false,
        },
        messageStatusSendErrors: {
          ...messageStatusSendErrors,
          [eventId]: error,
        },
      };
    }
    default:
      return state;
  }
};
