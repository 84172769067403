import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
import { EditCaseIcon } from "../../../images";

function TranslateButton({ onClick, id }) {
  const { t } = useTranslation();

  return (
    <div className="no-print">
      <Button
        id={id}
        size="small"
        style={{ fontSize: "12px" }}
        startIcon={
          <EditCaseIcon
            style={{ color: OriolaColors.primaryOrange, width: 12, height: 12 }}
          />
        }
        onClick={onClick}
      >
        {t("translate.buttonText")}
      </Button>
    </div>
  );
}

TranslateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string,
};

TranslateButton.defaultProps = {
  id: null,
};

export default TranslateButton;
