import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import ProductHandlingView from "./productHandlingView/productHandlingView";
import ProductHandlingInstructionsView from "./productHandlingInstructionsView";
import ProductHandlingInformationView from "./productHandlingInformationView";
import CompensationInstructionsView from "./compensationInstructionsView";
import CompensationInformationView from "./compensationInformationView";
import ConclusionView from "./conclusionView";
import HandlerInformationView from "./handlerInformationView";
import { Can, Permission, ANY_CUSTOMER } from "../../../auth";
import CompensationPermissionView from "./compensationPermissionView";

function SpdHandlingData({ handlingData, userData }) {
  const {
    isOriolaWaitingForInstructions,
    principalReferenceNumber,
    productHandlingInstructions,
    productHandlingInformation,
    compensationInstructions,
    compensationInformation,
    isPermissionFromPharmaCompanyRequiredForCompensation,
    conclusion,
    modifications,
    caseHandler,
    trackingCode,
  } = handlingData;

  return (
    <Box>
      <Can
        user={userData}
        perform={Permission.HANDLING_DATA_PRINCIPAL_VIEW}
        customerContext={ANY_CUSTOMER}
      >
        <ProductHandlingView
          mt={4}
          isOriolaWaitingForInstructions={isOriolaWaitingForInstructions}
          principalReferenceNumber={principalReferenceNumber}
          modifications={modifications}
          trackingCode={trackingCode}
        />
      </Can>

      <ProductHandlingInstructionsView
        mt={4}
        productHandlingInstructions={productHandlingInstructions || {}}
        userData={userData}
        modifications={modifications}
      />

      <ProductHandlingInformationView
        mt={4}
        productHandlingInformation={productHandlingInformation || {}}
        userData={userData}
        modifications={modifications}
      />
      <CompensationInstructionsView
        mt={4}
        compensationInstructions={compensationInstructions || {}}
        userData={userData}
        modifications={modifications}
      />
      <CompensationPermissionView
        mt={4}
        isPermissionFromPharmaCompanyRequiredForCompensation={
          isPermissionFromPharmaCompanyRequiredForCompensation
        }
        userData={userData}
        modifications={modifications}
      />
      <CompensationInformationView
        mt={4}
        compensationInformation={compensationInformation || {}}
        userData={userData}
        modifications={modifications}
      />
      <ConclusionView
        mt={4}
        conclusion={conclusion || {}}
        modifications={modifications}
      />
      <Can
        user={userData}
        perform={Permission.HANDLING_DATA_PRINCIPAL_VIEW}
        customerContext={ANY_CUSTOMER}
      >
        <HandlerInformationView mt={4} caseHandler={caseHandler} />
      </Can>
    </Box>
  );
}

SpdHandlingData.propTypes = {
  handlingData: PropTypes.shape({
    isOriolaWaitingForInstructions: PropTypes.bool,
    principalReferenceNumber: PropTypes.string,
    productHandlingInstructions: PropTypes.shape({}),
    productHandlingInformation: PropTypes.shape({}),
    compensationInstructions: PropTypes.shape({}),
    compensationInformation: PropTypes.shape({}),
    isPermissionFromPharmaCompanyRequiredForCompensation: PropTypes.bool,
    conclusion: PropTypes.shape({}),
    modifications: PropTypes.shape({}),
    caseHandler: PropTypes.shape({}),
    trackingCode: PropTypes.string,
  }).isRequired,
  userData: PropTypes.shape({}).isRequired,
};

export default SpdHandlingData;
