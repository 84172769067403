import React from "react";
import { Typography, Box, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import { ResetIcon } from "../../../images";

const useStyles = makeStyles(theme => ({
  resetText: {
    ...theme.typography.body2,
    color: OriolaColors.secondaryNavyBlue,
    marginLeft: theme.spacing(0.5),
  },
  disabled: {
    filter: "grayscale(1)",
    opacity: "0.5",
  },
}));

function ResetButton({ className = "", onClick = () => {}, disabled = true }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={`${className} ${disabled && classes.disabled}`}>
      <IconButton size="small" onClick={onClick} disabled={disabled}>
        <ResetIcon />
        <Typography className={classes.resetText}>{t("reset")}</Typography>
      </IconButton>
    </Box>
  );
}

export default ResetButton;
