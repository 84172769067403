import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BellIcon } from "../../../images/bell/bell.svg";
import CaseIcon from "./caseIcon";

function OtherRequestIcon({ ...props }) {
  const { t } = useTranslation();
  return (
    <CaseIcon tooltip={t("otherRequest")} {...props}>
      <BellIcon width="14px" height="100%" />
    </CaseIcon>
  );
}

export default OtherRequestIcon;
