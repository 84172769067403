import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { OriolaColors } from "../../../theme";

const useStyles = makeStyles(theme => ({
  titleText: {
    ...theme.typography.subtitle2,
    fontWeight: "700",
    color: OriolaColors.secondaryDarkGray,
  },
  secondaryTitleText: {
    ...theme.typography.button,
    textTransform: "none",
    color: OriolaColors.secondaryDarkGray,
    marginLeft: theme.spacing(1),
  },
}));

function TitleComponent({
  title,
  secondaryTitle,
  children,
  ...containerProps
}) {
  const classes = useStyles();

  return (
    <Box {...containerProps}>
      <Box display="flex" alignItems="center">
        <Typography className={classes.titleText} noWrap>
          {title}
        </Typography>
        {secondaryTitle && (
          <Typography className={classes.secondaryTitleText} noWrap>
            {secondaryTitle}
          </Typography>
        )}
      </Box>
      <Box mt={2}>{children}</Box>
    </Box>
  );
}

TitleComponent.propTypes = {
  title: PropTypes.string,
  secondaryTitle: PropTypes.string,
  children: PropTypes.node,
};

TitleComponent.defaultProps = {
  secondaryTitle: null,
  title: "",
  children: null,
};

export default TitleComponent;
