export const CaseStatus = Object.freeze({
  DRAFT: "DRAFT",
  OPEN_SUBMITTED: "OPEN_SUBMITTED",
  OPEN_INFORMATION_REQUESTED_FROM_CUSTOMER:
    "OPEN_INFORMATION_REQUESTED_FROM_CUSTOMER",
  // TODO: what is the correct status for solved?
  SOLVED: "SOLVED",
  ALL: "ALL",
});

export default CaseStatus;
