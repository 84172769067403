import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";
// eslint-disable-next-line import/no-cycle
import DateSelect from "./dateSelect";

function ExpectedReceiveDate({
  itemData,
  id,
  config,
  error,
  updateItem,
  updateItemValidationError,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <DateSelect
      title={t("shippingBox.expectedReceiveDate")}
      optional={config.optional}
      date={itemData.expectedReceiveDate}
      onSelect={date => {
        dispatch(
          updateItemValidationError(itemData.uuid, { expectedReceiveDate: "" })
        );
        dispatch(
          updateItem({
            ...itemData,
            expectedReceiveDate: date
              ? moment(date).format("YYYY-MM-DD")
              : null,
          })
        );
      }}
      id={`${id}-expected-receive-date`}
      error={!!error}
      helperText={getValidationErrorMessage(error, t)}
      placeholder={t("datepickerPlaceholder")}
      disableFuture
      width="310px"
    />
  );
}

ExpectedReceiveDate.propTypes = {
  itemData: PropTypes.shape({
    expectedReceiveDate: PropTypes.string,
    uuid: PropTypes.string,
  }),
  id: PropTypes.string,
  config: PropTypes.shape({
    optional: PropTypes.bool,
  }),
  error: PropTypes.string,
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
};

ExpectedReceiveDate.defaultProps = {
  itemData: {},
  id: "",
  config: {},
  error: "",
  updateItem: null,
  updateItemValidationError: null,
};

export default ExpectedReceiveDate;
