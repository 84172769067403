import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line import/no-cycle
import { updateValidationError, fetchCustomerById } from "../../../../redux";
import {
  transformCustomer,
  formatCustomerName,
} from "../../../../utils/customer/customer";
import OrganizationSearch from "../organizationSearch/organizationSearch";

function CustomerSearch({ handleSelected, disabled }) {
  const dispatch = useDispatch();
  const { customerError } = useSelector(state => state.validationErrors);
  const contactInfo = useSelector(state => state.contactInfo);
  const [selectedCustomer, setSelectedCustomer] = useState({
    id: "",
    name: "",
  });
  useEffect(() => {
    if (contactInfo.customer) {
      setSelectedCustomer({
        id: contactInfo.customer.customerId,
        name: formatCustomerName(contactInfo.customer),
      });
    } else {
      setSelectedCustomer({
        id: "",
        name: "",
      });
    }
  }, [contactInfo.customer]);

  const onOrganizationSelected = organization => {
    if (organization != null) {
      dispatch(updateValidationError({ customerError: null }));
      // get full customer data
      dispatch(fetchCustomerById(organization.id)).then(customer =>
        handleSelected(transformCustomer(customer))
      );
      setSelectedCustomer(organization);
    }
  };

  return (
    <OrganizationSearch
      handleSelected={onOrganizationSelected}
      selectedCustomer={selectedCustomer}
      error={customerError}
      disabled={disabled}
      id="organization-auto-complete-box"
    />
  );
}

CustomerSearch.propTypes = {
  handleSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CustomerSearch.defaultProps = {
  disabled: false,
};

export default CustomerSearch;
