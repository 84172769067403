import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
// eslint-disable-next-line import/no-cycle
import { updateCase, updateContactInfo } from "../../../../redux";
import { isAllowed, Permission, isOriolaUser } from "../../../auth/permission";
import ContactPersonInput from "./contactPersonInput";
import DeliveryAddressSelect from "./deliveryAddressSelect";
import CustomerSelect from "./customerSelect";
import PhoneNumberSelect from "./phoneNumberSelect";
import NewPhoneNumberField from "./newPhoneNumberField";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormHelperText-root.Mui-error": {
      marginLeft: 0,
    },
    "& .MuiFormHelperText-root": {
      fontSize: "1rem",
    },
  },
  title: {
    marginBottom: theme.spacing(3),
    textTransform: "uppercase",
    fontSize: "1rem",
    color: "#2b3e5b",
  },
  selectContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  selectField: {
    height: theme.spacing(5),
    minWidth: "32rem",
  },
  dropdown: {
    width: "32rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textField: {
    height: theme.spacing(5),
    minWidth: "32rem",
  },
  selectTitleText: {
    ...theme.typography.subtitle2,
    color: "#757070",
    marginBottom: theme.spacing(2),
  },
  newSelectItem: {
    borderTop: "1px",
    borderTopColor: "#ffa000",
    borderTopStyle: "solid",
    marginTop: "0.75rem",
  },
}));

function ContactInfo({
  customerId,
  deliveryAddressId,
  contactPerson,
  phoneNumber,
  disabled,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customers = useSelector(state => state.customer.customers);
  const { userData } = useSelector(state => state.user);
  const [editableCustomers, setEditableCustomers] = useState([]);
  const contactInfo = useSelector(state => state.contactInfo);
  const [newPhoneNumberOption] = useState({
    id: "new",
    text: t("contactInformation.newNumber"),
    number: "",
  });
  const oriolaUser = isOriolaUser(userData);

  useEffect(() => {
    const data = (customers || []).filter(c =>
      isAllowed(userData, Permission.CASE_CREATE, c.customerId)
    );
    setEditableCustomers(data);
  }, [userData, customers]);

  const getDeliveryAddress = useCallback(
    c => {
      if (!c || !c.deliveryAddresses) {
        return "";
      }

      if (deliveryAddressId) {
        return (
          c.deliveryAddresses.find(a => a.ShipTo === deliveryAddressId) || ""
        );
      }
      return c.deliveryAddresses[0] || "";
    },
    [deliveryAddressId]
  );

  const getPhoneNumber = useCallback(
    c => {
      if (!c || !c.phoneNumbers) {
        return "";
      }
      if (phoneNumber) {
        const found = c.phoneNumbers.find(p => p === phoneNumber);
        if (found) {
          return found;
        }
        newPhoneNumberOption.number = phoneNumber;
        return newPhoneNumberOption;
      }
      if (c.phoneNumbers.length === 1) {
        return c.phoneNumbers[0];
      }
      return "";
    },
    [newPhoneNumberOption, phoneNumber]
  );

  useEffect(() => {
    let selectedCustomer = null;
    if (oriolaUser === false) {
      if (disabled) {
        selectedCustomer =
          customerId && customers.find(c => c.customerId === customerId);
      } else {
        selectedCustomer =
          (customerId &&
            editableCustomers.find(c => c.customerId === customerId)) ||
          editableCustomers[0];
      }
    } else if (contactInfo.customer) {
      selectedCustomer = contactInfo.customer;
    } else {
      const [firstEditableCustomer] = editableCustomers || [];
      selectedCustomer = firstEditableCustomer;
    }
    dispatch(
      updateContactInfo({
        customer: selectedCustomer,
        deliveryAddress: getDeliveryAddress(selectedCustomer),
        contactPerson: contactPerson || userData.name,
        phoneNumber: getPhoneNumber(selectedCustomer),
      })
    );
  }, [
    contactInfo.customer,
    contactPerson,
    getDeliveryAddress,
    getPhoneNumber,
    userData.name,
    dispatch,
    customerId,
    customers,
    disabled,
    editableCustomers,
    oriolaUser,
  ]);

  /* when customer changes, select delivery address
   * and phone number if only one option exists
   */
  const handleCustomerSelected = useCallback(
    customer => {
      dispatch(updateContactInfo({ customer }));
      // clear old contactInfo saved in case
      dispatch(
        updateCase({
          contactPhoneNumber: "",
          deliveryAddressId: "",
          customerId: customer.customerId,
        })
      );
    },
    [dispatch]
  );

  let newPhoneNumberRef = null;
  // Focus new phone number field when it appears on screen
  useEffect(() => {
    if (newPhoneNumberRef) {
      newPhoneNumberRef.focus();
    }
  }, [contactInfo.phoneNumber, newPhoneNumberRef]);

  if (!contactInfo.customer && oriolaUser === false) {
    return null;
  }

  return (
    <div id="contact-information" className={classes.root}>
      <Typography className={classes.title} variant="h5" color="secondary">
        {t("contactInformation.contactInformation")}
      </Typography>
      <ContactPersonInput
        contactInfo={contactInfo}
        disabled={disabled}
        classes={classes}
      />
      <CustomerSelect
        contactInfo={contactInfo}
        disabled={disabled}
        userData={userData}
        customers={customers}
        handleCustomerSelected={handleCustomerSelected}
        classes={classes}
        oriolaUser={oriolaUser}
        title="contactInformation.customerNumber"
      />
      <PhoneNumberSelect
        contactInfo={contactInfo}
        disabled={disabled}
        newPhoneNumberOption={newPhoneNumberOption}
        classes={classes}
      />
      <NewPhoneNumberField
        contactInfo={contactInfo}
        disabled={disabled}
        setNewPhoneNumberRef={element => {
          newPhoneNumberRef = element;
        }}
        newPhoneNumberOption={newPhoneNumberOption}
        classes={classes}
      />
      <DeliveryAddressSelect
        contactInfo={contactInfo}
        disabled={disabled}
        classes={classes}
      />
    </div>
  );
}

ContactInfo.propTypes = {
  customerId: PropTypes.string,
  deliveryAddressId: PropTypes.string,
  contactPerson: PropTypes.string,
  phoneNumber: PropTypes.string,
  disabled: PropTypes.bool,
};

ContactInfo.defaultProps = {
  customerId: "",
  deliveryAddressId: "",
  contactPerson: "",
  phoneNumber: "",
  disabled: false,
};

export default ContactInfo;
