import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import { useDispatch } from "react-redux";
// eslint-disable-next-line import/no-cycle
import { FieldTitle } from "..";
import { CssTextField, StatefullTextField } from "../../../common";

function ProductPharmaceuticalForm({
  classes,
  id,
  product,
  updateItem,
  itemData,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const onChange = value => {
    dispatch(
      updateItem({
        ...itemData,
        product: {
          ...product,
          pharmaceuticalForm: [{ language: "English", value }],
        },
      })
    );
  };
  return (
    <div>
      <FieldTitle title={t("caseItemFields.pharmaceuticalForm")} />
      <div className={classes.controlContainer}>
        {product.customerAddedProduct ? (
          <StatefullTextField
            className={classes.stringField}
            initialValue={
              ProductUtil.getProductPharmaceuticalForm(
                product,
                i18n.language
              ) || ""
            }
            variant="outlined"
            placeholder={t("caseItemFields.pharmaceuticalForm")}
            margin="dense"
            id={`${id}-pharmaceutical-form`}
            onChange={onChange}
          />
        ) : (
          <CssTextField
            className={classes.stringField}
            value={
              ProductUtil.getProductPharmaceuticalForm(
                product,
                i18n.language
              ) || ""
            }
            variant="outlined"
            placeholder={t("caseItemFields.pharmaceuticalForm")}
            margin="dense"
            disabled
            id={`${id}-pharmaceutical-form`}
          />
        )}
      </div>
    </div>
  );
}

ProductPharmaceuticalForm.propTypes = {
  classes: PropTypes.shape({
    controlContainer: PropTypes.string,
    stringField: PropTypes.string,
  }),
  id: PropTypes.string,
  product: PropTypes.shape({
    customerAddedProduct: PropTypes.bool,
  }),
  updateItem: PropTypes.func,
  itemData: PropTypes.shape({}),
};

ProductPharmaceuticalForm.defaultProps = {
  product: {},
  updateItem: null,
  id: "",
  classes: {},
  itemData: {},
};

export default ProductPharmaceuticalForm;
