import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

function SelectedFilters({ items, classes }) {
  const profitCenters = items.filter(
    item => item.reclamation_spd_email_addresses != null
  );
  const supplierNumbers = items.filter(
    item => item.reclamation_spd_email_addresses == null
  );

  let showingProfitCenters = [];
  let showingSuppliers = [];

  if (
    profitCenters.length <= 2 ||
    profitCenters.length + supplierNumbers.length <= 4
  ) {
    showingProfitCenters = [...profitCenters];
  } else {
    showingProfitCenters = [
      profitCenters[0],
      { number: `+${profitCenters.length - 1}` },
    ];
  }

  if (
    supplierNumbers.length <= 1 ||
    profitCenters.length + supplierNumbers.length <= 3
  ) {
    showingSuppliers = [...supplierNumbers];
  } else {
    showingSuppliers = [
      supplierNumbers[0],
      { number: `+${supplierNumbers.length - 1}` },
    ];
  }

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
      {showingProfitCenters.map(item => (
        <div
          className={`${classes.selectedItemFilter} ${classes.selectedProfitCenterFilter}`}
          key={item.number}
        >
          {item.number}
        </div>
      ))}
      {showingSuppliers.map(item => (
        <div
          className={`${classes.selectedItemFilter} ${classes.selectedSupplierFilter}`}
          key={item.number}
        >
          {item.number}
        </div>
      ))}
    </Box>
  );
}

SelectedFilters.propTypes = {
  items: PropTypes.shape([]),
  classes: PropTypes.shape({
    selectedItemFilter: PropTypes.string,
    selectedProfitCenterFilter: PropTypes.string,
    selectedSupplierFilter: PropTypes.string,
  }),
};

SelectedFilters.defaultProps = {
  items: [],
  classes: {},
};

export default SelectedFilters;
