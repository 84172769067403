import React from "react";
import { useTranslation } from "react-i18next";
import "./unsavedChangesLabel.css";

function UnsavedChangesLabel() {
  const { t } = useTranslation();
  return <span className="unsaved-label">{t("unsavedChanges")}</span>;
}

export default UnsavedChangesLabel;
