import { CaseStatus } from "../../../constants/caseStatus";
import { CaseType } from "../../../constants/caseType";

const {
  isOriolaUser,
  isCustomer,
  isPharmaceuticalCompany,
} = require("../../auth/permission");

const isProductReturn = productReturn =>
  productReturn && productReturn.returnValue === true;
const isConvertedCase = reclamationCase =>
  !!(reclamationCase || {}).sourceReclamationId;
const isProductReturnRequired = (items = []) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const item of items) {
    if (item && item.handlingData && item.handlingData.productReturnRequired) {
      return true;
    }
  }
  return false;
};

const getResult = (
  returnDocumentButtonEnabled = false,
  printCaseDetailsButtonEnabled = false
) => ({
  returnDocumentButtonEnabled,
  printCaseDetailsButtonEnabled,
});

const getSpd = (reclamationCase, user) => {
  const { productReturn, caseStatus, items } = reclamationCase;
  const caseIsSolved = caseStatus === CaseStatus.SOLVED;

  if (caseIsSolved) {
    return getResult(false, true);
  }

  if (isProductReturn(productReturn) || isProductReturnRequired(items)) {
    return getResult(
      isOriolaUser(user) || isCustomer(user),
      isOriolaUser(user) || isPharmaceuticalCompany(user)
    );
  }
  return getResult(false, isOriolaUser(user) || isPharmaceuticalCompany(user));
};

const getPRICDP = (reclamationCase, user) => {
  const { productReturn, caseStatus, items } = reclamationCase;
  const caseIsSolved = caseStatus === CaseStatus.SOLVED;

  if (caseIsSolved) {
    return getResult(false, isCustomer(user));
  }

  if (isProductReturn(productReturn) || isProductReturnRequired(items)) {
    return getResult(isOriolaUser(user) || isCustomer(user), false);
  }

  return getResult();
};

const printButtonsEnabled = (reclamationCase, user) => {
  const { caseStatus, caseType, productReturn } = reclamationCase;

  // for drafts disabled
  if (caseStatus === CaseStatus.DRAFT) {
    return getResult();
  }

  // converted case
  if (isConvertedCase(reclamationCase)) {
    if (caseType === CaseType.DAMAGED_PRODUCT) {
      return isProductReturn(productReturn)
        ? getResult(isOriolaUser(user), false)
        : getResult();
    }
    return getResult(isOriolaUser(user), isPharmaceuticalCompany(user));
  }

  if (caseType === CaseType.SUSPECTED_PRODUCT_DEFECT) {
    return getSpd(reclamationCase, user);
  }
  return getPRICDP(reclamationCase, user);
};

export default printButtonsEnabled;
