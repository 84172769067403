/* eslint-disable default-param-last */
import { RestService } from "../../components/common";
import { ensureTrailingSlash } from "../../utils/url/url";

export const Cases = Object.freeze({
  DELETE_START: "DELETE_START",
  DELETE_SUCCESS: "DELETE_SUCCESS",
  DELETE_ERROR: "DELETE_ERROR",
});

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_RECLAMATION_SERVICE);
const reclamationApi = process.env.REACT_APP_RECLAMATION_API;

// -- ACTIONS --

const deleteCase = caseData => async dispatch => {
  try {
    dispatch({
      type: Cases.DELETE_START,
      payload: {
        caseId: caseData.reclamationId,
      },
    });

    const path = `${baseUrl}${reclamationApi}/${caseData.reclamationId || ""}/${
      caseData.version
    }`;

    await RestService.delete(path);

    dispatch({
      type: Cases.DELETE_SUCCESS,
      payload: {
        caseId: caseData.reclamationId,
      },
    });

    return {
      status: "SUCCESS",
    };
  } catch (err) {
    dispatch({
      type: Cases.DELETE_ERROR,
      payload: err,
    });
    return {
      status: "ERROR",
      data: err,
    };
  }
};

export const deleteDraft = caseData => deleteCase(caseData);
// -- REDUCER --

const INIT_STATE = {
  cases: [],
  fetchError: null,
  fetching: false,
  overallCount: null,
};

export const draftsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Cases.DELETE_START:
      return { ...state };
    case Cases.DELETE_SUCCESS:
      return { ...state };
    case Cases.DELETE_ERROR:
      return { ...state };
    default:
      return state;
  }
};
