import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Button } from "@oriola-origo/origo-ui-core";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  cancelButton: {
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.2)",
    width: "50%",
    fontWeight: 700,
  },
  saveButton: {
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.2)",
    width: "50%",
    fontWeight: 700,
  },
  saveAndCloseButton: {
    width: "100%",
    backgroundColor: "#FFA000",
    fontWeight: 700,
  },
}));

export const HANDLING_BUTTON_GROUP_HEIGHT_FULL = 160;
export const HANDLING_BUTTON_GROUP_HEIGHT = 125;

function SaveAndCloseButtonGroup({
  onCancel,
  onSave,
  onSaveAndClose,
  currentUserIsOriolaUser,
  loading,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      className="handling-section"
      style={{ boxShadow: "0px -2px 3px 0px rgba(0, 0, 0, 0.10)" }}
    >
      <div className={classes.root}>
        <Button
          className={classes.cancelButton}
          variant="secondary"
          onClick={onCancel}
          id="cancel-handling-data-button"
        >
          {t("cancel")}
        </Button>
        <Button
          className={classes.saveButton}
          variant="primary"
          onClick={onSave}
          id="save-handling-data-button"
          loading={loading === "save"}
          disabled={!!loading}
        >
          {loading === "save" ? t("loading") : t("save")}
        </Button>
      </div>
      {currentUserIsOriolaUser && (
        <div>
          <Button
            className={classes.saveAndCloseButton}
            onClick={onSaveAndClose}
            id="save-close-handling-data-button"
            loading={loading === "saveAndClose"}
            disabled={!!loading}
          >
            {loading === "saveAndClose" ? t("loading") : t("saveAndClose")}
          </Button>
        </div>
      )}
    </div>
  );
}

SaveAndCloseButtonGroup.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveAndClose: PropTypes.func.isRequired,
  currentUserIsOriolaUser: PropTypes.bool,
  loading: PropTypes.string,
};

SaveAndCloseButtonGroup.defaultProps = {
  currentUserIsOriolaUser: false,
  loading: "",
};

export default SaveAndCloseButtonGroup;
