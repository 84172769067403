import { v4 as uuid } from "uuid";
import moment from "moment";
import { AttachmentService } from "@oriola-origo/origo-common-client-lib";
import { RestService } from "../../components/common";
import Operation from "../../constants/operation";
import { CaseStatus } from "../../constants/caseStatus";
import { CaseType } from "../../constants/caseType";
import EventType from "../../constants/eventType";
import { Case, SendingStatus, baseUrl, reclamationApi } from "./constants";
// eslint-disable-next-line import/no-cycle
import { getInitItems, prepareCaseData, updateAttachments } from "./caseUtils";

export {
  mapCaseToCopiedCase,
  cancelEditingCopiedCase,
  startToCopyCase,
  getRelatedCases,
} from "./copyCaseActions";

export {
  clearlocalHandlingData,
  removeItemHandligData,
  sendlocalHandlingData,
  setLocalHandlingData,
  submitConclusion,
  updateLocalHandlingDataField,
  updateLocalhandlingData,
  addCaseHandlingItems,
  initializeCaseHandlingItems,
  removeCaseHandlingItems,
} from "./handlingDataActions";

// -- ACTIONS --
export const fetchCase = reclamationId => async dispatch => {
  const path = `${baseUrl}${reclamationApi}/${reclamationId}`;
  try {
    dispatch({
      type: Case.FETCH_STARTED,
    });

    const reclamationCase = await RestService.get(path);

    if (reclamationCase && (reclamationCase.items || []).length === 0) {
      // if there are no items, maybe initialize with something
      reclamationCase.items = getInitItems(reclamationCase.caseType);
    }

    dispatch({
      type: Case.FETCH_FINISHED,
      payload: reclamationCase || {},
    });
    return reclamationCase;
  } catch (error) {
    // TODO: error handling
    dispatch({ type: Case.FETCH_ERROR, payload: error });
  }
  return null;
};

export const newCase = caseType => dispatch => {
  // url template for opening this project
  const { origin } = window.location;
  const url = `${origin}/case/[reclamationId]`;

  const stateValue = {
    caseType,
    reasonIdentifier: undefined,
    orderNumber: undefined,
    productReceivedDate: moment().format("YYYY-MM-DD"),
    caseDescription: undefined,
    productReturn:
      caseType === CaseType.SUSPECTED_PRODUCT_DEFECT
        ? { returnValue: true, amount: "" }
        : {},
    sending: false,
    sendError: null,
    items: getInitItems(caseType),
    confirm: null,
    expirationDate: moment().format("YYYY-MM-DD"),
    url,
    courierCompanyId: null,
  };
  return dispatch({ type: Case.NEW_CASE, payload: stateValue });
};

export const updateCase = stateValue => dispatch =>
  dispatch({ type: Case.UPDATE_CASE, payload: stateValue });

export const addItem = (type, item) => dispatch =>
  dispatch({ type: Case.ADD_ITEM, payload: { type, ...item } });

export const removeItem = itemId => dispatch =>
  dispatch({ type: Case.REMOVE_ITEM, payload: itemId });

export const updateItem = (item, itemUuid) => (dispatch, getState) => {
  const targetUuid = itemUuid || item.uuid;
  const existingItems = getState().case.case.items;
  const index = existingItems.findIndex(x => x.uuid === targetUuid);
  const items = [...existingItems];
  items[index] = {
    ...items[index],
    ...item,
  };
  return dispatch({
    type: Case.UPDATE_ITEM,
    payload: items,
  });
};

const storeCase = (caseData, operation) => async (dispatch, getState) => {
  const state = getState();
  try {
    if (state && state.case && state.case.sendingCase) {
      return {
        status: SendingStatus.SENDING,
      };
    }
    dispatch({ type: Case.SEND_STARTED });
    caseData = caseData || state.case.case;
    const isNew = !caseData.reclamationId;
    const path = `${baseUrl}${reclamationApi}/${caseData.reclamationId || ""}`;
    let saveResult;
    if (operation === Operation.REOPEN) {
      saveResult = await RestService.put(path, {
        operation,
        version: caseData.version,
      });
    } else if (operation === Operation.SAVE_TRANSLATION) {
      saveResult = await RestService.put(path, { ...caseData, operation });
    } else if (operation === Operation.SET_SUB_STATUS) {
      saveResult = await RestService.put(path, { ...caseData, operation });
    } else {
      const { contactInfo, fieldsConfig } = getState();
      const data = prepareCaseData({
        contactInfo,
        caseData,
        operation,
        fieldsConfig,
      });

      const createOrUpdate = isNew ? RestService.post : RestService.put;
      const storedCase = await createOrUpdate(`${path}?preSaving=true`, data);
      const { reclamationId, version } = storedCase;

      const updateAttachmentResult = await updateAttachments(
        caseData.attachments,
        reclamationId,
        version
      );
      const caseWithAttachments = {
        ...storedCase,
        attachments: updateAttachmentResult.attachments,
        operation,
        version: updateAttachmentResult.version,
      };

      // update with attachments
      const updatePath = `${baseUrl}${reclamationApi}/${reclamationId}`;
      saveResult = await RestService.put(updatePath, caseWithAttachments);
    }

    dispatch({ type: Case.SEND_FINISHED, payload: saveResult });
    return {
      status: SendingStatus.SUCCESS,
      data: saveResult,
    };
  } catch (err) {
    dispatch({ type: Case.SEND_ERROR, payload: err });
    return {
      status: SendingStatus.ERROR,
      data: err,
    };
  }
};

export const saveDraft = caseData => storeCase(caseData, Operation.SAVE_DRAFT);

export const submitCase = caseData => {
  const operation = caseData.sourceReclamationId
    ? Operation.COPY
    : Operation.SUBMIT;
  return storeCase(caseData, operation);
};

export const provideRequiredInformation = caseData =>
  storeCase(caseData, Operation.PROVIDE_INFORMATION_BY_CUSTOMER);

export const editCase = (caseData, requireMoreInfoFromCustomer) => {
  const operation = requireMoreInfoFromCustomer
    ? Operation.REQUEST_INFORMATION_FROM_CUSTOMER
    : Operation.EDIT;
  return storeCase(caseData, operation);
};

export const clearCase = () => dispatch => dispatch({ type: Case.CLEAR_CASE });

export const updateTranslations = caseData =>
  storeCase(caseData, Operation.SAVE_TRANSLATION);

export const setSubStatus = caseData =>
  storeCase(caseData, Operation.SET_SUB_STATUS);

export const setRequireMoreInfoFromCustomer = require => dispatch => {
  dispatch({ type: Case.REQUIRE_MORE_INFO_FROM_CUSTOMER, payload: require });
};

export const setEditedKeyField = val => dispatch => {
  dispatch({ type: Case.SET_EDITED_KEY_FIELD, payload: val });
};

export const fetchProductReturnPdfUrl = reclamationId => async dispatch => {
  try {
    dispatch({ type: Case.FETCH_PDF_STARTED });

    const path = `${baseUrl}${reclamationApi}/${reclamationId}/productReturnDocument`;
    const res = await RestService.get(path);

    dispatch({ type: Case.FETCH_PDF_FINISHED });

    return res;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("fetchProductReturnPdfUrl - error", error);
    dispatch({ type: Case.FETCH_PDF_ERROR, payload: error });
    return Promise.reject(error);
  }
};

export const downloadAttachment = async (caseData, reclamationId) => {
  const attachmentService = new AttachmentService(
    `${baseUrl}${reclamationApi}`
  );
  const downloadAttachments = (caseData.attachments || []).filter(
    attachment => attachment.download === true
  );

  await attachmentService.getAttachments(reclamationId, downloadAttachments);
};

export const updateSerialNumber =
  (updateNumber, index) => (dispatch, getState) => {
    const state = getState();
    const prevSerialNumberData = state.case.case.items[0].uniqueSerialNumber;
    prevSerialNumberData[index] = updateNumber;
    return dispatch({
      type: Case.UPDATE_SERIAL_NUMBER_DATA,
      payload: prevSerialNumberData,
    });
  };

export const removeSerialNumber = index => (dispatch, getState) => {
  const state = getState();
  const prevSerialNumberData = state.case.case.items[0].uniqueSerialNumber;
  prevSerialNumberData.splice(index, 1);
  return dispatch({
    type: Case.REMOVE_SERIAL_NUMBER_DATA,
    payload: prevSerialNumberData,
  });
};

export const changeQueue =
  (
    reclamationId,
    reclamationVersion,
    sourceQueue,
    destinationQueue,
    internalMessage,
    destinationSubQueue = null
  ) =>
  async dispatch => {
    try {
      dispatch({ type: Case.QUEUE_CHANGE_STARTED });

      const event = {
        type: "QUEUE_CHANGE",
        sourceQueue,
        destinationQueue,
        destinationSubQueue,
        message: internalMessage,
      };
      const path = `${baseUrl}${reclamationApi}/${reclamationId}/${reclamationVersion}/events`;

      const result = await RestService.post(path, event);

      dispatch({ type: Case.QUEUE_CHANGE_FINISHED, payload: destinationQueue });

      dispatch(fetchCase(reclamationId));

      return {
        status: SendingStatus.SUCCESS,
        data: result,
      };
    } catch (error) {
      dispatch({ type: Case.QUEUE_CHANGE_FINISHED });

      return {
        status: SendingStatus.ERROR,
        data: error,
      };
    }
  };

export const reopenCase =
  (reclamationId, reclamationVersion, messageText) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: Case.REOPEN_CASE_START });

      const event = {
        type: EventType.REOPEN_CASE,
        message: messageText,
      };

      const path = `${baseUrl}${reclamationApi}/${reclamationId}/${reclamationVersion}/events`;
      const savedMessage = await RestService.post(path, event);

      const { messages } = getState().message;
      const updatedMessages = [savedMessage].concat(messages);

      const reopenPath = `${baseUrl}${reclamationApi}/${reclamationId || ""}`;

      await RestService.put(reopenPath, {
        operation: Operation.REOPEN,
        version: reclamationVersion,
      });
      dispatch({ type: Case.REOPEN_CASE_FINISHED, payload: updatedMessages });
      return {
        status: SendingStatus.SUCCESS,
      };
    } catch (error) {
      dispatch({ type: Case.SEND_ERROR, payload: error });
      return {
        status: SendingStatus.ERROR,
        data: error,
      };
    }
  };

/**
 * Add uuids to products. Needed for identifying purpose when editing.
 */

const addItemsUuids = (items, atLeastOneItem = true) => {
  if (items && items.length === 0 && atLeastOneItem) {
    return [{ uuid: uuid() }];
  }
  return (items || []).map(item =>
    item.uuid ? item : { ...item, uuid: uuid() }
  );
};

const addProductAndCompensationUuids = caze => {
  const isDraft = caze.caseStatus === CaseStatus.DRAFT;
  return {
    ...caze,
    items: addItemsUuids(caze.items, isDraft),
  };
};

export const getProfitCenterDetails =
  profitCenter => async (dispatch, getState) => {
    const getProfitCenterApi =
      process.env.REACT_APP_RECLAMATION_GET_PROFIT_CENTER_API;
    const path = `${baseUrl}${getProfitCenterApi}/${profitCenter}`;
    try {
      dispatch({ type: Case.FETCH_PROFIT_CENTER_START });
      const profitCenterDetails = await RestService.get(path);
      const newItems = getState().case.case.items.map(item => {
        if (
          item.product &&
          item.product.profitCenter === profitCenterDetails.number
        ) {
          return {
            ...item,
            product: {
              ...item.product,
              profitCenterName: profitCenterDetails.name,
              useSupplier:
                profitCenterDetails.reclamation_use_supplier === true,
            },
          };
        }
        return {
          ...item,
        };
      });
      dispatch({
        type: Case.FETCH_PROFIT_CENTER_FINISHED,
        payload: newItems,
      });
    } catch (err) {
      dispatch({ type: Case.FETCH_PROFIT_CENTER_ERROR });
    }
  };

// -- REDUCER --

const INIT_STATE = {
  case: { case: { items: [] } },
  sourceCase: {},
  editingCopiedCase: false,
  caseHandlingItems: [],
  localHandlingData: {},
  fetchError: null,
  fetching: false,
  sendingCase: false,
  sendingHandlingData: false,
  changingQueue: false,
  fetchingPdfUrl: false,
  pdfFetchUrlError: null,
  requireMoreInfoFromCustomer: false,
  editedKeyField: false,
  suspectedAdverseReaction: {},
  profitCenters: [],
  fetchingProfitCenters: false,
};

export const caseReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case Case.NEW_CASE:
      return { case: { ...action.payload } };
    case Case.UPDATE_CASE:
      return { ...state, case: { ...state.case, ...action.payload } };
    case Case.FETCH_STARTED:
      return { ...state, fetching: true, fetchError: null };
    case Case.FETCH_ERROR:
      return { ...state, fetching: false, fetchError: action.payload };
    case Case.FETCH_FINISHED: {
      return {
        ...state,
        fetching: false,
        editingCopiedCase: false,
        case: addProductAndCompensationUuids(action.payload),
      };
    }
    case Case.ADD_ITEM:
      return {
        ...state,
        case: {
          ...state.case,
          items: [
            { uuid: uuid(), ...action.payload },
            ...(state.case.items || []),
          ],
        },
      };
    case Case.CHANGE_ITEM:
      return {
        ...state,
        case: {
          ...state.case,
          items: [
            {
              uuid: uuid(),
              uniqueSerialNumber: [""],
              ...action.payload,
            },
          ],
        },
      };
    case Case.REMOVE_ITEM: {
      const items = state.case.items.filter(x => x.uuid !== action.payload);
      return { ...state, case: { ...state.case, items } };
    }
    case Case.UPDATE_ITEM: {
      const items = action.payload;
      return { ...state, case: { ...state.case, items } };
    }
    case Case.SEND_STARTED:
      return { ...state, sendingCase: true };
    case Case.SEND_FINISHED: {
      return {
        ...state,
        sendingCase: false,
        case: addProductAndCompensationUuids(action.payload),
      };
    }
    case Case.SEND_ERROR:
      return { ...state, sendingCase: false };
    case Case.CLEAR_CASE:
      return { ...state, case: {} };
    case Case.UPDATE_MODIFIED_HANDLING_DATA: {
      const newState = { ...state, localHandlingData: action.payload };
      return newState;
    }
    case Case.UPDATE_SERIAL_NUMBER_DATA:
      return {
        ...state,
        case: {
          ...state.case,
          items: [
            {
              ...(state.case.items[0] || {}),
              uniqueSerialNumber: action.payload,
            },
          ],
        },
      };
    case Case.REMOVE_SERIAL_NUMBER_DATA:
      return {
        ...state,
        case: {
          ...state.case,
          items: [
            {
              ...(state.case.items[0] || {}),
              uniqueSerialNumber: action.payload,
            },
          ],
        },
      };
    case Case.HANDLING_DATA_SEND_STARTED:
      return { ...state, case: { ...state.case, sendingHandlingData: true } };
    case Case.HANDLING_DATA_SEND_FINISHED:
      return {
        ...state,
        case: {
          ...addProductAndCompensationUuids(action.payload),
          sendingHandlingData: false,
        },
      };
    case Case.HANDLING_DATA_SEND_ERROR:
      return { ...state, case: { ...state.case, sendingHandlingData: false } };
    case Case.QUEUE_CHANGE_STARTED:
      return { ...state, changingQueue: true };
    case Case.QUEUE_CHANGE_FINISHED:
      return {
        ...state,
        changingQueue: false,
        case: { ...state.case, queue: action.payload },
      };
    case Case.UPDATE_CASE_HANDLING_ITEMS:
      return { ...state, caseHandlingItems: action.payload };
    case Case.FETCH_PDF_STARTED:
      return { ...state, fetchingPdfUrl: true, pdfFetchUrlError: null };
    case Case.FETCH_PDF_FINISHED:
      return { ...state, fetchingPdfUrl: false };
    case Case.FETCH_PDF_ERROR:
      return {
        ...state,
        fetchingPdfUrl: false,
        pdfFetchUrlError: action.payload,
      };
    case Case.REQUIRE_MORE_INFO_FROM_CUSTOMER:
      return { ...state, requireMoreInfoFromCustomer: action.payload };
    case Case.SET_EDITED_KEY_FIELD:
      return { ...state, editedKeyField: action.payload };
    case Case.REOPEN_CASE_FINISHED:
    case Case.CLOSE_CASE:
      return { ...state, messages: action.payload };
    case Case.FETCH_PROFIT_CENTER_FINISHED: {
      return { ...state, case: { ...state.case, items: action.payload } };
    }
    case Case.COPY_CASE_START: {
      return {
        ...state,
        editingCopiedCase: true,
        sourceCase: state.case,
        copiedCase: {},
      };
    }
    case Case.MAP_COPIED_CASE: {
      return {
        ...state,
        editingCopiedCase: true,
        case: action.payload.copiedCase,
      };
    }
    case Case.CANCEL_EDITING_COPIED_CASE: {
      return {
        ...state,
        ...action.payload,
        editingCopiedCase: false,
      };
    }
    case Case.UPDATE_RELATED_CASES: {
      return {
        ...state,
        case: { ...state.case, relatedCases: action.payload },
      };
    }
    default:
      return state;
  }
};
