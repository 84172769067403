import React from "react";
import PropTypes from "prop-types";
import FieldsCaseView from "../fields/fieldsCaseView/fieldsCaseView";
import { ProductDefect, ProductDelivery, DamagedProductDraft } from ".";

function CaseView(props) {
  return (
    <FieldsCaseView
      ProductDefect={ProductDefect}
      ProductDelivery={ProductDelivery}
      DamagedProduct={DamagedProductDraft}
      isDraft
      {...props}
    />
  );
}

CaseView.propTypes = {
  match: PropTypes.shape({}),
};

CaseView.defaultProps = {
  match: { params: undefined },
};

export default CaseView;
