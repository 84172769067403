import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EuroIcon } from "../../../images/euro/euro.svg";
import CaseIcon from "./caseIcon";

function RequestToCompensateIcon({ ...props }) {
  const { t } = useTranslation();
  return (
    <CaseIcon tooltip={t("rebatePermit")} {...props}>
      <EuroIcon width="10px" height="100%" />
    </CaseIcon>
  );
}

export default RequestToCompensateIcon;
