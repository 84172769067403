import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Checkbox, FormControlLabel } from "@oriola-origo/origo-ui-core";
import { FormHelperText, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line import/no-cycle
import { FieldTitle } from "..";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";
import { StatefullTextField } from "../../../common";
import SpecialFiledValue from "../../../../constants/specialFieldValue";

const useStyles = makeStyles(() => ({
  checkBox: {
    paddingTop: "1px",
  },
}));

function BatchNumber({
  itemData,
  id,
  config,
  error,
  updateItem,
  updateItemValidationError,
  disabled,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [batchNumberUnavailableChecked, setBatchNumberUnavailableChecked] =
    useState(false);

  useEffect(() => {
    if (itemData.batchNumber === SpecialFiledValue.NOT_AVAILABLE) {
      setBatchNumberUnavailableChecked(true);
    } else {
      setBatchNumberUnavailableChecked(false);
    }
  }, [itemData.batchNumber]);

  return (
    <Box maxWidth="550px">
      <Box display="flex" flexDirection="column">
        <FieldTitle
          title={t("caseItemFields.batchNumber")}
          optional={config.optional}
        />
        <StatefullTextField
          initialValue={
            batchNumberUnavailableChecked ? "" : itemData.batchNumber || ""
          }
          error={!!error}
          disabled={batchNumberUnavailableChecked || disabled}
          variant="outlined"
          margin="dense"
          onChange={batchNumber => {
            dispatch(
              updateItemValidationError(itemData.uuid, { batchNumber: "" })
            );
            dispatch(
              updateItem({
                ...itemData,
                batchNumber,
              })
            );
          }}
          id={`${id}-batch-number`}
        />
        {config.notAvailableConfirm && (
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                className={classes.checkBox}
                checked={batchNumberUnavailableChecked}
                onChange={event => {
                  dispatch(
                    updateItem({
                      ...itemData,
                      batchNumber: event.target.checked
                        ? SpecialFiledValue.NOT_AVAILABLE
                        : "",
                    })
                  );
                  dispatch(
                    updateItemValidationError(itemData.uuid, {
                      batchNumber: "",
                    })
                  );
                }}
                value="checkedA"
                id={`${id}-batch-not-available`}
              />
            }
            label={t("batchNumberNotAvailable")}
          />
        )}
      </Box>
      {error && (
        <FormHelperText error={!!error} style={{ fontSize: "1rem" }}>
          {getValidationErrorMessage(error, t, { field: t("batchNumber") })}
        </FormHelperText>
      )}
    </Box>
  );
}

BatchNumber.propTypes = {
  itemData: PropTypes.shape({
    batchNumber: PropTypes.string,
    uuid: PropTypes.string,
  }),
  classes: PropTypes.shape({}),
  id: PropTypes.string,
  config: PropTypes.shape({
    optional: PropTypes.bool,
    notAvailableConfirm: PropTypes.bool,
  }),
  error: PropTypes.string,
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
  disabled: PropTypes.bool,
};

BatchNumber.defaultProps = {
  itemData: {},
  classes: {},
  id: "",
  config: {},
  error: "",
  updateItem: null,
  updateItemValidationError: null,
  disabled: false,
};

export default BatchNumber;
