import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  withStyles,
} from "@material-ui/core";
import { OriolaColors } from "@oriola-origo/origo-ui-core";

export const StyledFormControl = withStyles({
  root: {
    "& label": {
      color: OriolaColors.Grey,
    },
    "& label.Mui-focused": {
      color: OriolaColors.secondaryDarkGray,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: OriolaColors.Grey,
        borderWidth: "1px",
      },
    },
  },
})(FormControl);

function SelectMenu({
  optionValue,
  options,
  label,
  onSelectOption,
  ...containerProps
}) {
  return (
    <Box {...containerProps}>
      <StyledFormControl variant="outlined" size="small" fullWidth>
        <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
        <Select
          value={optionValue}
          onChange={e => onSelectOption(e.target.value)}
          label={label}
        >
          {options.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={{ marginLeft: option.subOption ? "24px" : "12px" }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </Box>
  );
}

SelectMenu.propTypes = {
  optionValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  onSelectOption: PropTypes.func.isRequired,
  label: PropTypes.string,
};

SelectMenu.defaultProps = {
  optionValue: "",
  options: [],
  label: "",
};

export default SelectMenu;
