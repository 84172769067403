import moment from "moment";

export const isMandatoryFieldEmpty = (fieldConfig, fieldValue) =>
  !fieldConfig.optional && !fieldValue;

export const isInvalidNumber = (fieldConfig, fieldValue) =>
  fieldConfig.number && !/^\d+$/.test(fieldValue);

export const isInvalidDate = (fieldConfig, fieldValue) =>
  fieldConfig.date && !moment(fieldValue).isValid();

export const isInvalidFutureDate = (fieldConfig, fieldValue) =>
  fieldConfig.disableFutureDate && moment(fieldValue).isAfter();

export const isInvalidLength = (fieldConfig, fieldValue = "") =>
  fieldConfig.validLength && fieldValue.length !== fieldConfig.validLength;

export const isOverLength = (fieldConfig, fieldValue = "") => {
  const maxLength = fieldConfig.maxLength || Number.MAX_SAFE_INTEGER;
  return fieldValue.length > maxLength;
};

export const isInValidBoolean = (fieldConfig, fieldValue) =>
  fieldConfig.boolean && typeof fieldValue !== "boolean";
