import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-cycle
import QuantityField from "./quantityField";
import Quantities from "./quantities";
import BatchNumber from "./batchNumber";
import ProductField from "./productField";
import ExpirationDate from "./expirationDate";
import Stored from "./stored";
import PriceInput from "./priceInput";
import TransferToAnotherCustomer from "./transferToAnotherCustomer";
import ProductCode from "./productCode";
import AlertCode from "./alertCode";
import UniqueSerialNumbers from "./uniqueSerialNumbers";
import ProductPrincipal from "./productPrincipal";
import ProductPharmaceuticalForm from "./productPharmaceuticalForm";

function ItemRowProducts({
  itemData,
  id,
  validationError,
  caseConfig,
  fieldsConfig,
  updateItem,
  updateItemValidationError,
  classes,
  disabled,
}) {
  const { t } = useTranslation();
  const { items } = useSelector(state => state.case.case);
  const getValidationError = fieldName =>
    validationError ? validationError[fieldName] : undefined;

  return (
    <>
      {fieldsConfig.missingProduct && (
        <ProductField
          itemData={itemData}
          classes={classes}
          id={`${id}-missing-product`}
          productFieldName="missingProduct"
          config={fieldsConfig.missingProduct}
          error={getValidationError("missingProduct")}
          updateItem={updateItem}
          updateItemValidationError={updateItemValidationError}
          disabled={disabled}
        />
      )}
      {fieldsConfig.quantityMissing && (
        <div className={classes.rowContainer}>
          <QuantityField
            itemData={itemData}
            classes={classes}
            id={id}
            fieldName="quantityMissing"
            config={fieldsConfig.quantityMissing}
            error={getValidationError("quantityMissing")}
            updateItem={updateItem}
            updateItemValidationError={updateItemValidationError}
            disabled={disabled}
          />
        </div>
      )}
      {fieldsConfig.deliveredProduct && (
        <ProductField
          itemData={itemData}
          classes={classes}
          id={`${id}-delivered-product`}
          productFieldName="deliveredProduct"
          config={fieldsConfig.deliveredProduct}
          error={getValidationError("deliveredProduct")}
          updateItem={updateItem}
          updateItemValidationError={updateItemValidationError}
          disabled={disabled}
        />
      )}
      {fieldsConfig.product && (
        <ProductField
          itemData={itemData}
          classes={classes}
          id={`${id}-product`}
          productFieldName="product"
          config={fieldsConfig.product}
          error={getValidationError("product")}
          updateItem={updateItem}
          updateItemValidationError={updateItemValidationError}
          disabled={disabled}
        />
      )}
      {fieldsConfig.product &&
        (fieldsConfig.product.profitCenterName ||
          fieldsConfig.product.pharmaceuticalForm) && (
          <div className={classes.flexRowContainer}>
            {fieldsConfig.product && fieldsConfig.product.profitCenterName && (
              <ProductPrincipal
                product={itemData.product}
                classes={classes}
                updateItem={updateItem}
                itemData={itemData}
                id={id}
                disabled={disabled}
              />
            )}
            {fieldsConfig.product &&
              fieldsConfig.product.pharmaceuticalForm && (
                <ProductPharmaceuticalForm
                  product={itemData.product}
                  classes={classes}
                  updateItem={updateItem}
                  itemData={itemData}
                  disabled={disabled}
                />
              )}
          </div>
        )}
      {fieldsConfig.transferToAnotherCustomer && (
        <TransferToAnotherCustomer
          itemData={itemData}
          id={`${id}-transfer-to-another-customer`}
          error={getValidationError("transferToAnotherCustomer")}
          updateItem={updateItem}
          updateItemValidationError={updateItemValidationError}
        />
      )}
      {!caseConfig.noExtraRow && (
        <div className={classes.flexRowContainer}>
          <Quantities
            itemData={itemData}
            id={id}
            fieldsConfig={fieldsConfig}
            getValidationError={getValidationError}
            updateItem={updateItem}
            updateItemValidationError={updateItemValidationError}
            disabled={disabled}
          />
          {fieldsConfig.amountOfPackages && (
            <QuantityField
              itemData={itemData}
              id={id}
              fieldName="amountOfPackages"
              config={fieldsConfig.amountOfPackages}
              error={getValidationError("amountOfPackages")}
              updateItem={updateItem}
              updateItemValidationError={updateItemValidationError}
              description={t("amountOfPackagesDescription")}
              disabled={disabled}
            />
          )}
          {fieldsConfig.batchNumber && (
            <BatchNumber
              itemData={itemData}
              classes={classes}
              id={id}
              config={fieldsConfig.batchNumber}
              error={getValidationError("batchNumber")}
              updateItem={updateItem}
              updateItemValidationError={updateItemValidationError}
              disabled={disabled}
            />
          )}
          {fieldsConfig.expirationDate && (
            <ExpirationDate
              itemData={itemData}
              classes={classes}
              id={id}
              config={fieldsConfig.expirationDate}
              error={getValidationError("expirationDate")}
              updateItem={updateItem}
              updateItemValidationError={updateItemValidationError}
              disabled={disabled}
            />
          )}
          {fieldsConfig.stored && (
            <Stored
              itemData={itemData}
              classes={classes}
              id={id}
              config={fieldsConfig.stored}
              error={getValidationError("stored")}
              updateItem={updateItem}
              updateItemValidationError={updateItemValidationError}
              disabled={disabled}
            />
          )}
          <div className={classes.flexRowField}>
            {fieldsConfig.productCode && (
              <ProductCode
                itemData={itemData}
                classes={classes}
                id={id}
                config={fieldsConfig.productCode}
                height={fieldsConfig.uniqueSerialNumber}
                error={getValidationError("productCode")}
                updateItem={updateItem}
                updateItemValidationError={updateItemValidationError}
                disabled={disabled}
              />
            )}
            {fieldsConfig.alertCode && (
              <AlertCode
                itemData={itemData}
                classes={classes}
                id={id}
                config={fieldsConfig.alertCode}
                error={getValidationError("alertCode")}
                updateItem={updateItem}
                updateItemValidationError={updateItemValidationError}
                disabled={disabled}
              />
            )}
            {fieldsConfig.uniqueSerialNumber && (
              <UniqueSerialNumbers
                config={fieldsConfig.uniqueSerialNumber}
                itemData={items[0]}
                classes={classes}
                error={getValidationError("uniqueSerialNumber")}
                updateItem={updateItem}
                updateItemValidationError={updateItemValidationError}
                disabled={disabled}
              />
            )}
          </div>
          {fieldsConfig.pricePerUnitCharged && (
            <PriceInput
              itemData={itemData}
              id={id}
              fieldName="pricePerUnitCharged"
              config={fieldsConfig.pricePerUnitCharged}
              error={getValidationError("pricePerUnitCharged")}
              updateItem={updateItem}
              updateItemValidationError={updateItemValidationError}
              disabled={disabled}
            />
          )}
          {fieldsConfig.pricePerUnit && (
            <PriceInput
              itemData={itemData}
              id={id}
              fieldName="pricePerUnit"
              config={fieldsConfig.pricePerUnit}
              error={getValidationError("pricePerUnit")}
              updateItem={updateItem}
              updateItemValidationError={updateItemValidationError}
              disabled={disabled}
            />
          )}
        </div>
      )}
    </>
  );
}

ItemRowProducts.propTypes = {
  itemData: PropTypes.shape({
    product: PropTypes.shape({}),
  }),
  id: PropTypes.string,
  reasonIdentifierConfig: PropTypes.shape({}),
  validationError: PropTypes.shape({}),
  fieldsConfig: PropTypes.shape({
    missingProduct: PropTypes.shape({}),
    quantityMissing: PropTypes.shape({}),
    deliveredProduct: PropTypes.shape({}),
    product: PropTypes.shape({
      profitCenterName: PropTypes.shape({}),
      pharmaceuticalForm: PropTypes.shape({}),
    }),
    transferToAnotherCustomer: PropTypes.shape({}),
    amountOfPackages: PropTypes.shape({}),
    batchNumber: PropTypes.shape({}),
    expirationDate: PropTypes.shape({}),
    stored: PropTypes.shape({}),
    productCode: PropTypes.shape({}),
    alertCode: PropTypes.shape({}),
    uniqueSerialNumber: PropTypes.shape({}),
    pricePerUnitCharged: PropTypes.shape({}),
    pricePerUnit: PropTypes.shape({}),
  }),
  caseConfig: PropTypes.shape({
    noExtraRow: PropTypes.bool,
  }),
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
  classes: PropTypes.shape({
    flexRowContainer: PropTypes.string,
    flexRowField: PropTypes.string,
    rowContainer: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

ItemRowProducts.defaultProps = {
  itemData: {},
  id: "",
  reasonIdentifierConfig: {},
  validationError: {},
  fieldsConfig: {},
  caseConfig: {},
  updateItem: null,
  updateItemValidationError: null,
  classes: {},
  disabled: false,
};

export default ItemRowProducts;
