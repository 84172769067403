/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import Box from "@oriola-origo/core/lib/Box";
import { styled } from "@oriola-origo/core/lib/styles";
import TextField from "@oriola-origo/core/lib/TextField";
import Typography from "@oriola-origo/core/lib/Typography";
import IconButton from "@oriola-origo/core/lib/IconButton";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import Autocomplete from "@oriola-origo/core/lib/Autocomplete";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import { OriolaColors } from "@oriola-origo/core/lib/Theme/OriolaTheme";

// eslint-disable-next-line import/no-cycle
import {
  updateLocalHandlingDataField,
  updateValidationError,
} from "../../../../redux";
import DateAndEditor from "../../dateAndEditor";
import countries from "../../../../constants/countries";
import {
  prepareNewReturnAddressText,
  getModificationDataToReturnAddress,
} from "../../accordionItem/spdHandlingData/utils";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";
import { ReturnAddressV2Props, ReturnAddressSubfield } from "./types";

const Title = styled(Typography)(({ theme }) => ({
  position: "relative",
  top: theme.spacing(1.75),
  left: theme.spacing(1.25),
  width: "fit-content",
  paddingRight: theme.spacing(0.5),
}));

const Container = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "auto",
  gridTemplateAreas: `
      "company company"
      "contact phone"
      "address address"
      "country country"
      "modifier copy"
    `,
  columnGap: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[400]}`,
  padding: `${theme.spacing(2.5)} ${theme.spacing(2.5)} ${theme.spacing(0.5)} ${theme.spacing(2.5)}`,
  borderRadius: 4,
  marginBottom: theme.spacing(2.5),
}));

const CompanyField = styled(Box)(() => ({
  gridArea: "company",
}));

const ContactNameField = styled(Box)(() => ({
  gridArea: "contact",
}));

const PhoneField = styled(Box)(() => ({
  gridArea: "phone",
}));

const AddressField = styled(Box)(() => ({
  gridArea: "address",
}));

const CountryField = styled(Box)(() => ({
  gridArea: "country",
}));

const ModifierField = styled(Box)(() => ({
  gridArea: "modifier",
  alignSelf: "center",
}));

const CopyButtonField = styled(Box)(() => ({
  gridArea: "copy",
  justifySelf: "end",
}));

function ReturnAddressV2({
  user,
  disabled,
  returnAddress,
  isReturnToPrincipalRequired,
  getModificationData,
  setIsAuthorizedRecipientConfirmed,
}: Readonly<ReturnAddressV2Props>) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const {
    spdReturnToPrincipalAddressCompanyFieldRequired,
    spdReturnToPrincipalAddressContactNameFieldRequired,
    spdReturnToPrincipalAddressPhoneNumberFieldRequired,
    spdReturnToPrincipalAddressAddressFieldRequired,
    spdReturnToPrincipalAddressCountryFieldRequired,
  } = useSelector((state: any) => state.validationErrors);

  const setNewReturnAddressSubfield = (
    value: string,
    scope: ReturnAddressSubfield
  ) => {
    // clear validation errors
    const returnAddressErrorToClear = {
      company: { spdReturnToPrincipalAddressCompanyFieldRequired: null },
      contactName: {
        spdReturnToPrincipalAddressContactNameFieldRequired: null,
      },
      phoneNumber: {
        spdReturnToPrincipalAddressPhoneNumberFieldRequired: null,
      },
      address: { spdReturnToPrincipalAddressAddressFieldRequired: null },
      country: { spdReturnToPrincipalAddressCountryFieldRequired: null },
    };

    dispatch(
      updateValidationError({
        ...returnAddressErrorToClear[scope],
      })
    );

    const valueToStoreInLocalHandling =
      value !== "" && value !== undefined ? value : undefined;

    dispatch(
      updateLocalHandlingDataField(
        valueToStoreInLocalHandling,
        "productHandlingInstructions",
        "returnAddress",
        scope
      )
    );

    // clear authorization since changed
    setIsAuthorizedRecipientConfirmed(null);
  };

  const isEmptyReturnAddress = Object.keys(returnAddress).length === 0;

  const prepareLocalReturnAddressData = () => {
    const localData = getModificationData("returnAddress");
    delete localData.modificationTime;
    delete localData.userName;
    delete localData.isLocalChange;
    delete localData.userType;
    delete localData.userId;

    const filteredData = Object.fromEntries(
      Object.entries(localData).filter(([, value]) => value !== undefined)
    );
    return filteredData;
  };

  return (
    <OriolaThemeProvider>
      <Box mt={2}>
        <Title
          variant="h6"
          color="textSecondary"
          sx={{
            backgroundColor: disabled
              ? OriolaColors.backgroundPaper
              : OriolaColors.backgroundDefault,
          }}
        >
          {t("productHandlingInstructions.returnAddress")}
        </Title>
        <Container>
          <CompanyField>
            <TextField
              disabled={disabled}
              style={{ marginBottom: 16, marginTop: 8 }}
              value={returnAddress.company || ""}
              label={t("productHandlingInstructions.returnAddressCompanyField")}
              onChange={e =>
                setNewReturnAddressSubfield(
                  e.target.value,
                  ReturnAddressSubfield.Company
                )
              }
              required
              maxRows={1}
              fullWidth
              error={
                spdReturnToPrincipalAddressCompanyFieldRequired &&
                isReturnToPrincipalRequired === true
              }
              helperText={getValidationErrorMessage(
                spdReturnToPrincipalAddressCompanyFieldRequired,
                t
              )}
              id="product-handling-instructions-return-address-company"
            />
          </CompanyField>
          <ContactNameField>
            <TextField
              disabled={disabled}
              style={{ marginBottom: 10 }}
              value={returnAddress.contactName || ""}
              label={t(
                "productHandlingInstructions.returnAddressContactNameField"
              )}
              onChange={e =>
                setNewReturnAddressSubfield(
                  e.target.value,
                  ReturnAddressSubfield.ContactName
                )
              }
              required
              maxRows={1}
              fullWidth
              error={
                spdReturnToPrincipalAddressContactNameFieldRequired &&
                isReturnToPrincipalRequired === true
              }
              helperText={getValidationErrorMessage(
                spdReturnToPrincipalAddressContactNameFieldRequired,
                t
              )}
              id="product-handling-instructions-return-address-contact-name"
            />
          </ContactNameField>
          <PhoneField>
            <TextField
              disabled={disabled}
              style={{ marginBottom: 10 }}
              value={returnAddress.phoneNumber || ""}
              label={t("productHandlingInstructions.returnAddressPhoneField")}
              onChange={e =>
                setNewReturnAddressSubfield(
                  e.target.value,
                  ReturnAddressSubfield.PhoneNumber
                )
              }
              required
              maxRows={1}
              fullWidth
              error={
                spdReturnToPrincipalAddressPhoneNumberFieldRequired &&
                isReturnToPrincipalRequired === true
              }
              helperText={
                getValidationErrorMessage(
                  spdReturnToPrincipalAddressPhoneNumberFieldRequired,
                  t
                ) || "E.g. +358 40 123 4567"
              }
              id="product-handling-instructions-return-address-phone-number"
            />
          </PhoneField>
          <AddressField>
            <TextField
              disabled={disabled}
              style={{ marginBottom: 16 }}
              value={returnAddress.address || ""}
              onChange={e =>
                setNewReturnAddressSubfield(
                  e.target.value,
                  ReturnAddressSubfield.Address
                )
              }
              multiline
              required
              maxRows={3}
              fullWidth
              label={t("productHandlingInstructions.returnAddressAddressField")}
              error={
                spdReturnToPrincipalAddressAddressFieldRequired &&
                isReturnToPrincipalRequired === true
              }
              helperText={getValidationErrorMessage(
                spdReturnToPrincipalAddressAddressFieldRequired,
                t
              )}
              id="product-handling-instructions-return-address-address"
            />
          </AddressField>
          <CountryField>
            <Autocomplete
              id="return-address-country-select"
              options={countries}
              disabled={disabled}
              style={{ marginBottom: 8 }}
              value={
                countries.find(
                  country => country.label === returnAddress.country
                ) || null
              }
              label={t("productHandlingInstructions.returnAddressCountryField")}
              onChange={(e, value: any) =>
                setNewReturnAddressSubfield(
                  value?.label,
                  ReturnAddressSubfield.Country
                )
              }
              autoHighlight
              getOptionLabel={(option: any) => option.label}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t(
                    "productHandlingInstructions.returnAddressCountryField"
                  )}
                  error={
                    spdReturnToPrincipalAddressCountryFieldRequired &&
                    isReturnToPrincipalRequired === true
                  }
                  required
                  helperText={getValidationErrorMessage(
                    spdReturnToPrincipalAddressCountryFieldRequired,
                    t
                  )}
                />
              )}
            />
          </CountryField>
          <ModifierField>
            {!isEmptyReturnAddress && (
              <DateAndEditor
                id="productHandlingInstructions-date-editor-return-address"
                modificationData={getModificationDataToReturnAddress(
                  prepareLocalReturnAddressData()
                )}
                user={user}
              />
            )}
          </ModifierField>
          <CopyButtonField>
            <Tooltip title={copied ? t("copied") : t("copy")} placement="top">
              <IconButton
                size="small"
                sx={{ paddingRight: 0, backgroundColor: "transparent" }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    prepareNewReturnAddressText(returnAddress)
                  );
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 1000);
                }}
              >
                <FontIcon color="secondary">file_copy</FontIcon>
              </IconButton>
            </Tooltip>
          </CopyButtonField>
        </Container>
      </Box>
    </OriolaThemeProvider>
  );
}

export default ReturnAddressV2;
