import React from "react";
import { useTranslation } from "react-i18next";
import CaseLabel from "./caseLabel";

function NonReturningNarcoticsLabel({ ...props }) {
  const { t } = useTranslation();
  return <CaseLabel label={t("nonret-narcotic")} {...props} />;
}

export default NonReturningNarcoticsLabel;
