import { showDialog, hideDialog } from "../../../redux";

const confirmAndCancelCreate = (t, dispatch, onCancel) => {
  dispatch(
    showDialog({
      open: true,
      title: t("confirmLeavinPage.title"),
      description: null,
      cancelButtonText: t("confirmLeavinPage.cancel"),
      okButtonText: t("confirmLeavinPage.confirm"),
      onCancel: () => dispatch(hideDialog()),
      onOk: () => {
        dispatch(hideDialog());
        if (onCancel) {
          onCancel();
        }
      },
    })
  );
};
export default confirmAndCancelCreate;
