import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Box, IconButton, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MuiDatePicker, OriolaColors } from "@oriola-origo/origo-ui-core";
import moment from "moment";
import { Trashcan } from "../../../images";
import { CompactTitleInput } from "../../common";
import {
  CompensationErrors,
  IDENTIFIER_CODE_MIN_LENGTH,
  IDENTIFIER_CODE_MAX_LENGTH,
  COMPENSATION_NUMBER_MIN_LENGTH,
  COMPENSATION_NUMBER_MAX_LENGTH,
  HANDLER_MIN_LENGTH,
  HANDLER_MAX_LENGTH,
} from "../../../utils/validations/validations";

const useStyles = makeStyles(theme => ({
  compensationRow: {
    "& > div:nth-child(-n+2)": {
      flexBasis: "50%",
      flexGrow: 1,
    },
    "& > div:nth-child(n+3)": {
      flexBasis: "10%",
      flexGrow: 0,
    },
  },
  trashCan: {
    alignSelf: "flex-end",
    marginBottom: theme.spacing(4),
  },
  hiddenTrashCan: {
    visibility: "hidden",
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    borderBottomColor: "#e7e7e7",
  },
}));

const StyledMuiDatePicker = withStyles({
  root: {
    "& label": {
      color: OriolaColors.Grey,
    },
  },
})(MuiDatePicker);

function Compensation({
  compensation,
  hideRemoveButton,
  onCompensationUpdated,
  onCompensationRemoved,
  domId,
}) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { compensationsError } = useSelector(state => state.validationErrors);
  const [errorIs, setErrorIs] = useState({
    identifierCodeError: "",
    compensationNumberError: "",
  });
  const { id, identifierCode, compensationNumber, compensationDate, handler } =
    compensation;
  const compensationError = compensationsError[id];
  const {
    identifierCodeError,
    compensationNumberError,
    handlerError,
    compensationDateError,
  } = errorIs;

  useEffect(() => {
    if (compensationError) {
      compensationError.forEach(error => {
        let errorName;
        let errorMessage;
        if (error === CompensationErrors.IDENTIFIER_CODE_ERROR) {
          errorName = "identifierCodeError";
          errorMessage = t("lengthIsIncorrect", {
            unit: "code",
            minLength: IDENTIFIER_CODE_MIN_LENGTH,
            maxLength: IDENTIFIER_CODE_MAX_LENGTH,
          });
        }
        if (error === CompensationErrors.COMPENSATION_NUMBER_ERROR) {
          errorName = "compensationNumberError";
          errorMessage = t("lengthIsIncorrect", {
            unit: t("compensationInformation.number").toLowerCase(),
            minLength: COMPENSATION_NUMBER_MIN_LENGTH,
            maxLength: COMPENSATION_NUMBER_MAX_LENGTH,
          });
        }
        if (error === CompensationErrors.HANDLER_ERROR) {
          errorName = "handlerError";
          errorMessage = t("lengthIsIncorrect", {
            unit: t("compensationInformation.handlerDescription"),
            minLength: HANDLER_MIN_LENGTH,
            maxLength: HANDLER_MAX_LENGTH,
          });
        }
        if (error === CompensationErrors.COMPENSATION_DATE_ERROR) {
          errorName = "compensationDateError";
          errorMessage = t("dateIncorrect");
        }
        setErrorIs(prevState => ({ ...prevState, [errorName]: errorMessage }));
      });
    }
  }, [compensationError, t]);
  const removeButton = () =>
    hideRemoveButton === false ? (
      <Box className={classes.trashCan}>
        <IconButton
          size="small"
          onClick={() => onCompensationRemoved(compensation)}
          id={`${domId}-remove-button`}
        >
          <Trashcan color="primary" />
        </IconButton>
      </Box>
    ) : (
      <Box className={classes.hiddenTrashCan}>
        <IconButton size="small">
          <Trashcan color="primary" />
        </IconButton>
      </Box>
    );

  return (
    <Box display="flex">
      <Box>
        <Box mb={4} display="flex" className={classes.compensationRow}>
          <CompactTitleInput
            id={`${domId}-identifier-code`}
            error={!!identifierCodeError}
            helperText={identifierCodeError}
            title={t("identifierCode")}
            mr={1}
            value={identifierCode}
            onChange={value => {
              onCompensationUpdated({ ...compensation, identifierCode: value });
              setErrorIs({ ...errorIs, identifierCodeError: "" });
            }}
          />
          <CompactTitleInput
            id={`${domId}-compensation-number`}
            error={!!compensationNumberError}
            helperText={compensationNumberError}
            title={t("compensationNumber")}
            value={compensationNumber}
            onChange={value => {
              onCompensationUpdated({
                ...compensation,
                compensationNumber: value.trim(),
              });
              setErrorIs({ ...errorIs, compensationNumberError: "" });
            }}
          />
        </Box>
        <Box display="flex" className={classes.compensationRow}>
          <StyledMuiDatePicker
            id={`${domId}-compensation-date`}
            error={!!compensationDateError}
            value={compensationDate}
            placeholder={t("datepickerPlaceholder")}
            onChange={value => {
              onCompensationUpdated({
                ...compensation,
                compensationDate: value
                  ? moment(value).format("YYYY-MM-DD")
                  : undefined,
              });
              setErrorIs({ ...errorIs, compensationDateError: "" });
            }}
            locale={i18n.language}
            helperText={compensationDateError}
            mr={1}
            label={t("compensationDate")}
          />
          <CompactTitleInput
            id={`${domId}-row-handler`}
            ml={1}
            error={!!handlerError}
            helperText={handlerError}
            title={t("handler")}
            value={handler}
            onChange={value => {
              onCompensationUpdated({ ...compensation, handler: value });
              setErrorIs({ ...errorIs, handlerError: "" });
            }}
          />
        </Box>
        {!hideRemoveButton && <Divider className={classes.divider} />}
      </Box>
      {removeButton()}
    </Box>
  );
}

Compensation.propTypes = {
  compensation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    identifierCode: PropTypes.string.isRequired,
    compensationNumber: PropTypes.string.isRequired,
    compensationDate: PropTypes.string.isRequired,
    handler: PropTypes.string.isRequired,
  }),
  hideRemoveButton: PropTypes.bool,
  onCompensationUpdated: PropTypes.func.isRequired,
  onCompensationRemoved: PropTypes.func.isRequired,
  domId: PropTypes.string,
};

Compensation.defaultProps = {
  compensation: {
    id: "",
    identifierCode: "",
    compensationNumber: "",
    compensationDate: "",
    handler: "",
  },
  hideRemoveButton: false,
  domId: "",
};

export default Compensation;
