import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { CaseType } from "../../constants/caseType";

const objectPath = require("object-path");

const useStyles = makeStyles(theme => ({
  columnValueText: {
    color: theme.palette.text.primary,
    width: "100%",
    ...theme.typography.button,
    textTransform: "none",
  },
}));

function Principal({ singleCase, currentUserIsOriolaUser }) {
  const classes = useStyles();
  const { items, caseType } = singleCase;

  if (items.length !== 1 || caseType !== CaseType.SUSPECTED_PRODUCT_DEFECT) {
    return "-";
  }

  const item = items[0];
  const principalNumber = objectPath.get(item, "product.profitCenter");
  const principalName = objectPath.get(item, "product.profitCenterName");

  return (
    <Box>
      <Typography className={classes.columnValueText}>
        {principalName || "-"}
      </Typography>
      {currentUserIsOriolaUser && (
        <Typography className={classes.columnValueText}>
          {principalNumber || "-"}
        </Typography>
      )}
    </Box>
  );
}

Principal.propTypes = {
  singleCase: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        product: PropTypes.shape({
          profitCenter: PropTypes.string,
          profitCenterName: PropTypes.string,
        }),
      })
    ),
    caseType: PropTypes.string,
  }),
  currentUserIsOriolaUser: PropTypes.bool,
};

Principal.defaultProps = {
  singleCase: {
    items: [],
    caseType: "",
  },
  currentUserIsOriolaUser: false,
};

export default Principal;
