import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, TextField, Typography } from "@material-ui/core";
import Title from "../title/title";
import { TitleInput } from "../../common";
import DateAndEditor from "../dateAndEditor";
import { CaseType } from "../../../constants/caseType";
import {
  updateValidationError,
  updateLocalHandlingDataField,
} from "../../../redux";
import { getSpdModificationData } from "../../../utils/getSpdModificationData/getSpdModificationData";
import {
  getValidationErrorMessage,
  FieldErrors,
  HANDLING_INFORMATION_MAX_LENGTH,
} from "../../../utils/validations/validations";

const StorageLocation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { storageLocationError } = useSelector(state => state.validationErrors);
  const caseType = useSelector(state => state.case.case.caseType);
  const { localHandlingData = {} } = useSelector(state => state.case);
  const { userData } = useSelector(state => state.user);
  const { modifications = {}, storageLocation = "" } = localHandlingData;
  const getModificationData = field =>
    getSpdModificationData(modifications, `${field}`);

  const handleChange = event => {
    const storageLocationInput =
      caseType !== CaseType.SUSPECTED_PRODUCT_DEFECT
        ? event.target.value
        : event;
    if (storageLocationInput.length > HANDLING_INFORMATION_MAX_LENGTH) {
      dispatch(
        updateValidationError({
          storageLocationError: FieldErrors.HANDLING_INFORMATION_OVER_LENGTH,
        })
      );
    } else {
      dispatch(
        updateLocalHandlingDataField(storageLocationInput, "storageLocation")
      );
      dispatch(updateValidationError({ storageLocationError: "" }));
    }
  };

  const renderStorageLocationSPDCase = () => (
    <Box id="storage-location" className="handling-section">
      <Title title={t("storageLocation.title")} />
      <Box mt={2}>
        <TitleInput
          mt={4}
          title={t("storageLocation.label")}
          secondaryTitle={t("optional")}
          value={storageLocation || ""}
          onChange={event => {
            handleChange(event);
          }}
          rows={3}
          placeholder={t("storageLocation.placeholder")}
          enableCopyButton
        />
        {getModificationData("storageLocation") && (
          <DateAndEditor
            id="storage-location-info"
            modificationData={getModificationData("storageLocation")}
            user={userData}
          />
        )}
      </Box>
    </Box>
  );

  const renderStorageLocationNonSPDCase = () => (
    <div id="storage-location" className="storage-location-section">
      <Title title={t("storageLocation.title").toUpperCase()} />
      <Typography noWrap>
        {`${t("storageLocation.label")} ${t("optional")}`}
      </Typography>
      <TextField
        id="storage-location-input"
        fullWidth
        multiline
        minRows="3"
        value={storageLocation || ""}
        variant="outlined"
        placeholder={t("storageLocation.placeholder")}
        margin="dense"
        error={!!storageLocationError}
        helperText={getValidationErrorMessage(storageLocationError, t)}
        onChange={event => {
          handleChange(event);
        }}
      />
      {getModificationData("storageLocation") && (
        <DateAndEditor
          id="storage-location-info"
          modificationData={{
            ...getModificationData("storageLocation"),
            hideModifierName: true,
          }}
          user={userData}
        />
      )}
    </div>
  );

  const renderStorageLocationPerCaseType = tmpCaseType =>
    tmpCaseType !== CaseType.SUSPECTED_PRODUCT_DEFECT
      ? renderStorageLocationNonSPDCase()
      : renderStorageLocationSPDCase();

  return renderStorageLocationPerCaseType(caseType);
};

export default StorageLocation;
