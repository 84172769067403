import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { StatefullTextField } from "../../../common";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";
// eslint-disable-next-line import/no-cycle
import { FieldTitle } from "..";

function TextAreaField({
  itemData,
  classes,
  id,
  fieldName,
  error,
  disabled,
  config,
  updateItem,
  updateItemValidationError,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <div className={classes.rowContainer}>
      <FieldTitle
        title={t(`caseItemFields.${fieldName}.title`)}
        optional={config.optional}
      />
      <div className={classes.controlContainer}>
        <StatefullTextField
          error={!!error}
          helperText={getValidationErrorMessage(error, t, {
            maxLength: config.maxLength,
          })}
          initialValue={itemData[fieldName] || ""}
          variant="outlined"
          fullWidth
          multiline
          rows="3"
          placeholder={t(`caseItemFields.${fieldName}.placeholder`)}
          margin="dense"
          disabled={disabled}
          onChange={newValue => {
            if (config.maxLength && newValue.length > config.maxLength) {
              return;
            }
            dispatch(
              updateItemValidationError(itemData.uuid, { [fieldName]: "" })
            );
            dispatch(
              updateItem({
                ...itemData,
                [fieldName]: newValue,
              })
            );
          }}
          id={`${id}-${fieldName}`}
        />
      </div>
    </div>
  );
}

TextAreaField.propTypes = {
  classes: PropTypes.shape({
    rowContainer: PropTypes.string,
    controlContainer: PropTypes.string,
  }),
  id: PropTypes.string,
  error: PropTypes.string,
  itemData: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  config: PropTypes.shape({
    maxLength: PropTypes.number,
    optional: PropTypes.bool,
  }),
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
};

TextAreaField.defaultProps = {
  disabled: false,
  classes: {},
  id: "",
  error: "",
  itemData: {},
  fieldName: "",
  config: {},
  updateItem: null,
  updateItemValidationError: null,
};

export default TextAreaField;
