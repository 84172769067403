import { getFormattedDate } from "../../../utils/date/date";

const simpleField = (key, item, format, field) =>
  field(key, `caseItemFields.${key}`, item, format);

const formatDate = strDate => (strDate ? getFormattedDate(strDate) : null);
const orderNumber = (item, field) =>
  simpleField("orderNumber", item, null, field);
const invoiceNumber = (item, field) =>
  simpleField("invoiceNumber", item, null, field);
const dateReceived = (item, field) =>
  simpleField("dateReceived", item, formatDate, field);
const amountOfPackages = (item, field) =>
  simpleField("amountOfPackages", item, null, field);
const quantity = (item, field) => simpleField("quantity", item, null, field);
const quantityOrdered = (item, field) =>
  simpleField("quantityOrdered", item, null, field);
const quantityMissing = (item, field) =>
  simpleField("quantityMissing", item, null, field);
const quantityReceived = (item, field) =>
  simpleField("quantityReceived", item, null, field);
const quantityReturned = (item, field) =>
  simpleField("quantityReturned", item, null, field);
const productCode = (item, field) =>
  simpleField("productCode", item, null, field);
const uniqueSerialNumber = (item, field) =>
  simpleField("uniqueSerialNumber", item, (x = []) => x.join(", "), field);
const alertCode = (item, field) =>
  field("alertCode", "caseItemFields.alertCode.title", item, null, field);
const batchNumber = (item, field, notAvailableText) =>
  field("batchNumber", "batchNumber", item, x => x || notAvailableText);
const expirationDate = (item, field) =>
  field("expirationDate", "expirationDate", item, formatDate);
const stored = (item, field, storageTemperatureText) =>
  field("stored", "stored", item, storageTemperatureText);
const salesRepresentative = (item, field) =>
  field(
    "salesRepresentative",
    "caseItemFields.salesRepresentative.title",
    item
  );
const whoAgreedReturn = (item, field) =>
  field("whoAgreedReturn", "caseItemFields.whoAgreedReturn.title", item);
const reasonForReturn = (item, field) =>
  field("reasonForReturn", "caseItemFields.reasonForReturn.title", item);
const reasonForProductRecall = (item, field) =>
  field(
    "reasonForProductRecall",
    "caseItemFields.reasonForProductRecall.title",
    item
  );
const reasonForRefund = (item, field) =>
  field("reasonForRefund", "caseItemFields.reasonForRefund.title", item);
const pricePerUnitCharged = (item, field, formatPrice) =>
  simpleField("pricePerUnitCharged", item, formatPrice, field);
const pricePerUnit = (item, field, formatPrice) =>
  simpleField("pricePerUnit", item, formatPrice, field);
const expectedReceiveDate = (item, field) =>
  simpleField("expectedReceiveDate", item, formatDate, field);
const shippingBoxCode = (item, field) =>
  field(
    "serialShippingContainerCode",
    "caseItemFields.serialShippingContainerCode.title",
    item
  );
const transferToAnotherCustomer = (item, field, formatCustomer) =>
  field(
    "transferToAnotherCustomer",
    "caseItemFields.transferCustomer",
    item,
    formatCustomer
  );
const moreInfo = (item, field) =>
  field("moreInfo", "caseItemFields.moreInfo.title", item);

const Field = {
  orderNumber,
  invoiceNumber,
  dateReceived,
  amountOfPackages,
  quantity,
  quantityOrdered,
  quantityMissing,
  quantityReceived,
  quantityReturned,
  batchNumber,
  expirationDate,
  stored,
  productCode,
  uniqueSerialNumber,
  alertCode,
  salesRepresentative,
  whoAgreedReturn,
  reasonForReturn,
  reasonForProductRecall,
  reasonForRefund,
  pricePerUnitCharged,
  pricePerUnit,
  expectedReceiveDate,
  shippingBoxCode,
  transferToAnotherCustomer,
  moreInfo,
};

export default Field;
