import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import useMediaQuery from "@oriola-origo/core/lib/useMediaQuery";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import Button from "@oriola-origo/core/lib/Button";
import { useSelector } from "react-redux";
import CaseStatusButtonGroup from "./caseStatusButtonGroup";
import CaseTypeSelect from "./caseTypeSelect";
import HandlerSelect from "./handlerSelect";
import ProductDetailsSelect from "./productDetailsSelect";
import DaterangeSelect from "./daterangeSelect";
import SortSelect from "./sortSelect";
import { isOriolaUser, isPharmaceuticalCompany } from "../../auth/permission";
import PrincipalAndSupplierFilter from "./principalAndSupplierFilter/principalAndSupplierFilter";

const useStyles = makeStyles(theme => ({
  filterBox: {
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingBottom: "2rem",
    paddingTop: "2rem",
    display: "flex",
    gridGap: "2em",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "1rem",
      paddingTop: "1rem",
      gridGap: "1em",
    },
  },
}));

function CaseFilters({
  updateFilters,
  fetchCases,
  onSizeChange,
  showFilterToggle,
}) {
  const { userData } = useSelector(state => state.user);
  const filters = useSelector(state => state.casesFilters);
  const userIsOriolaUser = isOriolaUser(userData);
  const userIsPrincipalUser = isPharmaceuticalCompany(userData);
  const [showFilters, setShowFilters] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  // Reclamation default theme and ODS theme have different breakpoints
  // Reclamation theme breakpoints:
  // { "xs": 0, "sm": 600, "md": 960, "lg": 1280, "xl": 1920 }
  // => use Reclamation theme breakpoints

  const smallScreen = useMediaQuery("(max-width:1280px)"); // md:  - 1280px
  const mobile = useMediaQuery("(max-width:960px)"); // xs & sm: 0 - 960px

  // On small screens, show button for toggling filters
  // On large screens, show filters always
  useEffect(() => {
    if (!smallScreen) {
      setShowFilters(true);
      onSizeChange(true);
    }
  }, [smallScreen, onSizeChange]);

  // On "mobile" screens, hide filters by default
  useEffect(() => {
    if (mobile) {
      setShowFilters(false);
      onSizeChange(false);
    }
  }, [mobile, onSizeChange]);

  const updateFiltersAndFetchCases = newFilters => {
    updateFilters(newFilters);
    fetchCases(newFilters);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
    onSizeChange(!showFilters);
  };

  const renderFilters = () => (
    <>
      <CaseTypeSelect
        id="case-filter-type"
        flexBasis="300px"
        flexGrow="1"
        flexShrink="1"
        maxWidth="500px"
        caseType={filters.caseType}
        onCaseTypeSelected={value =>
          updateFiltersAndFetchCases({ ...filters, caseType: value })
        }
      />
      <DaterangeSelect
        id="case-filter-daterange"
        flexBasis="360px"
        flexGrow="1"
        flexShrink="0"
        maxWidth="500px"
        startDate={filters.startDate}
        endDate={filters.endDate}
        onDatesSelected={(startDate, endDate) =>
          updateFiltersAndFetchCases({ ...filters, startDate, endDate })
        }
      />
      {userIsOriolaUser && (
        <HandlerSelect
          id="case-filter-handler"
          flexBasis="400px"
          flexGrow="1"
          flexShrink="3"
          maxWidth="300px"
          handler={filters.subQueue ? filters.subQueue : filters.handler}
          onHandlerSelected={(handler, subQueue) =>
            updateFiltersAndFetchCases({ ...filters, handler, subQueue })
          }
        />
      )}
      {(userIsOriolaUser || userIsPrincipalUser) && (
        <ProductDetailsSelect
          id="case-filter-product-details"
          flexBasis="150px"
          flexGrow="1"
          flexShrink="3"
          maxWidth="300px"
          productDetail={filters.productDetail}
          onProductDetailsSelect={value =>
            updateFiltersAndFetchCases({ ...filters, productDetail: value })
          }
          isPrincipalUser={userIsPrincipalUser}
        />
      )}
      {userIsOriolaUser && (
        <PrincipalAndSupplierFilter
          onFiltersSelected={(profitCenters, supplierNumbers) => {
            updateFiltersAndFetchCases({
              ...filters,
              profitCenters,
              supplierNumbers,
            });
          }}
          currentProfitCenterFilters={filters.profitCenters}
          currentSupplierFilters={filters.supplierNumbers}
        />
      )}
      <SortSelect
        id="case-filter-sort"
        flexBasis="300px"
        flexGrow="1"
        flexShrink="3"
        maxWidth="300px"
        sortType={filters.sortType}
        onSortTypeSelected={value => {
          updateFiltersAndFetchCases({ ...filters, sortType: value });
        }}
      />
    </>
  );

  const displayFilterToggle = showFilterToggle || smallScreen;

  return (
    <Box id="casesFilters" className={classes.filterBox}>
      <Box
        display="flex"
        gridGap="16px"
        width={displayFilterToggle ? "100%" : "auto"}
      >
        <CaseStatusButtonGroup
          id="case-filter-status"
          caseStatus={filters.caseStatus}
          caseSubStatus={filters.caseSubStatus}
          onCaseStatusSelected={(caseStatus, caseSubStatus) => {
            updateFiltersAndFetchCases({
              ...filters,
              caseStatus,
              caseSubStatus,
            });
          }}
          userIsOriolaUser={userIsOriolaUser}
        />
        {displayFilterToggle && (
          <Button
            data-testid="show-filters-toggle"
            sx={{ whiteSpace: "nowrap" }}
            onClick={toggleFilters}
          >
            {showFilters ? t("hideFilters") : t("showFilters")}
          </Button>
        )}
      </Box>
      {(showFilters || !smallScreen) && renderFilters()}
    </Box>
  );
}

CaseFilters.propTypes = {
  updateFilters: PropTypes.func.isRequired,
  fetchCases: PropTypes.func.isRequired,
  onSizeChange: PropTypes.func.isRequired,
  showFilterToggle: PropTypes.bool,
};

CaseFilters.defaultProps = {
  showFilterToggle: false,
};

export default CaseFilters;
