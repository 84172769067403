import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputAdornment,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
// eslint-disable-next-line import/no-cycle
import { FieldTitle } from "..";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";

const useStyles = makeStyles(theme => ({
  rowContainer: {
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  adornment: {
    height: theme.spacing(5),
    maxHeight: "fit-content",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    backgroundColor: "#F5F4F3",
    borderLeft: " #C4C4C4 1px solid",
  },
  adornmentText: {
    color: "#2b3e5b",
  },
  adornedEnd: {
    paddingRight: 0,
  },
  errorText: {
    fontSize: "1rem",
    color: theme.palette.error.main,
  },
}));

function PriceInput({
  itemData,
  config,
  error,
  fieldName,
  id,
  updateItem,
  updateItemValidationError,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <div>
      <FieldTitle
        title={t(`caseItemFields.${fieldName}`)}
        optional={config.optional}
        type="selection"
      />
      <OutlinedInput
        id={`${id}-standard-adornment-weight-${fieldName}`}
        value={itemData[fieldName]}
        error={!!error}
        classes={{
          adornedEnd: classes.adornedEnd,
        }}
        margin="dense"
        onChange={event => {
          const { value } = event.target;
          dispatch(
            updateItemValidationError(itemData.uuid, { [fieldName]: "" })
          );
          dispatch(
            updateItem({
              ...itemData,
              [fieldName]: value,
            })
          );
        }}
        endAdornment={
          <InputAdornment className={classes.adornment} position="end">
            {" "}
            <p className={classes.adornmentText}>
              {t("caseItemFields.priceUnit")}
            </p>
          </InputAdornment>
        }
        aria-describedby="standard-weight-helper-text"
        inputProps={{
          "aria-label": "weight",
        }}
      />
      {!!error && (
        <FormHelperText className={classes.errorText} error>
          {getValidationErrorMessage(error, t)}
        </FormHelperText>
      )}
    </div>
  );
}

PriceInput.propTypes = {
  classes: PropTypes.shape({}),
  itemData: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  config: PropTypes.shape({
    optional: PropTypes.bool,
  }),
  error: PropTypes.string,
  fieldName: PropTypes.string,
  id: PropTypes.string,
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
};

PriceInput.defaultProps = {
  classes: {},
  itemData: {},
  config: {},
  error: "",
  fieldName: "",
  id: "",
  updateItem: null,
  updateItemValidationError: null,
};

export default PriceInput;
