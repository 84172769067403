import moment from "moment";

const objectPath = require("object-path");

export const getSpdModificationData = (modifications, objectPathStr) => {
  if (modifications == null || objectPathStr == null) {
    return null;
  }
  return objectPath.get(modifications, objectPathStr);
};

export const getFieldModificationData = user => ({
  userName: user.name,
  modificationTime: moment().toISOString(),
  isLocalChange: true,
});
