import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Radio,
  Typography,
  FormControl,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  titleText: {
    ...theme.typography.subtitle2,
    color: "#757070",
    marginBottom: theme.spacing(1),
  },
  label: {
    fontSize: "0.875rem",
  },
  formControlLabel: {
    marginRight: theme.spacing(6),
  },
  errorText: {
    fontSize: "1rem",
    color: theme.palette.error.main,
  },
}));

export const YesNoSelectionValues = Object.freeze({
  Yes: "yes",
  No: "no",
});

export const getYesNoSelectValues = value => {
  if (value == null) {
    return "";
  }
  return value === true ? YesNoSelectionValues.Yes : YesNoSelectionValues.No;
};

function YesNoSelection({
  error,
  errorText,
  disabled,
  title,
  value,
  onValueChanged,
  id,
  className,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControl
      className={className}
      error={error}
      disabled={disabled}
      component="fieldset"
    >
      <Typography className={`${classes.titleText}`} noWrap>
        {title}
      </Typography>
      <RadioGroup value={value} onChange={onValueChanged} row id={id}>
        <FormControlLabel
          value={YesNoSelectionValues.Yes}
          control={
            <Radio
              checked={value === YesNoSelectionValues.Yes}
              classes={{ root: classes.radioIcon }}
            />
          }
          label={<Typography className={classes.label}>{t("yes")}</Typography>}
          className={classes.formControlLabel}
        />
        <FormControlLabel
          value={YesNoSelectionValues.No}
          control={
            <Radio
              checked={value === YesNoSelectionValues.No}
              classes={{ root: classes.root }}
            />
          }
          label={<Typography className={classes.label}>{t("no")}</Typography>}
        />
      </RadioGroup>
      <FormHelperText name="returnProductError" className={classes.errorText}>
        {errorText}
      </FormHelperText>
    </FormControl>
  );
}

YesNoSelection.propTypes = {
  error: PropTypes.bool,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string,
  onValueChanged: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
};

YesNoSelection.defaultProps = {
  error: false,
  errorText: null,
  disabled: false,
  title: null,
  value: null,
  onValueChanged: null,
  id: null,
  className: null,
};

export default YesNoSelection;
