import React from "react";
import { Checkbox, FormControlLabel } from "@oriola-origo/origo-ui-core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import DateAndEditor from "./dateAndEditor";

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: "14.2px",
  },
  checkBox: {
    paddingRight: "3px",
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

function CheckBoxWithLabel({
  onChecked,
  checked,
  user,
  title,
  fieldName,
  modificationData,
  ...containerProps
}) {
  const classes = useStyles();

  return (
    <Box {...containerProps}>
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkBox}
            name={fieldName}
            onChange={e => {
              onChecked(e);
            }}
            checked={checked || false}
            value="check"
          />
        }
        label={
          <Box pt={checked ? "14px" : ""} className={classes.label}>
            {title}
            {checked && (
              <DateAndEditor modificationData={modificationData} user={user} />
            )}
          </Box>
        }
      />
    </Box>
  );
}

CheckBoxWithLabel.propTypes = {
  onChecked: PropTypes.func,
  checked: PropTypes.bool,
  user: PropTypes.shape({}),
  title: PropTypes.string,
  fieldName: PropTypes.string,
  modificationData: PropTypes.shape({}),
};

CheckBoxWithLabel.defaultProps = {
  onChecked: null,
  checked: false,
  user: {},
  title: "",
  fieldName: "",
  modificationData: {},
};

export default CheckBoxWithLabel;
