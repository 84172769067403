import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import MessageAction from "./messageAction";

const useStyles = makeStyles(theme => ({
  text: {
    ...theme.typography.body2,
    color: OriolaColors.secondaryDarkGray,
    marginRight: theme.spacing(0.5),
  },
  bold: {
    ...theme.typography.body2,
    color: OriolaColors.secondaryDarkGray,
    marginRight: theme.spacing(0.5),
    fontWeight: "bold",
  },
}));

function NotificationSent({ message }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { creationTime, recipient } = message;
  const date = moment(creationTime).format("DD.MM.YYYY, HH:mm");

  return (
    <MessageAction message={message} additionalMessage="">
      <Typography noWrap className={classes.bold}>
        {t("notificationSent").toUpperCase()}
        &nbsp;
        {!!recipient && (
          <>
            {t(
              `notificationSentRecipient.${recipient.type}`
            ).toLocaleUpperCase()}
            &nbsp;
          </>
        )}
      </Typography>
      <Typography noWrap className={classes.text}>
        {date}
      </Typography>
    </MessageAction>
  );
}

NotificationSent.propTypes = {
  message: PropTypes.shape({
    creationTime: PropTypes.string,
    recipient: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
};

NotificationSent.defaultProps = {};

export default NotificationSent;
