import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Box from "@oriola-origo/core/lib/Box";
import ODSTypography from "@oriola-origo/core/lib/Typography";
import SvgIcon from "@oriola-origo/core/lib/Icons/SvgIcon";
import { OriolaColors } from "@oriola-origo/origo-ui-core";

const useStyles = makeStyles(theme => ({
  badge: {
    display: "flex",
    borderRadius: 4,
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    backgroundColor: OriolaColors.primaryLightest,
    border: `1px solid ${OriolaColors.primaryLighter}`,
    color: theme.palette.primary.main,
    alignItems: "center",
  },
}));

function RowNote({ fontSize, text, iconSize }) {
  const classes = useStyles();

  const vb = `0 0 ${iconSize} ${iconSize}`;

  return (
    <Box id="detail-row-note" className={classes.badge}>
      <Box display="flex" sx={{ alignItems: "center", paddingRight: "4px" }}>
        <SvgIcon
          fontSize="small"
          titleAccess={text}
          viewBox={vb}
          sx={{ width: `${iconSize}px`, height: `${iconSize}px` }}
        >
          <path
            d="M1.33333 13.8C0.966667 13.8 0.652667 13.6693 0.391333 13.408C0.130444 13.1471 0 12.8333 0 12.4666V3.1333C0 2.76664 0.130444 2.45264 0.391333 2.19131C0.652667 1.93042 0.966667 1.79997 1.33333 1.79997H7.28333L5.95 3.1333H1.33333V12.4666H10.6667V7.8333L12 6.49997V12.4666C12 12.8333 11.8696 13.1471 11.6087 13.408C11.3473 13.6693 11.0333 13.8 10.6667 13.8H1.33333ZM8.78333 2.18331L9.73333 3.11664L5.33333 7.51664V8.46664H6.26667L10.6833 4.04997L11.6333 4.9833L6.83333 9.79997H4V6.96664L8.78333 2.18331ZM11.6333 4.9833L8.78333 2.18331L10.45 0.516638C10.7167 0.249972 11.0362 0.116638 11.4087 0.116638C11.7807 0.116638 12.0944 0.249972 12.35 0.516638L13.2833 1.46664C13.5389 1.72219 13.6667 2.0333 13.6667 2.39997C13.6667 2.76664 13.5389 3.07775 13.2833 3.3333L11.6333 4.9833Z"
            fill="#FFA000"
          />
        </SvgIcon>
      </Box>
      <ODSTypography
        sx={{
          fontSize: { fontSize },
          fontWeight: 700,
          color: OriolaColors.primaryOrange,
          fontFamily: "verdana",
        }}
      >
        {text}
      </ODSTypography>
    </Box>
  );
}

RowNote.propTypes = {
  fontSize: PropTypes.string,
  text: PropTypes.string,
  iconSize: PropTypes.number,
};

RowNote.defaultProps = {
  fontSize: "12px",
  text: null,
  iconSize: 14,
};

export default RowNote;
