import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
import { useDispatch, useSelector } from "react-redux";
import { Badge, RadioButtonSelection, TitleInput } from "../../common";
// eslint-disable-next-line import/no-cycle
import {
  updateLocalHandlingDataField,
  updateValidationError,
} from "../../../redux";
import DateAndEditor from "../dateAndEditor";
import ReturnToPrincipal from "./returnToPrincipal";
import { getValidationErrorMessage } from "../../../utils/validations/validations";
import { getSpdModificationData } from "../../../utils/getSpdModificationData/getSpdModificationData";
import CheckBoxWithLabel from "../checkBoxWithLabel";
import { isOriolaUser } from "../../auth/permission";
import Title from "../title/title";
import ResetButton from "../../common/resetButton/resetButton";
import PhotographRequest from "./photographRequest/photographRequest";

const useStyles = makeStyles(theme => ({
  greyText14: {
    ...theme.typography.button,
    textTransform: "none",
    fontWeight: "400",
    color: OriolaColors.secondaryDarkGray,
  },
  italic: {
    fontStyle: "italic",
  },
  input: {
    backgroundColor: "#f4f3f2",
  },
  titleAndResetButton: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const ProductDisposers = Object.freeze({
  ORIOLA: "ORIOLA",
  CUSTOMER: "CUSTOMER",
});

function ProductHandlingInstructions({
  user,
  disabled,
  currentUserIsPharmaceuticalCompanyEditRole,
  ...containerProps
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { localHandlingData = {} } = useSelector(state => state.case);
  const {
    spdCertificationOfDisposalValueRequired,
    spdMethodOfDisposalRequired,
  } = useSelector(state => state.validationErrors);

  const {
    productHandlingInstructions = {},
    modifications = {},
    isHandlingInstructionsReadConfirmed,
  } = localHandlingData;
  const {
    methodOfDisposal = "",
    handlingInstructions = "",
    isCertificateOfDestructionRequired,
  } = productHandlingInstructions;

  const setProductDisposerValue = updatedValue => {
    // clear validation errors
    dispatch(updateValidationError({ spdMethodOfDisposalRequired: null }));

    dispatch(
      updateLocalHandlingDataField(
        updatedValue,
        "productHandlingInstructions",
        "methodOfDisposal"
      )
    );

    const customerDisposes = updatedValue === ProductDisposers.CUSTOMER;
    if (customerDisposes === false) {
      dispatch(
        updateLocalHandlingDataField(
          isCertificateOfDestructionRequired,
          "productHandlingInstructions",
          "isCertificateOfDestructionRequired"
        )
      );
    }
  };

  const getCertificationOfDesctructionNeededValue = () => {
    if (isCertificateOfDestructionRequired == null) {
      return "";
    }
    return isCertificateOfDestructionRequired === true ? "true" : "false";
  };

  const setCertificationOfDesctructionNeededValue = updatedValue => {
    // clear validation errors
    dispatch(
      updateValidationError({ spdCertificationOfDisposalValueRequired: null })
    );

    const booleanValue = updatedValue === "true";
    dispatch(
      updateLocalHandlingDataField(
        booleanValue,
        "productHandlingInstructions",
        "isCertificateOfDestructionRequired"
      )
    );
  };

  const setHandlingInstructions = textValue => {
    dispatch(
      updateLocalHandlingDataField(
        textValue,
        "productHandlingInstructions",
        "handlingInstructions"
      )
    );
  };

  const resetProductDisposerValue = () => {
    // clear validation errors
    dispatch(updateValidationError({ spdMethodOfDisposalRequired: null }));

    dispatch(
      updateLocalHandlingDataField(
        null,
        "productHandlingInstructions",
        "methodOfDisposal"
      )
    );

    dispatch(
      updateLocalHandlingDataField(
        null,
        "productHandlingInstructions",
        "isCertificateOfDestructionRequired"
      )
    );
  };

  const setHandlingInstructionsReadConfirmantion = value => {
    dispatch(
      updateLocalHandlingDataField(value, "isHandlingInstructionsReadConfirmed")
    );
  };

  const certificationOfDestructionValue =
    getCertificationOfDesctructionNeededValue();

  const getModificationData = field =>
    getSpdModificationData(
      modifications,
      `productHandlingInstructions.${field}`
    );

  const hasModificationData = field => !!getModificationData(field);

  return (
    <Box
      id="product-handling-instructions"
      className="handling-section"
      {...containerProps}
    >
      <div className={classes.titleAndResetButton}>
        <Title title={t("productHandlingInstructions.title")} />
        {currentUserIsPharmaceuticalCompanyEditRole && (
          <ResetButton
            onClick={resetProductDisposerValue}
            disabled={!methodOfDisposal}
          />
        )}
      </div>
      <ReturnToPrincipal
        my={3}
        user={user}
        productHandlingInstructions={productHandlingInstructions}
        modifications={modifications}
        disabled={disabled}
      />
      <PhotographRequest
        my={3}
        user={user}
        productHandlingInstructions={productHandlingInstructions}
        modifications={modifications}
        disabled={disabled}
      />
      <RadioButtonSelection
        disabled={disabled}
        value={methodOfDisposal}
        button1Value={ProductDisposers.ORIOLA}
        button2Value={ProductDisposers.CUSTOMER}
        onChecked={setProductDisposerValue}
        title={t("productHandlingInstructions.disposeTitle")}
        button1LabelText={t("productHandlingInstructions.oriolaWillDispose")}
        button2LabelText={t("productHandlingInstructions.customerWillDispose")}
        button1LabelChildren={
          methodOfDisposal === ProductDisposers.ORIOLA ? (
            <DateAndEditor
              id="productHandlingInstructions-date-editor-oriola-disposes"
              modificationData={getModificationData("methodOfDisposal")}
              user={user}
            />
          ) : null
        }
        button2LabelChildren={
          methodOfDisposal === ProductDisposers.CUSTOMER ? (
            <DateAndEditor
              id="productHandlingInstructions-date-editor-customer-disposes"
              modificationData={getModificationData("methodOfDisposal")}
              user={user}
            />
          ) : null
        }
        error={spdMethodOfDisposalRequired != null}
        helperText={getValidationErrorMessage(spdMethodOfDisposalRequired, t)}
      />
      {methodOfDisposal === ProductDisposers.ORIOLA && (
        <Box mx={4} mt={2}>
          <RadioButtonSelection
            disabled={disabled}
            id="certification-of-destruction"
            value={certificationOfDestructionValue}
            button1Value="true"
            button2Value="false"
            onChecked={setCertificationOfDesctructionNeededValue}
            title={t(
              "productHandlingInstructions.certificateOfDestructionNeeded"
            )}
            button1LabelText={t("productHandlingInstructions.yes")}
            button2LabelText={t("productHandlingInstructions.no")}
            button1LabelChildren={
              isCertificateOfDestructionRequired === true ? (
                <DateAndEditor
                  id="productHandlingInstructions-date-editor-certificate-yes"
                  modificationData={getModificationData(
                    "isCertificateOfDestructionRequired"
                  )}
                  user={user}
                />
              ) : null
            }
            button2LabelChildren={
              isCertificateOfDestructionRequired === false ? (
                <DateAndEditor
                  id="productHandlingInstructions-date-editor-certificate-no"
                  modificationData={getModificationData(
                    "isCertificateOfDestructionRequired"
                  )}
                  user={user}
                />
              ) : null
            }
            error={spdCertificationOfDisposalValueRequired != null}
            helperText={getValidationErrorMessage(
              spdCertificationOfDisposalValueRequired,
              t
            )}
          />
        </Box>
      )}
      <Box mt={2}>
        <TitleInput
          disabled={disabled}
          title={t("productHandlingInstructions.handlingInstructions")}
          secondaryTitle={t("optional")}
          inputClassName={disabled ? classes.input : null}
          value={handlingInstructions}
          onChange={setHandlingInstructions}
          rows={4}
          placeholder={t("productHandlingInstructions.instructionsPlaceholder")}
          enableCopyButton
          id="product-handling-instructions-handling-instructions"
        />
      </Box>
      {hasModificationData("handlingInstructions") && (
        <DateAndEditor
          id="productHandlingInstructions-date-editor-handlingInstructions"
          modificationData={getModificationData("handlingInstructions")}
          user={user}
        />
      )}
      {handlingInstructions && isOriolaUser(user) && (
        <Box position="relative">
          <Badge
            text={t("oriola")}
            sx={{ position: "absolute", right: 0, top: 5 }}
          />
          <CheckBoxWithLabel
            key="isHandlingInstructionsReadConfirmed"
            title={t("readConfirmation")}
            fieldName="isHandlingInstructionsReadConfirmed"
            modificationData={getSpdModificationData(
              modifications,
              "isHandlingInstructionsReadConfirmed"
            )}
            onChecked={e => {
              setHandlingInstructionsReadConfirmantion(e.target.checked);
            }}
            checked={isHandlingInstructionsReadConfirmed}
            user={user}
          />
        </Box>
      )}
    </Box>
  );
}

ProductHandlingInstructions.propTypes = {
  user: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  currentUserIsPharmaceuticalCompanyEditRole: PropTypes.bool,
};

ProductHandlingInstructions.defaultProps = {
  disabled: false,
  currentUserIsPharmaceuticalCompanyEditRole: false,
};

export default ProductHandlingInstructions;
