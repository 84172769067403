import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@material-ui/core";
import { WarningMessage } from "@oriola-origo/origo-ui-core";
// eslint-disable-next-line import/no-cycle
import { updateCase, updateValidationError } from "../../../../redux";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";
import { YesNoSelection, YesNoSelectionValues } from "../../../common";

function SuspectedInfectiousRadio({ disabled, ...containerProps }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { suspectedInfectiousProduct } = useSelector(state => state.case.case);
  const { suspectedInfectiousProductError } = useSelector(
    state => state.validationErrors
  );

  const getSuspectedInfectiousProductValue = () => {
    if (suspectedInfectiousProduct == null) {
      return "";
    }
    return suspectedInfectiousProduct === true
      ? YesNoSelectionValues.Yes
      : YesNoSelectionValues.No;
  };

  return (
    <>
      <Box
        {...containerProps}
        data-error-name="suspectedInfectiousProductError"
      >
        <YesNoSelection
          id="new-case-suspected-infectious-product"
          disabled={disabled}
          title={t("returnOfSuspectedInfectiousProduct.radioQuestion")}
          error={!!suspectedInfectiousProductError}
          errorText={getValidationErrorMessage(
            suspectedInfectiousProductError,
            t
          )}
          value={getSuspectedInfectiousProductValue()}
          onValueChanged={event => {
            const value = event.target.value === YesNoSelectionValues.Yes;
            dispatch(
              updateValidationError({ suspectedInfectiousProductError: "" })
            );
            dispatch(updateCase({ suspectedInfectiousProduct: value }));
          }}
        />
      </Box>
      {suspectedInfectiousProduct === true && (
        <Box mb={2} width="602px">
          <WarningMessage
            text={t("returnOfSuspectedInfectiousProduct.warning")}
            type="warning"
          />
        </Box>
      )}
    </>
  );
}

SuspectedInfectiousRadio.propTypes = {
  disabled: PropTypes.bool,
  hideConfirm: PropTypes.bool,
};

SuspectedInfectiousRadio.defaultProps = {
  disabled: false,
  hideConfirm: false,
};

SuspectedInfectiousRadio.displayName = "SuspectedInfectiousRadio";

export default SuspectedInfectiousRadio;
