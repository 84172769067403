import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Box, Tabs, Tab } from "@material-ui/core";
import SendMessage, { SendMessageMode } from "./sendMessage";
import MessageList from "./messageList";
import NoteList from "./noteList";
import { sendNewEvent } from "../../../redux";
import { isOriolaUser } from "../../auth/permission";
import { CaseStatus } from "../../../constants/caseStatus";
import { Permission, Can } from "../../auth";
import {
  handleOptimisticLockError,
  isOptimisticLockError,
} from "../../../utils/optimisticLock/optimisticLock";
import EventType from "../../../constants/eventType";
import { CaseType } from "../../../constants/caseType";
import UserType from "../../../constants/userType";
import { getMessageCreatorIdentities } from "../../../utils/message";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F5F5F5",
  },
  tabs: {
    "& > div > div > .Mui-selected": {
      backgroundColor: "#F5F5F5",
      color: "#2B3E5B",
      fontWeight: "bold",
    },
  },
  tab: {
    color: "#938F8F",
    fontWeight: "bold",
  },
}));

const TabType = Object.freeze({
  Messages: 0,
  Notes: 1,
});

function Messages({ reclamationCase }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    messages,
    fetchingMessages,
    sendingMessage,
    messageSendError,
    updateMessageQueueList,
  } = useSelector(state => state.message);
  const { userData } = useSelector(state => state.user);
  const messageRef = useRef();
  const [value, setValue] = React.useState(TabType.Messages);
  const currentUserIsOriolaUser = isOriolaUser(userData);
  const caseSolved = reclamationCase.caseStatus === CaseStatus.SOLVED;
  const [attachments, setAttachments] = useState([]);

  const scrollToRef = ref =>
    window.scrollTo({
      top: ref.current.offsetTop - 200,
      behavior: "smooth",
    });

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (updateMessageQueueList === true) {
      scrollToRef(messageRef);
    }
  }, [updateMessageQueueList]);

  const handleSendMessageEvent = event => {
    const { reclamationId, version } = reclamationCase;
    dispatch(
      sendNewEvent(reclamationId, version, {
        ...event,
        attachments,
      })
    ).then(result => {
      if (isOptimisticLockError(result)) {
        handleOptimisticLockError(dispatch, t);
      }
      scrollToRef(messageRef);
      setAttachments([]);
    });
  };

  const isInternalMessage = message =>
    message.type === EventType.MESSAGE &&
    message.creator.type === UserType.ORIOLA &&
    (message.recipient || {}).type === UserType.ORIOLA;

  const getPublicMessages = () =>
    messages.filter(
      message =>
        // messages excluding internal messages
        message.type === EventType.MESSAGE &&
        isInternalMessage(message) === false
    );

  const getInternalMessages = () =>
    messages.filter(
      message =>
        // all other events + internal messages
        message.type !== EventType.MESSAGE ||
        isInternalMessage(message) === true
    );

  const onAttachmentsUpload = newAttachments => {
    setAttachments([...newAttachments]);
  };

  const isSpdCase =
    reclamationCase.caseType === CaseType.SUSPECTED_PRODUCT_DEFECT;
  return (
    <Box className={classes.root} pb={6}>
      <Box>
        <Box bgcolor="white">
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              style: { height: "0px", top: "35px" },
            }}
          >
            <Tab className={classes.tab} label={t("messages")} />
            {currentUserIsOriolaUser && (
              <Tab className={classes.tab} label={t("notes")} />
            )}
          </Tabs>
        </Box>
        {value === TabType.Messages && (
          <Box ref={messageRef} pb={3} maxWidth="80%" margin="0 auto">
            <MessageList
              userData={userData}
              reclamationCase={reclamationCase}
              messages={getPublicMessages()}
              fetching={fetchingMessages}
              messageCreatorIdentities={getMessageCreatorIdentities(
                reclamationCase
              )}
            />
            <Can
              user={userData}
              perform={Permission.SEND_MESSAGE}
              customerContext={reclamationCase.customerId}
            >
              <SendMessage
                sendMessageMode={
                  isSpdCase
                    ? SendMessageMode.SELECT_RECIPIENT
                    : SendMessageMode.AUTOMATIC_RECIPIENT
                }
                reclamationCase={reclamationCase}
                userData={userData}
                onSendMessage={event => handleSendMessageEvent(event)}
                sending={sendingMessage}
                sendError={messageSendError}
                disabled={caseSolved && isSpdCase === false}
                attachments={attachments}
                onAttachmentsUpload={onAttachmentsUpload}
              />
            </Can>
          </Box>
        )}
        {value === TabType.Notes && (
          <Box ref={messageRef} pb={3} maxWidth="80%" margin="0 auto">
            <NoteList
              userData={userData}
              reclamationCase={reclamationCase}
              notes={getInternalMessages()}
              fetching={fetchingMessages}
            />
            <Can
              user={userData}
              perform={Permission.SEND_MESSAGE}
              customerContext={reclamationCase.customerId}
            >
              <SendMessage
                sendMessageMode={SendMessageMode.INTERNAL}
                reclamationCase={reclamationCase}
                userData={userData}
                onSendMessage={event => handleSendMessageEvent(event)}
                sending={sendingMessage}
                sendError={messageSendError}
                attachments={attachments}
                onAttachmentsUpload={onAttachmentsUpload}
                disabled={caseSolved && !currentUserIsOriolaUser}
              />
            </Can>
          </Box>
        )}
      </Box>
    </Box>
  );
}

Messages.propTypes = {
  reclamationCase: PropTypes.shape({
    caseType: PropTypes.string,
    caseStatus: PropTypes.string,
    customerId: PropTypes.string,
    reclamationId: PropTypes.string,
    version: PropTypes.string,
  }),
};

Messages.defaultProps = {
  reclamationCase: {},
};

export default Messages;
