import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, TextField, Box } from "@material-ui/core";
import { Button, OriolaColors, Progress } from "@oriola-origo/origo-ui-core";
import PropTypes from "prop-types";
import {
  getValidationErrorMessage,
  HANDLING_INFORMATION_MAX_LENGTH,
  FieldErrors,
} from "../../../utils/validations/validations";
import { updateValidationError } from "../../../redux";
import SelectMessageRecipient from "./selectMessageRecipient";
import { isOriolaUser } from "../../auth/permission";
import { getCustomerRecipient, getOriolaRecipient } from "./recipients";
import EventType from "../../../constants/eventType";
import UserType from "../../../constants/userType";
import Attachments from "../../fields/common/attachments/attachments";

const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: OriolaColors.White,
    "& .MuiFormHelperText-root": {
      background: "#f5f5f5",
      margin: 0,
    },
  },
  inputDisabled: {
    backgroundColor: theme.palette.background.disabled,
  },
  titleText: {
    ...theme.typography.subtitle2,
    color: "#757070",
    marginBottom: theme.spacing(1),
  },
  submitButton: {
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.2)",
    marginLeft: "auto",
    height: 40,
    position: "relative",
    top: 35,
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    textAlign: "right",
  },
}));

export const SendMessageMode = Object.freeze({
  AUTOMATIC_RECIPIENT: "AUTOMATIC_RECIPIENT",
  SELECT_RECIPIENT: "SELECT_RECIPIENT",
  INTERNAL: "INTERNAL",
});

function SendMessage({
  reclamationCase,
  userData,
  disabled,
  onSendMessage,
  sending,
  sendError,
  sendMessageMode,
  attachments,
  onAttachmentsUpload,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { messageError, messageRecipientError } = useSelector(
    state => state.validationErrors
  );
  const [typedMessage, setTypedMessage] = useState("");
  const currentUserIsOriolaUser = isOriolaUser(userData);
  const [messageRecipient, setMessageRecipient] = useState(null);
  const isSendModeInternal = sendMessageMode === SendMessageMode.INTERNAL;
  const [attachmentError, setAttachmentError] = useState(null);

  const handleInputChanged = event => {
    const { value } = event.target;
    const valueValid = value.length <= HANDLING_INFORMATION_MAX_LENGTH;
    const newMessageError = valueValid ? "" : FieldErrors.MESSAGE_OVER_LENGTH;
    const newValue = valueValid ? value : typedMessage;

    dispatch(updateValidationError({ messageError: newMessageError }));
    setTypedMessage(newValue);
  };

  const handleRecipientChanged = recipient => {
    dispatch(updateValidationError({ messageRecipientError: null }));
    setMessageRecipient(recipient);
  };

  const getMessageRecipient = () => {
    let recipient = null;
    if (sendMessageMode === SendMessageMode.AUTOMATIC_RECIPIENT) {
      if (currentUserIsOriolaUser) {
        recipient = getCustomerRecipient(reclamationCase);
      } else {
        recipient = getOriolaRecipient();
      }
    } else {
      recipient = messageRecipient;
    }
    return recipient;
  };

  const handleSendMessage = () => {
    if (typedMessage === "") {
      dispatch(
        updateValidationError({ messageError: FieldErrors.MESSAGE_MANDATORY })
      );
      return;
    }

    if (attachmentError) {
      return;
    }

    const messageEvent = {
      type: EventType.MESSAGE,
      message: typedMessage,
    };

    if (isSendModeInternal === false) {
      const recipient = getMessageRecipient();
      if (recipient == null) {
        dispatch(
          updateValidationError({
            messageRecipientError: FieldErrors.MESSAGE_RECIPIENT_MANDATORY,
          })
        );
        return;
      }

      // update
      messageEvent.recipient = { type: recipient.type };
    } else {
      messageEvent.recipient = { type: UserType.ORIOLA };
    }

    onSendMessage(messageEvent);
    setTypedMessage("");
    setMessageRecipient(null);
  };

  let titleText;
  if (sendMessageMode === SendMessageMode.AUTOMATIC_RECIPIENT) {
    titleText =
      currentUserIsOriolaUser === true
        ? t("message.sendToCustomer")
        : t("message.sendToOriola");
  } else {
    titleText = isSendModeInternal
      ? t("message.sendInternalMessage")
      : t("message.sendMessage");
  }

  return (
    <div className="no-print">
      <Box>
        <Typography className={classes.titleText} noWrap>
          {titleText}
        </Typography>
        {sendMessageMode === SendMessageMode.SELECT_RECIPIENT && (
          <Box width="60%">
            <SelectMessageRecipient
              messageRecipient={messageRecipient}
              onMessageRecipientChanged={recipient =>
                handleRecipientChanged(recipient)
              }
              reclamationCase={reclamationCase}
              userData={userData}
              errorText={
                messageRecipientError
                  ? getValidationErrorMessage(messageRecipientError, t)
                  : null
              }
            />
          </Box>
        )}
        <Box>
          <TextField
            id="message-input"
            className={disabled ? classes.inputDisabled : classes.input}
            fullWidth
            multiline
            value={typedMessage}
            variant="outlined"
            placeholder={t("message.placeholder")}
            margin="dense"
            error={!!messageError}
            helperText={getValidationErrorMessage(messageError, t)}
            onChange={event => handleInputChanged(event)}
            disabled={disabled}
          />
        </Box>
        <Box display="flex" alignItems="flex-start">
          <Attachments
            existingAttachments={attachments}
            onAttachmentsUpload={onAttachmentsUpload}
            disabled={disabled}
            showTitle={false}
            padding={1}
            flexDirection="row"
            attachmentError={attachmentError}
            setAttachmentError={setAttachmentError}
            reclamationCase={reclamationCase}
          />
          <Button
            variant="primary"
            className={classes.submitButton}
            onClick={() => handleSendMessage()}
            id="submit-message"
            disabled={disabled || sending === true}
          >
            <Box display="flex" alignItems="center">
              {sending === true && <Progress show size={20} pr={2} />}
              {sending === false ? t("message.send") : t("message.sending")}
            </Box>
          </Button>
        </Box>

        {sendError != null && (
          <Typography className={classes.errorText}>
            {t("message.sendingFailed")}
          </Typography>
        )}
      </Box>
    </div>
  );
}

SendMessage.propTypes = {
  reclamationCase: PropTypes.shape({}).isRequired,
  userData: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  onSendMessage: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  sendError: PropTypes.shape({}),
  sendMessageMode: PropTypes.string,
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  onAttachmentsUpload: PropTypes.func,
};

SendMessage.defaultProps = {
  disabled: false,
  sendError: null,
  sendMessageMode: SendMessageMode.AUTOMATIC_RECIPIENT,
  attachments: [],
  onAttachmentsUpload: null,
};

export default SendMessage;
