/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Box from "@oriola-origo/core/lib/Box";
import Typography from "@oriola-origo/core/lib/Typography";
import { Popper } from "@material-ui/core";
import { styled } from "@oriola-origo/core/lib/styles";
import {
  NoProductOrRelevantFound,
  LinkToDialog,
} from "./styledProductSearchComponents";

const StyledPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-noOptions": {
    display: "none",
  },
  "& .MuiAutocomplete-loading": {
    background: theme.palette.common.white,
  },
}));

export const renderProductSearchHelper = (
  setOpenMissingProductDialog: (value: boolean) => void,
  switchProductComponent: (value: boolean) => void,
  t: (key: string) => string,
  translationKey: string
) => (
  <NoProductOrRelevantFound>
    <Typography variant="body1" color="secondary">
      {t(translationKey)}
    </Typography>
    <LinkToDialog
      onMouseDown={event => {
        event.preventDefault();
        event.stopPropagation();
        setOpenMissingProductDialog(true);
        switchProductComponent(true);
      }}
    >
      {t("addMissingProduct.here")}
    </LinkToDialog>
  </NoProductOrRelevantFound>
);

const renderProductSearchHelperWithCondition = (
  data: Array<any>,
  setOpenMissingProductDialog: (value: boolean) => void,
  switchProductComponent: (value: boolean) => void,
  t: (key: string) => string,
  input: string,
  selectedProduct: any,
  fetching: boolean
) => {
  if (!input || fetching || selectedProduct) return null;
  return renderProductSearchHelper(
    setOpenMissingProductDialog,
    switchProductComponent,
    t,
    data.length === 0
      ? "addMissingProduct.noResultFound"
      : "addMissingProduct.foundResultsNotRelevant"
  );
};

export const renderCustomPopper = (
  props: any,
  setOpenMissingProductDialog: (value: boolean) => void,
  switchProductComponent: (value: boolean) => void,
  t: (key: string) => string,
  input: string,
  selectedProduct: any,
  fetching: boolean
) => {
  const { children, ...other } = props;
  return (
    <StyledPopper {...other}>
      <Box>
        {children}
        {renderProductSearchHelperWithCondition(
          children.props.ownerState.options,
          setOpenMissingProductDialog,
          switchProductComponent,
          t,
          input,
          selectedProduct,
          fetching
        )}
      </Box>
    </StyledPopper>
  );
};

export const renderOptions = (params: any) => (
  <Box>
    <Typography id={params.id} variant="body1" color="textPrimary" {...params}>
      {params.key}
    </Typography>
  </Box>
);
