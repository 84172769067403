import moment from "moment";
import { getSpdModificationData } from "../../../../utils/getSpdModificationData/getSpdModificationData";

const getModifierStrOrNull = (condition, modifications, objectPath) => {
  if (condition === true) {
    const data = getSpdModificationData(modifications, objectPath);
    if (data != null && data.modificationTime != null) {
      const { modificationTime, userName } = data;
      return `(${moment(modificationTime).format("DD.MM.YYYY")} - ${userName})`;
    }
  }
  return null;
};

export default getModifierStrOrNull;
