import React from "react";
import PropTypes from "prop-types";
import { Button, IconTextLabel, Progress } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { fetchExportToExcelUrl, addNotification } from "../../redux";
import { ImportExcelIcon } from "../../images";

function ExportReport({ classes, filtersWithOrg }) {
  const { fetchingExcelReport } = useSelector(state => state.listCases);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const exportToExcel = () => {
    dispatch(fetchExportToExcelUrl(filtersWithOrg, i18n.language))
      .then(response => {
        window.open(response);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error("Error when importing the excel", error);
        dispatch(
          addNotification({
            title: t("exportToExcelFailed.title"),
            primaryText: t("exportToExcelFailed.description"),
            type: "error",
          })
        );
      });
  };
  return (
    <div className={classes.exportButtonWrapper}>
      {fetchingExcelReport ? (
        <Progress className={classes.exportLoader} show />
      ) : (
        <Button
          className={classes.exportButton}
          onClick={exportToExcel}
          id="import-excel-button"
        >
          <IconTextLabel
            icon={<ImportExcelIcon />}
            text={t("exportToExcel")}
            variant="body1"
          />
        </Button>
      )}
    </div>
  );
}

ExportReport.propTypes = {
  filtersWithOrg: PropTypes.shape({}),
  classes: PropTypes.shape({
    exportButtonWrapper: PropTypes.string,
    exportLoader: PropTypes.string,
    exportButton: PropTypes.string,
  }),
};

ExportReport.defaultProps = {
  filtersWithOrg: {},
  classes: {
    exportButtonWrapper: "",
    exportLoader: "",
    exportButton: "",
  },
};

export default ExportReport;
