const EventType = Object.freeze({
  MESSAGE: "MESSAGE",
  QUEUE_CHANGE: "QUEUE_CHANGE",
  CASE_UPDATE: "CASE_UPDATE",
  CREATE_LABELS: "CREATE_LABELS",
  REOPEN_CASE: "REOPEN_CASE",
  CASE_CREATED: "CASE_CREATED",
  CASE_CLOSED: "CASE_CLOSED",
  COPY_CASE: "COPY_CASE",
  NOTES: "NOTES",
});

module.exports = EventType;
