import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  columnValueText: {
    color: theme.palette.text.primary,
    width: "100%",
    ...theme.typography.button,
    textTransform: "none",
  },
}));

function CaseNumber({ singleCase, ...rest }) {
  const classes = useStyles();
  return (
    <div {...rest}>
      <Typography className={classes.columnValueText} color="textPrimary">
        {singleCase.caseNumber || "-"}
      </Typography>
    </div>
  );
}

CaseNumber.propTypes = {
  singleCase: PropTypes.shape({
    caseNumber: PropTypes.number,
  }),
};

CaseNumber.defaultProps = {
  singleCase: {},
};

export default CaseNumber;
