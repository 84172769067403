import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import DetailRow from "../detailRow";
import { Can, Permission, ANY_CUSTOMER } from "../../../auth";
import getModifierStrOrNull from "./getModifierStrOrNull";
import getValueIfNotNull from "./getValueIfNotNull";

function CompensationInformationView({
  compensationInformation,
  userData,
  modifications,
  ...containerProps
}) {
  const { t } = useTranslation();

  const { isCustomerCompensated, compensations = [] } = compensationInformation;

  return (
    <Box {...containerProps}>
      <Typography variant="subtitle2">
        {t("compensationInformation.title").toUpperCase()}
      </Typography>
      <Box mt={3}>
        <DetailRow
          keyName={t("compensationInformation.customerCompensation")}
          value={getValueIfNotNull(
            isCustomerCompensated,
            isCustomerCompensated
              ? t("compensationInformation.customerIsCompensated")
              : t("compensationInformation.customerIsNotCompensated")
          )}
          secondaryValue={getModifierStrOrNull(
            isCustomerCompensated != null,
            modifications,
            "compensationInformation.isCustomerCompensated"
          )}
        />
        <Can
          user={userData}
          perform={Permission.HANDLING_DATA_CUSTOMER_VIEW}
          customerContext={ANY_CUSTOMER}
        >
          {compensations.map((compensation, index) => {
            const {
              identifierCode,
              compensationNumber,
              compensationDate,
              handler,
            } = compensation;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`fragment-${index}`}>
                <Can
                  user={userData}
                  perform={Permission.HANDLING_DATA_ORIOLA_VIEW}
                  customerContext={ANY_CUSTOMER}
                >
                  <DetailRow
                    keyName={t("compensationInformation.reasonIdentifierCode")}
                    value={identifierCode}
                    secondaryValue={getModifierStrOrNull(
                      identifierCode != null,
                      modifications,
                      "compensationInformation.identifierCode"
                    )}
                  />
                </Can>
                <DetailRow
                  keyName={t("compensationInformation.number")}
                  value={compensationNumber}
                  secondaryValue={getModifierStrOrNull(
                    compensationNumber != null,
                    modifications,
                    "compensationInformation.compensationNumber"
                  )}
                />
                <DetailRow
                  keyName={t("compensationInformation.date")}
                  value={compensationDate}
                  secondaryValue={getModifierStrOrNull(
                    compensationDate != null,
                    modifications,
                    "compensationInformation.compensationDate"
                  )}
                />
                <Can
                  user={userData}
                  perform={Permission.HANDLING_DATA_ORIOLA_VIEW}
                  customerContext={ANY_CUSTOMER}
                >
                  <DetailRow
                    keyName={t("compensationInformation.handler")}
                    value={handler}
                    secondaryValue={getModifierStrOrNull(
                      handler != null,
                      modifications,
                      "compensationInformation.handler"
                    )}
                  />
                </Can>
              </Fragment>
            );
          })}
        </Can>
        <Can
          user={userData}
          perform={Permission.HANDLING_DATA_PRINCIPAL_VIEW}
          customerContext={ANY_CUSTOMER}
        >
          <DetailRow
            keyName={t("compensationInformation.title")}
            value={compensationInformation.compensationInformation}
            secondaryValue={getModifierStrOrNull(
              compensationInformation.compensationInformation != null,
              modifications,
              "compensationInformation.compensationInformation"
            )}
          />
        </Can>
      </Box>
    </Box>
  );
}

CompensationInformationView.propTypes = {
  compensationInformation: PropTypes.shape({
    isCustomerCompensated: PropTypes.bool,
    compensations: PropTypes.arrayOf(
      PropTypes.shape({
        identifierCode: PropTypes.string,
        compensationNumber: PropTypes.string,
        compensationDate: PropTypes.string,
        handler: PropTypes.string,
      })
    ),
    compensationInformation: PropTypes.string,
  }).isRequired,
  userData: PropTypes.shape({}).isRequired,
  modifications: PropTypes.shape({}),
};

CompensationInformationView.defaultProps = {
  modifications: null,
};

export default CompensationInformationView;
